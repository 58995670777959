import React, { ChangeEvent, useState } from "react";
import { FileUploadInputProps } from "Pages/Auth/Verification";
import { AiOutlineCloudUpload } from "react-icons/ai";

const FileUploadInput = ({ label, name, value, onChange }: FileUploadInputProps) => {
    const [fileName, setFileName] = useState<string>("");

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        onChange(file);

        if (file) {
            setFileName(file.name);
        } else {
            setFileName("");
        }
    };

    const inputId = `file-upload-input-${name}`;

    return (
        <div className="flex flex-col">
            <label htmlFor={inputId} className="mb-3 text-md font-bold text-gray-800 cursor-pointer">
                {label}
                <div className="flex items-center justify-start w-full h-24 mt-1 border-2 border-dashed rounded-lg">
                    <div className="flex items-center px-8">
                        <AiOutlineCloudUpload className="text-5xl text-gray-400" />
                        <p className="px-6 text-sm text-assetize-dark">
                            {fileName || (
                                <>
                                    Drop your document here or <span className="text-assetize-primary">Browse</span> to upload
                                    <br />
                                    <span className="text-[#92929D] text-xs">Supported file types: JPEG, PNG, PDF, Max file size: 2mb</span>
                                </>
                            )}
                        </p>
                    </div>
                    <input
                        type="file"
                        accept="image/jpeg, image/png, application/pdf"
                        id={inputId}
                        className="hidden"
                        onChange={handleFileUpload}
                    />
                </div>
            </label>
        </div>
    );
};

export default FileUploadInput;
