import { DeveloperType } from "types/Auth.types";
import ApiEndpoints from "./ApiRoutes";

export const capitalizeFirstLetter = (data: string) => {
    if (!data) return "";
    return data !== "" && typeof data !== "undefined" ? data.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()) : "";
};

export const formatDate = (date: Date): string => new Date(date).toLocaleDateString();

export const numberFormat = (number: number) => {
    if (!number) return 0;
    return new Intl.NumberFormat("en-EN").format(number);
};

export const readableDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
        timeZone: "Africa/Lagos"
    };

    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split(" ");
    return ` ${month} ${day} ${year}`;
};

export const fomatHeaderText = (str: string) => {
    const firstLetter = str.charAt(0)?.toUpperCase();
    const restOfString = str.slice(1);
    return `${firstLetter}${restOfString}`;
};

export const truncateText = (str: string, num: number): string => {
    if (str?.length > num) {
        return `${str.substring(0, num)}...`;
    }
    return str;
};

export const checkPasswordRequirements = (password: string) => {
    const hasSpecialChar = /[!@#$%^&*()_+[\]{};':"\\|,.<>?]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);

    if (hasSpecialChar && hasUppercase && hasLowercase && hasDigit) {
        return true;
    }

    return false;
};

export const kycEndpoint = (documentType: string): string => {
    switch (documentType) {
        case "Drivers License":
            return ApiEndpoints.VERIFYDRIVERSLICENSE;
        case "BVN":
            return ApiEndpoints.VERIFYBVN;
        case "NIN":
            return ApiEndpoints.VERIFYNIN;
        default:
            return ApiEndpoints.VERIFYPVC;
    }
};

export const navigateWithAccountType = (userAccountType: string, url: string) => {
    if (url === "admin" || url === "investor" || url === "developer" || url === "account-settings" || url === "notifications") {
        return url;
    }
    return `${userAccountType}/${url}`;
};

interface Option {
    name: string;
}

export const filterDataByDateRange = <T extends { createdAt: Date | string }>(data: T[], selectedRange: Option): T[] => {
    if (!selectedRange) {
        return data;
    }

    const selectedMonth = selectedRange.name.slice(0, 3);

    return data?.filter((item) => {
        const createdAtMonth =
            item.createdAt instanceof Date
                ? item.createdAt.toLocaleString("en-us", { month: "short" })
                : new Date(item.createdAt).toLocaleString("en-us", { month: "short" });

        return createdAtMonth === selectedMonth;
    });
};

export const filterDataByDateRangeWithUpdatedAt = <T extends { updatedAt: Date | string }>(data: T[], selectedRange: Option): T[] => {
    if (!selectedRange) {
        return data;
    }

    const selectedMonth = selectedRange.name.slice(0, 3);
    return data?.filter((item) => {
        const createdAtMonth =
            item?.updatedAt instanceof Date
                ? item.updatedAt.toLocaleString("en-us", { month: "short" })
                : new Date(item.updatedAt).toLocaleString("en-us", { month: "short" });

        return createdAtMonth === selectedMonth;
    });
};

export function sortByRange<T>(selectedOptions: string, data: T[], datePropertyName: keyof T): T[] {
    return selectedOptions === "All"
        ? data
        : data?.sort((a, b) => {
              const dateA = new Date(a[datePropertyName] as unknown as string);
              const dateB = new Date(b[datePropertyName] as unknown as string);

              if (selectedOptions === "Newest") {
                  // Sort in descending order
                  return dateB.getTime() - dateA.getTime();
              }
              return dateA.getTime() - dateB.getTime();
          });
}

export function sortByTransactionType<T>(selectedOptions: string, data: T[]): T[] {
    if (!selectedOptions) {
        return data;
    }

    return selectedOptions === "All"
        ? data
        : data.filter((item: any) => item.transactionType?.toLowerCase() === selectedOptions?.toLowerCase());
}

const getLastDateOfMonth = (date: Date) => {
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDate.getDate();
};

export const getShortCodeDate = () => {
    const currentDate = new Date();
    const shortMonth = currentDate.toLocaleString("en-us", { month: "short" });
    const lastDateOfMonth = getLastDateOfMonth(currentDate);
    const initialDateFilter = { name: `${shortMonth} 01 - ${shortMonth} ${lastDateOfMonth}` };
    return initialDateFilter;
};

export const searchArray = <T extends { [s: string]: any }>(data: T[], searchString: string): T[] => {
    if (!searchString) {
        return data;
    }

    const searchTerm = searchString.toLowerCase();

    return data?.filter((item) =>
        Object.values(item).some((value) => {
            if (typeof value === "string") {
                return value.toLowerCase().includes(searchTerm);
            }
            return false;
        })
    );
};

export const getDateAndTime = (date: Date) => {
    const dateTime = new Date(date);

    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();
    const second = dateTime.getSeconds();
    const fullMonth = dateTime.toLocaleString("en-US", { month: "long" });
    const amPm = dateTime.toLocaleString("en-US", { hour: "numeric", hour12: true });

    const dateString = `${day} ${fullMonth} ${year}`;
    const timeString = `${hour}:${minute} ${amPm}`;

    return { dateString, timeString };
};

export const getYearAndMonthAgo = (dateString: Date) => {
    const currentDate = new Date();
    const targetDate = new Date(dateString);

    const yearsDiff = targetDate.getFullYear() - currentDate.getFullYear();
    const monthsDiff = targetDate.getMonth() - currentDate.getMonth();

    const formattedDate = `${yearsDiff} years ${monthsDiff} months`;

    return formattedDate;
};

export const getTimeAgo = (dateString: Date) => {
    const currentDate = new Date();
    const targetDate = new Date(dateString);

    const timeDiff = Math.floor((currentDate.getTime() - targetDate.getTime()) / 1000);

    const seconds = timeDiff % 60;
    const minutes = Math.floor((timeDiff % 3600) / 60);
    const hours = Math.floor(timeDiff / 3600);
    const days = Math.floor(timeDiff / (3600 * 24));
    const months = Math.floor(timeDiff / (3600 * 24 * 30));
    const years = Math.floor(timeDiff / (3600 * 24 * 365));

    if (years > 0) {
        return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
    if (months > 0) {
        return `${months} ${months === 1 ? "month" : "months"} ago`;
    }
    if (days > 0) {
        return `${days} ${days === 1 ? "day" : "days"} ago`;
    }
    if (hours > 0) {
        return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    }
    if (minutes > 0) {
        return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    }
    if (seconds > 0) {
        return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
    }

    return "Just now";
};

export const getInitials = (name: string) => {
    const initialOne = name?.split(" ")[0];
    const initialTwo = name?.split(" ")[1];

    return initialOne[0] + initialTwo[0];
};

export const capitalizeText = (str: string) => {
    if (typeof str !== "string" || str.length === 0) return str;

    const firstChar = str.charAt(0)?.toUpperCase();
    const restOfString = str.slice(1)?.toLowerCase();

    return firstChar + restOfString;
};

export function convertToSentence(inputString: string): string {
    const convertWord = inputString[0].toUpperCase();
    const remainingLetters = inputString.slice(1, inputString.length);
    const converted = convertWord + remainingLetters;

    const words: string[] = converted.match(/[A-Z][a-z]*/g) || [];

    if (!words) {
        return inputString;
    }

    const sentence: string = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

    return sentence;
}

export const getErrorMessge = (error: any) => {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return message;
};

export const formatAmountWithCommas = (inputValue: string) => {
    const unformattedValue = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedValue;
};

export const formatAmountToNumber = (inputValue: string): number => {
    const numericAmount = parseFloat(inputValue.replace(/,/g, ""));

    return numericAmount;
};
