/* eslint-disable react/react-in-jsx-scope */
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import Pagination from "Components/elements/Pagination";
import { GetTransactionHistoryApi, GetWalletUsdApi, getCardTransactionStatusApi } from "Services";
import React, { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import { Option, WalletTransactionProps, Walletprops, dateFilter, transactionFilter } from "types/data";
import TransactionCard from "./TransactionCard";
import { useParams, useSearchParams } from "react-router-dom";
import { Modal } from "rsuite";
import ModalPanel from "Components/elements/Modal/Index";
import { getErrorMessge } from "Utils/Helpers";
import { toast } from "react-toastify";

const Transactions = () => {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState<boolean>(true);
    const [usdWallets, setUsdWallets] = useState<Walletprops>({
        walletId: 0,
        walletAddress: "",
        type: "",
        userId: 0,
        signedHash: "",
        balance: 0,
        tierLevel: null,
        createdAt: "",
        updatedAt: "",
        totalBalance: 0
    });

    const walletKeys = Object.keys(usdWallets) as (keyof Walletprops)[];
    const [walletActions, setWalletActons] = useState<Record<string, boolean>>({});
    const [cardPaymentFeedback, setCardPaymentFeedback] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [transactionHistory, setTransactionHistory] = useState<WalletTransactionProps[]>([]);
    const [selectedOptions, setSelectedOptions] = useState({
        transactionFilter: { name: "All" },
        dateFilter: { name: "Jan 01 - Jan 31" }
    });
    const handleWalletAction = (action: string) => {
        setWalletActons({ ...walletActions, [action]: true });
    };

    const closeWalletAction = () => {
        setWalletActons({});
    };

    const closeCardPaymentFeedbackAction = () => {
        setIsModalOpen(false);
    };

    const handleContinue = () => {
        setIsModalOpen(false);
        setCardPaymentFeedback("");
    };

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    const getWalletTransactions = async () => {
        try {
            const response = await Promise.all([GetTransactionHistoryApi()]);

            const transactionHistoryData = response[0];

            setTransactionHistory(transactionHistoryData);
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        } finally {
            setLoading(false);
        }
    };

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getWalletTransactions();
    }, []);

    return (
        <div>
            <div>
                {loading ? (
                    <div className="flex items-center justify-center mt-64">
                        <BarLoader color="#ca1611" />
                    </div>
                ) : (
                    <div>
                        <div className="flex items-center justify-center px-2 mt-6 bg-white border rounded border-assetize-gray">
                            <div className="flex-[.4] py-2">
                                <h3 className="text-black text-md font-latoBold">Transactions</h3>
                            </div>
                            <div className="hidden flex-[.6] lg:flex justify-between">
                                <div className="flex-1 py-2 mx-1 ">
                                    <ActiveSearch
                                        bg="white"
                                        border="border-aasetize-gray"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Search here..."
                                    />
                                </div>
                                <div className="mx-1 border-r border-assetize-gray h-14" />
                                <div className="flex-[0.5] mx-1 py-2">
                                    <Select
                                        options={transactionFilter}
                                        selected={selectedOptions.transactionFilter}
                                        onChange={(option) => handleOptionChange("transactionFilter", option)}
                                        placeholder="All"
                                    />
                                </div>
                                <div className="flex-[0.7] mx-1 py-2">
                                    <Select
                                        options={dateFilter}
                                        selected={selectedOptions.dateFilter}
                                        onChange={(option) => handleOptionChange("dateFilter", option)}
                                        placeholder="Jan 01 - January 31"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="py-2 mt-2 bg-white rounded ">
                            <div className="hidden lg:flex justify-between px-8 py-3">
                                <h3 className="uppercase flex-[.1] font-latoLight text-sm ">details</h3>
                                <h3 className="uppercase flex-[.1] font-latoLight text-sm ">Date</h3>
                                <h3 className="uppercase flex-[.2] font-latoLight text-sm ">usd value</h3>
                                <h3 className="uppercase flex-[.1] font-latoLight text-sm ">status</h3>
                                <h3 className="uppercase flex-[.1] font-latoLight text-sm ">action</h3>
                            </div>
                            {transactionHistory.map((item) => (
                                <TransactionCard key={item.transactionId} item={item} />
                            ))}
                        </div>
                        <div className="flex justify-end mt-5">{/* <Pagination /> */}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Transactions;
