import { OptionsProps, Status, WalletTransactionProps } from "types/data";
import React, { useEffect, useState } from "react";
import { AuthActionStatus, DeveloperType } from "types/Auth.types";
import { DotsWithBg, EyeIcon, EyeSee, ThreeDotsVertical } from "Assets/Index";
import MenuList, { Option } from "Components/elements/Menu";
import { useNavigate } from "react-router-dom";
import ModalPanel from "Components/elements/Modal/Index";
import AnswerModal from "./AnswerModal";

interface AuthenticationProps {
    item: any;
}

const PropertyCard = ({ item }: AuthenticationProps) => {
    const { adminApprovalStatus: actionStatus, createdAt, id, metaData, fiatValue, propertyName, location, propertyGallery } = item;
    const navigate = useNavigate();
    const [createdAtDate, setCreatedAtDate] = useState("");
    const [showModal, setSHowModal] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });
    useEffect(() => {
        const requestDate = new Date(createdAt);
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "long",
            year: "numeric"
        };
        const formatedDate = requestDate.toLocaleDateString("en-US", options);
        setCreatedAtDate(formatedDate);
    }, [createdAt]);

    const options: OptionsProps[] = [
        { text: "View Details", value: "view" },
        { text: "Approve", value: "approve" },
        { text: "Reject", value: "reject" }
    ];
    const optionTwo: OptionsProps[] = [{ text: "View Details", value: "view" }];

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "view") {
            navigate(`${id}`);
        } else if (option?.value === "approve") {
            setShowReplyModal({
                open: true,
                action: "approve"
            });
        } else {
            setShowReplyModal({
                open: true,
                action: "reject"
            });
        }
    };

    const handleOptionTwo = async (option: Option) => {
        navigate(`${id}`);
    };

    let statusText;
    if (actionStatus === AuthActionStatus.APPROVED) {
        statusText = (
            <div className="bg-assetize-green-gradient flex justify-start flex-[.05] p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-green-text">Approved</p>
            </div>
        );
    } else if (actionStatus === AuthActionStatus.REJECTED) {
        statusText = (
            <div className="bg-assetize-primary-gradient flex justify-start flex-[.05] p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-primary">Rejected</p>
            </div>
        );
    } else {
        statusText = (
            <div className="bg-assetize-warning-gradient flex justify-start flex-[.05]  p-1 rounded-2xl">
                <p className="text-[#F39B0D] text-sm px-2">Pending</p>
            </div>
        );
    }

    return (
        <div>
            <div className="hidden md:flex items-center justify-between w-full p-3 px-2 py-5 my-2 border rounded border-assetize-gray">
                <div className="text-md font-latoRegular capitalize text-center flex justify-center w-[13%] text-sm h-20">
                    <img src={propertyGallery?.propertyImages[0]} className="w-full object-cover rounded" alt="" />
                </div>
                <div className="w-[17%] px-3 justify-start">
                    <h3 className="text-sm md:text-md font-latoBold leading-6 text-assetize-light-text">{propertyName}</h3>
                    <p className="text-[12px] font-latoRegular">{location}</p>
                </div>
                <h3 className="text-md font-latoRegular capitalize text-sm flex justify-start w-[19%]">$ {fiatValue.toLocaleString()}</h3>
                <h3 className="text-md font-latoRegular capitalize text-sm flex justify-start w-[20%]">{createdAtDate}</h3>
                <div className="flex justify-start w-[15%]">{statusText}</div>
                <div className="text-md font-latoRegular  capitalize underline w-[15%] flex justify-start ">
                    <div className="">
                        <MenuList
                            options={optionTwo}
                            size="28"
                            onSelect={handleOptionTwo}
                            icon={EyeSee}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                    <div className="">
                        <MenuList
                            options={options}
                            size="28"
                            onSelect={handleOptionSelect}
                            icon={DotsWithBg}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                </div>

                <ModalPanel open={showReplyModal.open} closeButton closeModal={() => setShowReplyModal({ open: false, action: "" })}>
                    <AnswerModal action={showReplyModal.action} id={id?.toString()} isHome />
                </ModalPanel>
            </div>
            <div className="flex md:hidden">
                <div className="flex items-center justify-between w-full px-4 py-5 my-2 border rounded border-assetize-gray">
                    <img src={propertyGallery?.propertyImages[0]} className="w-10 h-10 rounded" alt="" />
                    <div className="text-center w-1/2 flex gap-3 items-center flex-col">
                        <div>
                            <h3 className="text-sm md:text-md font-latoBold leading-6 text-assetize-light-text">{propertyName}</h3>
                            <p className="text-[12px] font-latoRegular">{location}</p>
                        </div>
                        <div className="flex justify-between text-sm font-bold text-dark">{statusText}</div>
                    </div>
                    <div className="items-end w-1/2 flex gap-3">
                        <div className="flex flex-col">
                            <h3 className="text-md font-latoRegular capitalize text-sm flex justify-center flex-[.2]">${fiatValue}</h3>
                            <h3 className="text-md font-latoRegular capitalize text-sm flex justify-center flex-[.2] ">{createdAtDate}</h3>
                        </div>
                        <div className="text-md font-latoRegular flex-col capitalize underline flex-[.2] flex justify-center ">
                            <div className="">
                                <MenuList
                                    options={optionTwo}
                                    size="28"
                                    onSelect={handleOptionTwo}
                                    icon={EyeSee}
                                    bg="white"
                                    textColor="assetize-dark-gray"
                                />
                            </div>
                            <div className="">
                                <MenuList
                                    options={options}
                                    size="28"
                                    onSelect={handleOptionSelect}
                                    icon={DotsWithBg}
                                    bg="white"
                                    textColor="assetize-dark-gray"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
