import { VerificationPending } from "Assets/Index";
import { MainButton } from "Components/elements/Button/Index";
import AuthLogoDisplay from "Components/elements/Display/AuthLogoDisplay";
import FileUploadInput from "Components/elements/Forms/FileUploadInput";
import { developersKycVerification } from "Services";
import { getErrorMessge } from "Utils/Helpers";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type SelectedFiles = {
    "incorporation-documents": File | null;
    "profile-document": File | null;
    "corporate-governance": File | null;
};

export type AllowedKeys = "incorporation-documents" | "profile-document" | "corporate-governance";

export interface FileUploadInputProps {
    label: string;
    name: AllowedKeys;
    value?: File | null;
    onChange: (file: File | null) => void;
}

const VerificationDisplay = ({ handleIsVerified }: { handleIsVerified: () => void }) => {
    const [selectedFiles, setSelectedFiles] = useState<SelectedFiles>({
        "incorporation-documents": null,
        "profile-document": null,
        "corporate-governance": null
    });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const fileUploadOptions: FileUploadInputProps[] = [
        {
            label: "Incorporation Documents",
            name: "incorporation-documents",
            onChange: (file: File | null) => setSelectedFiles((prevFiles) => ({ ...prevFiles, "incorporation-documents": file }))
        },
        {
            label: "Company’s Profile Document",
            name: "profile-document",
            onChange: (file: File | null) => setSelectedFiles((prevFiles) => ({ ...prevFiles, "profile-document": file }))
        },
        {
            label: "Corporate Governance",
            name: "corporate-governance",
            onChange: (file: File | null) => setSelectedFiles((prevFiles) => ({ ...prevFiles, "corporate-governance": file }))
        }
    ];

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (selectedFiles["incorporation-documents"] && selectedFiles["profile-document"] && selectedFiles["corporate-governance"]) {
            setError("");
            const formData = new FormData();
            formData.append("IncorporationDocument", selectedFiles["incorporation-documents"]);
            formData.append("CompanyProfileDocument", selectedFiles["profile-document"]);
            formData.append("CorporateGovernance", selectedFiles["corporate-governance"]);

            try {
                setIsLoading(true);
                const res = await developersKycVerification(formData);

                localStorage.removeItem("atk");
                handleIsVerified();
            } catch (error) {
                const errorMessage = getErrorMessge(error);
                toast.error(errorMessage);
            } finally {
                setIsLoading(false);
            }
        } else {
            setError("Please upload all three files.");
        }
    };

    return (
        <div className="flex flex-col w-full items-center mt-10  font-latoRegular ">
            <div className="w-full flex md:mt-10 flex-col items-center">
                <AuthLogoDisplay />
                <h2 className="text-assetize-primary text-xl font-latoBold align-center mt-8">Verification Documents</h2>
                <p className="text-assetize-dark-gray text-md align-center text-sm">Upload the following documents for verification</p>
            </div>
            <div className="my-3">{error && <p className="mt-1 text-md font-latoRegular text-assetize-primary ">{error}</p>}</div>
            <div className="w-full md:w-[90%] mt-5">
                <form onSubmit={handleSubmit}>
                    {fileUploadOptions.map((option, index) => (
                        <FileUploadInput
                            key={index}
                            label={option.label}
                            name={option.name}
                            value={selectedFiles[option.name]}
                            onChange={option.onChange}
                        />
                    ))}
                    <div className="mt-4">
                        <MainButton isLoading={isLoading} disabled={isLoading} type="submit">
                            Continue
                        </MainButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

const VerificationSuccesfull = () => {
    const navigate = useNavigate();
    const continueToApp = localStorage.getItem("continue") ?? "true";

    return (
        <div className="flex flex-col w-full items-center mt-5 lg:mt-10 font-latoRegular ">
            <div className="w-full flex flex-col items-center">
                <AuthLogoDisplay />
            </div>
            <div className="mt-20 flex flex-col justify-center items-center">
                <img src={VerificationPending} alt="verification successful" className="w-32" />
                <h2 className="text-assetize-primary text-2xl font-latoBold align-center mt-8">Verification Pending</h2>
                <p className="text-assetize-dark-gray my-2 text-md text-center text-md w-[100%]">
                    Your Account Verification is pending and <br />
                    awaiting admin approval
                </p>
                <div className="mt-10 w-full md:w-200">
                    <MainButton
                        onClick={() => {
                            if (continueToApp) {
                                localStorage.removeItem("continue");
                                navigate("/developer");
                            } else {
                                navigate("/auth/login");
                            }
                        }}
                        type="submit"
                    >
                        {continueToApp ? "Contine" : " Login"}
                    </MainButton>
                </div>
            </div>
        </div>
    );
};

const Verification = () => {
    const [isVerfied, setIsVerified] = useState(false);
    const handleIsVerified = () => {
        setIsVerified(true);
    };
    return isVerfied ? <VerificationSuccesfull /> : <VerificationDisplay handleIsVerified={handleIsVerified} />;
};

export default Verification;
