import { FilterButton } from "Components/elements/Button/Index";
import IconBox from "Components/elements/Display/IconBox";
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import { DashboardStyle, Option, investmentTypeOptions, propertyTypeOptions } from "types/data";
import React, { useEffect, useState } from "react";
import { CiBoxList, CiGrid41 } from "react-icons/ci";
import { BiSearch } from "react-icons/bi";
import { MdClose } from "react-icons/md";

const statusOptions: Option[] = [{ name: "All" }, { name: "Re-sale" }, { name: "Available" }];
const statusOptionsWithSolOut: Option[] = [{ name: "All" }, { name: "Re-sale" }, { name: "Available" }, { name: "Sold-Out" }];

interface SubHeaderProps {
    dashboardStyle?: DashboardStyle;
    setDashboardStyle: (arg: DashboardStyle) => any;
    headerText?: string;
    canChangeStyle?: boolean;
    propertyType?: boolean;
    investmentType?: boolean;
    status?: boolean;
    filterBtn?: boolean;
    child?: React.ReactNode;
    value?: string;
    onChange?: (event: any) => void;
    filterOperation?: () => void;
    // setData?: (data: any) => void;
    showSearch?: boolean;
    isUnverified?: boolean;
    updatePropertyTypeFilter?: (value: string) => void;
    updateInvestmentTypeFilter?: (value: string) => void;
    updateSoldOutStatusFilter?: (value: string) => void;
    onSearch?: (value?: string) => void;
    showSoldOut?: boolean;
}

const SubHeader = ({
    dashboardStyle,
    setDashboardStyle,
    headerText,
    canChangeStyle,
    child,
    propertyType,
    status,
    filterBtn,
    investmentType,
    value,
    onChange,
    filterOperation,
    updatePropertyTypeFilter,
    updateInvestmentTypeFilter,
    updateSoldOutStatusFilter,
    onSearch,
    showSearch,
    showSoldOut = false,
    isUnverified = false
}: SubHeaderProps) => {
    const [search, setSearch] = useState("");
    const [showMobileSearch, setShowMobileSearch] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState({
        propertyType: { name: "Property Type" },
        investmentType: { name: "Investment Type" },
        status: { name: "Status" }
    });

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));

        if (optionName === "propertyType") {
            if (updatePropertyTypeFilter) {
                updatePropertyTypeFilter(option?.name || "");
            }
        }
        if (optionName === "investmentType") {
            if (updateInvestmentTypeFilter) {
                updateInvestmentTypeFilter(option?.name || "");
            }
        }
        if (optionName === "status") {
            if (updateSoldOutStatusFilter) {
                updateSoldOutStatusFilter(option?.name || "");
            }
        }
        if (optionName === "search" && option) {
            if (onSearch) {
                onSearch(option.name);
            }
        }
    };

    useEffect(() => {
        // const filterProperty =
    }, [selectedOptions.propertyType]);

    return (
        <div className="w-full">
            <div className="flex items-center mt-5 w-full justify-between">
                <div className="flex-[.55] md:flex-[.6]">{child}</div>
                {showSearch && (
                    <div className="flex-[.3] hidden md:flex md:flex-[.3] 2xl:fex-[.4] mx-1">
                        <ActiveSearch
                            bg="white"
                            border="border-assetize-gray"
                            value={value}
                            onChange={onChange}
                            placeholder="Search here..."
                            onSearch={(value) => {
                                if (onSearch) {
                                    onSearch(value);
                                }
                            }}
                        />
                    </div>
                )}
                {showSearch && (
                    <div className="flex md:hidden">
                        <IconBox isActive={false} handler={() => setShowMobileSearch(true)}>
                            <BiSearch fontSize="26px" color="#4B4B4D" />
                        </IconBox>
                    </div>
                )}
                <div className="border-r hidden md:flex border-assetize-gray mx-1 h-8" />
                <div className=" flex items-center flex-[.38] md:flex-[.5] justify-evenly">
                    <div className="border-r md:hidden border-assetize-gray mx-1 h-8" />
                    <div className={`${!canChangeStyle && "hidden"} flex-[.1] mx-1 `}>
                        <IconBox isActive={dashboardStyle === DashboardStyle.grid} handler={() => setDashboardStyle(DashboardStyle.grid)}>
                            <CiGrid41 fontSize="26px" color={dashboardStyle === DashboardStyle.grid ? "#fff" : "#4B4B4D"} />
                        </IconBox>
                    </div>
                    <div className={`${!canChangeStyle && "hidden"} flex-[.1] mx-1`}>
                        <IconBox isActive={dashboardStyle === DashboardStyle.list} handler={() => setDashboardStyle(DashboardStyle.list)}>
                            <CiBoxList fontSize="26px" color={dashboardStyle === DashboardStyle.list ? "#fff" : "#4B4B4D"} />
                        </IconBox>
                    </div>
                    {propertyType && (
                        <div className="hidden md:flex flex-1 mx-1">
                            <Select
                                options={propertyTypeOptions}
                                selected={selectedOptions.propertyType}
                                onChange={(option) => handleOptionChange("propertyType", option)}
                                placeholder="Property Type"
                            />
                        </div>
                    )}
                    {investmentType && (
                        <div className=" hidden md:flex flex-1 mx-1">
                            <Select
                                options={investmentTypeOptions}
                                selected={selectedOptions.investmentType}
                                onChange={(option) => handleOptionChange("investmentType", option)}
                                placeholder="Investment Type"
                            />
                        </div>
                    )}
                    {status && (
                        <div className="hidden md:flex flex-[.7] mx-1">
                            <Select
                                options={showSoldOut ? statusOptionsWithSolOut : statusOptions}
                                selected={selectedOptions.status}
                                onChange={(option) => handleOptionChange("status", option)}
                                placeholder="Status"
                            />
                        </div>
                    )}
                    {filterBtn && (
                        <div className="flex-[1]">
                            <FilterButton onClick={() => (filterOperation ? filterOperation() : "no implementation")} />
                        </div>
                    )}
                </div>
            </div>
            <div className="w-full md:hidden">
                {showMobileSearch && (
                    <div className="flex justify-between py-2">
                        <div className=" flex-[1] ">
                            <ActiveSearch
                                showOnMobile
                                bg="white"
                                border="border-assetize-gray"
                                value={value}
                                onChange={onChange}
                                placeholder="Search here..."
                                onSearch={(value) => {
                                    if (onSearch) {
                                        onSearch(value);
                                    }
                                }}
                            />
                        </div>

                        <div className="flex md:hidden ml-2">
                            <div
                                tabIndex={0}
                                role="button"
                                onClick={() => setShowMobileSearch(false)}
                                onKeyDown={() => setShowMobileSearch(false)}
                                className="border rounded border-black flex justify-center item-center p-0.5 items-center h-full w-full"
                            >
                                <MdClose fontSize="25px" color="#4B4B4D" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubHeader;
