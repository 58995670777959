/* eslint-disable react/react-in-jsx-scope */
import { MainButton } from "Components/elements/Button/Index";
import Input from "Components/elements/Forms/Input";
import Select from "react-select";
import ModalPanel from "Components/elements/Modal/Index";
import { useEffect, useState } from "react";
import { validateAll } from "indicative/validator";
import { SafelockWalletFundsApi, SetExchangeRate } from "Services";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import { useNavigate } from "react-router-dom";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";

type Props = {
    closeModal(): void;
};

export default function ({ closeModal }: Props) {
    const [basePrice, setBasePrice] = useState("0");
    const [quotePrice, setQuotePrice] = useState("0");
    const [processingFee, setProcessingFee] = useState(0);
    const [totalamount, setTotalAmount] = useState(0);
    const [duration, setDuration] = useState("");
    const [description, setDescription] = useState("");
    const [errors, setErrors] = useState<any>({});
    const [lockDone, setLockDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lockStage, setLockStage] = useState(0);

    const navigate = useNavigate();

    const handleOptionChange = (option: any) => {
        setDuration(option.label);
    };

    const setExchange = async () => {
        try {
            setLoading(true);
            const payload = {
                converter: "USD/NGN",
                basePrice: parseFloat(basePrice),
                quotePrice: parseFloat(quotePrice)
            };
            const res = await SetExchangeRate(payload);
            setLockStage(2);
        } catch (err: any) {
            setLoading(false);
            toast.error(
                err?.response?.data?.message
                    ? err?.response?.data?.message
                    : " Sorry, we could not process your Withdrawal at the moment, try again later."
            );
            setLockDone(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const rules = {
            basePrice: "required",
            quotePrice: "required"
        };

        const messages = {
            "basePrice.required": "Amount is required",
            "quotePrice.required": "Amount is required"
        };
        const data = {
            basePrice,
            quotePrice
        };
        validateAll(data, rules, messages)
            .then(() => {
                setLockStage(1);
            })
            .catch((err: any) => {
                const formattedErrors = {} as any;
                err.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });

                setErrors(formattedErrors);
            });
    };

    let componentTODisplay;
    if (lockStage === 0) {
        componentTODisplay = (
            <div className=" font-latoRegular">
                <div className="w-full px-2 md:px-8 mx-auto ">
                    <p className="font-latoBold text-md text-center">Edit and manage conversion Rate</p>
                    <form className="space-y-8 mt-10 ">
                        <div>
                            <Input
                                label="We buy $1 at:"
                                type="number"
                                name="basePrice"
                                value={basePrice}
                                placeholder="₦0.00"
                                size="md"
                                error={errors.basePrice}
                                onChange={(e) => setBasePrice(e.target.value)}
                            />
                        </div>
                        <div>
                            <Input
                                label="We sell $1 at:"
                                type="number"
                                name="quotePrice"
                                value={quotePrice}
                                placeholder="₦0.00"
                                size="md"
                                error={errors.quotePrice}
                                onChange={(e) => setQuotePrice(e.target.value)}
                            />
                        </div>

                        <div className="pt-4">
                            <MainButton onClick={handleSubmit} className="py-3 font-bold">
                                Save
                            </MainButton>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else if (lockStage === 1) {
        componentTODisplay = (
            <ConfirmActionModal
                headingText="Confirm Exchange Rate"
                descriptionText={`You are about to set an exchange fee. Please confirm `}
                handleCancel={() => setLockStage(0)}
                handleAction={setExchange}
                loading={loading}
                buttonText="Save"
                cancelButton="Discard"
            />
        );
    } else if (lockStage === 2) {
        componentTODisplay = (
            <SuccessMessageModal
                handleClick={() => window.location.reload()}
                descriptiontext="Exchange has been set successfully"
                headingText="Exchange set Successful!"
            />
        );
    }

    return (
        <ModalPanel closeModal={closeModal} open closeButton title="Manage Exchange Rate">
            {componentTODisplay}
        </ModalPanel>
    );
}
