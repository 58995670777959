import React, { useEffect, useState } from "react";
import { MainButton } from "Components/elements/Button/Index";
import UserTotal from "./Cards/UserTotal";
import Chart from "./Cards/Chart";
import { HiOutlineRefresh } from "react-icons/hi";
import Select from "Components/elements/Forms/Select";
import { GetApplicationTransactionStats, GetApplicationTotalTransactionStats, GetApplicationOverviewStats } from "Services";
import { Option, WalletTransactionProps, SafeLockTransactionProps, dateFilter, transactionFilter } from "types";
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import TransactionCard from "./Cards/TransactionCard";
import ModalPanel from "Components/elements/Modal/Index";
import Safelock from "./modals/SafeLock";
import ExchangeRate from "./modals/ExchangeRate";
import { ScaleLoader } from "react-spinners";
import { constants } from "buffer";
import BarChart from "../developer/analytics/Charts/BarChart";
import { NotFoundImg } from "Assets/Index";
import Pagination from "Components/elements/Pagination";
import { filterDataByDateRangeWithUpdatedAt, getErrorMessge, getShortCodeDate } from "Utils/Helpers";
import { toast } from "react-toastify";

const AdminDashboard = () => {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState(false);
    const [totalInvestment, setTotalInvestment] = useState("");
    const [totalInvestmentOverview, setTotalInvestmentOverview] = useState("");
    const [transactionHistory, setTransactionHistory] = useState<(WalletTransactionProps & SafeLockTransactionProps)[]>([]);
    const [displayList, setDisplayList] = useState<(WalletTransactionProps & SafeLockTransactionProps)[]>([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageData, setPageData] = useState<any>();
    const [showReplyModal, setShowReplyModal] = useState({
        action: "",
        open: false
    });
    const [selectedOptions, setSelectedOptions] = useState({
        transactionFilter: { name: "Monthly" },
        dateFilter: { name: "Jan 01 - Jan 31" }
    });
    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    // const getCurrentMonth = () => {
    //     const currentDate = new Date();
    //     const currentMonth = currentDate.toLocaleString('default', { month: 'short' }).toLowerCase();
    //     return currentMonth;
    // };

    // const currentMonth = getCurrentMonth();
    const previousMonth = (() => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1); // Subtract one month to get the previous month
        return currentDate.toLocaleString("default", { month: "short" }).toLowerCase();
    })();

    const previousDay = (() => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); // Subtract one day to get the previous day
        return currentDate.toLocaleDateString(undefined, { weekday: "short" }).toLowerCase();
    })();

    const previousYear = (() => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 1); // Subtract one year to get the previous year
        return currentDate.getFullYear();
    })();

    const getWalletTransactions = async () => {
        try {
            // setRefreshing(true);
            setLoading(true);

            // Use Promise.all to fetch multiple API responses concurrently
            const [totalTransactionStats, totalInvestmentOverview, allTransactionsAdmin] = await Promise.all([
                GetApplicationTotalTransactionStats(),
                GetApplicationOverviewStats(selectedOptions.transactionFilter.name),
                GetApplicationTransactionStats(pageNum)
            ]);

            setTotalInvestment(totalTransactionStats);
            setTotalInvestmentOverview(totalInvestmentOverview);

            setPageData(allTransactionsAdmin.metaData);
            setTransactionHistory(allTransactionsAdmin.allTransactions);
            setDisplayList(allTransactionsAdmin.allTransactions);
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    useEffect(() => {
        const initialDateFilter = getShortCodeDate();
        setSelectedOptions((prevOptions) => ({ ...prevOptions, dateFilter: initialDateFilter }));
    }, []);

    useEffect(() => {
        const transactionType = filterDataByDateRangeWithUpdatedAt(transactionHistory, selectedOptions?.dateFilter);
        setDisplayList(transactionType);
    }, [selectedOptions.dateFilter]);

    useEffect(() => {
        const getTransaction = async () => {
            try {
                const res = await GetApplicationTransactionStats(pageNum);
                setPageData(res.metaData);
                setTransactionHistory(res.allTransactions);
                setDisplayList(res.allTransactions);
            } catch (error) {
                setLoading(false);
            } finally {
                setLoading(false);
                setRefreshing(false);
            }
        };
        getTransaction();
    }, [pageNum]);

    useEffect(() => {
        getWalletTransactions();
    }, []);

    let lastMonthKey: string | undefined;
    let lastMonthValue: number | undefined;
    let lastYearKey: string | undefined;
    let lastYearValue: number | undefined;
    let lastDayKey: string | undefined;
    let lastDayValue: number | undefined;

    if (totalInvestmentOverview) {
        Object.entries(totalInvestmentOverview).forEach(([key, value]) => {
            switch (selectedOptions.transactionFilter.name) {
                case "Monthly":
                    if (key === previousMonth) {
                        lastMonthKey = key;
                        lastMonthValue = Number(value);
                    }
                    break;
                case "Daily":
                    if (key === previousDay) {
                        lastDayKey = key;
                        lastDayValue = Number(value);
                    }
                    break;
                case "Yearly":
                    if (key === previousYear.toString()) {
                        lastYearKey = key;
                        lastYearValue = Number(value);
                    }
                    break;

                default:
                    break;
            }
        });
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center mt-64">
                <ScaleLoader color="#ca1611" />
            </div>
        );
    }

    return (
        <div className="flex justify-between gap-2 flex-col px-3">
            <div className="flex flex-col lg:flex-row justify-between gap-2 mb-4 items-center w-full">
                <UserTotal />
                <div className="flex w-full lg:w-[280px] lg:flex-col gap-2 lg:ml-6">
                    <MainButton onClick={() => setShowReplyModal({ action: "safelock", open: true })} className="px-3 text-xs">
                        SafeLock Duration
                    </MainButton>
                    <MainButton onClick={() => setShowReplyModal({ action: "exchange", open: true })} className="px-3 text-xs">
                        Manage Exchange Rate
                    </MainButton>
                </div>
            </div>
            <div className="flex w-full h-full flex-col gap-2 border shadow-md px-3 md:pr-0 md:px-5">
                <div className="flex text-assetize-primary items-center justify-between">
                    <h4 className="mt-2">Total Investments</h4>
                    <div className="flex p-3 gap-3 items-center relative">
                        <HiOutlineRefresh
                            className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                            onClick={() => {
                                if (!refreshing) {
                                    getWalletTransactions();
                                }
                            }}
                        />
                        <div className="space-x-3" style={{ zIndex: 1 }}>
                            <Select
                                options={[{ name: "Daily" }, { name: "Monthly" }, { name: "Yearly" }]}
                                selected={selectedOptions.transactionFilter}
                                onChange={(option) => handleOptionChange("transactionFilter", option)}
                                placeholder={selectedOptions.transactionFilter.name}
                            />
                        </div>
                    </div>
                </div>
                <div className="hidden lg:flex items-center gap-3 text-assetize-dark ">
                    <h3>${Number(totalInvestment).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                    <p>
                        {selectedOptions.transactionFilter.name === "Daily" && <p>Yesterday ${lastDayValue?.toLocaleString() || "0"}</p>}
                        {selectedOptions.transactionFilter.name === "Monthly" && (
                            <p>last month ${lastMonthValue?.toLocaleString() || "0"}</p>
                        )}
                        {selectedOptions.transactionFilter.name === "Yearly" && <p>last year ${lastYearValue?.toLocaleString() || "0"}</p>}
                    </p>
                </div>
                <div className="lg:hidden flex items-center gap-3 text-assetize-dark ">
                    <h5>${Number(totalInvestment).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>{" "}
                    <p>
                        {selectedOptions.transactionFilter.name === "Daily" && <p>Yesterday ${lastDayValue?.toLocaleString() || "0"}</p>}
                        {selectedOptions.transactionFilter.name === "Monthly" && (
                            <p>last month ${lastMonthValue?.toLocaleString() || "0"}</p>
                        )}
                        {selectedOptions.transactionFilter.name === "Yearly" && <p>last year ${lastYearValue?.toLocaleString() || "0"}</p>}
                    </p>
                </div>
                <div className="px-0">
                    <BarChart data={totalInvestmentOverview} />
                </div>
            </div>
            <div className="lg:flex lg:items-center lg:justify-center lg:px-2 mt-6 lg:bg-white lg:border lg:rounded lg:border-assetize-gray">
                <div className="flex-[.3] py-2 mt-6 md:mt-0">
                    <h3 className="text-[#000] text-[18px] lg:text-lg font-latoBold">Transactions</h3>
                </div>
                <div className="hidden flex-[.6] md:flex justify-between">
                    <div className="flex-1 py-2 mx-1 ">
                        <ActiveSearch
                            bg="white"
                            border="border-assetize-gray"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search here..."
                        />
                    </div>
                    <div className="mx-1 border-r border-assetize-gray h-14" />
                    <div className="flex-[0.5] mx-1 py-2">
                        <Select
                            options={transactionFilter}
                            selected={selectedOptions.transactionFilter}
                            onChange={(option) => handleOptionChange("transactionFilter", option)}
                            placeholder={selectedOptions.transactionFilter.name}
                        />
                    </div>
                    <div className="flex-[0.7] mx-1 py-2">
                        <Select
                            options={dateFilter}
                            selected={selectedOptions.dateFilter}
                            onChange={(option) => handleOptionChange("dateFilter", option)}
                            placeholder="Jan 01 - January 31"
                        />
                    </div>
                </div>
            </div>
            <div className="py-2 mt-2 bg-white rounded mb-9">
                <div className="hidden md:flex items-center justify-between w-full px-4">
                    <h3 className="w-1/5 text-[14px] font-normal uppercase flex justify-start text-[#625A67]">details</h3>
                    <h3 className="w-1/5 text-[14px] font-normal uppercase flex justify-start text-[#625A67] ml-2">Date</h3>
                    <h3 className="w-1/5 text-[14px] font-normal uppercase flex justify-start text-[#625A67] mr-5">usd value</h3>
                    <h3 className="w-1/5 text-[14px] font-normal uppercase justify-start text-[#625A67] mr-3 flex-[.1] ">status</h3>
                    <h3 className="w-1/5 flex-[.1] font-normal uppercase justify-start text-[14px] text-[#625A67]">action</h3>
                </div>
                {displayList.length === 0 && (
                    <div className="flex flex-col items-center justify-center h-full mt-20 space-y-4 no-item">
                        <img src={NotFoundImg} alt="" />
                        <p className="text-base font-semibold">No Transaction History</p>
                    </div>
                )}
                {displayList.map((item) => (
                    <TransactionCard key={item.transactionId} item={item} />
                ))}
            </div>

            {showReplyModal.open && showReplyModal.action === "safelock" && (
                <Safelock closeModal={() => setShowReplyModal({ action: "", open: false })} />
            )}
            {showReplyModal.open && showReplyModal.action === "exchange" && (
                <ExchangeRate closeModal={() => setShowReplyModal({ action: "", open: false })} />
            )}
            <div className="flex justify-end mt-5">
                <Pagination pageData={pageData} setPageNum={setPageNum} />
            </div>
        </div>
    );
};

export default AdminDashboard;
