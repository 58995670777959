import useCurrentPath from "Hooks/useCurrentPath";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/store";
import SidebarLayout from "./SidebarLayout";
import {
    SidebarLinks,
    developerSidebarLinks,
    investorSidebarLinks,
    sidebarBottomLinks,
    adminSidebarLinks,
    developerSidebarBottomLinks,
    adminSidebarBottomLinks,
    investorSidebarBottomLinks
} from "./data";
import ModalPanel from "Components/elements/Modal/Index";
import SupportModal from "Pages/Support/Index";
import KycModal from "Layouts/Kyc/KycModal";
import { navigateWithAccountType } from "Utils/Helpers";
import { logoutUser } from "Services";

const Sidebar = () => {
    const currentPath = useCurrentPath();

    const navigate = useNavigate();
    const { user } = useSelector((state: any) => state.auth);

    const [showKycModal, setShowKycModal] = useState(false);
    const [supportToggleState, setSupportToggleState] = useState(false);

    const setSidebarData = useCallback(() => {
        switch (user?.accountType) {
            case "developer":
                return developerSidebarLinks;
            case "admin":
                return adminSidebarLinks;
            default:
                return investorSidebarLinks;
        }
    }, []);

    const setSidebarBottomData = useCallback(() => {
        switch (user?.accountType) {
            case "developer":
                return developerSidebarBottomLinks;
            case "admin":
                return adminSidebarBottomLinks;
            default:
                return investorSidebarBottomLinks;
        }
    }, []);

    const closeSupportModal = () => {
        setSupportToggleState(false);
    };

    const decideToNavigate = async (url: string) => {
        if (url && url.toLocaleLowerCase() === "support") {
            setSupportToggleState(true);
        } else {
            await logoutUser();
        }
    };

    const handleNavigation = (url: string) => {
        const navUrl = navigateWithAccountType(user?.accountType, url);

        navigate(navUrl);
    };

    useEffect(() => {
        if (!user) {
            setSidebarData();
            setSidebarBottomData();
        }
    }, []);

    useEffect(() => {
        let timer: any;
        if (user) {
            timer = setTimeout(() => {
                setShowKycModal(!user?.kycVerified);
            }, 7000);
        }
        return () => clearTimeout(timer);
    }, [user?.kycVerificationStatus, user]);

    return (
        <div>
            {setSidebarData().map(({ id, title, icon, url }) => (
                <div
                    onClick={() => handleNavigation(url)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {
                        handleNavigation(url);
                    }}
                    key={id}
                    className={`${url === currentPath && "bg-[#2B2A2A]"} rounded py-[1px] mx-2`}
                >
                    <SidebarLayout title={title} icon={icon} />
                </div>
            ))}

            <div className="mt-14">
                {setSidebarBottomData().map((item) => (
                    <div
                        onClick={() => decideToNavigate(item.url)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {
                            decideToNavigate(item.url);
                        }}
                        key={item.id}
                        className={` ${item.title === "Logout" && "bg-assetize-primary py-[1px]"} rounded `}
                    >
                        <SidebarLayout title={item.title} icon={item.icon} />
                    </div>
                ))}
            </div>

            <ModalPanel
                closeModal={() => {
                    setSupportToggleState(false);
                }}
                open={supportToggleState}
                closeButton
            >
                <SupportModal onClose={closeSupportModal} />
            </ModalPanel>

            {user?.accountType === "investor" && (
                <ModalPanel open={showKycModal} closeButton closeModal={() => setShowKycModal(false)}>
                    <KycModal onClose={() => setShowKycModal(false)} />
                </ModalPanel>
            )}
        </div>
    );
};

export default Sidebar;
