import React from "react";

interface SidebarLayoutProps {
    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    title: string;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ icon: IconComponent, title }) => (
    <div className="flex pl-6 my-4">
        {IconComponent && <IconComponent className="w-4 h-4" />}
        {title && <h1 className="pl-4 text-xs lg:text-sm text-white font-latoLight">{title}</h1>}
    </div>
);

export default SidebarLayout;
