/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import ASDocUpload from "Components/elements/Forms/ASDocUpload";
import Input from "Components/elements/Forms/Input";
import { AddNewPropertyListingApi, GetLearnDetailApi } from "Services";
import { useFormik } from "formik";
import { BiArrowBack } from "react-icons/bi";
import { HiPlusSm, HiX } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { AddImage, DelImage, EditIcon } from "Assets/Index";
import { toast } from "react-toastify";
import { CreateNewPost } from "Services/apis/admin.api";
import PreviewMode from "Pages/appcore/developer/property/PreviewMode";
import GoBack from "Components/elements/Display/GoBack";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";

const postType = [
    { label: "Article", value: "ARTICLE" },
    { label: "News", value: "NEWS" }
];

const CreatePost = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState("1");
    const [postData, setPostData] = useState<any>({});
    const [img, setImg] = useState(null);
    const [imgFile, setImgFile] = useState("");
    const [imgError, setImgError] = useState("");

    const handleImageUpload = (event: any) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                setImgError("Image size exceeds 5MB. Please choose a smaller image.");
                return;
            }

            setImgError("");
            const imageUrl = URL.createObjectURL(file);

            setImg(file);

            setImgFile(imageUrl);
        }
    };

    const createPost = async () => {
        try {
            setLoading(true);

            const res = await CreateNewPost(postData);

            toast.success("Your post has been posted successfully.");
            navigate("/admin/learn");
        } catch (error: any) {
            const err = Array.isArray(error?.response?.data?.message)
                ? error?.response?.data?.message.join(",")
                : error?.response?.data?.message;
            toast.error(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        validateOnChange: true,
        initialValues: {
            title: "",
            postType: "",
            content: ""
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            postType: Yup.string().required("Publication Type is required"),
            content: Yup.string()
                .required("Content is required")
                .test("wordLimit", "Content should not exceed 1500 words", (value) => {
                    if (value) {
                        const wordCount = value.trim().split(/\s+/).length;
                        return wordCount <= 1500;
                    }
                    return true;
                })
        }),

        onSubmit: (values, { resetForm }): void => {
            setStage("2");
            setPostData({ ...values, headerImage: img, img: imgFile });
        }
    });

    return (
        <div>
            {stage === "1" ? (
                <div className="container py-4 mx-auto space-y-6">
                    <div className="flex items-center justify-start font-semibold gap-x-2">
                        <button onClick={() => navigate(-1)} type="button" className="flex items-center gap-x-2">
                            <BiArrowBack className="text-assetize-primary" />
                            Back
                        </button>
                    </div>
                    <div className="w-11/12 pb-8 mx-auto space-y-8">
                        <div className="flex flex-col items-center justify-center">
                            <h4> New Post</h4>
                            <p>Publish a new Article or News</p>
                        </div>
                        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                            <div className="py-8 space-y-4 border-y-2">
                                <div className="grid grid-cols-2 gap-x-8">
                                    <div className="space-y-1 h-fit">
                                        <label className="font-semibold text-gray-800" htmlFor="propertyType">
                                            Publication Type <span className="text-assetize-primary">*</span>
                                        </label>
                                        <div>
                                            <Select
                                                id="postType"
                                                options={postType}
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        outline: "none",
                                                        background: "transparent",
                                                        border: formik.errors.postType && "solid red 1px"
                                                    })
                                                }}
                                                placeholder="Select publication type"
                                                name="propertyType"
                                                aria-errormessage={formik.errors.postType}
                                                onChange={(selectedOption) => formik.setFieldValue("postType", selectedOption?.value)}
                                            />
                                            <p className="mt-1 text-xs text-red-600">{formik.errors.postType}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="font-semibold text-gray-800" htmlFor="propertyType">
                                            Title <span className="text-assetize-primary">*</span>
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.title}
                                            type="text"
                                            className="bg-transparent"
                                            name="title"
                                            placeholder="Enter post name"
                                            error={formik.errors.title}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="textarea" className="mb-2 text-base font-bold text-gray-800 cursor-pointer">
                                        Content
                                        <textarea
                                            className="w-full p-4 mt-4 bg-transparent border-2 rounded-lg"
                                            rows={10}
                                            id="textarea"
                                            name="content"
                                            value={formik.values.content}
                                            onChange={formik.handleChange}
                                            maxLength={1500}
                                        />
                                        <p className="text-xs text-red-600">
                                            {formik.errors.content}
                                            {formik.values.content.length > 1500 && " Content should not exceed 1500 words"}
                                        </p>
                                    </label>
                                </div>
                                <div className="w-1/2">
                                    <ASDocUpload
                                        label="Upload image"
                                        name="image"
                                        accept="image/jpeg, image/png, application/pdf"
                                        onChange={(e: any) => handleImageUpload(e)}
                                        error={imgError}
                                    />
                                </div>
                            </div>
                            <div className="my-6 ml-auto space-x-6 font-semibold w-fit">
                                <button type="button" className="px-20 rounded-lg border py-2.5 border-black hover:bg-zinc-300">
                                    Cancel
                                </button>

                                <button type="submit" className="text-white hover:bg-assetize-primary py-2.5 rounded-lg px-14 bg-red-500">
                                    Publish
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            ) : (
                <div className="bg-white">
                    <div className="bg-assetize-primary p-3 flex justify-center items-center font-latoRegular text-sm text-white rounded-tl-md rounded-tr-md mb-4">
                        Preview Mode
                    </div>

                    <GoBack link="/admin/learn" />

                    <div className="w-full flex pb-12 flex-col">
                        <div className="flex w-[85%] md:justify-end py-4">
                            <div className="relative md:w-[50%] flex justify-end space-x-2">
                                <OutlineButton onClick={() => setStage("1")} color="assetize-gray">
                                    <div className="flex items-center justify-around">
                                        <EditIcon />
                                        <p className="font-latoBold text-sm text-assetize-light-text">Edit document</p>
                                    </div>
                                </OutlineButton>
                                <MainButton isLoading={loading} disabled={loading} onClick={createPost}>
                                    Publish
                                </MainButton>
                            </div>
                        </div>
                        <div className="flex w-[70%] flex-col items-center justify-center mx-auto">
                            <h3 className="text-xl font-latoBold py-2 text-center">{postData?.title}</h3>
                            <img src={postData?.img} alt="blog" className="rounded my-6" />
                            <p className="text-sm text-assetize-light-text font-latoRegular">{postData?.content}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreatePost;
