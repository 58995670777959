import { toast } from "react-toastify";
import { Dispatch } from "redux";
import {
    forgotPasswordFailure,
    forgotPasswordStart,
    forgotPasswordSuccess,
    loginFailure,
    loginStart,
    loginSuccess,
    loginUser,
    logoutUser,
    otpCodeFailure,
    otpCodeStart,
    otpCodeSuccess,
    refreshUserStart,
    refreshUserSuccess,
    resetFailure,
    resetStart,
    resetSuccess,
    signupFailure,
    signupStart,
    signupSuccess,
    verifyBvnFailure,
    verifyBvnStart,
    verifyBvnSuccess,
    verifyNinFailure,
    verifyNinStart,
    verifyNinSuccess,
    verifyPvcFailure,
    verifyPvcStart,
    verifyPvcSuccess,
    welcome
} from "redux/Reducers/AuthReducer";
import { User, UserAccountType, UserType } from "types/Auth.types";
import store from "store";
import AuthService from "Services/AuthService";

const authService = new AuthService();
export const login = (email: string, password: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(loginStart());
        const user = await authService.login(email, password);
        if (user !== null) {
            dispatch(loginUser(user as User));
            dispatch(
                loginSuccess({
                    ...(user as User)
                })
            );
        } else {
            throw new Error("Login failed. Invalid credentials.");
        }
    } catch (error: any) {
        dispatch(loginFailure(error.message));
        throw new Error("Login failed. Invalid credentials.");
    }
};

export const forgotPassword = (emailAddress: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(forgotPasswordStart());
        const user = await authService.forgotPassword(emailAddress);
        dispatch(forgotPasswordSuccess(user as User));
    } catch (error: any) {
        dispatch(forgotPasswordFailure(error.message));
        toast.error(error.message);
        throw new Error(error.message);
    }
};

export const logout = () => (dispatch: Dispatch) => {
    store.set("isLoggedIn", false);
    store.set("rtk", "");
    store.set("atk", "");
};

export const welcomeUser = (selectedOption: UserAccountType) => (dispatch: Dispatch) => {
    dispatch(welcome(selectedOption));
};

export const register =
    ({ accountType, dateOfBirth, emailAddress, firstName, lastName, password, phoneNumber }: UserType) =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(signupStart());
            const user = await authService.register({
                accountType,
                dateOfBirth,
                emailAddress,
                firstName,
                lastName,
                password,
                phoneNumber
            });

            if (user !== null) {
                dispatch(
                    signupSuccess({
                        ...(user as User)
                    })
                );
            } else {
                throw new Error("Signup failed. Invalid credentials.");
            }
        } catch (error: any) {
            dispatch(signupFailure(error.message));
            throw new Error(error.message);
        }
    };

export const refreshUser = () => async (dispatch: Dispatch) => {
    try {
        dispatch(refreshUserStart());
        const user = await authService.refreshUser();

        dispatch(refreshUserSuccess(user as User));
    } catch (error: any) {
        dispatch(signupFailure(error.message));
        throw new Error(error.message);
    }
};

export const verifyOtp = (emailAddress: string, otp: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(otpCodeStart());
        const user = await authService.otpCode(emailAddress, otp);
        dispatch(otpCodeSuccess(user as User));
    } catch (error: any) {
        dispatch(otpCodeFailure(error.message));
        throw new Error(error.message);
    }
};

export const resetPassword = (emailAddress: string | null, newPassword: string, otp: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(resetStart());
        const user = await authService.resetPassword(emailAddress, newPassword, otp);
        dispatch(resetSuccess(user as User));
    } catch (error: any) {
        dispatch(resetFailure(error.message));
        throw new Error(error.message);
    }
};

export const kycVerification = (documentType: string, documentNumber: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(verifyBvnStart());

        const user = await authService.kycVerify(documentType, documentNumber);
        dispatch(verifyBvnSuccess());
    } catch (error: any) {
        dispatch(verifyBvnFailure(error.message));
        throw new Error(error.message);
    }
};
