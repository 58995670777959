import { MainButton } from "Components/elements/Button/Index";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import CheckBox from "Components/elements/Forms/CheckBox";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const VerifyInfo = () => {
    const [investorForm, setInvestmentForm] = useState(false);
    const [termsandCondition, setTermsAndCondition] = useState(false);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const buy = async () => {};

    let display;

    if (step === 1) {
        return (
            <div>
                <div className="flex flex-col  mt-7 font-latoRegular">
                    <div>
                        <h2 className="text-assetize-primary text-[19px] leading-3 pb-2 font-latoBold text-center ">$1000.32</h2>
                        <p className="text-sm  text-center ">=0.0093 token</p>
                    </div>
                    <div className="flex-[.6] border border-assetize-gray rounded py-4 px-1 my-5">
                        <div className="grid grid-cols-3  ">
                            <div className="border-b border-r py-4 px-1  border-assetize-gray">
                                <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">initial value</h3>
                                <h3 className="text-assetize-light-text  text-center font-latoRegular text-sm  mt-2">$1000 </h3>
                            </div>
                            <div className="border-b border-r border-assetize-gray py-4 px-1">
                                <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">usd Equivalent </h3>
                                <h3 className="text-assetize-light-text text-center font-latoRegular text-sm  mt-2">$1000.00</h3>
                            </div>
                            <div className="border-b border-assetize-gray py-4 px-1 ">
                                <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase"> Shares </h3>
                                <h3 className="text-assetize-light-text text-center font-latoRegular text-sm  mt-2"> 5 </h3>
                            </div>
                            <div className="border-r border-assetize-gray py-4 px-1 ">
                                <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">token equivalent </h3>
                                <h3 className="text-assetize-light-text text-center font-latoRegular text-sm mt-2 "> 0.003344 </h3>
                            </div>
                            <div className="border-r border-assetize-gray py-4 px-1">
                                <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Estimated return</h3>
                                <h3 className="text-assetize-light-text text-center font-latoRegular text-sm mt-2"> 20% </h3>
                            </div>
                            <div className=" border-assetize-gray py-4 px-1 ">
                                <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">maturity date</h3>
                                <h3 className="text-assetize-light-text text-center font-latoRegular text-sm"> 23-04-12023</h3>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="  flex justify-between items-center border-b border-assetize-gray pb-2 ">
                            <p>Payment method </p>

                            <p className="font-latoRegular text-sm flex items-center text-assetize-primary">USD Wallet</p>
                        </div>
                        <div className="  flex justify-between items-center pb-2 ">
                            <p>Payment method </p>

                            <p className="font-latoRegular text-sm flex items-center text-assetize-primary">USD Wallet</p>
                        </div>
                        <div className="py-4">
                            <div className="flex items-start">
                                <div className="mt-2">
                                    <CheckBox
                                        onChange={(checked) => setInvestmentForm(checked)}
                                        name="checkInvestorInformForm"
                                        label=""
                                        error=""
                                    />
                                </div>
                                <p className="pl-2 text-assetize-dark-gray font-latoRegular pt-[-10px]">
                                    I have reviewed and agree with all the information on the{" "}
                                    <span>
                                        <Link className="text-assetize-primary underline hover:text-black" to="#">
                                            Investor Information Form
                                        </Link>
                                    </span>{" "}
                                </p>
                            </div>

                            <div className="flex items-start">
                                <CheckBox
                                    name="checkTermsAndConditionAgreement"
                                    onChange={(checked) => {
                                        setTermsAndCondition(checked);
                                    }}
                                    label=""
                                    error=""
                                />{" "}
                                <p className="pl-2 text-assetize-dark-gray font-latoRegular">
                                    I have read and agree to the
                                    <span>
                                        <Link className="text-assetize-primary underline hover:text-black" to="#">
                                            Terms and Conditions
                                        </Link>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <MainButton onClick={buy}>Claim Offer</MainButton>
                </div>
            </div>
        );
    }
    if (step === 2) {
        <ConfirmActionModal
            headingText="Sell your Shares"
            descriptionText="  You are about to sell your shares at Beverly Properties at 3.5 shares for $2,500.00. Please confirm"
            handleCancel={() => {}}
            handleAction={() => {}}
            loading={loading}
            buttonText="Yes"
        />;
    } else {
        <SuccessMessageModal
            handleClick={() => navigate("/investor/portfolio")}
            descriptiontext="    Your offer has been created successfully. You will be notified when other Investors buy from you"
            headingText="Offer Created Successfully!"
        />;
    }

    return <div>{display}</div>;
};

export default VerifyInfo;
