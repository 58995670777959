/* eslint-disable jsx-a11y/label-has-associated-control */
import Input from "Components/elements/Forms/Input";
import Select from "react-select";
import ModalPanel from "Components/elements/Modal/Index";
import {
    AccountLookup,
    GetBanksApi,
    GetTransactionChargesApi,
    GetWalletUsdApi,
    WithdrawFromSafelockToFiatApi,
    WithdrawToExternalBank
} from "Services";
import { useEffect, useState } from "react";
import { Option, Walletprops } from "types/data";
import { MainButton } from "Components/elements/Button/Index";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import { useNavigate } from "react-router-dom";
import { validate, validateAll } from "indicative/validator";
import { getErrorMessge } from "Utils/Helpers";

/* eslint-disable react/react-in-jsx-scope */
type Props = {
    closeModal(): void;
    safeLockBalance: number;
    pocketId: any;
    pocketData: any;
};

type BankType = {
    label: string;
    value: string;
};

const SafeLockWithdrawal = ({ pocketData, closeModal, safeLockBalance, pocketId }: Props) => {
    const [allBanks, setAllBanks] = useState<BankType[]>([]);
    // (pocketData);
    const [amount, setAmount] = useState("0");
    const [errorMessage, setErrorMessage] = useState("");
    const [amountErr, setAmountError] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [verifyingAccount, setVerifyingAccount] = useState(false);
    const [processingFee, setProcessingFee] = useState(0);
    const [charges, setCharges] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [withdrawDone, setWithdrawDone] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [chargeUser, setChargeUser] = useState(false);
    const [fee, setFee] = useState(0);
    const [total, setTotal] = useState(0);
    const [pocket, setpocket] = useState<any>();

    const navigate = useNavigate();

    useEffect(() => {
        setpocket(pocketData);
    }, [pocketData]);

    const withdrawToExternalBank = async () => {
        try {
            setLoading(true);
            const payload = {
                amount: parseFloat(amount)
            };
            const res = await WithdrawFromSafelockToFiatApi(payload, pocketId);

            setWithdrawDone(true);
        } catch (err: any) {
            setLoading(false);

            toast.error(
                err?.response?.data?.message
                    ? err?.response?.data?.message
                    : " Sorry, we could not process your Withdrawal at the moment, try again later."
            );
            setWithdrawDone(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (pocket) {
            setAmount(pocket?.safelockAmount);
            const maturityDate = new Date(pocket?.pocketMaturationDate);
            const currentDate = new Date();
            if (maturityDate.getTime() > currentDate.getTime()) {
                setChargeUser(true);
            } else {
                setChargeUser(false);
            }
        }
    }, [pocket]);

    useEffect(() => {
        if (chargeUser) {
            const fetchPrice = async () => {
                try {
                    const response = await GetTransactionChargesApi("FUNDING");
                    setCharges(response);
                    const calcFee = parseFloat(pocket?.safelockAmount) / 100;

                    setFee(calcFee);
                    setTotal(parseFloat(pocket?.safelockAmount) - calcFee);
                } catch (err) {
                    const errMsg = getErrorMessge(err);
                    toast.error(errMsg);
                }
            };
            fetchPrice();
        }
    }, [chargeUser, pocket]);

    useEffect(() => {
        if (amount) {
            const fee = parseFloat(amount) / 100;

            setTotalAmount(fee + parseFloat(amount));
            setProcessingFee(fee);
        }
        if (parseFloat(amount) > safeLockBalance) {
            setAmountError("Balance is insufficient for this transaction");
        }
    }, [amount]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const numericAmount = parseFloat(amount);

        const rules = {
            amount: "required|number|above:1"
        };

        const messages = {
            "amount.required": "Amount is required",
            "amount.above": "Amount should be greater than 1 USD"
        };
        const formData = { amount: numericAmount };
        validateAll(formData, rules, messages)
            .then(() => withdrawToExternalBank())
            .catch((err: any) => {
                const formattedErrors = {} as any;
                err.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setErrors(formattedErrors);
            });
    };

    return (
        <ModalPanel closeModal={closeModal} open closeButton>
            {!withdrawDone ? (
                <div className="w-full py-6 mx-auto px-5 md:px-14 font-latoRegular">
                    <div className="py-6 mx-auto text-center w-full bg-assetize-primary/10">
                        <h4 className="text-md font-latoRegular text-assetize-primary">Safe Lock </h4>
                        <h3 className="text-assetize-primary text-lg font-latoBold">
                            <span className="text-xl">{safeLockBalance ? `${safeLockBalance.toLocaleString()} USD` : "0 USD"}</span>
                        </h3>
                    </div>
                    <form className="w-full mx-auto my-10 space-y-4">
                        <div>
                            <Input
                                label="Amount"
                                type="number"
                                name="amount"
                                value={amount}
                                placeholder="Amount"
                                size="md"
                                error={errors.amount}
                                onChange={(e) => setAmount(e.target.value)}
                                amountInput
                                disabled
                            />
                        </div>

                        <div className="border border-assetize-primary w-full rounded-md p-5 fex flex-col">
                            <div className="flex justify-between w-full items-center">
                                <p className="text-assetize-light-text font-latoRegular">Amount to withdraw</p>
                                <p className="text-assetize-light-text font-latoRegular">${amount?.toLocaleString()}</p>
                            </div>
                            {chargeUser && (
                                <div className="flex justify-between w-full items-center">
                                    <p className="text-assetize-light-text font-latoRegular">Processing Fee(1%)</p>
                                    <p className="text-assetize-light-text font-latoRegular">${Number.isNaN(fee) ? "0" : fee}</p>
                                </div>
                            )}
                            <div className="flex justify-between w-full items-center">
                                <p className="text-assetize-light-text font-latoBold">Total</p>
                                <p className="text-assetize-light-text font-latoBold">${Number.isNaN(total) ? "0" : total}</p>
                            </div>
                        </div>
                        <div className="w-full mt-10">
                            <MainButton isLoading={loading} disabled={loading} onClick={handleSubmit}>
                                Withdraw
                            </MainButton>
                        </div>
                    </form>
                </div>
            ) : (
                <SuccessMessageModal
                    handleClick={() => window.location.reload()}
                    descriptiontext="Your Safe lock was successful, your will receive your funds within 24 hours."
                    headingText="Withdrawal Successful!"
                />
            )}
        </ModalPanel>
    );
};

export default SafeLockWithdrawal;
