import React, { useEffect, useState } from "react";
import { NotFoundImg, Verified } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Textarea from "Components/elements/Forms/TextArea";
import { authenticateDeveloper } from "Services/apis/admin.api";
import { useNavigate } from "react-router-dom";
import { BuyShares, GetInvestorsInThisProperty } from "Services";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";
import { getInitials } from "Utils/Helpers";
import { FadeLoader } from "react-spinners";

interface AnswerProps {
    id: string;
    isHome: boolean;
    onClose: () => void;
}

const SeeInvestors = ({ id, isHome, onClose }: AnswerProps) => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [investors, setInvestors] = useState<any>([]);

    const navigate = useNavigate();

    const getInvestors = async () => {
        setError("");

        try {
            setLoading(true);

            const res = await GetInvestorsInThisProperty("123");

            const investors: any = [];
            res?.mergedInvestors?.map((item: any) => {
                if (item.propertyId === id) {
                    investors.push(item);
                }
                return null;
            });

            setInvestors(investors);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInvestors();
    }, []);

    const currentLocation = window.location;

    if (loading) {
        return (
            <div className="w-full h-full justify-center items-center flex">
                <FadeLoader />
            </div>
        );
    }

    return (
        <div className=" justify-center items-center flex flex-col">
            <h3 className="text-assetize-primary font-latoBold text-md">Investors</h3>
            <p className="text-lg font-latoBold text-assetize-dark-text mt-2">A list of all investors on this property</p>

            <div className=" bg-assetize-primary-gradient p-5 rounded flex-col flex justify-center items-center my-4">
                <h5 className="text-assetize-primary font-latoRegular text-sm uppercase">Property investors</h5>
                <h1 className="text-assetize-primary font-latoBold text-2xl">{investors.length}</h1>
            </div>
            <div className="w-full h-72 overflow-scroll">
                {investors.length === 0 ? (
                    <div className="flex flex-col items-center mt-2 justify-center w-full h-full space-y-4 no-item">
                        <div className="flex flex-col justify-center items-center py-5">
                            <img src={NotFoundImg} className="w-28" alt="" />
                            <p className="text-base font-semibold">No investors on this property yet!</p>
                        </div>
                    </div>
                ) : (
                    investors.map((item: any) => (
                        <div className="border border-assetize-gray rounded-lg p-3 my-2 w-full justify-between flex items-center ">
                            <div className="flex items-center ">
                                <div className="bg-assetize-primary-gradient w-16 h-16 rounded-full flex justify-center items-center">
                                    <h3 className="text-md font-latoBold text-assetize-primary uppercase">{getInitials(item.name)}</h3>
                                </div>
                                <div className="pl-3">
                                    <p className="font-latoBold text-md ">{item.name}</p>
                                    <p className="text-sm font-latoRegular">${item.percentageOwned * item.sharePrice}</p>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <p className="text-md uppercase font-latoRegular text-assetize-dark">Percentage owned</p>
                                <p className="text-md uppercase font-latoRegular text-assetize-primary">
                                    {" "}
                                    {item.sharePercentage.toFixed(2)}%
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SeeInvestors;
