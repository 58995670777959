/* eslint-disable react/react-in-jsx-scope */
import ModalPanel from "Components/elements/Modal/Index";
import { capitalizeText } from "Utils/Helpers";
import { useState } from "react";
import { Status, WalletTransactionProps, SafeLockTransactionProps } from "types/data";

interface TransactioNCardProps {
    item: WalletTransactionProps & SafeLockTransactionProps;
}

const TransactionCard = ({ item }: TransactioNCardProps) => {
    const [showTransactionDetails, setShowTransactionDetails] = useState(false);
    let statusText;
    let viewStatusText;

    if (item.transactionStatus === Status.SUCCESSFUL) {
        statusText = (
            <div className="bg-assetize-green-gradient flex justify-start flex-[.05] p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-green-text">Successful</p>
            </div>
        );
    } else if (item.transactionStatus === Status.FAILED) {
        statusText = (
            <div className="bg-assetize-primary-gradient flex justify-start flex-[.05] p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-primary">failed</p>
            </div>
        );
    } else {
        statusText = (
            <div className="bg-assetize-warning-gradient flex justify-start flex-[.05]  p-1 rounded-2xl">
                <p className="text-[#F39B0D] text-sm px-2">pending</p>
            </div>
        );
    }

    if (item.transactionStatus === Status.SUCCESSFUL) {
        viewStatusText = <p className=" text-assetize-green-text">Successful</p>;
    } else if (item.transactionStatus === Status.FAILED) {
        viewStatusText = <p className=" text-assetize-primary">Failed</p>;
    } else {
        viewStatusText = <p className="text-[#F39B0D] ">Pending</p>;
    }

    const handleShowTransactionDetails = () => {
        setShowTransactionDetails(!showTransactionDetails);
    };

    return (
        <div key={item.transactionId}>
            <div className="hidden md:flex items-center justify-between w-full p-2 px-4 py-5 my-2 border rounded border-assetize-gray">
                <div className="text-start w-1/5 flex gap-3 items-start flex-col">
                    <h3 className="flex-[.1] text-md font-latoRegular capitalize flex justify-start  text-sm">
                        {item.transactionDetails || item.transactionType || item.shareTransactionType || "N/A"}
                    </h3>
                </div>
                <h3 className="w-1/5 text-md font-latoRegular capitalize text-sm flex justify-start ">
                    {item.transactionDate || item.createdAt
                        ? new Date(item.transactionDate || item.createdAt).toLocaleDateString("en-Uk", {
                              year: "numeric",
                              month: "long",
                              day: "numeric"
                          })
                        : "N/A"}
                </h3>

                <h3 className="w-1/5 text-md font-latoRegular capitalize text-sm flex justify-start">
                    {(() => {
                        if (item.amount !== undefined) {
                            return `$${item.amount.toLocaleString()}`;
                        }
                        if (item.fiatShareValue !== undefined) {
                            return `$${item.fiatShareValue.toLocaleString()}`;
                        }
                        return "N/A";
                    })()}
                </h3>

                <h3 className="w-1/5 text-md font-latoRegular capitalize text-sm justify-start flex-[.1]">{statusText}</h3>
                <button
                    type="button"
                    onClick={handleShowTransactionDetails}
                    className="flex-[.1] text-assetize-primary text-md text-base font-semibold underline"
                >
                    View
                </button>
            </div>

            <div className="flex md:hidden">
                <button type="button" onClick={handleShowTransactionDetails} className="cursor-pointer w-full">
                    <div className="flex items-center justify-between w-full px-3 py-5 my-2 border rounded border-assetize-gray">
                        <div className="text-start w-1/2 flex gap-3 items-start flex-col">
                            <h4 className=" flex-[.1] text-md font-latoRegular capitalize flex justify-start  text-sm">
                                {item.transactionDetails || item.transactionType || item.shareTransactionType || "N/A"}
                            </h4>
                            <h4 className="text-md font-latoRegular capitalize text-sm flex justify-start flex-[.1]">{statusText}</h4>
                        </div>
                        <div className="items-end w-1/2 flex gap-3 flex-col">
                            <h4 className=" text-md font-latoRegular capitalize text-sm flex justify-start flex-[.1]">
                                {(() => {
                                    if (item.amount !== undefined) {
                                        return `$${item.amount.toLocaleString()}`;
                                    }
                                    if (item.fiatShareValue !== undefined) {
                                        return `$${item.fiatShareValue.toLocaleString()}`;
                                    }
                                    return "N/A";
                                })()}
                            </h4>
                            <h4 className="flex-[.1] text-md font-latoRegular capitalize text-sm flex justify-start  ">
                                {item.transactionDate || item.createdAt
                                    ? new Date(item.transactionDate || item.createdAt).toLocaleDateString("en-Uk", {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric"
                                      })
                                    : "N/A"}
                            </h4>
                        </div>
                    </div>
                </button>
            </div>
            <ModalPanel closeModal={() => setShowTransactionDetails(false)} open={showTransactionDetails} closeButton>
                <div className="flex flex-col px-2 mb-4 font-latoRegular">
                    <h3 className="w-1/5 text-md font-latoRegular capitalize text-sm flex justify-start flex-[.1]">
                        {(() => {
                            if (item.amount !== undefined) {
                                return `$${item.amount.toLocaleString()}`;
                            }
                            if (item.fiatShareValue !== undefined) {
                                return `$${item.fiatShareValue.toLocaleString()}`;
                            }
                            return "N/A";
                        })()}
                    </h3>

                    <hr className="border-t-4 border-solid " />
                    <div className="flex justify-between text-sm  text-dark">
                        <p>Details </p> {item.transactionDetails || item.transactionType || item.shareTransactionType || "N/A"}
                    </div>
                    <hr className="border-t-2 border-solid " />
                    <div className="flex justify-between text-sm  text-dark ">
                        <p>Status</p> {viewStatusText}
                    </div>
                    <hr className="border-t-2 border-solid " />
                    <div className="flex justify-between text-sm  text-dark">
                        <p> Reference</p>
                        {item.transactionReference || "N/A"}
                    </div>
                    <hr className="border-t-2 border-solid " />
                    <div className="flex justify-between text-sm  text-dark">
                        <p> Date</p>
                        {item.transactionDate || item.createdAt
                            ? new Date(item.transactionDate || item.createdAt).toLocaleDateString("en-Uk", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric"
                              })
                            : "N/A"}
                    </div>

                    <hr className="border-t-2 border-solid " />
                </div>
            </ModalPanel>
        </div>
    );
};

export default TransactionCard;
