import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Thumb, Fail } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Input from "Components/elements/Forms/Input";
import Select from "Components/elements/Forms/Select";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { validateAll } from "indicative/validator";
import { kycPayloadType } from "types/Auth.types";
import { VerifyBvn, kycVerification } from "Services/apis";

interface ErrorType {
    idNumber: string;
    documentType: string;
    [key: string]: string | undefined;
}

const KycModal: FC<{ onClose: () => void }> = ({ onClose }) => {
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const authData = useSelector((state: any) => state.auth);
    const [step, setStep] = useState<number>(0);
    const [errors, setErrors] = useState<any>({});
    const [idNumber, setIdNumber] = useState("");
    const [requireBvnVerification, setRequireBvnVerification] = useState(false);
    const [selectedVerificationType, setSelectedVerificationType] = useState("");
    const [bvn, setBvn] = useState("");
    const [bvnError, setBvnError] = useState("");

    interface Option {
        name: string;
    }

    const Identification: Option[] = [{ name: "BVN" }, { name: "NIN" }, { name: "PVC" }, { name: "Drivers License" }];
    const [selectedId, setSelectedId] = useState<Option>({ name: "" });
    const [loading, setLoading] = useState(false);
    const [consent, setConsent] = useState(false);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setConsent(e.target.checked);
    };

    const handlePersonChange = (Identification: Option) => {
        setSelectedId(Identification);
        setSelectedVerificationType(Identification.name);
    };

    useEffect(() => {
        if (selectedVerificationType !== "BVN") {
            setRequireBvnVerification(true);
        } else {
            setRequireBvnVerification(false);
        }
    }, [selectedVerificationType]);

    const verifUser = async () => {
        const payload: kycPayloadType = {
            documentType: selectedId.name,
            documentNumber: idNumber,
            consent: true
        };

        try {
            setLoading(true);

            await kycVerification(payload);
            if (requireBvnVerification) {
                setStep(4);
            } else {
                setStep(2);
            }
        } catch {
            setStep(3);
        } finally {
            setLoading(false);
        }
    };

    const verifyBvn = async () => {
        try {
            setLoading(true);
            const payload = {
                documentType: "BVN",
                documentNumber: bvn,
                consent: true
            };
            const res = await VerifyBvn(payload);
            setStep(2);
        } catch (error) {
            setLoading(false);
            setStep(3);
        } finally {
            setLoading(false);
        }
    };

    const currentLocation = window.location;

    const handleVerification = async () => {
        const validationRules = {
            idNumber: "required"
        };

        const validationMessages = {
            "idNumber.required": "ID Number is required"
        };
        if (!idNumber) {
            setErrors((err: any) => ({ ...err, idNumber: "ID number is required" }));
        } else {
            setErrors((err: any) => ({ ...err, idNumber: "" }));
        }
        if (selectedId.name === "") {
            setErrors((err: any) => ({ ...err, documentType: "Please select document type" }));
        } else {
            setErrors((err: any) => ({ ...err, documentType: "" }));
        }
        if (!consent) {
            setErrors((err: any) => ({ ...err, consent: "You must consent to continue" }));
        } else {
            setErrors((err: any) => ({ ...err, consent: "" }));
        }

        if (!errors.idNumber && !errors.consent && !errors.documentType) {
            verifUser();
        }
    };

    const handleRetryVerification = () => {
        setStep(1);
    };

    const renderModalContent = () => {
        switch (step) {
            case 0:
                return (
                    <div className="p-8 w-full font-latoRegular">
                        <img src={Thumb} alt="" className="mx-auto mb-10" />

                        <div className="text-center flex flex-col md:py-4">
                            <h1 className="text-[24px] text-assetize-primary ">Complete KYC Verification</h1>
                            <p className="text-assetize-dark text-md mb-8 my-2 px-2 md:px-5 mt-0">
                                We need some information to confirm your identity. Upload <br />a valid government-issued ID (driver&apos;s
                                license, passport, <br />
                                national ID)
                            </p>

                            <div className="w-2/3 self-center mb-4">
                                <MainButton onClick={() => setStep(1)}>Verify now</MainButton>
                            </div>
                            <div className="w-2/3 self-center border-hidden ">
                                <button type="button" className="text-assetize-primary text-md text-base font-semibold" onClick={onClose}>
                                    Skip for now
                                </button>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="flex flex-col p-5">
                        <form className=" flex flex-col md:py-6">
                            <h1 className="text-[24px] text-assetize-primary text-center my-1">KYC Verification</h1>
                            <p className="text-assetize-dark text-md text-center my-6 mt-0">
                                Assetize requires a valid government-issued ID <br /> (driver&apos;s license, NIN, and Voter&apos;s Card)
                            </p>
                            <div className="my-5">
                                <p className="flex self-start text-md text-assetize-dark  my-1 mt-0">
                                    Document Type<span className="text-assetize-primary"> *</span>
                                </p>
                                <Select
                                    options={Identification}
                                    selected={selectedId}
                                    onChange={handlePersonChange}
                                    placeholder="Select an ID type"
                                    error={errors.documentType}
                                    textColor="text-assetize-primary"
                                />
                                <p className="flex self-start text-md text-assetize-dark  my-1 mt-5">
                                    ID Number<span className="text-assetize-primary"> *</span>
                                </p>
                                <Input
                                    label=" "
                                    type="text"
                                    name="idNumber"
                                    value={idNumber as string}
                                    placeholder="ID Number"
                                    error={errors.idNumber}
                                    size="md"
                                    onChange={(e) => setIdNumber(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <p className="self-start">
                                    <span className="text-assetize-primary mt-5"> *</span>Please confirm all the information is correct
                                </p>
                                <div className="flex">
                                    <input
                                        type="checkbox"
                                        checked={consent}
                                        onChange={handleCheckboxChange}
                                        className="accent h-4 w-4 transition duration-150 ease-in-out"
                                    />
                                    <p className="ml-2">Yes, I confirm the information provided is correct</p>
                                </div>
                                {errors.consent && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{errors.consent}</p>}
                            </div>
                        </form>
                        <div className="w-1/2 self-center">
                            <MainButton isLoading={loading} disabled={loading} onClick={handleVerification}>
                                {" "}
                                Verify
                            </MainButton>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="p-5">
                        <img src={Thumb} alt="" className="mx-auto mb-10" />

                        <div className="text-center flex flex-col md:py-6">
                            <h1 className="text-xl text-assetize-primary my-6">Verification Successful!</h1>
                            <p className="text-assetize-dark text-md my-6 mt-0">Your Account has been verified successfully</p>

                            <div className="w-2/3 self-center">
                                <MainButton
                                    onClick={() => {
                                        currentLocation.reload();
                                        onClose();
                                    }}
                                >
                                    Continue
                                </MainButton>
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="p-5">
                        <img src={Fail} alt="" className="mx-auto mb-10" />

                        <div className="text-center flex flex-col md:py-6">
                            <h1 className="text-xl text-assetize-primary my-6">Verification Failed!</h1>
                            <p className="text-assetize-dark text-md my-6 mt-0">
                                Sorry, Something is wrong with your verification. <br />
                                Please try again or contact{" "}
                                <a
                                    href="mailto:hello@assetizenow.com"
                                    className="text-assetize-primary active:text-assetize-primary hover:text-assetize-primary visited:text-assetize-primary"
                                >
                                    hello@assetizenow.com
                                </a>
                            </p>

                            <div className="w-2/3 self-center">
                                <MainButton onClick={handleRetryVerification}>Try again</MainButton>
                            </div>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="flex flex-col p-5">
                        <form className=" flex flex-col md:py-6">
                            <h1 className="text-xl text-assetize-primary my-6 text-center">BVN Verification</h1>
                            <p className="text-assetize-dark text-md my-6 mt-0 text-center">Assetize Requires your BVN to proceed</p>
                            <div className="my-5">
                                <p className="flex self-start text-assetize-dark text-base my-1 mt-0">
                                    BVN<span className="text-assetize-primary"> *</span>
                                </p>

                                <Input
                                    label=" "
                                    type="text"
                                    name="bvn"
                                    value={bvn}
                                    placeholder=""
                                    error={bvnError}
                                    size="md"
                                    onChange={(e) => setBvn(e.target.value)}
                                />
                            </div>
                        </form>
                        <div className="w-1/2 self-center">
                            <MainButton isLoading={loading} disabled={loading} onClick={verifyBvn}>
                                Verify BVN
                            </MainButton>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return <div className="font-latoRegular">{renderModalContent()}</div>;
};

export default KycModal;
