import { AuthBg, NoShareListed, Verified } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import CheckBox from "Components/elements/Forms/CheckBox";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface InvestmentProps {
    cancel: () => void;
    accept: () => void;
}

const InvestorInformation = ({ cancel, accept }: InvestmentProps) => (
    <div>
        <h2 className="text-assetize-primary text-2xl font-latoBold align-center mt-8 text-center">Investor Information Form</h2>

        <div className="border border-b border-assetize-gray pb-3 rounded p-2">
            <p className="text-assetize-dark-gray  text-md align-center text-md w-full mt-5">
                <p className="font-latoBold py-2">1. Information Collection and Usage</p>
                Assetize, a product of Veritasi Homes & Properties Limited, is committed to protecting your privacy. When you use our
                platform, we collect and use certain information to provide our services effectively.
                <br />
                • Personal Information: We may collect personal information, such as your name, email address, and contact details when you
                register on our platform.
                <br />
                • Property Information: Real estate developers provide property details for tokenization. This information may include
                property location, size, and related data.
                <br />
                • Usage Data: We collect data about your interactions with our platform, such as log files, usage history, and device
                information.
                <br />
                <p className="font-latoBold py-2"> 2. Information Security</p>
                We implement reasonable security measures to protect your data. However, please be aware that no online data transmission or
                storage is entirely secure. You are responsible for safeguarding your login credentials and reporting any unauthorized
                access to your account.
                <p className="font-latoBold py-2"> 3. Information Sharing</p>
                We do not sell your personal information to third parties. However, we may share your information with service providers,
                partners, or regulatory authorities as necessary to operate our platform or comply with legal requirements.
                <p className="font-latoBold py-2"> 4. Cookie Usage</p>
                Assetize uses cookies and similar technologies to improve your user experience. You can manage your cookie preferences
                through your browser settings.
                <p className="font-latoBold py-2"> 5. Third-Party Links</p>
                Our platform may contain links to third-party websites or services. We are not responsible for their privacy practices, and
                we encourage you to review their policies before engaging with them.
                <p className="font-latoBold py-2"> 6. Access and Control</p>
                You have the right to access, correct, or delete your personal information. You can also request information about the data
                we hold about you or withdraw your consent for data processing.
                <p className="font-latoBold py-2"> 7. Changes to Privacy Policy</p>
                Assetize may update this Privacy Policy as needed. We will notify you of significant changes, but we encourage you to review
                this policy periodically.
                <p className="font-latoBold py-2"> 8. Contact Information</p>
                If you have any questions or concerns about our Privacy Policy or data practices, please contact us at{" "}
                <a href="mailto:hello@assetizenow.com" className="text-assetize-primary">
                    hello@assetizenow.com
                </a>
                <br />
                <br />
                <p className="font-latoBold py-2">Disclaimer</p>
                <p className="font-latoBold py-1"> 1. Investment Risks</p>
                Assetize offers real estate tokenization services to facilitate property investment. However, all investments carry risks,
                and the value of your investment can fluctuate. Assetize does not guarantee returns on your investments, and past
                performance is not indicative of future results.
                <p className="font-latoBold py-1"> 2. No Legal or Financial Advice</p>
                Assetize provides information and tools related to real estate tokenization but does not offer legal, financial, or
                investment advice. Users should seek professional advice before making investment decisions.
                <p className="font-latoBold py-1"> 3. Property Ownership</p>
                Token ownership through Assetize represents fractional ownership of real estate assets but does not confer direct ownership
                rights over the physical properties. Legal ownership and rights are determined by applicable laws and agreements.
                <p className="font-latoBold py-1"> 4. Platform Functionality</p>
                Assetize strives to maintain a seamless and efficient tokenization process. However, we do not warrant or guarantee
                uninterrupted access to our platform or its error-free functionality.
                <p className="font-latoBold py-1"> 5. Third-Party Content</p>
                Assetize may include content, links, or information from third-party sources. We do not endorse or guarantee the accuracy or
                reliability of such content.
                <p className="font-latoBold py-1"> 6. Governing Law</p>
                Any disputes arising from your use of Assetize are subject to Nigerian law and the jurisdiction of Nigerian courts.
                <br />
                <br />
                By using Assetize, you acknowledge that you have read, understood, and agreed to our Privacy Policy and Disclaimer. These
                documents are subject to change, and it is your responsibility to stay informed about any updates.
                <br />
                <br />
            </p>
        </div>
        <div className="w-full flex justify-center mt-5">
            <div className="flex-[.45] pr-5">
                <OutlineButton onClick={cancel}>Cancel</OutlineButton>
            </div>
            <div className="flex-[.45] pl-5">
                <MainButton onClick={accept}>Accept Agreement</MainButton>
            </div>
        </div>
    </div>
);

export default InvestorInformation;
