import React from "react";
import { MainButton } from "../Button/Index";
import { Verified } from "Assets/Index";

interface SuccessMessageModalProps {
    descriptiontext: string;
    headingText: string;
    handleClick: () => void;
    btnTxt?: string;
}

const SuccessMessageModal = ({ descriptiontext, headingText, handleClick, btnTxt = "Continue" }: SuccessMessageModalProps) => (
    <div>
        <div className="mt-8 flex flex-col justify-center items-center">
            <img src={Verified} alt="verification successful" className="w-32" />
            <h2 className="text-assetize-primary text-2xl font-latoBold align-center mt-8">{headingText}</h2>
            <p className="text-assetize-dark-gray my-2 text-md align-center text-center text-md font-latoRegular">{descriptiontext}</p>

            <div className="mt-5 w-full flex justify-center items-center">
                <div className="w-[65%]">
                    <MainButton onClick={handleClick} type="submit">
                        {btnTxt}
                    </MainButton>
                </div>
            </div>
        </div>
    </div>
);

export default SuccessMessageModal;
