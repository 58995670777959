import { combineReducers } from "redux";
import authReducer from "./Reducers/AuthReducer";
import AuthReducer from "./slices/auth.slice";
import PropertyReducer from "./slices/property.slice";
import AdminReducer from "./slices/admin.slice";

const rootReducer = combineReducers({
    auth: authReducer,
    dAuth: AuthReducer,
    properties: PropertyReducer,
    admin: AdminReducer
});

export default rootReducer;
