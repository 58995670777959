import React from "react";
import { MainButton } from "Components/elements/Button/Index";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowUp } from "react-icons/ai";
import { ArrowUp, NullImg } from "Assets/Index";
import { PropertyDataProps, PropertyStatus } from "types";

interface PortfolioProps {
    property: PropertyDataProps;
    isGrid: boolean;
}

const PortfolioCard = ({ property, isGrid }: any) => {
    const isAvailable = property?.property?.availabilityStatus === "AVAILABLE";
    const isResale = property?.property?.availabilityStatus === "RESALE";

    const statusStyle = {
        background: "",
        text: "",
        textContent: ""
    };

    if (isAvailable) {
        statusStyle.background = "bg-green-200";
        statusStyle.text = "text-assetize-green-text";
        statusStyle.textContent = "Available";
    } else if (isResale) {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Re-sale ";
    } else {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Sold Out";
    }

    const navigate = useNavigate();
    return (
        <div
            role="button"
            tabIndex={0}
            className={`${
                !isGrid ? "flex justify-between flex-col md:flex-row items-center w-full" : "w-[24%] flex flex-col"
            }  border border-[#DCD6D8] rounded-md p-2`}
        >
            <div
                role="button"
                tabIndex={0}
                onClick={() => navigate(`${property?.property?.id}`, { state: property })}
                onKeyDown={() => navigate(`${property?.property?.id}`, { state: property })}
                className="flex md:hidden justify-between w-full"
            >
                <div className="h-[150px]  rounded-md overflow-hidden w-[50%] ">
                    <img
                        src={property?.property?.propertyImages[0]}
                        className={` h-full object-cover  w-full`}
                        alt={property?.property?.propertyName}
                        style={{
                            backgroundImage: `url(${NullImg})`
                        }}
                    />
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between w-full">
                    <div className="ml-2 py-2 w-full ">
                        <div className="flex items-center justify-between w-full">
                            <h3 className="text-sm  md:text-md font-latoBold text-assetize-dark-gray  ">
                                {property?.property?.propertyName}
                            </h3>
                            {/* <div
                                className={`${property?.property?.availabilityStatus === PropertyStatus.AVAILABLE
                                    ? 'bg-assetize-green-gradient '
                                    : 'bg-assetize-primary-gradient '
                                    } p-1 rounded-full ${!isGrid ? 'ml-2' : ' my-1'}`}
                            > */}
                            <p
                                className={`text-xs mx-2 p-1 w-14 rounded-2xl text-center ${
                                    isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                                } ${statusStyle.text}`}
                            >
                                {statusStyle.textContent}
                            </p>
                            {/* </div> */}
                        </div>
                        <p className="text-xs  font-latoRegular my-1 text-assetize-dark-gray">{property?.property?.location}</p>
                    </div>

                    <div className="md:hidden w-full border-t my-1 border-assetize-gray " />
                    <div className="flex justify-between w-full md:flex-col items-center px-2">
                        <p className="text-assetize-light-text font-latoLight text-[11px] md:text-sm uppercase">shares owned</p>
                        <p className={`${!isGrid ? "mt-2" : "mt-0"} text-assetize-light-text font-latoRegular text-md text-center`}>
                            {property?.sharesOwned}
                        </p>
                    </div>
                    <div className={` flex justify-between w-full md:flex-col items-center px-2 `}>
                        <p className="text-assetize-light-text font-latoLight text-[11px] md:text-sm uppercase">Percentage owned</p>
                        <p className={`${!isGrid ? "mt-2" : "mt-0"} text-assetize-light-text font-latoRegular text-md text-center`}>
                            {property?.percentageOwned?.toFixed(2)}%
                        </p>
                    </div>

                    <div className="hidden md:block h-[35px] w-full  mr-2">
                        <MainButton onClick={() => navigate(`${property?.property?.id}`, { state: property })}>View details</MainButton>
                    </div>
                </div>
            </div>
            <div className="md:flex hidden justify-between w-full">
                <div className="h-[100px] pr-2 rounded-md overflow-hidden w-[150px]">
                    <img
                        src={property?.property?.propertyImages[0]}
                        className={` h-full object-cover rounded-md w-full`}
                        alt={property?.property?.propertyName}
                        style={{
                            backgroundImage: `url(${NullImg})`
                        }}
                    />
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between w-full">
                    <div className="ml-2 py-2 w-full ">
                        <div className="flex items-center justify-between w-full">
                            <h3 className="text-sm md:text-md font-latoBold text-assetize-dark-gray  ">
                                {property?.property?.propertyName}
                            </h3>

                            <p
                                className={`text-xs mx-2 w-14 p-1 rounded-2xl text-center ${
                                    isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                                } ${statusStyle.text}`}
                            >
                                {statusStyle.textContent}
                            </p>
                        </div>
                        <p className="text-xs  font-latoRegular my-1 text-assetize-dark-gray">{property?.property?.location}</p>
                    </div>

                    <div className="md:hidden w-full border-t my-1 border-assetize-gray " />
                    <div className="flex justify-between w-full md:flex-col items-center px-2">
                        <p className="text-assetize-light-text font-latoLight text-[11px] md:text-sm uppercase">shares owned</p>
                        <p className={`${!isGrid ? "md:mt-2" : "mt-0"} text-assetize-light-text font-latoRegular text-md text-center`}>
                            {property?.sharesOwned}
                        </p>
                    </div>
                    <div className={` flex justify-between w-full md:flex-col items-center px-2 `}>
                        <p className="text-assetize-light-text font-latoLight text-[11px] md:text-sm uppercase">Percentage owned</p>
                        <p className={`${!isGrid ? "md:mt-2" : "mt-0"} text-assetize-light-text font-latoRegular text-md text-center`}>
                            {property?.percentageOwned?.toFixed(2)}%
                        </p>
                    </div>

                    <div className="hidden md:block h-[35px] w-full  mr-2">
                        <MainButton onClick={() => navigate(`${property?.property?.id}`, { state: property })}>View details</MainButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioCard;
