import React from "react";
import { Link } from "react-router-dom";
import { GoBack as GoBackIcon } from "Assets/Index";

interface GoBackProps {
    link: string;
}

const GoBack = ({ link }: GoBackProps) => (
    <Link to={link} className="hover:no-underline">
        <div className="flex justify-start items-center">
            <GoBackIcon className="w-8 h-8 pl-3 text-assetize-primary" />
            <p className="ml-2 text-assetize-dark font-latoBold font-[18px] text-md hover:text-assetize-dark-text">Back</p>
        </div>
    </Link>
);

export default GoBack;
