import { DeleteDoc } from "Assets/Index";
import React, { useState, useCallback, useEffect } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

type TASDocUpload = {
    onChange(e: any): void;
    error: string | undefined;
    accept?: string;
    name: string;
    label: string;
};

const ASDocEdit = ({ onChange, error, accept = "image/jpeg, image/png, application/pdf", name, label }: TASDocUpload) => {
    const [uploadedFileName, setUploadedFileName] = useState("");
    const [openDocSelector, setOpenDocSelector] = useState(false);

    const handleDrop = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            const droppedFiles = Array.from(e.dataTransfer.files);

            if (droppedFiles.length > 0) {
                const selectedFile = droppedFiles[0];
                setUploadedFileName(selectedFile.name);

                onChange(selectedFile);
            }
        },
        [onChange]
    );

    const localOnChange = (e: any) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setUploadedFileName(selectedFile.name);
        } else {
            setUploadedFileName("");
        }

        onChange(e);
    };

    const subTextContent = !uploadedFileName ? (
        <p className="px-6 text-assetize-dark font-latoRegular text-[12px] ">
            Drag and drop your document here or <span className="text-assetize-primary font-bold">Browse</span> to upload
            <br />
            <span className="text-[#92929D] text-[10px]">Supported file types: JPEG, PNG, PDF, Max file size: 5mb</span>
        </p>
    ) : (
        <p className="px-6 text-assetize-dark">{uploadedFileName}</p>
    );
    return (
        <div>
            {openDocSelector ? (
                <label className="font-latoBold text-assetize-dark-gray  cursor-pointer" htmlFor={name}>
                    {label}
                    <div
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        className="flex items-center justify-start w-full h-91 bg-[#F9FAFB] mt-3 border-2 border-dashed rounded-lg"
                    >
                        <div className="flex align-center items-center px-10 py-5 pt-5 pb-5">
                            <AiOutlineCloudUpload className="text-5xl w-[24px] h-[24px] text-gray-400" />
                            {subTextContent}
                        </div>

                        <input type="file" name={name} className="hidden" onChange={localOnChange} accept={accept} id={name} />
                    </div>
                </label>
            ) : (
                <div className="border rounded-md p-5 flex justify-between items-center font-latoBold text-assetize-dark-gray  cursor-pointer">
                    <label className="font-latoBold text-assetize-dark-gray  cursor-pointer" htmlFor={name}>
                        {label}
                    </label>
                    <div onClick={() => setOpenDocSelector(true)} role="button" tabIndex={0} onKeyDown={() => setOpenDocSelector(true)}>
                        {" "}
                        <DeleteDoc />
                    </div>
                </div>
            )}
            {error && !uploadedFileName && <p className="text-xs text-red-600">{error}</p>}
        </div>
    );
};

export default ASDocEdit;
