/* eslint-disable no-useless-catch */
import axiosInstance from "Utils/Https";

const token = localStorage.getItem("atk");

// API Request/Response Types
type TInvestInAssetRequest = { numberOfShares: string };
type TListAssetSharesRequest = {
    numberOfShares: string;
    askingPrice: string;
    listingDuration: string;
    termsAndConditions: string;
};

type TBuyAssetShares = {
    numberOfShares: string;
    paymentMethod: string;
};

type ListShares = {
    numberOfShares: number | string;
    pricePerShare: number;
    id?: string;
    alwaysOpen: boolean;
    closeDate: string | Date;
};

type UpdateShares = {
    shareNumber: number | string;
    sharePrice: number;
    alwaysOpen: boolean;
    closeDate: string | Date | null;
};

type ClaimOffer = {
    id: string;
    listingId: string;
};
// End of API Request/Response Types

/**
 * Description -  Get all properties in Marketplace
 */
export const GetMarketPlaceApi = async () => {
    try {
        const url = "market/properties";
        const res = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

export const GetSingleMarketPlaceApi = async (id: number | string) => {
    try {
        const url = `market/properties/${id}`;
        const res = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

export const GetMarketPlaceFilterApi = async (additionalFilterString?: string) => {
    try {
        const url = `market/properties/filter?${additionalFilterString}&page=1`;
        const res = await axiosInstance.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * Description -
 * @param  {string} id
 */
export const ChooseAssetApi = async (id: string) => {
    try {
        const url = `market/properties/${id}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * Description
 * @param  {TInvestInAssetRequest} payload
 */
export const InvestInAssetApi = async (payload: TInvestInAssetRequest) => {
    try {
        const url = "market/asset_id/invest";
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @param  {TListAssetSharesRequest} payload
 */
export const ListAssetSharesApi = async (payload: TListAssetSharesRequest) => {
    try {
        const url = "market/list-asset/asset_id";
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @param  {TBuyAssetShares} payload
 */
export const BuyAssetShareApi = async (payload: TBuyAssetShares) => {
    try {
        const url = "market/asset_id/buy";
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @param  {ListShares} payload
 */
export const ListSharesApi = async (payload: ListShares) => {
    try {
        const url = `property/${payload.id}/list-shares`;
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @param  {ListShares} payload
 */
export const UpdateSharesApi = async (id: string, payload: UpdateShares) => {
    try {
        const url = `investor/my-listings/${id}`;
        const res = await axiosInstance.patch(url, payload);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @returns {any}
 */
export const GetMyListings = async (page: number): Promise<any> => {
    try {
        const url = `investor/my-listings?page=${page}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @returns {any}
 */
export const GetSingleListings = async (id: string): Promise<any> => {
    try {
        const url = `/investor/my-listings/${id}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @returns {any}
 */
export const closeShareListing = async (id: string): Promise<any> => {
    try {
        const url = `property/my-listings/${id}/delist-share`;
        const res = await axiosInstance.patch(url);
        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @returns {any}
 */
export const GetOffersOnProperty = async (id: string): Promise<any> => {
    try {
        const url = `market/secondary-market/${id}/openoffers`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * @returns {any}
 */
export const ClaimMarketOffer = async (payload: { listerId: string; listingId: string; propertyId: string }): Promise<any> => {
    try {
        const url = `market/secondary-market/${payload.propertyId}/buy?listingId=${payload.listingId}`;
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};
