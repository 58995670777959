/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import ASDocUpload from "Components/elements/Forms/ASDocUpload";
import Input from "Components/elements/Forms/Input";
import { AddNewPropertyListingApi, EditListedProperty, GetSingleMarketPlaceApi } from "Services";
import { useFormik } from "formik";
import { BiArrowBack } from "react-icons/bi";
import { HiPlusSm, HiX } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { AddImage, DelImage } from "Assets/Index";
import { toast } from "react-toastify";
import GoBack from "Components/elements/Display/GoBack";
import { BounceLoader } from "react-spinners";
import { validateAll, extend, validate } from "indicative/validator";
import { FaRegQuestionCircle } from "react-icons/fa";
import { capitalizeText } from "Utils/Helpers";
import ASDocEdit from "Components/elements/Forms/AsDocEdit";

const investmentType = [
    { label: "Rental", value: "RENTAL" },
    { label: "Non Rental", value: "NON_RENTAL" }
];
const propertyType = [
    { label: "Apartment", value: "APARTMENT" },
    { label: "Commercial", value: "COMMERCIAL" },
    { label: "Estate", value: "ESTATE" },
    { label: "Mall", value: "MALL" },
    { label: "Land", value: "LAND" },
    { label: "Office", value: "OFFICE" },
    { label: "Others", value: "OTHERS" }
];

type PropertyData = {
    propertyName: string;
    propertyType: string;
    location: string;
    investmentType: string;
    numberOfShares: number | string;
    fiatValue: string;
    sharePrice: string;
    expectedCompletionDate: number | Date | any;
    expectedAnnualROI: string;
    dividend: number | string;
    description: string;
    financialProjectionsOfProject: File | null;
    approvalPermitOfProject: File | null;
    legalCompliance: File | null;
    projectPlan: File | null;
    titleDocumentOfProject: File | null;
    insuranceAndPerformanceBondFromContractors: File | null;
    floorPlans: any[];
    propertyImages: any[];
};

const checkFileSize = () => [];

const EditProperty = () => {
    const { state: item } = useLocation();

    const navigate = useNavigate();
    const [token, setToken] = useState(0);
    const [propertyData, setPropertyData] = useState<PropertyData>({
        propertyName: "",
        propertyType: "",
        location: "",
        investmentType: "",
        numberOfShares: 0,
        fiatValue: "",
        sharePrice: "",
        expectedCompletionDate: Date.now(),
        expectedAnnualROI: "",
        dividend: 0,
        description: "",
        financialProjectionsOfProject: null,
        approvalPermitOfProject: null,
        legalCompliance: null,
        projectPlan: null,
        titleDocumentOfProject: null,
        insuranceAndPerformanceBondFromContractors: null,
        floorPlans: [],
        propertyImages: []
    });
    const [files, setFiles] = useState<any[]>([]);
    const [isEdit, setIsEdit] = useState(false);
    const [loadingPropertyData, setLoadingPropertyData] = useState(false);
    const [errors, setErrors] = useState({
        propertyName: "",
        propertyType: "",
        location: "",
        investmentType: "",
        numberOfShares: "",
        fiatValue: "",
        sharePrice: "",
        expectedCompletionDate: "",
        expectedAnnualROI: "",
        dividend: "",
        description: "",
        financialProjectionsOfProject: "",
        approvalPermitOfProject: "",
        legalCompliance: "",
        projectPlan: "",
        titleDocumentOfProject: "",
        insuranceAndPerformanceBondFromContractors: ""
    });
    const [values, setValues] = useState<PropertyData>({
        propertyName: "",
        propertyType: "",
        location: "",
        investmentType: "",
        numberOfShares: 0,
        fiatValue: "",
        sharePrice: "",
        expectedCompletionDate: "",
        expectedAnnualROI: "",
        dividend: 0,
        description: "",
        financialProjectionsOfProject: null,
        approvalPermitOfProject: null,
        legalCompliance: null,
        projectPlan: null,
        titleDocumentOfProject: null,
        insuranceAndPerformanceBondFromContractors: null,
        floorPlans: [],
        propertyImages: []
    });
    const [imgError, setImgError] = useState("");
    const [loading, setLoading] = useState(false);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);

    const getSingleProperty = async (id: string) => {
        try {
            setLoadingPropertyData(true);
            const res = await GetSingleMarketPlaceApi(id);

            setPropertyData(res.property);
            setFiles([...res.propertyImages]);
        } catch (err: any) {
            setLoadingPropertyData(false);
        } finally {
            setLoadingPropertyData(false);
        }
    };

    useEffect(() => {
        if (item?.id) {
            setIsEdit(true);

            getSingleProperty(item?.id);
        }
    }, [item]);

    const editProperty = async () => {
        try {
            setLoading(true);
            const res = await EditListedProperty(values, item?.id, uploadedImages);

            toast.success(
                "Your property has been Updated successfully, our team will review to ensure that your property meets our standards."
            );
            navigate(`/developer`);
            setUploadedImages([]);
        } catch (error: any) {
            const err = Array.isArray(error?.response?.data?.message)
                ? error?.response?.data?.message.join(",")
                : error?.response?.data?.message;
            toast.error(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(propertyData).length > 0 && isEdit) {
            const updatedValues = {
                ...propertyData,
                expectedCompletionDate: propertyData?.expectedCompletionDate?.split("T")[0] || "",
                expectedAnnualROI: propertyData?.expectedAnnualROI?.toString(),
                dividend: propertyData?.dividend ? propertyData?.dividend?.toString() : "10",
                financialProjectionsOfProject: files[0],
                approvalPermitOfProject: files[0],
                legalCompliance: files[0],
                projectPlan: files[0],
                titleDocumentOfProject: files[0],
                insuranceAndPerformanceBondFromContractors: files[0]
            };

            const updatedImages = files.map((imageUrl, idx) => {
                if (!imageUrl && files && files[idx]) {
                    return URL.createObjectURL(files[idx]);
                }
                return imageUrl;
            });

            setValues(updatedValues);
            setUploadedImages(updatedImages);
        }
    }, [propertyData]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSelectChange = (name: string, selectedOption: any) => {
        setValues({
            ...values,
            [name]: selectedOption
        });
    };

    const handleFileChange = (name: string, file: File | null) => {
        setValues({
            ...values,
            [name]: file
        });
    };

    useEffect(() => {
        if (values.fiatValue) {
            const token = parseInt(values.fiatValue, 10) / 100;
            setToken(token);
        }
    }, [values.fiatValue]);

    useEffect(() => {
        if (values.fiatValue && values.numberOfShares) {
            values.sharePrice = (Number(values.fiatValue) / Number(values.numberOfShares)).toString();
        }
    }, [values.fiatValue, values.numberOfShares]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                setImgError("Image size exceeds 5MB. Please choose a smaller image.");
                return;
            }
            setImgError("");
            const imageUrl = URL.createObjectURL(file);
            const updatedImages = [...uploadedImages];
            updatedImages[idx] = imageUrl;
            setUploadedImages(updatedImages);
        }
    };

    const handleImageRemove = (idx: number) => {
        const updatedImages = [...uploadedImages];
        updatedImages[idx] = "";
        setUploadedImages(updatedImages);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const validationRules = {
            propertyName: "required",
            propertyType: "required",
            location: "required",
            investmentType: "required",
            numberOfShares: "required|integer",
            fiatValue: "required",
            sharePrice: "required|number",
            expectedCompletionDate: "required|date",
            expectedAnnualROI: "required",
            dividend: "required|number",
            description: "required",
            financialProjectionsOfProject: "required",
            approvalPermitOfProject: "required",
            legalCompliance: "required",
            projectPlan: "required",
            titleDocumentOfProject: "required",
            insuranceAndPerformanceBondFromContractors: "required"
        };

        const validationMessages = {
            required: "{{ field }} is required.",
            integer: "{{ field }} must be an integer.",
            number: "{{ field }} must be a number.",
            date: "{{ field }} must be a valid date."
        };

        validateAll(values, validationRules, validationMessages)
            .then(() => {
                editProperty();
            })
            .catch((error) => {
                if (Array.isArray(error)) {
                    const formattedErrors: any = {};
                    error.forEach((err) => {
                        formattedErrors[err.field] = err.message;
                    });

                    setErrors(formattedErrors);
                }
            });
    };

    if (loadingPropertyData) {
        return (
            <div className="w-full h-full flex justify-center items-center ">
                <BounceLoader color="#ca1611" />
            </div>
        );
    }

    return (
        <div className="container py-4 mx-auto space-y-6">
            <div className="flex items-center justify-start font-semibold text-red-200 gap-x-2">
                <GoBack link="/developer" />
            </div>
            <div className="w-full md:w-11/12 pb-4 md:pb-8 mx-auto space-y-8">
                <div className="flex flex-col items-center justify-center">
                    <h4>Edit Property Listing</h4>
                    <p>Edit property to update its details for investment</p>
                </div>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="py-8  space-y-4 border-y-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 md:gap-x-8">
                            <div>
                                <label className="text-sm font-latoRegular font-bold" htmlFor="propertyName">
                                    Property Name
                                </label>
                                <Input
                                    onChange={handleChange}
                                    value={values.propertyName}
                                    type="text"
                                    className="bg-transparent"
                                    name="propertyName"
                                    placeholder="Enter property name"
                                    error={errors.propertyName}
                                />
                            </div>
                            <div className="space-y-3 md:mt-0.5 md:space-y-0  h-fit ">
                                <label className="text-sm font-latoRegular font-bold" htmlFor="propertyType">
                                    Property Type
                                </label>
                                <div>
                                    <Select
                                        id="propertyType"
                                        options={propertyType}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                height: 45,
                                                outline: "none",
                                                background: "transparent",
                                                border: errors.investmentType && "solid red 1px"
                                            })
                                        }}
                                        value={{ label: values?.propertyType?.toLowerCase(), value: values?.propertyType }}
                                        placeholder="Select property type"
                                        name="propertyType"
                                        aria-errormessage={errors.propertyType}
                                        onChange={(selectedOption) => handleSelectChange("propertyType", selectedOption?.value)}
                                    />
                                    <p className="mt-1 text-xs text-red-600">{errors.propertyType}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label className="text-sm font-latoRegular font-bold" htmlFor="location">
                                Property Location
                            </label>
                            <Input
                                onChange={handleChange}
                                value={values.location}
                                type="text"
                                name="location"
                                placeholder="Enter property location"
                                className="bg-transparent"
                                error={errors.location}
                            />
                        </div>
                        <div className="grid grid-col2-1 md:grid-cols-2 py-2  gap-x-4">
                            <div className="space-y-1">
                                <label htmlFor="investmentType" className="flex items-center text-sm font-latoRegular font-bold">
                                    Investment Type
                                </label>
                                <div>
                                    <Select
                                        options={investmentType}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                height: 45,
                                                outline: "none",
                                                background: "transparent",
                                                border: errors.investmentType && "solid red 1px"
                                            })
                                        }}
                                        value={{ label: values?.investmentType?.toLowerCase(), value: values?.investmentType }}
                                        placeholder="Select investment type"
                                        name="investmentType"
                                        aria-errormessage={errors.investmentType}
                                        onChange={(selectedOption) => handleSelectChange("investmentType", selectedOption?.value)}
                                    />
                                    <p className="mt-1 text-xs text-red-600">{errors.investmentType}</p>
                                </div>
                            </div>
                            <div className="my-2 sm:my-0">
                                <label className="flex items-center text-sm font-latoRegular font-bold" htmlFor="numberOfShares">
                                    Total Shares
                                    <button type="button" className="px-2">
                                        <FaRegQuestionCircle size={14} color="#4B4B4D" style={{ opacity: 0.5 }} />
                                    </button>
                                </label>
                                <Input
                                    onChange={handleChange}
                                    value={values.numberOfShares as string}
                                    type="number"
                                    name="numberOfShares"
                                    className="bg-transparent"
                                    placeholder="0.0"
                                    error={errors.numberOfShares}
                                />
                            </div>
                        </div>
                        <div className="lg:flex lg:gap-4">
                            <div className="lg:flex-none lg:w-2/4">
                                <label htmlFor="fiatValue" className="text-sm font-latoRegular font-bold">
                                    Total Property Value
                                </label>
                                <Input
                                    onChange={handleChange}
                                    value={values.fiatValue}
                                    type="text"
                                    className="bg-transparent"
                                    name="fiatValue"
                                    placeholder="$0.00"
                                    error={errors.fiatValue}
                                />
                            </div>
                            <div className="lg:flex-grow">
                                <label className="text-sm font-latoRegular font-bold" htmlFor="sharePrice">
                                    Price per share
                                </label>
                                <Input
                                    onChange={handleChange}
                                    value={values.sharePrice}
                                    type="number"
                                    className="bg-transparent"
                                    name="sharePrice"
                                    placeholder="$0.00"
                                    error={errors.sharePrice}
                                    disabled
                                />
                            </div>
                            <div className="lg:flex-grow lg:mt-5">
                                <Input
                                    value={`${token} Tokens`}
                                    type="number"
                                    disabled
                                    className="bg-transparent"
                                    placeholder="0.00 Tokens"
                                />
                            </div>
                        </div>
                        <div className="grid grid-col-2 sm:grid-cols-6 gap-y-4 gap-x-8">
                            <div className="col-span-2">
                                <label className="text-sm font-latoRegular font-bold" htmlFor="expectedCompletionDate">
                                    Expected Completion Date
                                </label>
                                <Input
                                    onChange={handleChange}
                                    value={values.expectedCompletionDate}
                                    type="date"
                                    className="bg-transparent"
                                    name="expectedCompletionDate"
                                    placeholder="DD/MM/YYYY"
                                    error={errors.expectedCompletionDate as string}
                                />
                            </div>
                            <div className="col-span-2">
                                <label className="text-sm font-latoRegular font-bold" htmlFor="expectedAnnualROI">
                                    Expected Annual ROI
                                </label>
                                <Input
                                    onChange={handleChange}
                                    value={values.expectedAnnualROI.toString()}
                                    type="number"
                                    className="bg-transparent"
                                    name="expectedAnnualROI"
                                    placeholder="0%"
                                    error={errors.expectedAnnualROI}
                                />
                            </div>
                            <div className="col-span-2">
                                <label className="text-sm font-latoRegular font-bold" htmlFor="dividend">
                                    Dividend per share
                                </label>
                                <Input
                                    onChange={handleChange}
                                    value={values.dividend.toString()}
                                    type="number"
                                    className="bg-transparent"
                                    name="dividend"
                                    placeholder="0%"
                                    error={errors.dividend}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-10 space-y-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 lg:gap-x-10">
                            <ASDocEdit
                                label="Financial Projections of project"
                                name="financialProjectionsOfProject"
                                accept="image/jpeg, image/png, application/pdf"
                                onChange={(e: any) => handleFileChange("financialProjectionsOfProject", e?.currentTarget?.files[0])}
                                error={errors.financialProjectionsOfProject}
                            />
                            <ASDocEdit
                                label="Approval and Permit for the project"
                                name="approvalPermitOfProject"
                                accept="image/jpeg, image/png, application/pdf"
                                onChange={(e: any) => handleFileChange("approvalPermitOfProject", e?.currentTarget?.files[0])}
                                error={errors.approvalPermitOfProject}
                            />

                            <ASDocEdit
                                label="Title Document of project"
                                name="titleDocumentOfProject"
                                accept="image/jpeg, image/png, application/pdf"
                                onChange={(e: any) => handleFileChange("titleDocumentOfProject", e?.currentTarget?.files[0])}
                                error={errors.titleDocumentOfProject}
                            />
                            <ASDocEdit
                                label="Project/Business Plan"
                                name="projectPlan"
                                accept="image/jpeg, image/png, application/pdf"
                                onChange={(e: any) => handleFileChange("projectPlan", e?.currentTarget?.files[0])}
                                error={errors.projectPlan}
                            />

                            <ASDocEdit
                                label="Legal Compliance"
                                name="legalCompliance"
                                accept="image/jpeg, image/png, application/pdf"
                                onChange={(e: any) => handleFileChange("legalCompliance", e?.currentTarget?.files[0])}
                                error={errors.legalCompliance}
                            />

                            <ASDocEdit
                                label="Insurance and Performance Bond from Contractors"
                                name="insuranceAndPerformanceBondFromContractors"
                                accept="image/jpeg, image/png, application/pdf"
                                onChange={(e: any) =>
                                    handleFileChange("insuranceAndPerformanceBondFromContractors", e?.currentTarget?.files[0])
                                }
                                error={errors.insuranceAndPerformanceBondFromContractors}
                            />
                        </div>

                        <div>
                            <label htmlFor="textarea" className="mb-2 text-base font-bold text-gray-800 cursor-pointer">
                                About Property
                                <textarea
                                    className="w-full p-4 mt-4 bg-transparent border-2 rounded-lg"
                                    rows={8}
                                    id="textarea"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                />
                            </label>
                            <p className="text-xs text-red-600">{errors.description}</p>
                        </div>

                        <p className="text-md font-latoBold text-assetize-dark-gray">Upload image</p>
                        <div className="grid justify-between grid-cols-3 lg:grid-cols-5 gap-y-8 gap-x-8">
                            {Array.from({ length: 5 }).map((_, idx) => (
                                <label className="cursor-pointer" key={idx}>
                                    <div
                                        className={`flex flex-col items-center justify-center border rounded-xl  h-20  md:h-40 overflow-hidden relative ${
                                            uploadedImages[idx] ? "border-assetize-primary" : "border-dashed border-assetize-primary"
                                        }`}
                                    >
                                        {uploadedImages[idx] ? (
                                            <>
                                                <img
                                                    src={uploadedImages[idx]}
                                                    alt={`Uploaded ${idx}`}
                                                    className="w-full h-full object-cover rounded-xl "
                                                />
                                                <div
                                                    onClick={() => handleImageRemove(idx)}
                                                    role="button"
                                                    tabIndex={0}
                                                    onKeyDown={() => handleImageRemove(idx)}
                                                    className="absolute top-0 right-0 cursor-pointer p-1 rounded-full"
                                                >
                                                    <DelImage />
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                <AddImage className="w-14 sm:w-24" />
                                                <input
                                                    type="file"
                                                    accept="image/jpg, image/jpeg, image/png"
                                                    className="hidden"
                                                    onChange={(event) => handleImageUpload(event, idx)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </label>
                            ))}
                        </div>

                        {imgError && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{imgError}</p>}
                    </div>
                    <div className="my-6 ml-auto space-x-2 flex w-full justify-between sm:space-x-6 font-latoRegular">
                        <button
                            onClick={() => navigate("/developer")}
                            type="button"
                            className="px-10 flex-[.48] md:flex-[.3] md:px-20 rounded-lg border py-2.5 border-assetize-dark-gray hover:bg-zinc-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="text-white flex-[.48] md:flex-[.3] hover:bg-assetize-primary py-2.5 rounded-lg px-8 md:px-14 bg-assetize-primary"
                        >
                            {loading ? "Uploading..." : "Update Listing"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditProperty;
