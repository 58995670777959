import { MainButton } from "Components/elements/Button/Index";
import { useNavigate } from "react-router-dom";
import React from "react";
import { NullImg } from "Assets/Index";

interface PropertyProps {
    property: any;
    isGrid: boolean;
}

const DeveloperPropertyCard = ({ property, isGrid }: PropertyProps) => {
    const navigate = useNavigate();

    const isAvailable = property.availabilityStatus === "AVAILABLE";

    const statusStyle = {
        background: isAvailable ? " bg-green-200 " : "bg-red-200",
        text: isAvailable ? "text-assetize-green-text" : "text-assetize-primary",
        textContent: isAvailable ? "Available" : "Sold Out"
    };

    return (
        // <div className="grid grid-cols-3 gap-4">
        <div
            className={`${
                !isGrid ? "flex justify-between items-center w-full" : "grid gap-4"
            }  my-3 w-full border border-assetize-gray rounded-lg p-2`}
        >
            <div
                onClick={() => navigate(`${property.id}`, { state: property })}
                role="button"
                tabIndex={0}
                onKeyDown={() => navigate(`${property.id}`)}
                className={`${
                    !isGrid ? "flex flex-[1] justify-between items-center w-full" : "mb-5"
                }  border border-assetize-gray rounded-lg p-2 `}
            >
                <div
                    className={`${!isGrid ? "relative md:w-40 w-full h-[155px]" : "relative w-full h-32"} rounded-t-lg  `}
                    style={
                        true
                            ? {
                                  backgroundImage: `url(${NullImg})`
                              }
                            : {}
                    }
                >
                    <img
                        src={property.propertyGallery.propertyImages[0]}
                        className={`${!isGrid ? "w-full h-full object-cover" : "w-full h-full object-cover"} rounded-t-lg`}
                        alt={property.propertyName}
                    />
                </div>
                <div className={`${!isGrid ? " flex flex-col md:flex-row" : "flex flex-col "} w-full`}>
                    <div className={`${!isGrid ? "flex items-center flex-[.3] " : "flex flex-col"} `}>
                        <div className={`${!isGrid ? "ml-2" : "py-2"}`}>
                            <div className={`${!isGrid ? "flex items-center   justify-start" : "flex justify-between w-full"} `}>
                                <h4 className="text-sm md:text-md font-latoBold text-assetize-dark-gray ">{property?.propertyName}</h4>
                                <span
                                    className={`px-2 py-1 h-5 w-20 text-center  text-[10px] rounded-full  ${
                                        isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                                    } ${statusStyle.text}`}
                                >
                                    {statusStyle.textContent}
                                </span>
                            </div>
                            <p className="my-1 text-xs font-latoRegular text-assetize-dark-gray">{property.location}</p>
                            <h3 className="text-lg text-assetize-primary font-latoBold">${property.fiatValue?.toLocaleString("en-Us")}</h3>
                        </div>
                    </div>
                    <div
                        className={`${
                            !isGrid
                                ? "flex w-full border-t my-1 md:hidden border-assetize-gray"
                                : "w-full border-t my-1 border-assetize-gray "
                        }`}
                    />

                    <div
                        className={`${
                            !isGrid
                                ? "ml-2 flex md:flex-col justify-between md:justify-center  items-center flex-[.4]"
                                : "flex md:flex justify-between"
                        }`}
                    >
                        <p className="text-sm text-assetize-light-text font-latoLight">PRICE PER SHARE</p>
                        <p
                            className={`${
                                !isGrid ? "mt-2" : "mt-0"
                            } text-assetize-light-text font-latoRegular text-sm md:text-lg font-semibold`}
                        >
                            ${property.sharePrice}
                        </p>
                    </div>
                    <div
                        className={`${
                            !isGrid
                                ? "ml-2 flex md:flex-col justify-between md:justify-center  items-center flex-[.4]"
                                : "flex  justify-between"
                        }`}
                    >
                        <p className="text-sm text-assetize-light-text font-latoLight">TOTAL SHARES</p>
                        <p
                            className={`${
                                !isGrid ? "mt-2" : "mt-0"
                            } text-assetize-light-text font-latoRegular text-sm md:text-lg font-semibold`}
                        >
                            {property.numberOfShares}
                        </p>
                    </div>
                    <div className={`${isGrid ? "hidden " : "hidden md:flex justify-center items-center "} w-[100px] hidden  `}>
                        <MainButton>View details</MainButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeveloperPropertyCard;
