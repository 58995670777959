import React from "react";
import { MainButton } from "Components/elements/Button/Index";
import { useNavigate } from "react-router-dom";
import { PropertyStatus } from "types";
import { readableDate } from "Utils/Helpers";

interface PropertyProps {
    property: any;
}

const PropertyCard = ({ property }: PropertyProps) => {
    const navigate = useNavigate();

    const date = readableDate(property.dateAdded ? property.dateAdded : property.property.createdAt);
    return (
        <div
            onClick={() => navigate(`/admin/properties/${property?.property?.id}`, { state: property.property })}
            role="button"
            tabIndex={0}
            onKeyDown={() => navigate(`${property.id}`)}
            className="flex justify-between items-center w-full py-7 pl-5 my-3 border border-assetize-gray rounded-lg"
        >
            <div className="relative w-[30%]">
                <p className="text-md font-latoBold text-assetize-dark-gray ">{property?.property?.propertyName}</p>
            </div>

            <div className="flex w-[20%] items-center">
                <p className="text-md font-latoBold text-assetize-dark-gray ">{date}</p>
            </div>

            <div className="w-[20%]">
                <h3 className="text-lg text-assetize-primary font-latoBold">${property?.property?.fiatValue}</h3>
            </div>
            <div className="w-[20%]">
                <p className="  text-assetize-light-text font-latoRegular text-lg font-semibold">Successful</p>
            </div>
            <div className={` w-[20%]`}>
                <button type="button" className="flex-[.1] text-assetize-primary text-md text-base font-semibold underline">
                    View
                </button>
            </div>
        </div>
    );
};

export default PropertyCard;
