import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DeveloperType, KycDocsProps, PropertyType } from "types/Auth.types";

interface AdminState {
    developersRequest: DeveloperType[];
    developerData: DeveloperType | null;
    kycDocuments: KycDocsProps | null;
    propertyData: PropertyType | null;
    propertyImages: string[];
    floorPlans: string[];
    legalDocuments: string[];
}

const initialState: AdminState = {
    developersRequest: [],
    developerData: null,
    kycDocuments: null,
    propertyData: null,
    propertyImages: [],
    legalDocuments: [],
    floorPlans: []
};

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        addDevelopersRequest: (state, { payload }: PayloadAction<DeveloperType[]>) => {
            state.developersRequest = [...payload];
        },
        addDeveloperData: (state, { payload }: PayloadAction<DeveloperType>) => {
            state.developerData = payload;
        },
        addDeveloperKycData: (state, { payload }: PayloadAction<KycDocsProps>) => {
            state.kycDocuments = payload;
        },
        addPropertyData: (state, { payload }: PayloadAction<PropertyType>) => {
            state.propertyData = payload;
        },
        addLegalDocuments: (state, { payload }: PayloadAction<string[]>) => {
            state.legalDocuments = payload;
        },
        addPropertyImages: (state, { payload }: PayloadAction<string[]>) => {
            state.propertyImages = payload;
        },
        addFloorPlans: (state, { payload }: PayloadAction<string[]>) => {
            state.floorPlans = payload;
        }
    }
});

const { actions, reducer: adminReducer } = adminSlice;

export const {
    addDevelopersRequest,
    addDeveloperData,
    addDeveloperKycData,
    addPropertyData,
    addLegalDocuments,
    addPropertyImages,
    addFloorPlans
} = actions;
export default adminReducer;
