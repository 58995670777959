import React from "react";
import { Verified } from "Assets/Index";
import { MainButton } from "Components/elements/Button/Index";

interface SuccessMessageModalProps {
    propertyName: string;
    handleClick: () => void;
}

const SuccessWithdrawInitiate = ({ propertyName, handleClick }: SuccessMessageModalProps) => (
    <div>
        <div className="mt-8 flex flex-col justify-center items-center">
            <img src={Verified} alt="verification successful" className="w-32" />
            <h2 className="text-assetize-primary text-2xl font-latoBold align-center mt-8">Withdrawal Initiated</h2>
            <p className="text-assetize-dark-gray my-2 text-md text-center text-md font-latoRegular">
                Your funds Withdrawal for <span className="font-latoBold">{propertyName}</span> has been initiated and will be approved by
                the admin shortly.{" "}
            </p>

            <div className="mt-5 w-full flex justify-center items-center">
                <div className="w-[65%]">
                    <MainButton onClick={handleClick} type="submit">
                        Done
                    </MainButton>
                </div>
            </div>
        </div>
    </div>
);

export default SuccessWithdrawInitiate;
