/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import { DisabledButton, MainButton, OutlineButton } from "Components/elements/Button/Index";
import GoBack from "Components/elements/Display/GoBack";
import CheckBox from "Components/elements/Forms/CheckBox";
import Input from "Components/elements/Forms/Input";
import ModalPanel from "Components/elements/Modal/Index";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BuySharesConfimation from "../portfolio/modals/BuySharesConfimation";
import { ChevronRIght, GoBack as GoBackIcon } from "Assets/Index";
import { validateAll } from "indicative/validator";
import Radio from "Components/elements/Forms/Radio";
import { GetWalletUsdApi, GetTransactionChargesApi } from "Services";
import InvestorInformation from "../portfolio/modals/InvestorInformation";
import TermsAndCondition from "../portfolio/modals/TermsAndCondition";
import ControlledCheckBox from "Components/elements/Forms/ControlledCheckBox";
import FundWallet from "Pages/Wallets/FundWallet";

type TTansactionType = "FUNDING" | "PRIMARY_SALES" | "SECONDARY_SALES" | "SOLD_OUT_PROPERTY";

const Buyshares = () => {
    const { state } = useLocation();

    const [step, setStep] = useState(1);
    const [numberOfShares, setNumberOfShares] = useState("");
    const [choosePaymentMethod, setChoosePaymentMethod] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<any>({
        token: "",
        agreements: ""
    });
    const [transactionFee, setTransactionFee] = useState(0);
    const [transactionChargePercentage, setTransactionChargePercentage] = useState(0);
    const [token, setToken] = useState("");
    const [calculatedShare, setCalculatedShare] = useState(0);
    const [calculatedSharePrice, setCalculatedSharePrice] = useState(0);
    const [calculatedToken, setCalculatedToken] = useState(0);
    const [checkInvestorInformForm, setCheckInvestorInformForm] = useState(false);
    const [checkTermsAndConditionAgreement, setCheckTermsAndConditionAgreement] = useState(false);
    const [selectedPaymentOption, setSelectedOption] = useState<any>({});
    const [paymentMethodChoosen, setPaymentMethodChoosen] = useState(false);
    const [stageOneDone, setStageOneDone] = useState(false);
    const [Walletbalance, setWalletBalance] = useState(0);
    const [showInvestorFormModals, setShowInvestorFormModals] = useState(false);
    const [showTermsAndConditions, setShowTermsAndCondtionModals] = useState(false);
    const [loadingSharePrice, setLoadingSharePrice] = useState(false);

    const { id, availableNumberOfShares, sharePrice, fiatValue, computedRatioOfTokensPerShare, propertyName, totalNumberOfIssuedTokens } =
        state;

    const transactionType: TTansactionType = "PRIMARY_SALES";

    const [showFundingCard, setShowFundingCard] = useState(false);

    const radioOptions = [
        {
            value: "TokenWallet",
            label: "Token Wallet"
        },

        {
            value: "UsdWallet",
            label: "USD Wallet"
        }
    ];

    const getWallet = async () => {
        try {
            const res = await GetWalletUsdApi();

            setWalletBalance(res.balance);
        } catch (error) {
            setWalletBalance(0);
        }
    };

    useEffect(() => {
        getWallet();
    }, []);

    const handleRadioChange = (value: any) => {
        setSelectedOption({
            value,
            label: value === "TokenWallet" ? "Token Wallet" : "USD Wallet",
            amount: 1000
        });
        setChoosePaymentMethod(false);
        setPaymentMethodChoosen(true);
    };

    useEffect(() => {
        setCalculatedSharePrice(sharePrice);
    }, [sharePrice]);
    useEffect(() => {
        if (Number.isInteger(parseInt(token, 10)) && parseInt(token, 10) <= parseInt(availableNumberOfShares, 10)) {
            setLoadingSharePrice(true);
            GetTransactionChargesApi(transactionType)
                .then((res) => {
                    setLoadingSharePrice(false);
                    setTransactionChargePercentage(res);
                    const sharePrice = parseInt(token, 10) * calculatedSharePrice;
                    const tokenPrice = computedRatioOfTokensPerShare * parseInt(token, 10);
                    const transaction_fee = sharePrice * parseFloat(res);
                    setTransactionFee(transaction_fee);
                    setCalculatedShare(sharePrice);
                    setCalculatedToken(tokenPrice);
                })
                .catch((error) => {
                    setLoadingSharePrice(false);
                });
        }
    }, [token, calculatedSharePrice, computedRatioOfTokensPerShare]);

    useEffect(() => {
        if (
            Number.isInteger(parseInt(token, 10)) &&
            parseInt(token, 10) <= parseInt(availableNumberOfShares, 10) &&
            checkInvestorInformForm &&
            checkTermsAndConditionAgreement
        ) {
            setStageOneDone(true);
        } else {
            setStageOneDone(false);
        }
    }, [token, checkInvestorInformForm, checkTermsAndConditionAgreement, transactionFee]);

    const continueToBuyShare = () => {
        setStep(2);
    };

    const navigate = useNavigate();
    const goBackPrevious = () => {
        if (step > 1) {
            setStep(step - 1);
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="p-1 md:p-5  ">
            <div className="bg-white h-full rounded pb-20 md:p-5">
                <div
                    className="flex justify-start items-center cursor-pointer"
                    onClick={() => goBackPrevious()}
                    onKeyDown={() => goBackPrevious()}
                >
                    <GoBackIcon className="w-8 h-8 pl-3  text-assetize-primary" />
                    <p className="ml-2 font-latoBold font-[18px] text-assetize-dark">Back</p>
                </div>

                <div className="px-5">
                    <div className="w-full rounded-md bg-assetize-gray h-3 mt-5">
                        <div className={`${step === 1 ? "w-1/2" : "w-full"} bg-assetize-primary h-full rounded-md`} />
                    </div>
                </div>
                <div className="flex-col gap-5 flex md:flex-row justify-around px-5 mt-10">
                    <div className="flex-[.5] ">
                        <h2 className="text-xl mt-8 font-latoBold">
                            {step === 1 ? " How many shares will you be buying? " : "Review your Token Investment Plan"}
                        </h2>
                    </div>
                    <div className="flex-[.5]">
                        {step === 1 ? (
                            <div className="flex justify-center">
                                <div className="">
                                    <div>
                                        <input
                                            className={`
                ${
                    error
                        ? "border-assetize-primary bg-white focus:ring-assetize-primary focus:border-assetize-primary"
                        : "border-assetize-primary  bg-white focus:ring-assetize-primary focus:border-assetize-primary"
                }
             border rounded font-latoRegular bg-[#F59999]/30 focus:bg-[#F59999]/30 focus:ring-assetize-primary focus:border-assetize-primary text-gray-500 w-full p-2  py-3 text-sm appearance-none outline-none`}
                                            type="text"
                                            //   border-1 rounded-md
                                            id="token"
                                            name="token"
                                            value={token}
                                            onChange={(e) => setToken(e.target.value)}
                                            placeholder="Enter number of shares"
                                        />

                                        {error.token && (
                                            <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{error.token}</p>
                                        )}
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <p>
                                            Shares available: <span className="text-assetize-primary">{availableNumberOfShares}</span>
                                        </p>
                                        <p>
                                            Minimum Shares: <span className="text-assetize-primary">${sharePrice?.toLocaleString()}</span>
                                        </p>
                                    </div>
                                    <div className="mt-5">
                                        <h3 className="text-[18px] text-center text-assetize-primary">
                                            {loadingSharePrice ? "Calculating..." : `$${calculatedShare?.toLocaleString()}`}(1 share = $
                                            {calculatedSharePrice?.toLocaleString()})
                                        </h3>
                                        <p className="text-sm text-center">= {calculatedToken} Tokens</p>
                                    </div>
                                    <div>
                                        <div className="flex flex-col  mt-7">
                                            <div className="flex items-start">
                                                <div className="mt-2">
                                                    <ControlledCheckBox
                                                        checked={checkInvestorInformForm}
                                                        onChange={() => {
                                                            setCheckInvestorInformForm(!checkInvestorInformForm);
                                                        }}
                                                        name="checkInvestorInformForm"
                                                        label=""
                                                        error=""
                                                    />
                                                </div>
                                                <p className="pl-2 text-assetize-dark-gray font-latoRegular pt-[-10px]">
                                                    I have reviewed and agree with all the information on the{" "}
                                                    <span
                                                        onClick={() => setShowInvestorFormModals(true)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter" || e.key === "Space") {
                                                                setShowInvestorFormModals(true);
                                                            }
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        className="text-assetize-primary underline cursor-pointer"
                                                    >
                                                        Investor Information Form
                                                    </span>
                                                </p>
                                            </div>

                                            <div className="flex items-start mt-4">
                                                <ControlledCheckBox
                                                    checked={checkTermsAndConditionAgreement}
                                                    name="checkTermsAndConditionAgreement"
                                                    onChange={() => {
                                                        setCheckTermsAndConditionAgreement(!checkTermsAndConditionAgreement);
                                                    }}
                                                    label=""
                                                    error=""
                                                />
                                                <p className="pl-2 text-assetize-dark-gray font-latoRegular">
                                                    I have read and agree to the
                                                    <span
                                                        onClick={() => setShowTermsAndCondtionModals(true)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter" || e.key === "Space") {
                                                                setShowTermsAndCondtionModals(true);
                                                            }
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        className="text-assetize-primary underline cursor-pointer"
                                                    >
                                                        Terms and Conditions
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between mt-7 ">
                                        <div className=" flex-[.45]">
                                            <OutlineButton onClick={() => goBackPrevious()} className="font-bold ">
                                                Cancel
                                            </OutlineButton>
                                        </div>
                                        {stageOneDone ? (
                                            <div className="flex-[.45]">
                                                <MainButton onClick={continueToBuyShare}>Continue</MainButton>
                                            </div>
                                        ) : (
                                            <div className="flex-[.45]">
                                                <DisabledButton className="bg-[#515151]/80" onClick={() => {}}>
                                                    Continue
                                                </DisabledButton>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className=" px-2 py-3 border border-1 border-assetize-primary rounded ">
                                    <div className="">
                                        <h3 className="font-latoBold text-black text-[16px] md:text-md">{propertyName}</h3>
                                        <p className="font-latoBold text-[15px] text-assetize-primary">${calculatedShare}</p>
                                        <p className="font-latoRegular text-sm">= {totalNumberOfIssuedTokens} AST</p>
                                    </div>
                                    <div className="border-t border-assetize-gray border-b py-1">
                                        <div className="flex justify-between items-center">
                                            <p className="font-latoRegular text-sm">Assetize Tokens</p>
                                            <p className="font-latoRegular text-sm"> {calculatedToken}</p>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <p className="font-latoRegular text-sm">
                                                Transaction Fee ({transactionChargePercentage * 100} % )
                                            </p>
                                            <p className="font-latoRegular text-sm"> ${transactionFee?.toLocaleString()}</p>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <p className="font-latoRegular text-sm">Shares</p>
                                            <p className="font-latoRegular text-sm"> {token}</p>
                                        </div>

                                        <div className="border-t border-assetize-gray border-b flex justify-between items-center py-1">
                                            <p className="font-latoRegular text-sm">Total</p>
                                            <p className="font-latoRegular text-sm">
                                                {" "}
                                                ${(calculatedShare + transactionFee)?.toLocaleString()}
                                            </p>
                                        </div>
                                        <div className="  flex justify-between items-center py-1">
                                            <p>Payment method </p>
                                            <div
                                            // onClick={() => setChoosePaymentMethod(true)}
                                            // role="button"
                                            // tabIndex={0}
                                            // onKeyDown={() => setChoosePaymentMethod(true)}
                                            >
                                                <p className="font-latoRegular text-sm flex items-center text-assetize-primary">
                                                    USD Wallet
                                                </p>
                                            </div>
                                        </div>

                                        <div className="  flex justify-between items-center py-1">
                                            <p className="font-latoRegular text-sm">Wallet Balance</p>
                                            {Walletbalance > calculatedShare ? (
                                                <p className="font-latoRegular text-sm text-green-500">
                                                    {" "}
                                                    ${Walletbalance?.toLocaleString()}
                                                </p>
                                            ) : (
                                                <p className="text-assetize-primary">Insufficient funds</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8">
                                    <p className="text-assetize-primary text-sm font-latoRegular">
                                        *The current share/unit rate is subject to change.
                                    </p>
                                    <p className="text-assetize-dark-gray text-sm font-latoRegular">
                                        You are investing in a dollar mutual fund. Your shares will be converted to dollars to buy units of
                                        this property and interest will be paid according to the rates.
                                    </p>
                                    <div className="flex justify-between mt-7 mb-2">
                                        <div className=" flex-[.45]">
                                            <OutlineButton className="font-bold" onClick={() => setStep(1)}>
                                                Cancel
                                            </OutlineButton>
                                        </div>
                                        {Walletbalance > calculatedShare ? (
                                            <div className="flex-[.45]">
                                                <MainButton onClick={() => setShowModal(true)}>Invest</MainButton>
                                            </div>
                                        ) : (
                                            <div className="flex-[.45]">
                                                <MainButton onClick={() => setShowFundingCard(true)}>Fund Wallet</MainButton>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ModalPanel open={showModal} closeButton closeModal={() => setShowModal(false)}>
                <BuySharesConfimation
                    numberOfShares={token}
                    propertyName={propertyName}
                    totalAmount={(calculatedShare + transactionFee)?.toLocaleString()}
                    onClose={() => setShowModal(false)}
                    id={id}
                    isHome={false}
                />
            </ModalPanel>
            <ModalPanel open={choosePaymentMethod} closeButton closeModal={() => setChoosePaymentMethod(false)}>
                <div className="container p-5 mx-auto my-2 rounded bg-white w-[90%] md:w-[100%]">
                    <form>
                        <h3 className="text-md font-latoRegular mb-5 text-center text-assetize-primary">Payment Method</h3>
                        <p className="text-[18px] text-assetize-dark-gray py-2">Select Payment method to complete transaction</p>
                        <div className="mt-5 w-full flex justify-between items-center">
                            <div className="flex flex-col justify-center w-full">
                                <Radio
                                    options={radioOptions}
                                    name="property-owner"
                                    selectedValue={selectedPaymentOption}
                                    onChange={handleRadioChange}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </ModalPanel>
            <ModalPanel open={showInvestorFormModals} closeButton closeModal={() => setShowInvestorFormModals(false)}>
                <InvestorInformation
                    cancel={() => {
                        setShowInvestorFormModals(false);
                        setCheckInvestorInformForm(false);
                    }}
                    accept={() => {
                        setCheckInvestorInformForm(true);
                        setShowInvestorFormModals(false);
                    }}
                />
            </ModalPanel>
            <ModalPanel open={showTermsAndConditions} closeButton closeModal={() => setShowTermsAndCondtionModals(false)}>
                <TermsAndCondition
                    cancel={() => {
                        setShowTermsAndCondtionModals(false);
                        setCheckTermsAndConditionAgreement(false);
                    }}
                    accountType="investor"
                    accept={() => {
                        setCheckTermsAndConditionAgreement(true);
                        setShowTermsAndCondtionModals(false);
                    }}
                />
            </ModalPanel>

            {showFundingCard && <FundWallet closeModal={() => setShowFundingCard(false)} />}
        </div>
    );
};

export default Buyshares;
