import { MainButton } from "Components/elements/Button/Index";
import AuthLogoDisplay from "Components/elements/Display/AuthLogoDisplay";
import Input from "Components/elements/Forms/Input";
import Password from "Components/elements/Forms/Password";
import { forgotPassword, resetPassword } from "redux/Actions/AuthActions";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { validate, validateAll, validations } from "indicative/validator";
import { checkPasswordRequirements } from "Utils/Helpers";
import { toast } from "react-toastify";
import { ResendUserPasswordResetOTPApi } from "Services";

interface ForgotPasswordProps {
    handleForgotPassword: (email: string) => void;
    isLoading: boolean;
}

const ForgotPassDisplay = ({ handleForgotPassword, isLoading }: ForgotPasswordProps) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState();
    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const textClass = isActive ? "text-purple-500" : "text-assetize-primary";

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const rules = {
            email: "required|email"
        };

        const data = {}; // data object to validate

        const messages = {
            "email.required": "email is required",
            "email.email": "Please enter a valid email address"
        };
        validate({ email }, rules, messages)
            .then(() => {
                handleForgotPassword(email);
            })
            .catch((err) => {
                setError(err[0].message);
            });
    };
    return (
        <div className="flex flex-col items-center w-full mt-24 font-latoRegular">
            <AuthLogoDisplay />
            <div className="md:mt-20 md:w-[60%]">
                <h2 className="mt-4 text-2xl text-center text-assetize-primary font-latoBold align-center">Forgot Password</h2>
                <p className="text-sm text-center text-assetize-dark-gray text-md align-center ">
                    Enter your Assetize email address to reset password. You will receive a link to reset your password.
                </p>
            </div>
            <form onSubmit={handleSubmit} className="w-full md:w-[90%] lg:w-[60%] mt-2">
                <div className="py-2">
                    <Input
                        label="Email Address"
                        type="email"
                        name="email"
                        value={email}
                        placeholder="hi@example.com"
                        error={error}
                        size="md"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="pt-24 md:py-3">
                    <MainButton isLoading={isLoading} disabled={isLoading} type="submit">
                        Reset
                    </MainButton>
                </div>
            </form>
        </div>
    );
};

const ResetPassword = () => {
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const navigate = useNavigate();
    const email = localStorage.getItem("forgetEmail");

    const [formData, setFormData] = useState<any>({
        newPassword: "",
        confirmNewPassword: ""
    });
    const [error, setErrors] = useState<any>({});
    const [isActive, setIsActive] = useState(false);

    const handleInputChange = (name: string, value: any) => {
        if (name === "newPassword") {
            setFormData((prevState: any) => ({
                ...prevState,
                newPassword: value
            }));
        } else if (name === "confirmNewPassword") {
            setFormData((prevState: any) => ({
                ...prevState,
                confirmNewPassword: value
            }));
        }
    };

    const handleOtpChange = (name: number, value: string) => {
        if (!otp) {
            return false;
        }
        if (value.length > 1) {
            value = value.slice(0, 1);
        }
        if (Number.isNaN(parseInt(value, 10))) return false;

        setOtp((prevState: string[]) => prevState.map((data: string, index: number) => (index === name ? value : data)));

        const nextIndex = name + 1;
        if (nextIndex < otp.length) {
            const nextInput = document.getElementsByName("otp")[nextIndex];
            if (nextInput) {
                setTimeout(() => {
                    (nextInput as HTMLInputElement).focus();
                }, 0);
            }
        }

        return true;
    };

    const handleOtpFocus = (index: number) => {
        setOtp((prevState: string[]) => prevState.map((data: string, i: number) => (i === index ? "" : data)));

        const currentInput = document.getElementsByName("otp")[index];
        if (currentInput) {
            (currentInput as HTMLInputElement).focus();
        }
    };

    const updatePassword = async (otp: string) => {
        const email = localStorage.getItem("forgetEmail");
        try {
            await dispatch(resetPassword(email, formData.newPassword, otp));

            setFormData({
                newPassword: "",
                confirmNewPassword: ""
            });

            toast.success("Password has been reset, login to continue");
            navigate("/auth/login");
        } catch (error: any) {
            toast.error("OTP has expired or invalid");
        }
    };

    const resendLink = async () => {
        // send user reset email
        try {
            await ResendUserPasswordResetOTPApi(email || "");
            toast.success("Password reset OTP Resent");
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const reset = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsActive(!isActive);
        const emailAddress = email;
        const otpValue = otp.join("");
        const { newPassword, confirmNewPassword } = formData;

        const rules = {
            newPassword: "required|min:6",
            confirmNewPassword: "required|min:8|same:newPassword",
            otp: "required|min:4|max:4"
        };

        const messages = {
            "newPassword.required": "Password is required",
            "newPassword.min": "Password must be at least 8 characters",
            "confirmNewPassword.same": "Passwords do not match",
            "newPassword.function":
                "Password must contain at least one special character, one uppercase letter, one lowercase letter, and one digit",
            "confirmNewPassword.required": "Confirm password is required",
            "otp.required": "OTP is required",
            "otp.min": "OTP must be at 4 digits",
            "otp.max": "OTP must be  4 digits"
        };

        const data = {
            newPassword,
            confirmNewPassword,
            otp: otpValue
        };
        validateAll(data, rules, messages)
            .then(() => {
                updatePassword(otpValue);
            })
            .catch((err: any) => {
                const formattedErrors = {} as any;
                err.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setErrors(formattedErrors);
            });
    };

    return (
        <div className="flex flex-col items-center w-full mt-20 font-latoRegular">
            <AuthLogoDisplay />
            <div className="flex flex-col items-center w-full md:mt-16">
                <h2 className="mt-4 text-2xl text-assetize-primary font-latoBold align-center">Reset Password</h2>
                <p className="text-sm text-assetize-dark-gray align-center">Enter new password below to login</p>
            </div>
            <form className="w-full md:w-[90%] lg:w-[60%] mt-2">
                <div>
                    <div className="flex flex-row items-center justify-center my-2">
                        <p>Enter OTP sent to {email}</p>
                        {otp.map((data: string, index: number) => (
                            <input
                                className="mx-2 text-center border-2 rounded-md w-7 h-7 md:w-10 md:h-10 border-assetize-primary text-assetize-primary"
                                type="text"
                                name="otp"
                                maxLength={1}
                                key={index}
                                value={data}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                onFocus={() => handleOtpFocus(index)}
                            />
                        ))}
                    </div>

                    {error.otp && <p className="mb-5 text-xs font-latoRegular text-assetize-primary ">{error.otp}</p>}
                </div>
                <div className="py-2">
                    <Password
                        label="New Password"
                        name="newPassword"
                        value={formData.newPassword}
                        placeholder="Enter password"
                        error={error.newPassword}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="py-2">
                    <Password
                        label="Confirm New Password"
                        name="confirmNewPassword"
                        value={formData.confirmNewPassword}
                        placeholder="Enter password"
                        error={error.confirmNewPassword}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="pt-20 md:py-3">
                    <MainButton
                        onClick={reset}
                        className={`transition-all ease-in-out duration-300 px-4 py-2 ${
                            isActive ? "bg-red-200 hover:bg-red-200 text-white" : "bg-assetize-primary hover:bg-assetize-primary text-white"
                        }`}
                    >
                        Save Password
                    </MainButton>
                </div>
                <div className="py-2">
                    <p className="flex justify-center">
                        <span className="text-sm text-center text-underline text-assetize-dark-gray ">
                            Didn&lsquo;t receive any email? &nbsp;
                        </span>
                        <span
                            onKeyDown={resendLink}
                            role="button"
                            tabIndex={0}
                            onClick={resendLink}
                            className="text-sm text-assetize-primary"
                        >
                            Resend Link
                        </span>
                    </p>
                </div>
            </form>
        </div>
    );
};

const ForgotPassword = () => {
    const [userHasGottenMail, setUserHasGottenMail] = useState(false);
    const [error, setError] = useState("");
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleForgotPassword = async (email: string) => {
        try {
            setIsLoading(true);
            await dispatch(forgotPassword(email));
            localStorage.setItem("forgetEmail", email);

            setUserHasGottenMail(true);
        } catch (error: any) {
            setIsLoading(false);
            setError(error.message as string);
        } finally {
            setIsLoading(false);
        }
    };

    return userHasGottenMail ? <ResetPassword /> : <ForgotPassDisplay handleForgotPassword={handleForgotPassword} isLoading={isLoading} />;
};

export default ForgotPassword;
