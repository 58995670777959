/* eslint-disable no-useless-catch */
import { kycEndpoint } from "Utils/Helpers";
import axiosInstance from "Utils/Https";
import { DeveloperPersonalData, DeveloperType, TUser, kycPayloadType } from "types/Auth.types";
import store from "store";
import axios, { Axios } from "axios";

type UpdatePost = {
    title: string;
    content: string;
    category: string;
    postType: string;
};

/**
 * Description
 * @returns {Promise<DeveloperType[]>}
 */
export const GetDeveloperAuthRequest = async (num?: number): Promise<any> => {
    try {
        const url = `admin/new-developer-account-requests?page=${num}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @param {string} notificationId:string
 * @returns {any}
 */
export const GetDevelpersDetails = async (notificationId: string): Promise<DeveloperPersonalData> => {
    try {
        const url = `admin/new-developer-account-requests/${notificationId}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

interface AuthenticateDeveloperProps {
    verdict: boolean;
    reason: string;
}

/**
 * Description
 * @param {string } developerId:string
 * @param { AuthenticateDeveloperProps} payload:AuthenticateDeveloperProps
 * @returns {any}
 */
export const authenticateDeveloper = async (
    developerId: string,
    notificationId: string,
    payload: AuthenticateDeveloperProps
): Promise<any> => {
    try {
        const url = `admin/review-new-developer/${developerId}?notificationId=${notificationId}`;
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetProperties = async (page?: number): Promise<any> => {
    try {
        const url = `admin/property-listings?page=${page}`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetInvestorsInProperty = async (id: string): Promise<any> => {
    try {
        const url = `admin/properties/${id}/investors`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {string} id:string
 * @returns {any}
 */
export const GetPropertyDetails = async (id: string): Promise<any> => {
    try {
        const url = `admin/pending-listings/${id}`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetAllDevelopers = async (page: number): Promise<any> => {
    try {
        const url = `admin/users/developers?page=${page}`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetAllInvestors = async (page: number): Promise<any> => {
    try {
        const url = `admin/users/investors?page=${page}`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

interface ApprovePropertyProps {
    verdict: boolean;
    reason: string;
    reviewMessage: string;
    id: string;
}

/**
 * Description
 * @param {ApprovePropertyProps} payload:ApprovePropertyProps
 * @returns {any}
 */
export const ApproveProperty = async (payload: ApprovePropertyProps): Promise<any> => {
    try {
        const url = `admin/review-listing/${payload.id}`;
        const res = await axiosInstance.post(url, payload);
        return res;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {string} id:string
 * @returns {any}
 */
export const GetSingleDeveloper = async (id: string, page: number): Promise<any> => {
    try {
        const url = `admin/users/developers/${id}?page=${page}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {string} id:string
 * @returns {any}
 */
export const GetSingleInvestor = async (id: string, page: number): Promise<any> => {
    try {
        const url = `admin/users/investors/${id}?page=${page}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {any} payload:any
 * @returns {any}
 */
export const CreateNewPost = async (payload: any): Promise<any> => {
    try {
        const url = "learn/create-new-post";
        const formData = new FormData();

        formData.append("headerImage", payload.headerImage);
        formData.append("title", payload.title);
        formData.append("content", payload.content);
        formData.append("category", payload.postType.toLowerCase());
        formData.append("postType", payload.postType);

        const res = await axiosInstance.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @param {string} id:string
 * @returns {any}
 */
export const DeleteDeveloper = async (id: string): Promise<any> => {
    try {
        const url = `admin/developers/${id}/remove`;
        const res = await axiosInstance.delete(url);
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {string} id:string
 * @returns {any}
 */
export const DeleteInvestor = async (id: string) => {
    try {
        const url = `admin/investors/${id}/remove`;
        const res = await axiosInstance.delete(url);
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @returns {any}
 */

export const GetApplicationStats = async (): Promise<any> => {
    try {
        const url = `admin/application-stats`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {any} payload:any
 * @returns {any}
 */

export const SetExchangeRate = async (payload: any): Promise<any> => {
    try {
        const url = `admin/set-currency-rates`;
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {any} id:string
 * @returns {any}
 */

export const MakePropertyOfTheMonth = async (id: string): Promise<any> => {
    try {
        const url = `admin/${id}/set-property-of-the-week`;
        const res = await axiosInstance.patch(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetApplicationTransactionStats = async (page: number): Promise<any> => {
    try {
        const url = `admin/application-transactions?page=${page}&limit=12`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {any}
 * @returns {any}
 */
export const UpdateLearnPost = async (id: string, payload: UpdatePost): Promise<any> => {
    try {
        const url = `learn/posts/${id}`;
        const res = await axiosInstance.patch(url, payload);

        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @param {any}
 * @returns {any}
 */
export const CreateSafelock = async (payload: any): Promise<any> => {
    try {
        const url = `admin/safelock/create-investment-assets`;
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetAdminNotification = async () => {
    try {
        const url = "admin/notifications";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetAdminPropertyAnalytics = async (id: string, timeFrame: string) => {
    try {
        const url = `admin/properties/${id}/performance?timeFrame=${timeFrame}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetApplicationTotalTransactionStats = async () => {
    try {
        const url = `admin/application-investments`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetApplicationOverviewStats = async (timeFrame: string) => {
    try {
        const url = `admin/application-investments/get-by-time-frame?timeFrame=${timeFrame}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const ActivateAccount = async (payload: { email: string }) => {
    try {
        const url = "admin/users/reactivate-user-account";
        const res = await axiosInstance.patch(url, payload);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const GetSafeLockAssets = async () => {
    try {
        const url = `wallets/safelock/safelock-assets`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};
