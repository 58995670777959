import React, { useState } from "react";
import { Verified } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Textarea from "Components/elements/Forms/TextArea";
import { authenticateDeveloper } from "Services/apis/admin.api";
import { useNavigate } from "react-router-dom";
import { BuyShares } from "Services";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";

interface AnswerProps {
    id: string;
    isHome: boolean;
    onClose: () => void;
    numberOfShares: string;
    totalAmount: string;
    propertyName: string;
}

const BuySharesConfimation = ({ id, isHome, onClose, numberOfShares, totalAmount, propertyName }: AnswerProps) => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    const buyShares = async () => {
        setError("");

        try {
            setLoading(true);
            const payload = {
                id,
                numberOfShares: parseInt(numberOfShares, 10),
                paymentMethod: "USD"
            };
            const res = await BuyShares(payload);

            setAuthenticated(true);
            toast.success("Purchase was successful");
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const currentLocation = window.location;

    return (
        <div className="px-6 items-center">
            {authenticated ? (
                <div>
                    <div className="mt-8 flex flex-col justify-center items-center">
                        <img src={Verified} alt="verification successful" className="w-32" />
                        <h2 className="text-assetize-primary text-2xl font-latoBold align-center mt-8">Congratulations</h2>
                        <p className="text-assetize-dark-gray my-2 text-md align-center text-center text-md font-latoRegular">
                            Your {totalAmount} investment was successful! <br /> Welcome to building your wealth with Assetize
                        </p>

                        <div className="mt-5 w-full flex justify-center items-center">
                            <div className="w-[80%]">
                                <MainButton
                                    onClick={() => (isHome ? currentLocation.reload() : navigate("/investor/portfolio"))}
                                    type="submit"
                                >
                                    Continue
                                </MainButton>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <ConfirmActionModal
                    headingText="Buy Shares"
                    descriptionText={` You are about to invest $${totalAmount} in ${propertyName}. Please confirm`}
                    handleCancel={() => onClose()}
                    handleAction={buyShares}
                    loading={loading}
                    buttonText="Yes, continue "
                />
            )}
        </div>
    );
};

export default BuySharesConfimation;
