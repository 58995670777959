// store/reducers/authReducer.ts
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { User, UserAccountType } from "types/Auth.types";

interface AuthState {
    user: User | null;
    isLoading: boolean;
    error: string | null;
    isAuthenticated: boolean;
    userType: UserAccountType;
}

const initialState: AuthState = {
    user: null,
    isLoading: false,
    error: null,
    isAuthenticated: false,
    userType: UserAccountType.INVESTOR
};

type AuthSlice = Slice<AuthState>;

const authSlice: any = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
            state.isAuthenticated = true;
            state.error = null;
        },
        logoutUser: (state) => {
            state.user = null;
            state.isLoading = false;
            state.isAuthenticated = false;
            state.error = null;
        },
        loginStart: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        loginSuccess: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
            state.isAuthenticated = true;
        },
        loginFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.isAuthenticated = false;
        },
        forgotPasswordStart: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        forgotPasswordSuccess: (state, action: PayloadAction<User>) => {
            state.isLoading = false;
            state.user = action.payload;
        },
        forgotPasswordFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        welcome: (state, action: PayloadAction<UserAccountType>) => {
            state.userType = action.payload;
        },
        signupStart: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        signupSuccess: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
            state.isAuthenticated = false;
        },
        signupFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.isAuthenticated = false;
        },
        otpCodeStart: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        otpCodeSuccess: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
            state.isAuthenticated = true;
        },
        otpCodeFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.isAuthenticated = false;
        },
        resetStart: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        resetSuccess: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
        },
        resetFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        verifyKycStart: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        verifyKycSuccess: (state) => {
            state.error = null;
            state.isLoading = false;
        },
        verifyKycFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        refreshUserStart: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        refreshUserSuccess: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            state.isLoading = false;
            state.isAuthenticated = true;
        },
        refreshUserError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.isAuthenticated = false;
        }
    }
});

export const {
    refreshUserStart,
    refreshUserSuccess,
    refreshUserError,
    loginUser,
    loginStart,
    loginSuccess,
    loginFailure,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    logoutUser,
    welcome,
    signupStart,
    signupSuccess,
    signupFailure,
    otpCodeStart,
    otpCodeSuccess,
    otpCodeFailure,
    resetStart,
    resetSuccess,
    resetFailure,
    verifyBvnStart,
    verifyBvnSuccess,
    verifyBvnFailure,
    verifyNinStart,
    verifyNinSuccess,
    verifyNinFailure,
    verifyPvcStart,
    verifyPvcSuccess,
    verifyPvcFailure
} = authSlice.actions;
export default authSlice.reducer;
