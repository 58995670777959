import { convertToSentence } from "Utils/Helpers";
import React from "react";
import { FiDownload } from "react-icons/fi";

type Props = {
    document: string;
    name: string;
};

const DisplayDocument = ({ document, name }: Props) => {
    const nameOfDocs = convertToSentence(name);
    return (
        <div className="my-1">
            <a
                href={document}
                className="flex items-center hover:text-assetize-dark-text focus:text-assetize-dark justify-between px-4 py-3 rounded-lg cursor-pointer bg-[#ccc] text-assetize-dark"
                key={document}
                download={document}
                target="_blank"
                rel="noreferrer"
            >
                <p className="fonnt-latoBold "> {nameOfDocs}</p>
                <span>
                    <FiDownload color="#CA1611" />
                </span>
            </a>
        </div>
    );
};

export default DisplayDocument;
