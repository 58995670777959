import { NullImg } from "Assets/Index";
import { truncateText } from "Utils/Helpers";
import React from "react";
import { Link } from "react-router-dom";

interface BlogCardProps {
    heading: string;
    body: string;
    date?: string;
    id: string;
    imgUrl: string;
}

const BlogCard = ({ imgUrl, heading, body, date, id }: BlogCardProps) => {
    const formatDate = (dateString: string | number | Date) => {
        const date = new Date(dateString);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
        return formattedDate;
    };

    return (
        <Link to={`${id}`}>
            <div className="rounded border border-assetize-gray p-2 my-2 flex flex-col-reverse md:flex-row justify-between no-underline hover:text-assetize-primary ">
                <div className="flex-[.7] p-3">
                    <h3 className="font-latoBold text-[18px] mt-3 md:mt-0 text-assetize-dark-text leading-6">
                        {truncateText(heading, 50)}
                    </h3>
                    <p className="text-assetize-light-text mt-3 font-latoRegular text-sm py-1">{body.substring(0, 150)}...</p>{" "}
                    <p className="text-assetize-light-text font-latoLight text-xs mt-3 ">{date ? formatDate(date) : ""}</p>
                    <Link to={`${id}`} className="text-assetize-primary my-2 text-sm flex md:hidden">
                        Read More
                    </Link>
                </div>
                <div
                    className="flex-[.2] w-full h-full rounded overflow-hidden"
                    style={{
                        backgroundImage: `url(${NullImg})`
                    }}
                >
                    <img src={imgUrl} alt={heading} className="h-full md:h-[150px] w-full object-cover overflow-hidden" />
                </div>
            </div>
        </Link>
    );
};

export default BlogCard;
