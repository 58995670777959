import axiosInstance from "Utils/Https";

export const GetSupportApi = async () => {
    const url = "customer-support";
    const res = await axiosInstance.get(url);

    return res.data;
};
export const ContactSupportApi = async (payload: FormData) => {
    const url = "customer-support/new-ticket";
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    const res = await axiosInstance.post(url, payload, config);
    return res.data;
};
