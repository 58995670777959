/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MainButton } from "Components/elements/Button/Index";
import AuthLogoDisplay from "Components/elements/Display/AuthLogoDisplay";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { ResendUserOTPApi, VerifyUserByOTPApi } from "Services";
import { SignupStageType } from "types/Auth.types";
import { verifyOtp } from "redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import store from "store";
import { ClipLoader } from "react-spinners";

const Otp = ({ setStage }: SignupStageType) => {
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const authData = useSelector((state: any) => state.auth);
    const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
    const email = localStorage.getItem("userEmail");
    const [seconds, setSeconds] = useState(60);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const textClass = "text-assetize-primary";

    const resendOtp = async (userEmail: string) => {
        const payload = {
            emailAddress: userEmail
        };
        try {
            setResendLoading(true);
            const res = await ResendUserOTPApi(payload);
            setIsActive(!isActive);
            setSeconds(60);
            toast.success(res.message);
        } catch (error: any) {
            if (error.response.data.error) {
                toast.error(error?.response?.data?.error?.message);
            }
        } finally {
            setResendLoading(false);
        }
    };

    const verifyUserByOtp = async (data: { emailAddress: string; otp: string }) => {
        try {
            const res = await VerifyUserByOTPApi({ emailAddress: data.emailAddress, otp: data.otp });

            setStage("1");
            store.set("atk", res.accessToken);

            toast.success("User registered successfully");
            const accountType = localStorage.getItem("selectedOption");
            let routeTo;

            switch (accountType) {
                case "developer":
                    routeTo = "/auth/verification";
                    break;
                case "investor":
                    routeTo = "/auth/login";
                    break;
                default:
                    routeTo = "/admin";
                    break;
            }

            navigate(routeTo);
        } catch (error: any) {
            toast.error("OTP is invalid or has expired");
        }
    };

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const emailAddress = email as string;
        const otpValue = otp.join("");

        if (otpValue.length === 0) {
            setError("OTP is required");
            return;
        }
        if (otpValue.length < 4) {
            setError("OTP must be 4 digits");
            return;
        }
        setError("");

        const data = { emailAddress, otp: otpValue };
        verifyUserByOtp(data);
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [seconds, isActive]);

    return (
        <div className="flex flex-col items-center w-full mt-16 font-latoRegular">
            <AuthLogoDisplay />
            <div className="flex flex-col mt-5">
                <h2 className="mt-4 text-2xl text-center text-assetize-primary font-latoBold align-center">Verify your Account</h2>
                <p className="mt-2 text-[14px] text-center px-6 text-assetize-dark-gray font-latoBold align-center">
                    Enter 4-digit OTP code sent to <span className="text-assetize-primary font-semibold">{email}</span> to verify your
                    account
                </p>

                <div className="flex flex-col items-center justify-center mt-[48px]">
                    <OtpInput
                        value={otp.join("")}
                        onChange={(value) => setOtp(value.split(""))}
                        numInputs={4}
                        renderInput={(props) => (
                            <input
                                {...props}
                                type="number"
                                inputMode="numeric"
                                className="otp-input focus:outline-none border focus:border-assetize-primary focus:bg-[#F59999]/30"
                                style={{
                                    width: "48px",
                                    height: "48px",
                                    fontSize: "1.5rem",
                                    margin: "0 0.5rem",
                                    borderRadius: "0.25rem",
                                    textAlign: "center"
                                }}
                            />
                        )}
                    />
                    {error && <p className=" text-xs font-latoRegular text-assetize-primary mt-2 ">{error}</p>}
                </div>

                <div className="mx-10 mt-[48px] ">
                    <MainButton isLoading={authData.isLoading} disabled={authData.isLoading} onClick={handleSubmit}>
                        Verify Account
                    </MainButton>
                    <div className="mt-5 text-center text-assetize-primary align-center">
                        <button
                            onClick={() => resendOtp(email as string)}
                            type="button"
                            className={`cursor-pointer text-md md:text-[16px]  mt-[13px] ${textClass}`}
                        >
                            {seconds > 0 ? (
                                <span className="text-assetize-dark ">
                                    {` Resend verification code in 0:${seconds.toString().padStart(2, "0")}Sec`}{" "}
                                </span>
                            ) : (
                                <span className="underline hover:text-assetize-dark " role="button">
                                    {" "}
                                    Resend now {resendLoading && <ClipLoader color="#CA1611" size="20px" />}
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Otp;
