import React, { useState } from "react";
import { FiEyeOff, FiEye } from "react-icons/fi";

type PasswordFieldProps = {
    placeholder?: string;
    name?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    error?: string;
    size?: "sm" | "md" | "lg";
    label?: string;
};

const Password = ({
    placeholder = "Password",
    name = "",
    value = "",
    onChange,
    onFocus,
    error = "",
    size = "md",
    label
}: PasswordFieldProps) => {
    const [open, setOpen] = useState(true);

    const togglePassword = () => {
        setOpen(!open);
    };

    return (
        <div className="">
            <div className="relative flex flex-col">
                <label className="text-sm text-assetize-dark-gray py-2" htmlFor={name}>
                    {label || "Input Label"}
                </label>
                <input
                    type={open ? "password" : "text"}
                    className={` ${size === "sm" && "p-2"}  ${size === "lg" && "p-4"} ${
                        error
                            ? "border-assetize-primary bg-assetize-primary-gradient focus:ring-assetize-primary focus:border-assetize-primary"
                            : "border-assetize-gray  focus:ring-assetize-gray focus:border-assetize-gray"
                    }  border rounded font-latoRegular text-gray-500 w-full p-2  py-3 text-sm apperance-none outline-none `}
                    placeholder={placeholder || "Enter Password"}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                />
                {open ? (
                    <FiEyeOff className="absolute text-[#D0D0D0] right-2 bottom-3 cursor-pointer text-lg" onClick={togglePassword} />
                ) : (
                    <FiEye className="absolute text-[#D0D0D0] right-2 bottom-3 cursor-pointer text-lg" onClick={togglePassword} />
                )}
            </div>
            {error && <p className="mt-1/2 text-xs font-latoRegular text-assetize-primary ">{error}</p>}
        </div>
    );
};

export default Password;
