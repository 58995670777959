/* eslint-disable react/react-in-jsx-scope */
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Input from "Components/elements/Forms/Input";
import Select from "react-select";
import ModalPanel from "Components/elements/Modal/Index";
import { useEffect, useState } from "react";
import { validateAll } from "indicative/validator";
import { SafelockWalletFundsApi, GetSafeLockAssets } from "Services";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import { useNavigate } from "react-router-dom";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";
import Textarea from "Components/elements/Forms/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

type Props = {
    closeModal(): void;
    balance: number;
};

export default function Safelock({ closeModal, balance }: Props) {
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const { user } = useSelector((state: any) => state.auth);
    const [amountToLock, setAmountToLock] = useState("0");
    const [totalamount, setTotalAmount] = useState(0);
    const [duration, setDuration] = useState<string[]>([]);
    const [errors, setErrors] = useState<any>({});
    const [lockDone, setLockDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lockStage, setLockStage] = useState(0);
    const [formIsOkay, setFormIsOkay] = useState(false);
    const [propertyAssets, setPropertyAssets] = useState<string[]>([]);
    const [safeLockPocketName, setSafeLockPocketName] = useState("");
    const [propertyDescription, setPropertyDescription] = useState("");
    const [selectedPropertyDescription, setSelectedPropertyDescription] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (balance && amountToLock && user?.kycVerified === true) {
            if (balance > parseFloat(amountToLock) && user?.kycVerified === true) {
                setFormIsOkay(true);
            } else {
                setFormIsOkay(false);
            }
        }
    }, [balance, amountToLock, user?.kycVerified]);

    const getSafeLockAssets = async () => {
        try {
            const res = await GetSafeLockAssets();

            const options = res.map((property: { propertyName: string }) => ({
                label: property.propertyName,
                value: property.propertyName
            }));
            setPropertyAssets(options);
            return res; // Return the data
        } catch (err: any) {
            toast.error(
                err?.response?.data?.message
                    ? err?.response?.data?.message
                    : " Sorry, we could not process your Withdrawal at the moment, try again later."
            );
            return []; // Return an empty array or appropriate default value
        }
    };

    useEffect(() => {
        getSafeLockAssets();
    }, []);

    const handleOptionChange = async (option: any, context: string) => {
        const { label, value } = option;

        if (context === "SelectProperty") {
            // Handle Select Property
            setSafeLockPocketName(value || null);
            try {
                const assets = await getSafeLockAssets();
                const selectedProperty = assets.find((property: { propertyName: string }) => property.propertyName === value);
                if (selectedProperty) {
                    setSelectedPropertyDescription(selectedProperty.propertyDescription);
                } else {
                    setSelectedPropertyDescription("");
                }
            } catch (error) {
                setSelectedPropertyDescription("");
            }
        } else if (context === "Duration") {
            // Handle Duration
            setDuration(label);
        }
    };

    const lockFunds = async () => {
        try {
            setLoading(true);
            const payload = {
                amount: parseFloat(amountToLock),
                duration,
                assetClassTitle: "USD",
                assetClassId: 1,
                propertyDescription: selectedPropertyDescription,
                safeLockPocketName,
                assetClassDescription: "USD",
                propertyImageUrls: []
            };
            const res = await SafelockWalletFundsApi(payload);

            setLockStage(2);
        } catch (err: any) {
            setLoading(false);
            toast.error(
                err?.response?.data?.message
                    ? err?.response?.data?.message
                    : " Sorry, you cannot lock funds at the moment until your account is verified."
            );
            setLockDone(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const rules = {
            amount: "required|above:1",
            duration: "required",
            // description: 'required',
            safeLockPocketName: "required"
        };

        const messages = {
            "amount.required": "Amount is required",
            "amount.above": "Amount should be greater than 1 USD",
            "duration.required": "Please select duration",
            "description.required": "Description is required",
            "safeLockPocketName.required": "Please select property"
        };
        const data = {
            amount: amountToLock,
            duration: duration[0],
            description: propertyDescription,
            safeLockPocketName
        };
        validateAll(data, rules, messages)
            .then(() => setLockStage(1))
            .catch((err: any) => {
                const formattedErrors = {} as any;
                err.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });

                setErrors(formattedErrors);
                toast.error("Please fill all required fields");
            });
    };

    let componentTODisplay;
    if (lockStage === 0) {
        componentTODisplay = (
            <div className="my-4 space-y-4 font-latoRegular">
                <div className="w-full px-4 md:px-10 mx-auto ">
                    <p className="font-latoBold text-md text-center">Enter the USD amount you wish to safe lock</p>
                    <form className="space-y-6 my-6">
                        <div>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="pb-2 block mt-5 text-sm font-latoRegular">
                                Select Property
                                <Select
                                    id="SelectProperty"
                                    options={propertyAssets}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: 45,
                                            outline: "none",
                                            background: "transparent",
                                            border: errors.safeLockPocketName ? "solid #CA1611 1px" : "solid #D0D0D0 1px",
                                            boxShadow: state.isFocused ? "0 0 5px rgba(202, 22, 17, 0.5)" : "none", // Box shadow when focused
                                            "&:hover": {
                                                border: "solid #CA1611 1px",
                                                boxShadow: "0 0 5px rgba(202, 22, 17, 0.5)" // Box shadow on hover
                                            }
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            background: "white",
                                            color: "black",
                                            "&:hover": { background: "#CA1611" }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            border: "solid #CA1611 1px" // Border color when options are open
                                        })
                                    }}
                                    placeholder="  Select Property"
                                    name="SelectProperty"
                                    aria-errormessage={errors.safeLockPocketName}
                                    onChange={(selectedOption) => handleOptionChange(selectedOption, "SelectProperty")}
                                />
                            </label>
                            {errors.safeLockPocketName && <p className="mt-1 text-xs text-red-600">{errors.safeLockPocketName}</p>}
                        </div>
                        <div>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="textarea" className="mb-2 text-base font-latoRegular font-normal text-gray-800 cursor-pointer">
                                <Textarea
                                    label="Description"
                                    name="description"
                                    placeholder="Property description"
                                    value={selectedPropertyDescription}
                                    onChange={(e) => setPropertyDescription(e.target.value)}
                                    error={errors.description}
                                    disabled
                                />
                            </label>
                            {/* <p className="text-xs text-red-600">{description}</p> */}
                        </div>
                        <div>
                            <Input
                                label="Amount to safe lock"
                                type="number"
                                name="amount"
                                value={amountToLock}
                                placeholder="0.00"
                                size="md"
                                error={errors.amount}
                                onChange={(e) => setAmountToLock(e.target.value)}
                                amountInput
                            />
                        </div>
                        {/* <div className="text-center">
                            <h5 className="text-assetize-primary">
                                Wallet Balance:{' '}
                                <span className="font-normal text-black">
                                     ${(walletBalance - Number(amountToLock)).toLocaleString() || '0'}
                                </span>
                            </h5>
                        </div> */}
                        <div>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="pb-2 block mt-5 text-sm font-latoRegular">
                                Duration
                                <Select
                                    id="duration"
                                    options={[{ label: "3 months" }, { label: "6 months" }, { label: "12 months" }]}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: 45,
                                            outline: "none",
                                            background: "transparent",
                                            border: errors.duration ? "solid #CA1611 1px" : "solid #D0D0D0 1px",
                                            boxShadow: state.isFocused ? "0 0 5px rgba(202, 22, 17, 0.5)" : "none", // Box shadow when focused
                                            "&:hover": {
                                                border: "solid #CA1611 1px",
                                                boxShadow: "0 0 5px rgba(202, 22, 17, 0.5)" // Box shadow on hover
                                            }
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            background: "white",
                                            color: "black",
                                            "&:hover": { background: "#CA1611" }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            border: "solid #CA1611 1px" // Border color when options are open
                                        })
                                    }}
                                    placeholder="Select duration"
                                    name="duration"
                                    aria-errormessage={errors.duration}
                                    onChange={(selectedOption) => handleOptionChange(selectedOption, "Duration")}
                                />
                            </label>
                            {errors.duration && <p className="mt-1 text-xs text-red-600">{errors.duration}</p>}
                        </div>
                        {/* <div className="p-3 space-y-3 border rounded-lg border-assetize-primary">
                            <div className="flex items-center justify-between">
                                <p>Amount to Safe lock</p>
                                <span>${amountToLock.toLocaleString() || '0'}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <p>Interest Earning</p>
                                <span>$0</span>
                            </div>
                            <div className="flex items-center justify-between font-semibold">
                                <p>Total</p>
                                <span>${amountToLock.toLocaleString() || '0'}</span>
                            </div>
                        </div> */}
                        <div className="pt-4">
                            <MainButton disabled={!formIsOkay} onClick={handleSubmit} className="py-3 font-bold">
                                Save
                            </MainButton>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else if (lockStage === 1) {
        componentTODisplay = (
            <div className="container p-5 mx-auto my-2 rounded bg-white w-[90%] md:w-[100%]">
                <p className="text-[18px] text-assetize-dark-gray py-2 text-center">
                    `You are about to safe lock ${amountToLock}. <br />
                    Please confirm{" "}
                </p>
                <div className="mt-8 w-full flex justify-evenly items-center">
                    <div className="w-[40%]">
                        <OutlineButton onClick={() => setLockStage(0)}>Discard</OutlineButton>
                    </div>

                    <div className="w-[40%]">
                        <MainButton isLoading={loading} onClick={lockFunds} type="submit">
                            Continue
                        </MainButton>
                    </div>
                </div>
            </div>
        );
    } else if (lockStage === 2) {
        componentTODisplay = (
            <SuccessMessageModal
                handleClick={() => window.location.reload()}
                descriptiontext="Your funds have been locked successfully"
                headingText="Safe Lock Successful!"
            />
        );
    }

    return (
        <ModalPanel closeModal={closeModal} open closeButton title="Safe Lock Duration">
            {componentTODisplay}
        </ModalPanel>
    );
}
