import { Check, Profile, User } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Input from "Components/elements/Forms/Input";
import { GetSettingsApi, ResetUserPasswordApi, UpdateSettingsApi, DeactivateUser } from "Services";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TUser, UpdatePassType } from "types/Auth.types";
import ModalPanel from "Components/elements/Modal/Index";
import { BounceLoader } from "react-spinners";
import Password from "Components/elements/Forms/Password";
import { LuAlertCircle } from "react-icons/lu";
import { useSelector } from "react-redux";
import KycModal from "Layouts/Kyc/KycModal";

type FormErrorType = {
    oldPasswordError: string;
    newPasswordError: string;
    confirmPasswordError: string;
};

const AccountSettings = () => {
    const { user } = useSelector((state: any) => state.auth);

    const [showKycModal, setShowKycModal] = useState(false);
    const [userData, setUserData] = useState<any>({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        dateOfBirth: ""
    });
    const [error, setError] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        dateOfBirth: ""
    });
    const navigate = useNavigate();

    const [formData, setFormData] = useState<UpdatePassType>({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [formError, setFormError] = useState<FormErrorType>({
        oldPasswordError: "",
        newPasswordError: "",
        confirmPasswordError: ""
    });

    const [deactivateUser, setDeactivateUser] = useState(false);

    const handlePasswordChange = (name: string, value: any) => {
        setFormData((prevState: UpdatePassType) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleInputChange = (name: string, value: any) => {
        if (name === "dateOfBirth" && (value instanceof Date || !Number.isNaN(Date.parse(value)))) {
            value = new Date(value).toISOString().slice(0, 10);
        }

        setUserData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const [openModal, setOpenModal] = useState(false);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);

    const handlePasswordModal = () => {
        setOpenPasswordModal(!openPasswordModal);
    };

    const handleDeactivateUser = async () => {
        try {
            setDeactivateUser(true);
            const res = await DeactivateUser();
            toast.success("User deactivated successfully");
            navigate("/auth/login");
        } catch (error) {
            toast.error("Error deactivating user");
        } finally {
            setDeactivateUser(false);
        }
    };

    const fetchUserSettings = async () => {
        try {
            setIsLoading(true);
            const res = await GetSettingsApi();

            const { email, phoneNumber, ...restUserData } = res;
            const dateOfBirthDate = new Date(res.dateOfBirth).toISOString().split("T")[0];

            setUserData({
                ...restUserData,
                emailAddress: email,
                dateOfBirth: dateOfBirthDate,
                phoneNumber
            });
        } catch (error) {
            toast.error("Error fetching user");
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchUserSettings();
    }, []);

    const updateUserData = async () => {
        try {
            if (userData?.phoneNumber?.length !== 11) {
                setError({ ...error, phoneNumber: "Please enter a valid phone number" });
                return;
            }
            setError({ ...error, phoneNumber: "" });
            setUpdateLoading(true);

            const res = await UpdateSettingsApi(userData);

            if (res.phoneNumber !== undefined) {
                toast.success("Phone number was updated successfully");
            } else if (res.message && res.message.includes("phoneNumber should not be empty")) {
                toast.error("Phone number cannot be empty");
            } else {
                toast.success("Profile updated successfully");
            }
        } catch (error: any) {
            if (error.message === "Please enter a valid phone number") {
                toast.error(error.message);
            } else if ((error as { response?: { status: number } }).response?.status === 400) {
                toast.error("Phone number cannot be empty");
            }
        } finally {
            setUpdateLoading(false);
        }
    };

    const updateChangePassword = async () => {
        if (!formData.oldPassword || !formData.newPassword || !formData.confirmPassword) {
            setFormError({
                oldPasswordError: formData.oldPassword ? "" : "Old password is required",
                newPasswordError: formData.newPassword ? "" : "New password is required",
                confirmPasswordError: formData.confirmPassword ? "" : "Confirm password is required"
            });
            return;
        }

        if (formData.newPassword !== formData.confirmPassword) {
            setFormError({
                oldPasswordError: "",
                newPasswordError: "",
                confirmPasswordError: "Passwords do not match"
            });
            return;
        }

        if (formData.oldPassword === formData.newPassword) {
            setFormError({
                oldPasswordError: "New password cannot be the same as old password",
                newPasswordError: "",
                confirmPasswordError: ""
            });
            return;
        }

        try {
            setLoadingPassword(true);

            const res = await ResetUserPasswordApi(formData);

            setFormData({
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            });
            toast.success("Profile updated successfully");
            setOpenPasswordModal(false);
        } catch (error: any) {
            setLoadingPassword(false);

            const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).*$/;

            if (!passwordPattern.test(formData.newPassword)) {
                alert(formData.newPassword);
                setFormError({
                    oldPasswordError: "",
                    newPasswordError:
                        "Password must be at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter and 1 number",
                    confirmPasswordError: ""
                });
                return;
            }

            if (error.response.status === 400) {
                setFormError({
                    oldPasswordError: "Incorrect old password",
                    newPasswordError: "",
                    confirmPasswordError: ""
                });
            } else {
                setFormError({
                    oldPasswordError: "",
                    newPasswordError: "",
                    confirmPasswordError: ""
                });
            }
            toast.error("Error updating user");
        } finally {
            setLoadingPassword(false);
        }
    };

    if (isLoading) {
        return (
            <div className="w-full h-full flex justify-center items-center ">
                <BounceLoader color="#ca1611" />
            </div>
        );
    }

    return (
        <>
            <div className="p-4 bg-white rounded my-5 ">
                <div className="flex flex-col items-center mt-4 md:flex-row">
                    <img src={User} alt="user profile" className="border-2 rounded-full p-3 w-28 h-28 border-assetize-primary" />
                    <h3 className="text-[30px] capitalize md:pl-20 font-latoBold text-[#000]">
                        {userData?.firstName} {userData?.lastName}
                    </h3>
                </div>
                <div className="flex flex-col md:flex-row  md:items-center md:w-[60%] mt-10 justify-between pl-5">
                    <p className="text-latoBold text-[20px] flex-[.4] text-[#000]">Email</p>
                    <p className="text-latoRegular text-[16px] text-light flex-[.6] w-full">{userData?.emailAddress}</p>
                </div>
                <div className="flex flex-col md:flex-row md:items-center md:w-[60%] justify-between mt-4 pl-5">
                    <p className="text-latoBold text-[20px] flex-[.4] text-[#000]">First Name</p>
                    <div className=" flex-[.6] w-full">
                        <Input
                            value={userData?.firstName}
                            type="text"
                            name="firstName"
                            placeholder="john"
                            error={error.firstName}
                            size="md"
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            disabled={userData?.kycVerified}
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row md:items-center md:w-[60%]  mt-4 pl-5">
                    <p className="text-latoBold text-[20px] flex-[.4] text-[#000]">Last Name</p>
                    <div className="flex-[.6] w-full">
                        <Input
                            value={userData?.lastName}
                            type="text"
                            name="lastName"
                            placeholder="doe"
                            error={error.lastName}
                            size="md"
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            disabled={userData?.kycVerified}
                        />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:items-center md:w-[60%]  mt-4 pl-5">
                    <p className="text-latoBold text-[20px] flex-[.4] text-[#000]">Phone Number</p>
                    <div className="w-full flex-[.6]">
                        <Input
                            value={userData?.phoneNumber as string}
                            type="number"
                            name="phoneNumber"
                            placeholder="Enter phone number"
                            error={error.phoneNumber}
                            size="md"
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:items-center md:w-[60%]  mt-4 pl-5">
                    <p className="text-latoBold text-[20px] flex-[.4] text-[#000]">Date of Birth</p>
                    <div className="w-full flex-[.6]">
                        <Input
                            value={userData?.dateOfBirth}
                            type="date"
                            name="dateOfBirth"
                            placeholder="dateOfBirth"
                            className="uppercase"
                            error={error.firstName}
                            size="md"
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            disabled={userData?.kycVerified}
                        />
                    </div>
                </div>
                {userData?.accountType === "investor" && (
                    <div className="flex flex-col md:flex-row md:items-center md:w-[60%] mt-4 pl-5">
                        <p className="text-latoBold text-[20px] flex-[.4] text-[#000]">KYC Verification</p>
                        <div
                            className={`w-full flex-[.6] flex items-center ${
                                userData?.kycVerified ? "text-assetize-green" : "text-red-500"
                            }`}
                        >
                            {userData?.kycVerified ? (
                                <>
                                    <Check /> <span className="pl-2 text-[16px]">KYC verified</span>
                                </>
                            ) : (
                                <>
                                    {/* <span role="img" aria-label="not-verified" className="text-red-500 text-[16px]">
                                        &#x2716;
                                    </span>
                                    <span className="pl-2 text-[16px]">Not Verified</span> */}
                                    <MainButton onClick={() => setShowKycModal(true)}>Verify Account</MainButton>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className="flex flex-col md:flex-row md:items-center md:w-[159px] font-[14px] md:ml-48 mt-10 md:pl-5">
                    <MainButton isLoading={updateLoading} disabled={updateLoading} onClick={updateUserData}>
                        Save Changes
                    </MainButton>
                </div>

                <div className="mt-24 border-t border-assetize-gray">
                    <h3 className="text-[30px] text-assetize-primary opacity-[.19] ml-5 font-latoBold my-3">Account Security</h3>
                    <div className="flex flex-col justify-start items-start md:flex-row md:items-center md:w-[58%] mt-4 pl-5">
                        <p className="text-latoBold text-[20px] flex-[.6] text-[#000]">Update Password</p>
                        <div className="lg:ml-auto">
                            <OutlineButton
                                className="border-none text-assetize-primary text-[15px] font-bold underline"
                                onClick={() => setOpenPasswordModal(true)}
                                color="primary"
                            >
                                Change password
                            </OutlineButton>
                        </div>
                    </div>
                    {userData?.accountType !== "admin" && (
                        <div className="flex flex-col justify-start items-start md:flex-row md:items-center md:w-[61.5%] mt-4 pl-5">
                            <p className="text-latoBold text-[20px] flex-[.4] text-[#000]"> Account</p>
                            <div className="text-assetize-primary lg:ml-auto">
                                <OutlineButton
                                    className="border-none text-assetize-primary text-[15px] font-bold underline"
                                    onClick={() => setOpenModal(true)}
                                    color="primary"
                                >
                                    Deactivate my Account
                                </OutlineButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ModalPanel closeModal={() => setOpenPasswordModal(false)} open={openPasswordModal} closeButton>
                <h2 className="flex text-assetize-primary text-2xl font-latoBold align-center -mt-4 justify-center">Change Password</h2>
                <form className="w-full px-10 mt-2 justify-center flex flex-col">
                    <div className="py-2">
                        <Password
                            label="Old Password"
                            name="oldPassword"
                            value={formData.oldPassword}
                            placeholder="Enter current password"
                            error={formError.oldPasswordError}
                            size="md"
                            onChange={(e) => handlePasswordChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className="py-2">
                        <Password
                            label="New Password"
                            name="newPassword"
                            value={formData.newPassword}
                            placeholder="Enter password"
                            error={formError.newPasswordError}
                            size="md"
                            onChange={(e) => handlePasswordChange(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className="py-2">
                        <Password
                            label="Confirm New Password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            placeholder="Confirm password"
                            error={formError.confirmPasswordError}
                            size="md"
                            onChange={(e) => handlePasswordChange(e.target.name, e.target.value)}
                        />
                    </div>

                    <div className="pt-20 md:py-3">
                        <MainButton isLoading={loadingPassword} disabled={loadingPassword} onClick={updateChangePassword}>
                            Save Password
                        </MainButton>
                    </div>
                </form>
            </ModalPanel>
            <ModalPanel closeModal={() => setOpenModal(false)} open={openModal} closeButton>
                <div className="flex text-center flex-col px-5">
                    <LuAlertCircle className="text-assetize-primary self-center text-6xl font-latoBold" />
                    <h3 className="text-[#CA1611] text-[24px] self-center ml-5 font-latoBold">Deactivate Account?</h3>
                    <p className="text-latoRegular text-lg ml-5">
                        Are you sure you want to deactivate your account? Deactivation will take 7 days to be processed. Please ensure that
                        you withdraw all funds in your fiat wallet, safelock or fractional shares in your portfolio or share listings.{" "}
                    </p>
                    <div className="flex my-4 justify-center">
                        <OutlineButton className=" border text-assetize-primary md:w-[156px]" onClick={() => setOpenModal(false)}>
                            Cancel
                        </OutlineButton>
                        <MainButton
                            isLoading={deactivateUser}
                            disabled={deactivateUser}
                            onClick={handleDeactivateUser}
                            className="ml-5 md:w-[156px]"
                        >
                            Deactivate
                        </MainButton>
                    </div>
                </div>
            </ModalPanel>
            {user?.accountType === "investor" && (
                <ModalPanel open={showKycModal} closeButton closeModal={() => setShowKycModal(false)}>
                    <KycModal onClose={() => setShowKycModal(false)} />
                </ModalPanel>
            )}
        </>
    );
};

export default AccountSettings;
