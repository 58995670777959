import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { GetAllNotifications, MarkAllNotificationAsRead, MarkNotifcationAsRead } from "Services/apis/notification.api";
import { useSelector } from "react-redux";
import { getErrorMessge, getTimeAgo, readableDate, searchArray } from "Utils/Helpers";
import { Link, useNavigate } from "react-router-dom";
import ModalPanel from "Components/elements/Modal/Index";
import { toast } from "react-toastify";
import { GetAdminNotification } from "Services";

interface MyOption {
    label: string;
    value: string;
}

type NotificationItemProps = {
    imageSrc: string;
    content: string;
    time: string;
};

const options: MyOption[] = [
    { label: "All", value: "All" },
    { label: "Properties", value: "Properties" },
    { label: "Dividends", value: "Dividends" }
];

const NotificationList: React.FC = () => {
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState(options[0].value);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [displayList, setDisplayList] = useState<any[]>([]);
    const [showDetails, setShowDetails] = useState(false);
    const [notificationData, setNotificationData] = useState<any>();
    const [readNotificationLoading, setReadNotificationLoading] = useState(false);
    const { user } = useSelector((state: any) => state.auth);

    const handleFilterChange = (option: MyOption | null) => {
        if (option) {
            setFilter(option.value);
        }
    };
    const navigate = useNavigate();
    const getOptionBackgroundColor = (state: any) => {
        if (state.isSelected) {
            return "#000";
        }
        if (state.isFocused) {
            return "#333";
        }
        return "#000";
    };

    const readNotification = async (notificationId: string) => {
        try {
            const res = await MarkNotifcationAsRead(notificationId);
        } catch (err) {
            const error = getErrorMessge(err);
            toast.error(error);
        }
    };

    const getNotifications = async () => {
        try {
            let res;
            if (user?.accountType !== "admin") {
                res = await GetAllNotifications(user.id);
            } else {
                res = await GetAdminNotification();
            }
            const filternNotifications = user?.accountType === "admin" ? res : res.notifications;
            const sortedNotifications = filternNotifications.sort((a: any, b: any) => {
                const dateA: any = new Date(a.createdAt);
                const dateB: any = new Date(b.createdAt);

                return dateB - dateA;
            });
            setNotifications(sortedNotifications);
            setDisplayList(sortedNotifications);
        } catch (error) {
            const errMsg = getErrorMessge(error);
            toast.error(errMsg);
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    const getOptionColor = (state: any) => (state.isSelected ? "#fff" : "#fff");

    const markAllNotificationAsRead = async () => {
        try {
            setReadNotificationLoading(true);
            const res = await MarkAllNotificationAsRead();
            const updatedNotification = notifications.map((notification) => ({
                ...notification,
                read: true
            }));
            setNotifications(updatedNotification);
            setDisplayList(updatedNotification);
        } catch (err) {
            setReadNotificationLoading(false);

            const errMsg = getErrorMessge(err);
            toast.error(errMsg);
        } finally {
            setReadNotificationLoading(false);
        }
    };

    const categories = [
        "TRANSACTIONS",
        "SHARE_LISTINGS",
        "CREDIT",
        "DEBIT",
        "WITHDRAWAL",
        "REVERSAL",
        "DIVIDEND_REMINDER",
        "CONVERSION_TRANSACTION"
    ];

    useEffect(() => {
        if (filter === "All") {
            setDisplayList(notifications);
        } else if (filter === "Properties") {
            const data = notifications.filter(
                (item) =>
                    item.notificationType === "TRANSACTIONS" ||
                    item.notificationType === "SHARE_LISTINGS" ||
                    item.notificationType === "CREDIT" ||
                    item.notificationType === "DEBIT" ||
                    item.notificationType === "WITHDRAWAL" ||
                    item.notificationType === "REVERSAL" ||
                    item.notificationType === "CONVERSION_TRANSACTION"
            );
            setDisplayList(data);
        } else {
            const data = notifications.filter((item) => item.notificationType === "DIVIDEND_REMINDER");
            setDisplayList(data);
        }
    }, [filter]);

    useEffect(() => {
        const searchResult = searchArray(notifications, search);
        setDisplayList(searchResult);
    }, [search]);

    const handleView = (id: string, type: string) => {
        readNotification(id);
        const updatedNotifications = notifications.map((notification) => {
            if (notification.id === id) {
                return { ...notification, read: true };
            }
            return notification;
        });
        setNotifications(updatedNotifications);
        setDisplayList(updatedNotifications);

        // Open the details modal or navigate to the notification's details page as needed
        setShowDetails(true);
        setNotificationData(updatedNotifications.find((notification) => notification.id === id));
    };

    return (
        <div className="bg-white w-full mt-10">
            <div className="flex justify-between items-end  w-full px-4 py-2 flex-col bg-white rounded-md mb-8">
                <div className="flex-col md:flex-row flex items-end md:items-center mt-5 w-full justify-between">
                    <div className=" hidden md:flex items-center mt-5 flex-col md:flex-row md:w-[65%]  justify-start">
                        <div className="w-[100%] hidden md:flex md:w-[40%] ">
                            <ActiveSearch bg="white" border="1px" value={search} showOnMobile onChange={(e) => setSearch(e.target.value)} />
                        </div>{" "}
                        <div className="w-[100%] my-4 md:my-0 md:w-[20%]">
                            <Select
                                options={options.map((option) => ({ value: option.value, label: option.label }))}
                                value={{ value: filter, label: filter }}
                                onChange={handleFilterChange}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        backgroundColor: "#fff"
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: getOptionBackgroundColor(state),
                                        color: getOptionColor(state)
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        color: "#fff"
                                    })
                                }}
                                className="md:w-52 w-full ml-2"
                                menuPlacement="bottom"
                            />
                        </div>
                    </div>
                    <button
                        onClick={markAllNotificationAsRead}
                        onKeyDown={markAllNotificationAsRead}
                        type="button"
                        className="mt-5 md:mt-0 text-end text-assetize-primary  "
                    >
                        {readNotificationLoading ? "Loading..." : " mark all as read"}
                    </button>
                </div>
                <div className="w-full mt-10">
                    {displayList.map((item: any) => {
                        const dateTime = getTimeAgo(item.createdAt);
                        return (
                            <div className="bg-white flex-col   px-3 md:px-10  border-b border-assetize-gray w-full p-3 md:p-7 md:flex-row flex items-start justify-between">
                                <div className="flex justify-start md:justify-between items-center">
                                    <div
                                        className={`${
                                            !item.read ? "bg-assetize-primary" : "bg-assetize-dark-text"
                                        } w-2 h-2 rounded-full mr-5`}
                                    />

                                    <h4 className="text-sm md:text-md md:pl-0">{item.notificationMessage}</h4>
                                </div>

                                <div className="flex justify-between items-center w-full md:w-32 mt-5 md:mt-0">
                                    <p className="text-assetize-dark-gray text-md">{dateTime}</p>

                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleView(item.id, item.notificationType);
                                            setShowDetails(true);
                                            setNotificationData(item);
                                        }}
                                        onKeyDown={() => {
                                            handleView(item.id, item.notificationType);
                                            setNotificationData(item);
                                        }}
                                        className="underline text-assetize-primary text-md  "
                                    >
                                        View
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <ModalPanel closeModal={() => setShowDetails(false)} open={showDetails} closeButton>
                <div className="flex flex-col px-2 mb-4 font-latoRegular">
                    <div className="flex mt-16 text-3xl font-latoRegular text-assetize-primary">
                        {notificationData?.metaData?.amount ? `$${notificationData?.metaData?.amount?.toLocaleString()}` : "N/A"}
                    </div>
                    <hr className="border-t-4 border-solid " />
                    <div className="flex justify-between text-sm w-full items-start   text-dark">
                        <p>Details </p> <p className="text-right">{notificationData?.notificationMessage || "N/A"}</p>
                    </div>
                    {/* <hr className="border-t-2 border-solid " />
                    <div className="flex justify-between text-sm  text-dark ">
                        <p>Status</p> <p className="text-[#F39B0D]">{notificationData?.actionStatus}</p>
                    </div> */}
                    <hr className="border-t-2 border-solid " />
                    <div className="flex justify-between text-sm  text-dark">
                        <p> Reference</p>
                        {notificationData?.metaData?.transactionReference || "N/A"}
                    </div>
                    <hr className="border-t-2 border-solid " />
                    <div className="flex justify-between text-sm  text-dark">
                        <p> Date</p>

                        {readableDate(notificationData?.createdAt)}
                    </div>
                    <hr className="border-t-2 border-solid " />
                </div>
            </ModalPanel>
        </div>
    );
};

export default NotificationList;
