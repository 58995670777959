import { CarretDown } from "Assets/Index";
import { truncateText } from "Utils/Helpers";
import React, { useEffect, useState } from "react";

interface AboutBoxProps {
    text: string;
}

const AboutBox = ({ text }: AboutBoxProps) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const aboutText = truncateText(text, 150);

    useEffect(() => {
        if (text?.length > 350) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }, [text]);

    return (
        <div className="flex-[.4] border border-gray rounded  md:mx-4  m-2 md:my-3 p-2 mdp-3 ">
            <h3 className="text-assetize-primary text-lg my-1">About Property </h3>
            <div className=" w-full overflow-hidden">
                <p className="text-dark-gray text-sm font-latoRegular my-3 overflow-wrap break-word">{isTruncated ? aboutText : text} </p>
            </div>
            {isTruncated ? (
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => setIsTruncated(false)}
                    onKeyDown={() => setIsTruncated(false)}
                    className="flex items-center"
                >
                    <span className="text-assetize-primary text-sm mr-1 ">Show more</span> <CarretDown />
                </div>
            ) : (
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => setIsTruncated(true)}
                    onKeyDown={() => setIsTruncated(true)}
                    className="flex items-center"
                >
                    <span className="text-assetize-primary text-sm mr-1">Show less</span> <CarretDown />
                </div>
            )}
        </div>
    );
};

export default AboutBox;
