import axiosInstance from "Utils/Https";

export const GetLearnArticlesApi = async (page?: number) => {
    const url = `learn/articles?page=${page}`;
    const res = await axiosInstance.get(url);
    return res.data;
};

export const GetLearnDetailApi = async (id: any) => {
    const url = `learn/posts/${id}`;
    const res = await axiosInstance.get(url);
    return res.data;
};

export const GetLearnNewsApi = async (page?: number) => {
    const url = `learn/news?page=${page}`;
    const res = await axiosInstance.get(url);
    return res.data;
};

export const DeleteArticleApi = async (id: string) => {
    const url = `learn/posts/${id}/delete-post`;
    const res = await axiosInstance.delete(url);
    return res.data;
};
