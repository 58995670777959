import { NotFoundImg } from "Assets/Index";
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

interface PieChartProps {
    activeColor: string;
    soldOutColor: string;
    totalPropertyCount: number;
    soldOutPercentage: number;
    availablePercentage: number;
}

const PieChart: React.FC<PieChartProps> = ({ totalPropertyCount, soldOutPercentage, availablePercentage, activeColor, soldOutColor }) => {
    if (totalPropertyCount === 0) {
        return (
            <div className="flex flex-col justify-center items-center py-10">
                <img src={NotFoundImg} alt="" />
                <p className="text-base font-semibold">No Data Available!</p>
            </div>
        );
    }

    const formattedSoldOutPercentage = `${parseFloat(soldOutPercentage.toFixed(1))}%`;
    const formattedAvailablePercentage = `${parseFloat(availablePercentage.toFixed(1))}%`;

    const options: ApexOptions = {
        labels: ["Active Properties", "Sold Out Properties"],
        dataLabels: { enabled: false },
        legend: {
            position: "bottom",
            horizontalAlign: "center",
            show: true,
            fontSize: "16px"
        },
        series: [parseFloat(formattedAvailablePercentage), parseFloat(formattedSoldOutPercentage)],
        chart: {
            type: "donut",
            toolbar: {
                show: false
            },
            height: "100%"
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "83%",
                    labels: {
                        show: true,
                        name: { show: true, fontSize: "24px", fontFamily: "Lato", fontWeight: 700 },
                        total: {
                            show: true,
                            formatter: (w: string) => totalPropertyCount.toString(),
                            label: "Properties"
                        },
                        value: { show: true, fontSize: "24px", fontFamily: "Lato", fontWeight: 700, formatter: (w: string) => `${w}%` }
                    }
                }
            }
        },
        colors: [activeColor, soldOutColor],
        responsive: [
            {
                breakpoint: 320,
                options: {
                    chart: {
                        width: 500
                    }
                }
            }
        ]
    };

    return (
        <div className="flex justify-center items-center text-md h-full">
            <ReactApexChart
                options={options}
                series={[parseFloat(formattedAvailablePercentage), parseFloat(formattedSoldOutPercentage)]}
                type="donut"
                width={parseInt("280", 10)}
                height={parseInt("280", 10)}
            />
        </div>
    );
};

export default PieChart;
