import { MainButton } from "Components/elements/Button/Index";
import GoBack from "Components/elements/Display/GoBack";
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import { Walletprops, safeLockProps } from "types/data";
import React, { useEffect, useState } from "react";
import SafeLockPocketCard from "./SafeLockPocketCard";
import { SafelockWalletApi, GetWalletUsdApi } from "Services";
import { ScaleLoader } from "react-spinners";
import Safelock from "./Safelock";
import { getErrorMessge, searchArray } from "Utils/Helpers";
import { NotFoundImg } from "Assets/Index";
import { toast } from "react-toastify";

interface WalletcardProps {
    item: Walletprops | safeLockProps;
    walletKeys: (keyof Walletprops)[];
    title?: string;
    ctaAction: (choice: string) => void;
    ctaTitle?: string;
    slug?: string;
    pockets?: any;
}

const SafeLockPocket = () => {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [safeLockWallet, setSafeLockWallet] = useState<safeLockProps>({
        balance: 0,
        totalBalance: 0,
        createdAt: "",
        signedHash: "",
        tierLevel: "",
        type: "",
        updatedAt: "",
        userId: 0,
        walletAddress: "",
        walletId: 0,
        withdrawableBalance: 0
    });
    const [openSafeLock, setOpenSafeLock] = useState(false);
    const [pocketData, setPocketData] = useState<any>([]);
    const [displayList, setDisplayList] = useState<any>([]);
    const [usdWallet, setUsdWallet] = useState<any>([]);

    const getSafeLockWallet = async () => {
        try {
            setLoading(true);
            const res = await SafelockWalletApi();
            const res2 = await GetWalletUsdApi();

            setUsdWallet(res2);
            setSafeLockWallet(res.userSafeLockWallet);
            setPocketData(res.userSafeLockPockets);
            setDisplayList(res.userSafeLockPockets);
        } catch (error) {
            // const err = getErrorMessge(error);
            // toast.error(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getSafeLockWallet();
    }, []);

    useEffect(() => {
        const searchResult = searchArray(pocketData, search);
        setDisplayList(searchResult);
    }, [search]);

    return (
        <div className="w-full">
            {loading ? (
                <div className="flex items-center justify-center mt-64">
                    <ScaleLoader color="#ca1611" />
                </div>
            ) : (
                <div className="mt-5">
                    <GoBack link="/investor/wallets" />
                    <div className="grid grid-cols-1 my-8 gap-y-8 lg:grid-cols-3 lg:gap-x-8 items-center text-assetize-primary text-center justify-center">
                        <div className="mx-4 lg:mx-1 rounded-lg bg-[#FDE6E6] py-4 uppercase">
                            <p>available Balance</p>
                            <h3>{safeLockWallet?.totalBalance ? `$${safeLockWallet?.totalBalance.toLocaleString()}` : "$0"}</h3>
                        </div>
                        {/* <div className="mx-4 lg:mx-1  rounded-lg bg-[#FDE6E6] py-4 uppercase">
                            <p>Withdrawable Balance</p>
                            <h3>
                                {safeLockWallet?.withdrawableBalance ? `$${safeLockWallet?.withdrawableBalance.toLocaleString()}` : '$0'}
                            </h3>
                        </div> */}
                        <div className="mx-4 items-center">
                            <MainButton onClick={() => setOpenSafeLock(true)}>+ Add to Safelock</MainButton>
                        </div>
                    </div>
                    <div className="w-[290px]">
                        <ActiveSearch
                            bg="white"
                            border="border-assetize-gray"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search here..."
                        />
                    </div>
                    <div className="lg:py-2 lg:mt-2 bg-white rounded ">
                        <div className="hidden md:flex items-center justify-between w-full px-4">
                            <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-start text-[#625A67]">Property</h3>
                            <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-start text-[#625A67]">Value</h3>
                            <h3 className="w-1/6 text-[14px] font-normal uppercase justify-start text-[#625A67]">Created Date</h3>
                            <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-start text-[#625A67] ">Duration</h3>
                            <h3 className="w-1/6 text-[14px] font-normal uppercase justify-start text-[#625A67]">Due Date</h3>
                            <h3 className="w-1/6 flex-[.1] text-[14px] font-normal uppercase justify-start text-[#625A67] ">Interest</h3>
                            <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-end text-[#625A67]">action</h3>
                        </div>
                        {displayList?.length === 0 && (
                            <div className="flex flex-col items-center justify-center h-full py-10 mt-10 space-y-4 no-item">
                                <img src={NotFoundImg} alt="no-transaction" className="w-32" />
                                <p className="text-base font-semibold">No Transaction History</p>
                            </div>
                        )}
                        {displayList.map((item: any) => (
                            <SafeLockPocketCard key={item.userId} item={item} />
                        ))}
                    </div>
                </div>
            )}
            {openSafeLock && (
                <Safelock
                    closeModal={() => setOpenSafeLock(false)}
                    balance={typeof usdWallet?.balance === "number" ? usdWallet?.balance : parseInt(usdWallet?.balance, 10) || 0}
                />
            )}
        </div>
    );
};

export default SafeLockPocket;
