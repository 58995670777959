import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import { BiSearch } from "react-icons/bi";

interface FormInputProps {
    type?: string;
    name?: string;
    value?: string;
    placeholder?: string;
    required?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    bg?: string;
    border?: string;
    onSearch?: (value?: string) => void;
    showOnMobile?: boolean;
}

const ActiveSearch: FC<FormInputProps> = ({ type, name, value, bg, border, placeholder, showOnMobile, required, onChange, onSearch }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
        value = e.target.value;
    };

    return (
        <div className=" flex items-center flex-col w-full ">
            {/* <BiSearch className=" md:hidden  items-center" color="#CA1611" fontSize="20px" fontWeight="bold" /> */}
            <div
                className={`${
                    showOnMobile ? "flex w-[98%]  " : "hidden w-[90%]"
                }   md:flex items-center border border-${border} bg-${bg} py-1.5  rounded focus-within:none focus-within:none `}
            >
                <BiSearch className="ml-3 mr-3" color="#CA1611" fontSize={`${showOnMobile ? "25px" : "25px"} `} fontWeight="bold" />
                <input
                    className="flex-1 w-[210px] bg-transparent focus:outline-none border-none focus:ring-0 placeholder-[#999] focus:border-0 appearance-none outline-none text-assetize-dark-gray font-latoRegular text-[12px]"
                    type={type}
                    id={name}
                    name={name}
                    value={value}
                    onChange={handleInputChange}
                    placeholder="Search here..."
                    required={required}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            if (onSearch) {
                                onSearch(value);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ActiveSearch;
