import React, { useEffect, useState } from "react";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import { GetDevelpersDetails, GetPropertyDetails, MakePropertyOfTheMonth } from "Services/apis/admin.api";
import { capitalizeFirstLetter, capitalizeText, getErrorMessge, getYearAndMonthAgo, readableDate } from "Utils/Helpers";
import { FiDownload, FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "redux/store";
import MenuList, { Option } from "Components/elements/Menu";
import { CarretDown, ChevronDown, UserIcon, caretVerticalDown } from "Assets/Index";
import { OptionsProps } from "types/data";
import { addFloorPlans, addLegalDocuments, addPropertyData, addPropertyImages } from "redux/slices/admin.slice";
import ModalPanel from "Components/elements/Modal/Index";
import { BiArrowBack } from "react-icons/bi";
import { Carousel, SelectPicker, Toggle } from "rsuite";
import { AuthActionStatus } from "types/Auth.types";
import AnswerModal from "./AnswerModal";
import WrappedMenu from "Components/elements/Menu/WrappedMenu";
import DisplayDocument from "Components/elements/Display/DisplayDocument";
import DisplayAdminDocument from "Components/elements/Display/DisplayAdminDocument";
import SeeInvestors from "./SeeInvestors";
import GoBack from "Components/elements/Display/GoBack";
import PropertyPerformance from "./PropertyPerfomance";
import { toast } from "react-toastify";

const AdminPropertyDetails = () => {
    const { id } = useParams();

    const { propertyData, floorPlans, propertyImages, legalDocuments } = useSelector((state: any) => state.admin);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [_propertyData, setPropertyData] = useState();
    const navigate = useNavigate();
    const [docs, setDocs] = useState<any>();
    const [showModal, setSHowModal] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });
    const [showActionModal, setShowActionModal] = useState({
        open: false,
        action: ""
    });
    const [modalData, setModalData] = useState({
        image: "",
        heading: ""
    });
    const [loading, setLoading] = useState(false);

    const getDevelopersDetails = async (id: string) => {
        try {
            const res = await GetPropertyDetails(id);

            setPropertyData(res);
            dispatch(addPropertyData(res.property));
            setDocs({
                approvalPermitOfProject: res?.approvalPermitOfProject,
                financialProjectionsOfProject: res?.financialProjectionsOfProject,
                insuranceAndPerformanceBondFromContractors: res?.insuranceAndPerformanceBondFromContractors,
                legalCompliance: res?.legalCompliance,
                projectPlan: res?.projectPlan,
                titleDocumentOfProject: res?.titleDocumentOfProject
            });
            dispatch(addLegalDocuments(res.legalDocuments));
            dispatch(addFloorPlans(res.floorPlans));
            dispatch(addPropertyImages(res.propertyImages));
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        }
    };

    useEffect(() => {
        if (id !== null) {
            getDevelopersDetails(id as string);
        }
    }, [id]);

    const options: OptionsProps[] = [
        { text: "Approve", value: "approve" },
        { text: "Reject", value: "reject" },
        { text: "Reply", value: "reply" }
        // { text: 'Initiate Withdrawal', value: 'initiate-withdrawal' }
    ];
    const optionsApprove: OptionsProps[] = [
        { text: "See Investors", value: "investors" },
        { text: "See Performance", value: "performance" }
    ];

    const handleOptionsAvailableSelect = (option: Option) => {
        if (option?.value === "investors") {
            setShowActionModal({
                open: true,
                action: "investors"
            });
        } else if (option?.value === "performance") {
            setShowActionModal({
                open: true,
                action: "performance"
            });
        }
    };

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "approve") {
            setShowReplyModal({
                open: true,
                action: "approve"
            });
        } else if (option?.value === "reject") {
            setShowReplyModal({
                open: true,
                action: "reject"
            });
        } else if (option?.value === "reply") {
            setShowReplyModal({
                open: true,
                action: "reply"
            });
        }
    };

    const makePropertyOfTheWeek = async () => {
        try {
            setLoading(true);
            const res = await MakePropertyOfTheMonth(id as string);

            toast.success("This property is now Property of the week!");
        } catch (err) {
            setLoading(false);
            const errMsg = getErrorMessge(err);
            toast.error(errMsg);
        } finally {
            setLoading(false);
        }
    };

    let statusText;

    if (propertyData?.adminApprovalStatus === AuthActionStatus.APPROVED) {
        statusText = (
            <div className="flex-[.2] justify-center flex">
                <div className="bg-assetize-green-gradient flex justify-center  p-1 rounded-2xl">
                    <p className="text-assetize-green-text text-xs ">Approved</p>
                </div>
            </div>
        );
    } else if (propertyData?.adminApprovalStatus === AuthActionStatus.REJECTED) {
        statusText = (
            <div className="flex-[.2] justify-center flex">
                <div className="bg-assetize-primary-gradient flex justify-center  p-1 rounded-2xl">
                    <p className="text-assetize-primary text-xs">Rejected</p>
                </div>
            </div>
        );
    } else {
        statusText = (
            <div className="flex-[.2] flex justify-center">
                <div className="bg-assetize-warning-gradient flex justify-center   p-1 rounded-2xl">
                    <p className="text-[#F39B0D] text-xs">Pending</p>
                </div>
            </div>
        );
    }

    const completeionDate = readableDate(propertyData?.expectedCompletionDate);
    const listedDate = readableDate(propertyData?.createdAt);

    return (
        <div className="container p-4 mx-auto space-y-6">
            <div className="w-20">
                <GoBack link="/admin/properties" />
            </div>

            <div>
                <div className="flex flex-col md:flex-row justify-between lg:items-center lg:flex-row">
                    <div>
                        <h4 className="font-latoBold flex text-sm md:text-md items-center leading-6">
                            {propertyData?.propertyName} <span className="ml-2"> {statusText}</span>
                        </h4>
                        <p>{propertyData?.location}</p>
                        <p>{propertyData?.developer}</p>
                        <h6 className="mt-2 text-assetize-primary">${propertyData?.fiatValue.toLocaleString()}</h6>
                        <p className="my-1 text-xs font-latoItalic text-assetize-dark-gray ">
                            Listed by <span className="text-assetize-primary">{propertyData?.developerName} </span> on{" "}
                            <span>{listedDate}</span>
                        </p>
                    </div>
                    {propertyData?.adminApprovalStatus === AuthActionStatus.APPROVED ? (
                        <div className="flex flex-col items-center  md:flex-row space-y-2 ">
                            <div className="w-[200px] lg:w-[200px] 2xl:w-[200px] md:mt-1 ">
                                <OutlineButton onClick={makePropertyOfTheWeek}>
                                    {loading ? "Loading..." : "Make Property of the week"}
                                </OutlineButton>
                            </div>
                            <div className="bg-assetize-primary w-[180px] rounded-lg ml-2 lg:ml-5">
                                <WrappedMenu
                                    options={optionsApprove}
                                    onSelect={handleOptionsAvailableSelect}
                                    icon={CarretDown}
                                    bg="white"
                                    size="40"
                                    textColor="assetize-dark-gray"
                                    child={<div className="text-white">Choose Option </div>}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col space-y-2">
                            <div className="bg-assetize-primary w-[200px] lg:w-[220px] 2xl:w-[250px] rounded-lg ml-2 lg:ml-5">
                                <WrappedMenu
                                    options={options}
                                    onSelect={handleOptionSelect}
                                    icon={CarretDown}
                                    bg="white"
                                    size="40"
                                    textColor="assetize-dark-gray"
                                    child={<div className="text-white">Choose Option </div>}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="my-10 space-y-4">
                    <div className="my-10 property--img">
                        <Carousel autoplay shape="bar">
                            {propertyImages?.map((imgUrl: string) => (
                                <div className="relative w-full h-full" key={imgUrl}>
                                    <img src={imgUrl} alt="property_img" className="absolute object-cover w-full h-full" />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="p-4 border rounded-lg">
                        <h6>Property Information</h6>
                        <div className="grid w-11/12 grid-cols-2 mx-auto my-8 text-center md:grid-cols-3">
                            <div className="p-10 space-y-2 border-r-2 md:border-r-2">
                                <p>Property Type</p>
                                <p className="font-semibold ">{capitalizeText(propertyData?.propertyType)}</p>
                            </div>
                            <div className="p-10 space-y-2 md:border-r-2">
                                <p> Expected Annual ROI</p>
                                <p className="font-semibold">{propertyData?.expectedAnnualROI}%</p>
                            </div>
                            <div className="p-10 space-y-2 border-t-2 border-r-2 md:border-r-0 md:border-t-0">
                                <p>Total Shares</p>
                                <p className="font-semibold">{propertyData?.numberOfShares}</p>
                            </div>
                            <div className="p-10 space-y-2 border-t-2 md:border-r-2">
                                <p>Investment Type</p>
                                <p className="font-semibold">
                                    {propertyData?.investmentType?.toLowerCase() === "non_rental"
                                        ? "Non-rental"
                                        : propertyData?.investmentType}
                                </p>
                            </div>
                            <div className="p-10 space-y-2 border-t-2 border-r-2">
                                <p>Original value of shares</p>
                                <p className="font-semibold">{propertyData?.fiatValue}</p>
                            </div>
                            <div className="p-10 space-y-2 border-t-2">
                                <p>Expected Completion date</p>
                                <p className="font-semibold">{completeionDate}</p>
                            </div>
                        </div>
                    </div>
                    <div className="px-6 py-4 space-y-2 border rounded-lg">
                        <h6>About Property</h6>
                        <p>{propertyData?.description}</p>
                    </div>
                    <div className="px-6 py-4 space-y-3 border rounded-lg">
                        <h6 className="text-assetize-primary">Documents</h6>
                        <div className="flex-[.4]   md:mx-4  m-2 md:my-3 p-2 mdp-3 ">
                            <DisplayAdminDocument document={docs?.projectPlan} name="projectPlan" />
                            <DisplayAdminDocument document={docs?.legalCompliance} name="legalCompliance" />
                            <DisplayAdminDocument
                                document={docs?.insuranceAndPerformanceBondFromContractors}
                                name="insuranceAndPerformanceBondFromContractors"
                            />
                            <DisplayAdminDocument document={docs?.financialProjectionsOfProject} name="financialProjectionsOfProject" />
                            <DisplayAdminDocument document={docs?.approvalPermitOfProject} name="approvalPermitOfProject" />
                            <DisplayAdminDocument document={docs?.titleDocumentOfProject} name="titleDocumentOfProject" />
                        </div>
                    </div>
                </div>
            </div>
            <ModalPanel open={showReplyModal.open} closeButton closeModal={() => setShowReplyModal({ open: false, action: "" })}>
                <AnswerModal action={showReplyModal.action} id={propertyData?.id?.toString()} isHome={false} />
            </ModalPanel>
            <ModalPanel
                open={showActionModal.open && showActionModal.action === "investors"}
                closeButton
                closeModal={() => setShowActionModal({ open: false, action: "" })}
            >
                <SeeInvestors
                    onClose={() => setShowActionModal({ open: false, action: "" })}
                    id={propertyData?.id?.toString()}
                    isHome={false}
                />
            </ModalPanel>
            <ModalPanel
                open={showActionModal.open && showActionModal.action === "performance"}
                closeButton
                closeModal={() => setShowActionModal({ open: false, action: "" })}
                title="Property Performance"
                modalSize="w-[85%] md:w-[70]"
            >
                {id && <PropertyPerformance id={id} />}
            </ModalPanel>
        </div>
    );
};

export default AdminPropertyDetails;
