import React, { useEffect, useReducer } from "react";

import { Outlet, useNavigate } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import { AuthBg } from "Assets/Index";

const AuthLayout = (props: any) => {
    const navigate = useNavigate();
    const initialState = {
        openMobileSideBar: false
    };
    const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), initialState);
    const { openMobileSideBar } = state;

    useEffect(
        () =>
            // Cleanup method
            () => {
                setState({
                    ...initialState
                });
            },
        []
    );

    return (
        <div className="fixed grid w-screen  h-screen p-3 rounded-lg md:grid-cols-2 lg:p-7">
            <div
                className="relative hidden rounded-tl-lg rounded-bl-lg md:flex md:items-center md:justify-center"
                style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(202, 22, 17, 0.74), rgba(202, 22, 17, 0.74)), url(${AuthBg}) `,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
            >
                <div className="relative inset-0 max-w-xl text-white p-9">
                    <h1 className="text-4xl leading-[60px] font-latoBold">Real Estate Made Easy With ASSETIZE</h1>
                    <p className="text-xl leading-[30px] max-w-md font-latoRegular pr-8">
                        Invest and own a fraction of real estate assets with a seamless tokenized system
                    </p>
                </div>
            </div>
            <div className="flex flex-col justify-between px-4 overflow-scroll overflow-x-hidden  md:items-around ">
                <Outlet />
                <div className="flex items-center justify-end mb-1 md:hidden">
                    <p className="text-[#989898] text-xs md:text-sm  font-latoLight ">English &#40;UK&#41;</p>
                    <AiFillCaretDown color="#989898" fontSize="18px" className="ml-2" />
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;
