import React, { ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { refreshUser } from "redux/Actions/AuthActions";
import store from "store";

interface AuthHocProps {
    component: React.ComponentType<any>;
}

const AuthHoc = ({ component: Component, ...rest }: AuthHocProps) => {
    const authData = useSelector((state: any) => state.auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    useEffect(() => {
        if (!authData.isAuthenticated) {
            const fetchUserData = async () => {
                try {
                    await dispatch(refreshUser());
                    setIsLoading(false);
                } catch (err: any) {
                    navigate("/auth/login");
                }
            };

            const isLoggedIn = store.get("isLoggedIn") === true;
            if (!isLoggedIn) {
                navigate("/auth/login");
            } else {
                fetchUserData();
            }
        } else {
            setIsLoading(false);
        }
    }, [navigate, dispatch]);

    if (isLoading) {
        return (
            <div className="w-screen h-screen flex justify-center items-center ">
                {" "}
                <BounceLoader color="#ca1611" />
            </div>
        );
    }

    const isLoggedIn = store.get("isLoggedIn") === true;
    if (!isLoggedIn) {
        return <Navigate to="/auth/login" replace />;
    }

    return <Component {...rest} />;
};

export default AuthHoc;
