import React, { useState } from "react";

type Props = {
    id?: string;
    name: string;
    label?: string;
    onChange?: () => void;
    error?: string;
    checked: boolean;
};

const ControlledCheckBox = ({ id, name, label = "Check Label", onChange, error = "", checked }: Props) => (
    <div>
        <div className="flex gap-2 items-center justify-center">
            <input
                type="checkbox"
                name={name}
                id={name}
                checked={checked}
                onChange={onChange}
                className="accent h-4 w-4 transition duration-150 ease-in-out"
            />
            <label htmlFor={name} className="text-assetize-dark-gray font-latoRegular text-sm">
                {label || ""}
            </label>
        </div>
        {error && (
            <p className="mt-2 text-xs text-assetize-primary">
                <span className="font-medium">{error}</span>
            </p>
        )}
    </div>
);

export default ControlledCheckBox;
