import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { AiFillCaretDown } from "react-icons/ai";
import { CarretDown } from "Assets/Index";

export interface Option {
    text: string;
    value?: string;
}

interface MenuProps {
    options: Option[];
    onSelect: (option: Option) => void;
    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    bg?: string;
    textColor?: string;
    size?: "24" | "28" | "40";
    child: React.ReactNode;
}

const WrappedMenu: React.FC<MenuProps> = ({ options, onSelect, icon: IconComponent, bg, textColor, size = "24", child }) => {
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    const handleOptionClick = (option: Option) => {
        setSelectedOption(option);
        onSelect(option);
    };

    const getIconSizeClassName = () => {
        if (size === "24") {
            return "w-4 h-4  ";
        }
        if (size === "40") {
            return "w-16 h-16  ";
        }
        return "w-6 h-6  ";
    };

    return (
        <Menu as="div" className="relative flex w-full">
            <div className="flex justify-around items-center w-full">
                <Menu.Button
                    className={`flex w-full justify-center md:justify-around px-4 py-2 text-sm font-medium bg-transparent rounded-md `}
                >
                    <div> {child}</div>
                    <AiFillCaretDown className="text-white ml-3 text-lg pt-1" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute z-50 top-[-50] right-0 mt-2 w-${size} origin-top-right divide-y divide-gray-100 rounded-md bg-${bg} shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                    {options.map((option) => (
                        <div key={option.text} className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        type="button"
                                        className={`${`text-${textColor}`} group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        {option.text}
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default WrappedMenu;
