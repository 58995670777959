import React, { useState } from "react";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import { ListSharesApi, closeShareListing } from "Services/apis";
import { readableDate } from "Utils/Helpers";
import ModalPanel from "Components/elements/Modal/Index";
import CreateListing from "./CreateListing";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";
import { toast } from "react-toastify";

interface PropertyType {
    totalSharesForSale: number;
    sharePrice: string;
    shareNumber: number;
    tokenEquivalent: string;
    annualReturns: string;
    completionDate: string;
    propertyId: string;
    alwaysOpen: boolean;
    fiatValue: number;
    totalShares: number;
    listingId: string;
    originalValueOfShares: number;
    closeDate: string;
}

const ViewListing = ({
    alwaysOpen,
    totalSharesForSale,
    totalShares,
    sharePrice,
    shareNumber,
    tokenEquivalent,
    annualReturns,
    completionDate,
    propertyId,
    fiatValue,
    listingId,
    originalValueOfShares,
    closeDate
}: PropertyType) => {
    const [openModal, setOpenModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const sharePercent = (shareNumber / totalShares) * 100;
    const dateFomat = new Date(completionDate);
    const date = readableDate(dateFomat);
    const [actionData, setActionData] = useState({
        open: false,
        action: ""
    });
    const [loading, setLoading] = useState(false);

    const closeShare = async () => {
        try {
            setLoading(true);
            const res = await closeShareListing(listingId);
            setActionData({
                open: true,
                action: "closeSuccess"
            });
            toast.success("Share listing closed successfully");
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col  mt-7 font-latoRegular">
            <div>
                <h2 className="text-assetize-primary text-[19px] leading-3 pb-2 font-latoBold text-center ">Offer Info</h2>
                <p className="text-sm  text-center ">View information about this offer</p>
                <div className="flex justify-center flex-col  w-full my-4">
                    <h3 className="text-xl text-assetize-primary  text-center ">${parseFloat(sharePrice)} </h3>
                    {/* <p className="text-sm  text-center "> = {shareNumber} shares</p> */}
                </div>
            </div>
            <div className="col-span-2  border rounded-lg font-latoRegular">
                <div className="grid grid-cols-2  text-center md:grid-cols-2">
                    <div className="  border-r-2 md:border-r-2 uppercase p-5">
                        <p>total shares for sale</p>
                        <p className="font-semibold"> {shareNumber}</p>
                    </div>
                    <div className="  border-r-2 uppercase p-5">
                        <p> original value of shares</p>
                        <p className="font-latoBold text-md">${originalValueOfShares}</p>
                    </div>
                    <div className=" border-t-2 border-r-2  uppercase p-5">
                        <p>current value of shares</p>
                        <p className="font-latoBold text-md">${parseInt(sharePrice, 10) / shareNumber}</p>
                    </div>
                    <div className="  border-t-2 border-r-2 uppercase p-5">
                        <p>token equivalent</p>
                        <p className="font-latoBold text-md text-assetize-primary">N/A</p>
                    </div>
                    <div className=" border-t-2 border-r-2 uppercase p-5">
                        <p>estimate annual returns</p>
                        <p className="font-latoBold text-md">{annualReturns} %</p>
                    </div>
                    <div className=" border-t-2 uppercase p-5">
                        <p>property completion date</p>
                        <p className="font-latoBold text-md">{date}</p>
                    </div>
                </div>
            </div>
            <div className=" flex w-full mt-5 space-x-2 justify-between">
                <div className="w-full">
                    <MainButton
                        onClick={() => {
                            setOpenModal(true);
                        }}
                    >
                        Edit Offer
                    </MainButton>
                </div>
                <div className="w-full flex md:hidden ">
                    <OutlineButton
                        onClick={() => {
                            setIsModalOpen(true);
                            setActionData({ open: true, action: "close" });
                        }}
                    >
                        Close Offer
                    </OutlineButton>
                    {isModalOpen && (
                        <ModalPanel
                            closeModal={() => {
                                setActionData({ open: false, action: "" });
                                setIsModalOpen(false);
                            }}
                            open={actionData.action === "close" && actionData.open}
                            closeButton
                        >
                            <ConfirmActionModal
                                headingText="Close Share Listing"
                                descriptionText="Are you sure you want to close this share listing?"
                                handleCancel={() => setActionData({ open: false, action: "" })}
                                handleAction={closeShare}
                                loading={loading}
                                buttonText="Close Listing"
                            />
                        </ModalPanel>
                    )}
                </div>
            </div>

            <ModalPanel open={openModal} closeButton closeModal={() => setOpenModal(false)}>
                <CreateListing
                    isEdit
                    id={propertyId}
                    property={{
                        alwaysOpen,
                        listingId,
                        shareNumber,
                        sharePrice,
                        completionDate: closeDate,
                        fiatValue,
                        expectedAnnualROI: annualReturns
                    }}
                    percentageOwned={sharePercent}
                />
            </ModalPanel>
        </div>
    );
};

export default ViewListing;
