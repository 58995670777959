import React, { useState } from "react";
import { DeleteIcon, Verified } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Textarea from "Components/elements/Forms/TextArea";
import { authenticateDeveloper } from "Services/apis/admin.api";
import { useNavigate } from "react-router-dom";
import { DeleteArticleApi } from "Services";

interface AnswerProps {
    action: string;
    id: string;
    isHome: boolean;
    closeModal: () => void;
}

const AnswerModal = ({ action, id, isHome, closeModal }: AnswerProps) => {
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const navigate = useNavigate();

    const deletePost = async () => {
        setError("");
        try {
            setLoading(true);

            const res = await DeleteArticleApi(id);
            setDeleted(true);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const currentLocation = window.location;

    return (
        <div>
            {deleted ? (
                <div>
                    <div className="mt-8 flex flex-col justify-center items-center">
                        <img src={Verified} alt="verification successful" className="w-32" />
                        <h2 className="text-assetize-primary text-2xl font-latoBold text-center mt-8">Post Deleted</h2>
                        <p className="text-assetize-dark-gray my-2 text-md text-center  text-md w-full">
                            Post has been deleted successfully
                        </p>

                        <div className="mt-5 w-full flex justify-center items-center">
                            <div className="w-[85%]">
                                <MainButton onClick={() => (isHome ? window.location.reload() : navigate("/admin/learn"))} type="submit">
                                    Continue
                                </MainButton>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container flex flex-col items-center justify-center p-5 mx-auto my-2 rounded bg-white w-[90%] md:w-[100%]">
                    <div className="flex flex-col justify-center items-center">
                        <DeleteIcon />
                        <h3 className="text-md text-assetize-primary font-latoRegular mt-1">Delete Post</h3>
                        <h3 className="text-sm font-latoRegular mb-5 text-center text-assetize-dark-grey">
                            Are you sure you want to delete this post? This action cannot be undone
                        </h3>

                        <div className="mt-5 w-full flex justify-center items-center">
                            <div className="w-[45%] pr-1">
                                <OutlineButton onClick={() => closeModal()}>Cancel</OutlineButton>
                            </div>
                            <div className="w-[45%] pl-1">
                                <MainButton isLoading={loading} disabled={loading} onClick={deletePost} type="submit">
                                    Yes
                                </MainButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AnswerModal;
