import Pagination from "Components/elements/Pagination";
import SubHeader from "Components/blocks/SubHeader";
import { DashboardStyle, properties, Option } from "types/data";
import React, { useEffect, useState } from "react";
import PortfolioCard from "./PortfolioCard";
import ShareListingCard from "./ShareListingCard";
import { GetMyListings, GetMyPortfoilio, GetSingleListings } from "Services";
import { BounceLoader } from "react-spinners";
import { NotFoundImg } from "Assets/Index";
import { getErrorMessge, searchArray } from "Utils/Helpers";
import { toast } from "react-toastify";

enum SelectedOption {
    properties,
    shareListing
}

interface PropertyDataProps {
    percentageOwned: number;
    property: any;
    propertyUrl: string;
    sharesOwned: number;
}

function Portfolio() {
    const [dashboardStyle, setDashboardStyle] = useState(DashboardStyle.list);
    const [selectedOption, setSelectedOption] = useState(SelectedOption.properties);
    const [search, setSearch] = useState("");
    const [displayList, setDisplayList] = useState<any[] | null>(null);
    const [displayListShares, setDisplayListShares] = useState<any[] | null>(null);
    const [portfolio, setPortfolio] = useState<any>();
    const [shareListing, setShareListing] = useState<any>();
    const [pageNum, setPageNum] = useState<number>(1);
    const [sharePageNum, setSharePageNum] = useState<number>(1);
    const [filters, setFilters] = useState({
        propertyType: "All",
        investmentType: "All",
        status: "All"
    });

    const [pageData, setPageData] = useState<any>();
    const [sharePageData, setSharePageData] = useState<any>();
    const [loading, setLoading] = useState(false);

    const getProperties = async () => {
        try {
            setLoading(true);
            const res = await GetMyPortfoilio(pageNum);
            const searchList = res?.portfolio.map((item: any) => ({
                dateAdded: item.dateAdded,
                percentageOwned: item.percentageOwned,
                propertyUrl: item.propertyUrl,
                sharesOwned: item.sharesOwned,
                ...item.property
            }));
            setPortfolio(res.portfolio);
            setPageData(res.metaData);
            setDisplayList(res.portfolio);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const getMyListing = async () => {
        try {
            const res = await GetMyListings(sharePageNum);

            const sortedData = res.sort((a: any, b: any) => {
                const dateA: any = new Date(a.updatedAt);
                const dateB: any = new Date(b.updatedAt);

                return dateB - dateA;
            });
            const searchList = res?.map((item: any) => ({
                alwaysOpen: item.alwaysOpen,
                associatedSharePurchaseTransactionId: item.associatedSharePurchaseTransactionId,
                associatedShareSaleTransactionId: item.associatedShareSaleTransactionId,
                closeDate: item.closeDate,
                dateListed: item.dateListed,
                expired: item.expired,
                hasBeenClaimed: item.hasBeenClaimed,
                investorId: item.investorId,
                listingId: item.listingId,
                propertyImages: item.propertyImages,
                shareNumber: item.shareNumber,
                propertyId: item.propertyId,
                sharePrice: item.sharePrice,
                updatedAt: item.updatedAt,
                ...item.propertyData.property
            }));
            setShareListing(sortedData);
            setDisplayListShares(sortedData);
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        }
    };

    useEffect(() => {
        setFilters({
            propertyType: "All",
            investmentType: "All",
            status: "All"
        });
    }, [selectedOption]);

    useEffect(() => {
        const lowercasePropertyType = filters.propertyType?.toLowerCase();

        if (selectedOption === SelectedOption.properties) {
            let filteredProperties = portfolio;
            if (lowercasePropertyType === "all") {
                setDisplayList(portfolio);
            }

            if (lowercasePropertyType !== "all") {
                filteredProperties = portfolio?.filter(
                    (property: any) => property.property.propertyType.toLowerCase() === lowercasePropertyType
                );
            } else {
                filteredProperties = portfolio;
            }

            setDisplayList(filteredProperties);
        } else if (selectedOption === SelectedOption.shareListing) {
            let filteredShareListings = shareListing;

            if (lowercasePropertyType === "all") setDisplayList(filteredShareListings);

            if (lowercasePropertyType !== "all") {
                filteredShareListings = shareListing?.filter(
                    (property: any) => property.propertyData.property.propertyType.toLowerCase() === lowercasePropertyType
                );
            } else {
                filteredShareListings = shareListing;
            }

            setDisplayListShares(filteredShareListings);
        }
    }, [filters.propertyType, selectedOption]);

    useEffect(() => {
        const lowercaseInvestmentType = filters.investmentType?.toLowerCase();

        if (selectedOption === SelectedOption.properties) {
            let filteredProperties = portfolio;

            if (lowercaseInvestmentType === "all") setDisplayList(filteredProperties);

            if (lowercaseInvestmentType !== "all") {
                filteredProperties = portfolio?.filter(
                    (property: any) => property.property.investmentType.toLowerCase() === lowercaseInvestmentType
                );
            }

            setDisplayList(filteredProperties);
        } else if (selectedOption === SelectedOption.shareListing) {
            let filteredShareListings = shareListing;

            if (lowercaseInvestmentType === "all") setDisplayList(filteredShareListings);

            if (lowercaseInvestmentType !== "all") {
                filteredShareListings = shareListing?.filter(
                    (property: any) => property.propertyData.property.investmentType.toLowerCase() === lowercaseInvestmentType
                );
            } else {
                filteredShareListings = shareListing;
            }

            setDisplayListShares(filteredShareListings);
        }
    }, [filters.investmentType, selectedOption]);

    useEffect(() => {
        const lowercaseStatusType = filters?.status.toLowerCase();

        if (selectedOption === SelectedOption.properties) {
            let filteredProperties = portfolio;

            if (lowercaseStatusType === "all") setDisplayList(filteredProperties);

            if (lowercaseStatusType !== "all") {
                filteredProperties = portfolio?.filter(
                    (property: any) => property.property.availabilityStatus.toLowerCase() === lowercaseStatusType
                );
            }

            setDisplayList(filteredProperties);
        } else if (selectedOption === SelectedOption.shareListing) {
            let filteredShareListings = shareListing;

            if (lowercaseStatusType === "all") setDisplayList(filteredShareListings);

            if (lowercaseStatusType !== "all") {
                filteredShareListings = shareListing?.filter(
                    (property: any) => property.propertyData.property.investmentType.toLowerCase() === lowercaseStatusType
                );
            } else {
                filteredShareListings = shareListing;
            }

            setDisplayListShares(filteredShareListings);
        }
    }, [filters.status, selectedOption]);

    const applyFilterOnProperties = (filterType: string, value: string) => {
        if (filterType === "propertyType") {
            setFilters({ ...filters, propertyType: value === "All" ? "all" : value });
        }

        if (filterType === "investmentType") {
            let realValue = value;
            switch (value.toLocaleLowerCase()) {
                case "all":
                    realValue = "all";
                    break;
                case "rental":
                    realValue = "rental";
                    break;
                case "non-rental":
                    realValue = "non_rental";
                    break;
                default:
                    realValue = value;
            }

            setFilters({ ...filters, investmentType: realValue });
        }
        if (filterType === "status") {
            let realValue = value;
            switch (value.toLocaleLowerCase()) {
                case "all":
                    realValue = "all";
                    break;
                case "re-sale":
                    realValue = "RESALE";
                    break;
                case "available":
                    realValue = "AVAILABLE";
                    break;
                case "sold-out":
                    realValue = "SOLD_OUT";
                    break;
                default:
                    realValue = value;
            }
            setFilters({ ...filters, status: realValue });
        }
    };

    useEffect(() => {
        getProperties();
        getMyListing();
    }, []);

    useEffect(() => {
        // Create a function to filter the portfolio based on the search input
        const filterPortfolio = () => {
            // If the search input is empty, reset the displayList to the original portfolio
            if (search === "") {
                setDisplayList(portfolio);
                setDisplayListShares(shareListing);
            } else if (selectedOption === SelectedOption.properties) {
                // Otherwise, filter the portfolio based on the search input
                const filteredPortfolio = portfolio?.filter((item: { property: { propertyName: string; availabilityStatus: any } }) =>
                    item.property.propertyName.toLowerCase().includes(search.toLowerCase())
                );
                setDisplayList(filteredPortfolio);
            } else if (selectedOption === SelectedOption.shareListing) {
                const filteredShareListing = shareListing?.filter((item: { propertyData: { property: { propertyName: string } } }) =>
                    item.propertyData.property.propertyName.toLowerCase().includes(search.toLowerCase())
                );
                setDisplayListShares(filteredShareListing);
            } else {
                setDisplayList(portfolio);
                setDisplayListShares(shareListing);
            }
        };

        // Call the filter function when the search input changes
        filterPortfolio();
    }, [search, portfolio]);

    const displayPortfolio =
        displayList?.length === 0 ? (
            <div className="flex flex-col items-center mt-20 justify-center w-full h-full space-y-4 no-item">
                <div className="flex flex-col justify-center items-center py-10">
                    <img src={NotFoundImg} alt="" />
                    <p className="text-base font-semibold">You don&rsquo;t have a property yet!</p>
                </div>
            </div>
        ) : (
            displayList?.map((item) => <PortfolioCard key={item.id} property={item} isGrid={dashboardStyle === DashboardStyle.grid} />)
        );

    const displayShareListing =
        displayListShares?.length === 0 ? (
            <div className="flex w-full flex-col items-center mt-20 justify-center h-full space-y-4 no-item">
                <div className="flex flex-col justify-center items-center py-10">
                    <img src={NotFoundImg} alt="" />
                    <p className="text-base font-semibold">You dont have a property yet!</p>
                </div>
            </div>
        ) : (
            displayListShares
                ?.reverse()
                ?.map((item: any) => <ShareListingCard key={item.id} property={item} isGrid={dashboardStyle === DashboardStyle.grid} />)
        );

    if (loading) {
        return (
            <div className="flex items-center justify-center mt-64">
                <BounceLoader color="#ca1611" />
            </div>
        );
    }

    return (
        <div>
            <div className="hidden md:flex">
                <SubHeader
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    propertyType
                    investmentType
                    showSearch
                    dashboardStyle={dashboardStyle}
                    setDashboardStyle={setDashboardStyle}
                    canChangeStyle={false}
                    status
                    filterBtn={false}
                    showSoldOut
                    updatePropertyTypeFilter={(value) => {
                        applyFilterOnProperties("propertyType", value);
                    }}
                    updateInvestmentTypeFilter={(value) => {
                        applyFilterOnProperties("investmentType", value);
                    }}
                    updateSoldOutStatusFilter={(value) => {
                        applyFilterOnProperties("status", value);
                    }}
                />
            </div>

            <div className="rounded-tl-lg rounded-tr-lg border flex mt-10 border-assetize-gray pt-2 bg-white px-3 md:px-6">
                <div
                    onClick={() => setSelectedOption(SelectedOption.properties)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => setSelectedOption(SelectedOption.properties)}
                    className={`${
                        selectedOption === SelectedOption.properties ? "border-b border-assetize-primary  pb-1.5 " : ""
                    } px-3 md:px-8`}
                >
                    <p
                        className={`${
                            selectedOption === SelectedOption.properties ? "text-assetize-primary" : "text-assetize-dark-gray"
                        } font-latoRegular`}
                    >
                        My Properties
                    </p>
                </div>
                <div
                    onClick={() => setSelectedOption(SelectedOption.shareListing)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => setSelectedOption(SelectedOption.shareListing)}
                    className={`${
                        selectedOption === SelectedOption.shareListing ? "border-b border-assetize-primary  pb-1.5  " : ""
                    } px-3 md:px-8`}
                >
                    <p
                        className={`${
                            selectedOption === SelectedOption.shareListing ? "text-assetize-primary" : "text-assetize-dark-gray"
                        } font-latoRegular`}
                    >
                        Share Listings
                    </p>
                </div>
            </div>
            {selectedOption === SelectedOption.properties ? (
                <div className="bg-white p-2 flex flex-wrap gap-3 pb-20">{displayPortfolio}</div>
            ) : (
                <div className="bg-white p-2 flex flex-wrap gap-3 pb-20">{displayShareListing}</div>
            )}
            {
                selectedOption === SelectedOption.properties && (
                    <div className="mt-5 flex justify-end">
                        <Pagination setPageNum={setPageNum} pageData={pageData} />
                    </div>
                )
                // :
                // <div className="mt-5 flex justify-end">
                //     <Pagination setPageNum={setPageNum} pageData={pageData} />
                // </div>
            }
        </div>
    );
}

export default Portfolio;
