import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import TransactionCard from "Pages/Wallets/TransactionCard";
import React, { useEffect, useState } from "react";
import { Option, dateFilter, joinedFilter } from "types";
import { GetProperties } from "Services/apis/admin.api";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { NotFoundImg } from "Assets/Index";
import { Link } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { RootState } from "redux/store";
import { DeveloperType } from "types/Auth.types";
import { filterDataByDateRange, getErrorMessge, getShortCodeDate, searchArray, sortByRange } from "Utils/Helpers";
import PropertyCard from "./PropertyCard";
import Pagination from "Components/elements/Pagination";
import { HiOutlineRefresh } from "react-icons/hi";
import { toast } from "react-toastify";

const Properties = () => {
    const [search, setSearch] = useState("");
    const [_property, setProperty] = useState<DeveloperType[]>([]);
    const [displayList, setDisplayList] = useState<DeveloperType[]>([]);
    const [loading, setLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const { developersRequest } = useSelector((state: RootState) => state.admin);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    const [pageData, setPageData] = useState<any>();
    const [pageNum, setPageNum] = useState<number>(1);

    const fetchDevelopersRequest = async () => {
        setLoading(true);
        setRefreshing(true);
        try {
            const res = await GetProperties(pageNum);

            const sortedNotifications = res.propertiesWithGallery.sort((a: any, b: any) => {
                const dateA: any = new Date(a.createdAt);
                const dateB: any = new Date(b.createdAt);

                return dateB - dateA;
            });

            setProperty(sortedNotifications);
            setDisplayList(sortedNotifications);
            setPageData(res.metadata);
        } catch (error: any) {
            setLoading(false);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    useEffect(() => {
        fetchDevelopersRequest();
    }, []);

    const [selectedOptions, setSelectedOptions] = useState({
        joinedFilter: { name: "All" },
        dateFilter: { name: "" }
    });

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    useEffect(() => {
        const initialDateFilter = getShortCodeDate();
        setSelectedOptions((prevOptions) => ({ ...prevOptions, dateFilter: initialDateFilter }));
    }, []);

    useEffect(() => {
        const sortedData = sortByRange(selectedOptions.joinedFilter.name, _property, "createdAt");

        if (sortedData) {
            setDisplayList(sortedData);
        }
    }, [selectedOptions.joinedFilter.name]);

    useEffect(() => {
        const filteredData = filterDataByDateRange(_property, selectedOptions.dateFilter);

        if (filteredData) {
            setDisplayList(filteredData);
        }
    }, [selectedOptions.dateFilter.name]);

    useEffect(() => {
        const searchResult = searchArray(_property, search);

        setDisplayList(searchResult);
    }, [search]);

    useEffect(() => {
        const getProperties = async () => {
            try {
                const res = await GetProperties(pageNum);

                const sortedNotifications = res.propertiesWithGallery.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.createdAt);
                    const dateB: any = new Date(b.createdAt);

                    return dateB - dateA;
                });

                setProperty(sortedNotifications);
                setDisplayList(sortedNotifications);
            } catch (error) {
                const err = getErrorMessge(error);
                toast.error(err);
            }
        };
        getProperties();
    }, [pageNum]);

    let content;
    if (loading) {
        content = (
            <div className="flex items-center justify-center mt-64">
                <BounceLoader color="#ca1611" />
            </div>
        );
    } else if (displayList.length === 0) {
        content = (
            <div>
                <div className="flex flex-col items-center justify-center h-full mt-20 space-y-4 no-item">
                    <img src={NotFoundImg} alt="" />
                    <p className="text-base font-semibold">No property uploaded!</p>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="py-2 mt-2 bg-white rounded">
                <div className="hidden md:flex justify-between w-full px-2">
                    <h3 className="uppercase w-[30%] flex justify-start font-latoLight pl-4 text-sm">property</h3>
                    <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">total value</h3>
                    <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">date submitted</h3>
                    <h3 className="uppercase w-[17%] flex justify-start font-latoLight text-sm">status</h3>
                    <h3 className="uppercase w-[13%] flex justify-start font-latoLight text-sm">action</h3>
                </div>
                {displayList.map((item: any) => (
                    <PropertyCard key={item.id} item={item} />
                ))}
            </div>
        );
    }

    return (
        <div>
            <div className="flex items-center justify-end px-2 mt-6 bg-white border rounded border-assetize-gray">
                <div className="flex-[.6] flex justify-between items-center">
                    <div className="self-center flex-1 py-2 mx-1 ">
                        <ActiveSearch
                            bg="white"
                            border="border-assetize-gray"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search here..."
                        />
                    </div>
                    <div className="mx-1 border-r border-assetize-gray h-15" />
                    <div className="flex-[0.5] mx-1 py-2">
                        <Select
                            options={joinedFilter}
                            selected={selectedOptions.joinedFilter}
                            onChange={(option) => handleOptionChange("joinedFilter", option)}
                            placeholder="Newest"
                        />
                    </div>
                    <div className="flex-[0.7] mx-1 py-2">
                        <Select
                            options={dateFilter}
                            selected={selectedOptions.dateFilter}
                            onChange={(option) => handleOptionChange("dateFilter", option)}
                            placeholder="Jan 01 - January 31"
                        />
                    </div>
                    <HiOutlineRefresh
                        className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                        onClick={() => {
                            if (!refreshing) {
                                fetchDevelopersRequest();
                            }
                        }}
                    />
                </div>
            </div>
            {content}
            <div className="flex justify-end mt-5">
                <Pagination pageData={pageData} setPageNum={setPageNum} />
            </div>
        </div>
    );
};

export default Properties;
