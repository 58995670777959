import { Verified } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import React from "react";
import { LuAlertCircle } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const FundSuccess = ({ onContinue }: { onContinue: () => void }) => {
    const navigate = useNavigate();

    return (
        <div className="flex text-center flex-col self-center px-5">
            <img src={Verified} alt="verification successful " className="w-32 self-center" />

            <h3 className="text-[#CA1611] text-xl self-center ml-5 font-latoBold my-3">Wallet Funded Successfully</h3>
            <p className="text-latoRegular text-lg ml-5">
                Your Assetize wallet has been funded successfully! Invest in properties with ease and seamlessness
            </p>
            <div className="flex flex-col gap-2">
                <MainButton onClick={onContinue}>Continue</MainButton>
            </div>
        </div>
    );
};

export default FundSuccess;
