import { InvestActionProps } from "types/data";
import React, { useState } from "react";

const ActionCard: React.FC<InvestActionProps> = ({ heading, body, done, icon: IconComponent }) => (
    <div className="border border-assetize-gray p-4 flex justify-between flex-1 rounded-md">
        <div className="flex items-center">
            <div className="rounded-full flex justify-center items-center">
                {IconComponent && <IconComponent className="w-14 h-14 text-assetize-primary" />}
            </div>
            <div className="ml-5">
                <h3 className="text-sm text-black font-latoRegular">{heading}</h3>
                <p className="text-sm text-black font-latoLight ">{body}</p>
            </div>
        </div>
        <div>
            <input
                type="checkbox"
                checked={done}
                className="rounded border-assetize-primary outline-none bg-assetize-primary focus:ring-assetize-primary checked:bg-assetize-primary checked:border-assetize-primary checked:ring-assetize-primary"
            />
        </div>
    </div>
);

export default ActionCard;
