import { NewLogoColored, Notification, User, caretVerticalDown } from "Assets/Index";
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import MenuList, { Option } from "Components/elements/Menu";
import ModalPanel from "Components/elements/Modal/Index";
import sidebarLinks, { SidebarLinks } from "Components/blocks/Sidebar/data";
import useCurrentPath from "Hooks/useCurrentPath";
import { fomatHeaderText, getErrorMessge } from "Utils/Helpers";
import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import NotificationDetails, { NotificationItem } from "./Notification";
import { notificationData } from "types/data";
import { TUser } from "types/Auth.types";
import { GetAdminNotification, GetSettingsApi, logoutUser } from "Services";
import { logout } from "redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { BiMenu } from "react-icons/bi";
import MobileSideNav from "../Sidebar/MobileSideNav";
import { MdNotificationImportant } from "react-icons/md";
import { GetAllNotifications } from "Services/apis/notification.api";
import { toast } from "react-toastify";

interface OptionsProps {
    text: string;
}

const DashboardHeader = () => {
    const [search, setSearch] = useState("");
    const [headerText, setHeaderText] = useState<SidebarLinks | null>(null);
    const currentPath = useCurrentPath();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userData, setUserData] = useState<TUser | undefined>();
    const { user } = useSelector((state: any) => state.auth);

    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    const [hasNotification, setHasNotification] = useState(false);

    useEffect(() => {
        const getNotifications = async () => {
            try {
                let res;
                if (user?.accountType !== "admin") {
                    res = await GetAllNotifications(user.id);
                } else {
                    res = await GetAdminNotification();
                }

                const notificationData = user?.accountType === "admin" ? res : res.notifications;
                const notification = notificationData.filter((item: any) => item.read === false);
                setHasNotification(notification.length > 0);
            } catch (error) {
                const err = getErrorMessge(error);
                toast.error(err);
            }
        };
        getNotifications();
    }, []);

    const fetchUserSettings = async () => {
        try {
            const res = await GetSettingsApi();
            setUserData(res);
        } catch (error) {
            // toast.error('Error fetching user');
        }
    };

    useEffect(() => {
        fetchUserSettings();
    }, []);

    const options: OptionsProps[] = [{ text: "Profile" }, { text: "Logout" }];

    const handleOptionSelect = async (option: Option) => {
        // Perform desired action in the parent component

        if (option?.text === "Logout") {
            // log user out

            await logoutUser();
            // navigate('/auth/login')
        } else {
            navigate("/account-settings");
        }
    };

    useEffect(() => {
        const pathObj = Object.values(sidebarLinks)
            .flat()
            .find((link: SidebarLinks) => link.url === currentPath);
        if (pathObj) {
            setHeaderText(pathObj);
        }
    }, [currentPath]);

    const handleNotificationClick = () => {
        // Show the modal when Notification is clicked
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        // Close the modal when the close button or backdrop is clicked
        setIsModalOpen(false);
    };

    return (
        <div className="flex w-full md:flex justify-between md:items-center">
            <div className=" flex w-full items-center">
                <div className="md:flex md:flex-[.3] hidden ">
                    <h2 className="hidden md:flex md:pl-3 md:text-[25px] text-[#111]"> {headerText?.headingText}</h2>
                </div>

                <div className="flex md:hidden">
                    <img src={NewLogoColored} alt="logo" className="w-[40%]" />
                </div>

                <div className="flex-[1]   flex justify-end items-center center">
                    {false && <ActiveSearch bg="gray-100" border="0" value={search} onChange={(e) => setSearch(e.target.value)} />}
                    <button className="mr-5 cursor-pointer relative" type="button" onClick={handleNotificationClick}>
                        {hasNotification && <div className="w-3 h-3 absolute top-[-5px] right-0 rounded-full  bg-assetize-primary" />}{" "}
                        <Notification />
                    </button>
                    <img src={User} alt="user-profile" className="w-[30px] object-cover h-[30px]" />
                    <div className="z-50  flex items-center ml-3">
                        <div className="md:flex flex-col hidden ">
                            {userData?.accountType !== "admin" && (
                                <p className="text-sm text-black font-latoBold capitalize ">{userData?.firstName}</p>
                            )}
                            <p className="text-sm text-black capitalize font-latoBold ">{userData?.accountType}</p>
                        </div>
                        <div className="hidden md:flex">
                            <MenuList
                                options={options}
                                onSelect={handleOptionSelect}
                                icon={caretVerticalDown}
                                bg="assetize-dark"
                                textColor="white"
                            />
                        </div>

                        <MobileSideNav />
                    </div>
                </div>
            </div>
            {/* The modal */}
            <ModalPanel open={isModalOpen} closeButton closeModal={() => setIsModalOpen(false)}>
                <NotificationDetails onCloseModal={handleCloseModal} />
            </ModalPanel>
        </div>
    );
};

export default DashboardHeader;
