import { OptionsProps, Status, WalletTransactionProps } from "types/data";
import React, { useEffect, useState } from "react";
import { AuthActionStatus, DeveloperType } from "types/Auth.types";
import { DotsWithBg, ThreeDotsVertical } from "Assets/Index";
import MenuList, { Option } from "Components/elements/Menu";
import { useNavigate } from "react-router-dom";
import ModalPanel from "Components/elements/Modal/Index";
import { getDateAndTime } from "Utils/Helpers";
import { ActivateAccount, DeactivateUser } from "Services";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";

interface AuthenticationProps {
    item: DeveloperType;
}

const UserCard = ({ item }: AuthenticationProps) => {
    const { actionStatus, createdAt, id, metaData, emailAddress, firstName, lastName, accountDeactivated, active } = item;
    const navigate = useNavigate();
    const [createdAtDate, setCreatedAtDate] = useState("");
    const [showModal, setSHowModal] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });
    const [activateLoading, setActivateLoading] = useState(false);
    const [deactivateLoading, setDeActivateLoading] = useState(false);
    useEffect(() => {
        const requestDate = new Date(createdAt);
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "long",
            year: "numeric"
        };
        const formatedDate = requestDate.toLocaleDateString("en-US", options);
        setCreatedAtDate(formatedDate);
    }, [createdAt]);

    const handleActivateUser = async () => {
        try {
            setActivateLoading(true);
            const res = await ActivateAccount({ email: emailAddress });

            toast.success("User Activated successfully");
        } catch (error) {
            toast.error("Error reactivating user");
            setActivateLoading(false);
        } finally {
            setActivateLoading(false);
        }
    };

    const handleDeactivateUser = async () => {
        try {
            setDeActivateLoading(true);
            const res = await DeactivateUser();

            toast.success("User deactivated successfully");
        } catch (error) {
            toast.error("Error deactivating user");
            setDeActivateLoading(false);
        } finally {
            setDeActivateLoading(false);
        }
    };

    const options: OptionsProps[] = [
        { text: "View Details", value: "view" },
        { text: !active ? "Enable account" : "Disable account", value: !active ? "activate" : "disable" }
    ];

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "view") {
            navigate(`${id}`, { state: item });
        } else if (option?.value === "approve") {
            setShowReplyModal({
                open: true,
                action: "approve"
            });
        } else if (option?.value === "activate") {
            handleActivateUser();
        } else if (option?.value === "disable") {
            handleDeactivateUser();
        } else {
            setShowReplyModal({
                open: true,
                action: "reject"
            });
        }
    };

    let statusText;

    if (actionStatus === AuthActionStatus.APPROVED) {
        statusText = (
            <div className="flex-[.2] justify-center flex">
                <div className="bg-assetize-green-gradient flex justify-center w-[60%] px-2 py-1 rounded-2xl">
                    <p className="text-assetize-green-text text-sm ">Approved</p>
                </div>
            </div>
        );
    } else if (actionStatus === AuthActionStatus.REJECTED) {
        statusText = (
            <div className="flex-[.2] justify-center flex">
                <div className="bg-assetize-primary-gradient flex justify-center w-[60%] px-2 py-1 rounded-2xl">
                    <p className="text-assetize-primary text-sm">failed</p>
                </div>
            </div>
        );
    } else {
        statusText = (
            <div className="flex-[.2] flex justify-center">
                <div className="bg-assetize-warning-gradient flex justify-center  w-[60%] px-2 py-1 rounded-2xl">
                    <p className="text-[#F39B0D] text-sm">pending</p>
                </div>
            </div>
        );
    }

    const { dateString, timeString } = getDateAndTime(createdAt);
    const [hourMinute] = timeString.split(" ");

    return (
        <div className="border border-assetize-gray rounded p-3 w-full  flex justify-around items-center py-5 my-2">
            <h3 className="text-md w-[20%] font-latoRegular capitalize text-start flex pl-4  text-sm">#{id}</h3>
            <div className="flex flex-col md:flex-row  w-[20%]">
                <div>
                    <h3 className="text-md font-latoRegular capitalize text-start text-sm align-left flex justify-start flex-[.2]">
                        {firstName} {lastName}
                    </h3>
                </div>
            </div>
            <div className="w-[30%]">
                <h3 className="text-md font-latoRegular hidden capitalize text-start text-sm md:flex justify-start flex-[.2] ">
                    {emailAddress}
                </h3>
            </div>
            <div className="flex-col md:flex w-[20%]">
                <h3 className="text-md font-latoRegular capitalize text-sm flex text-start justify-center items-start flex-[.2] flex-col">
                    <span className="font-latoBold">{dateString}</span>
                    <span className="font-latoRegular text-[12px]">{hourMinute}</span>
                </h3>
            </div>

            <div className="text-md font-latoRegular  capitalize underline w-[10%] flex  ">
                {activateLoading || deactivateLoading ? (
                    <CircleLoader color="#ca1611" />
                ) : (
                    <div className="">
                        <MenuList
                            options={options}
                            size="28"
                            onSelect={handleOptionSelect}
                            icon={DotsWithBg}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                )}
            </div>

            {/* <ModalPanel open={showReplyModal.open} closeButton={true} closeModal={() => setShowReplyModal({ open: false, action: '' })}>
                <AnswerModal action={showReplyModal.action} developerId={userId?.toString()} isHome={true} />
            </ModalPanel> */}
        </div>
    );
};

export default UserCard;
