import React, { useEffect, useState } from "react";
import UserTotal from "../UserTotal";
import { HiOutlineRefresh } from "react-icons/hi";
import Select from "Components/elements/Forms/Select";
import { Option } from "types";
import { ScaleLoader } from "react-spinners";
import { GetPropertyAnalytics } from "Services";
import BarChart from "../analytics/Charts/BarChart";
import LineChart from "../analytics/Charts/Linechart";
import { MainButton } from "Components/elements/Button/Index";
import ExportButton from "Components/elements/Button/ExportButton";
import { getErrorMessge } from "Utils/Helpers";
import { toast } from "react-toastify";

interface PropertyPerformanceProps {
    id: string;
}

const PropertyPerfomance: React.FC<PropertyPerformanceProps> = ({ id }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState(false);
    const [propertyData, setPropertyData] = useState<any>();
    const [selectedOptions, setSelectedOptions] = useState({
        transactionFilter: { name: "Monthly" }
    });
    const [sumTotal, setSumTotal] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    const getCurrentMonth = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString("default", { month: "short" }).toLowerCase();
        return currentMonth;
    };

    const currentMonth = getCurrentMonth();
    const previousMonth = (() => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1); // Subtract one month to get the previous month
        return currentDate.toLocaleString("default", { month: "short" }).toLowerCase();
    })();

    const getAnalytics = async () => {
        try {
            setRefreshing(true);
            setLoading(true);
            const res = await GetPropertyAnalytics(id, selectedOptions.transactionFilter.name);

            setPropertyData(res);
        } catch (err: any) {
            const error = getErrorMessge(err);
            toast.error(error);
        } finally {
            setLoading(false);
            setRefreshing(false);
            setDisabled(true);
        }
    };

    useEffect(() => {
        getAnalytics();
    }, [selectedOptions]);

    let lastMonthKey: string | undefined;
    let lastMonthValue: number | undefined;

    if (propertyData?.investmentsByTimeFrame) {
        lastMonthKey = previousMonth; // Set lastMonthKey to the calculated previous month
        lastMonthValue = propertyData.investmentsByTimeFrame[lastMonthKey!] || 0;
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center mt-64">
                <ScaleLoader color="#ca1611" />
            </div>
        );
    }

    if (!propertyData) {
        return <div>Error loading data.</div>;
    }

    return (
        <div className="my-6">
            <p className="flex justify-center mb-4">Analytics and Report about this property</p>
            <div className="justify-center flex">
                <div className="flex flex-col lg:flex-row md:w-[70%] justify-between gap-2 mb-6">
                    <UserTotal title="Total Investors" value={propertyData?.numberOfInvestors || 0} />
                    <UserTotal title="Total Investment value" value={`$${(propertyData?.totalInvestmentValue || 0).toLocaleString()}`} />
                </div>
            </div>
            <div className="flex  flex-col lg:flex-row justify-between gap-2">
                <div className="flex w-full lg:h-full flex-col gap-2 border shadow-md px-3 md:pr-0 md:px-5">
                    <div className="flex text-assetize-primary items-center justify-between">
                        <h4 className="mt-2 md:text-sm">Usd Investment Statistics</h4>
                        <div className="flex p-3 gap-3 items-center relative">
                            <HiOutlineRefresh
                                className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                                onClick={() => {
                                    if (!refreshing) {
                                        getAnalytics();
                                    }
                                }}
                            />
                            <div className="space-x-3" style={{ zIndex: 1 }}>
                                <Select
                                    options={[{ name: "Daily" }, { name: "Monthly" }, { name: "Yearly" }]}
                                    placeholder={selectedOptions.transactionFilter.name}
                                    onChange={(selectedOption) => {
                                        handleOptionChange("transactionFilter", selectedOption);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="px-0 ">
                        <LineChart data={propertyData?.investmentsByTimeFrame} />
                    </div>
                </div>
                {/* <div className="flex lg:h-full flex-col gap-2 border shadow-md px-3 md:pr-0 md:px-5">
                    <div className="flex text-assetize-primary items-center justify-between">
                        <h4 className="mt-2 text-xs">Token Investment Statistics</h4>
                        <div className="flex p-3 gap-3 items-center relative">
                            <HiOutlineRefresh
                                className={`w-6 h-6 text-assetize-dark ${refreshing ? 'animate-spin' : ''}`}
                                onClick={() => {
                                    if (!refreshing) {
                                        getAnalytics();
                                    }
                                }}
                            />
                            <div className="space-x-3" style={{ zIndex: 1 }}>
                                <Select
                                    options={[{ name: 'Daily' }, { name: 'Monthly' }, { name: 'Yearly' }]}
                                    placeholder="Select an option"
                                    onChange={(selectedOption) => {
                                        ('Selected Option:', selectedOption);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="px-0 ">
                        <LineChart data={propertyData?.investmentsByTimeFrame} />
                    </div>
                </div> */}
            </div>

            <div className="flex w-full mt-8 h-full flex-col gap-2 border shadow-md px-3 md:pr-0 md:px-5">
                <div className="flex text-assetize-primary items-center justify-between">
                    <h4 className="mt-2">Total Revenue</h4>
                    <div className="flex p-3 gap-3 items-center relative">
                        <HiOutlineRefresh
                            className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                            onClick={() => {
                                if (!refreshing) {
                                    getAnalytics();
                                }
                            }}
                        />
                        <div className="space-x-3" style={{ zIndex: 1 }}>
                            <Select
                                options={[{ name: "Daily" }, { name: "Monthly" }, { name: "Yearly" }]}
                                placeholder={selectedOptions.transactionFilter.name}
                                onChange={(selectedOption) => {
                                    handleOptionChange("transactionFilter", selectedOption);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="hidden lg:flex items-center gap-3 text-assetize-dark ">
                    <h2>{`$${propertyData?.totalRevenue?.toLocaleString()}`}</h2>
                    <p>
                        {`last month $${
                            propertyData?.investmentsByTimeFrame
                                ? propertyData?.investmentsByTimeFrame[
                                      Object.keys(propertyData?.investmentsByTimeFrame)?.[
                                          Object.keys(propertyData?.investmentsByTimeFrame).length - 1
                                      ]
                                  ]?.toLocaleString() || "0"
                                : "0"
                        }`}
                    </p>
                </div>
                <div className="lg:hidden flex items-center gap-3 text-assetize-dark ">
                    <h5>{`$${(propertyData?.totalRevenue || 0).toLocaleString()}`}</h5>
                    <p>{`last month $${lastMonthValue ? lastMonthValue.toLocaleString() : "0"}`}</p>
                </div>
                <div className="px-0">
                    <BarChart data={propertyData.investmentsByTimeFrame || 0} />
                </div>
                <div className=" w-[50%] items-center self-center mb-6">
                    <ExportButton
                        data={{
                            numberOfInvestors: propertyData?.numberOfInvestors || 0,
                            totalInvestmentValue: propertyData?.totalInvestmentValue || 0,
                            totalRevenue: propertyData?.totalRevenue || 0,
                            investmentsByTimeFrame: propertyData?.investmentsByTimeFrame || {}
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PropertyPerfomance;
