import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { AiFillCaretDown } from "react-icons/ai";

interface Option {
    name: string;
}
interface SelectProps {
    options: Option[];
    selected?: Option;
    onChange?: (person: Option) => void;
    placeholder?: string;
    error?: string;
    textColor?: string;
}

const Select: React.FC<SelectProps> = ({ textColor, options, selected, onChange, placeholder, error }) => {
    const hasSelection = selected && selected.name;

    return (
        <div className="w-full">
            <Listbox value={selected} onChange={onChange}>
                <div className="relative  ">
                    <Listbox.Button className="flex gap-3 px-7 justify-between relative w-full cursor-default inset-0 rounded-lg bg-transparent  border border-assetize-gray py-2.5 pl-1 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ">
                        <div>
                            {hasSelection ? (
                                <span className={`block truncate font-latoRegular ${textColor || "text-assetize-dark-gray"} text-xs`}>
                                    {selected.name}
                                </span>
                            ) : (
                                <span className="block truncate font-latoRegular text-assetize-dark-gray">
                                    {placeholder || "Select an option"}
                                </span>
                            )}
                        </div>
                        <div>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <AiFillCaretDown color="#4B4B4D" fontSize="15px" />
                            </span>
                        </div>
                    </Listbox.Button>
                    <Transition as={Fragment} leave="transition ease-in duration-100 z-0" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options className="absolute mt-1 max-h-64 w-full z-50 overflow-auto rounded-md bg-assetize-dark py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {options.map((person, personIdx) => (
                                <Listbox.Option
                                    key={personIdx}
                                    className={({ active }) =>
                                        `relative cursor-default text-xs select-none py-2 pl-2 ${
                                            active ? "bg-assetize-dark text-white" : "text-white"
                                        }`
                                    }
                                    value={person}
                                >
                                    {({ selected }) => (
                                        <span className={`block truncate ${selected ? "font-latoRegular" : "font-latoLight"}`}>
                                            {person.name}
                                        </span>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
            {error && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{error}</p>}
        </div>
    );
};

export default Select;
