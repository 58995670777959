import axiosInstance from "Utils/Https";

export const GetAllNotifications = async (userId: string) => {
    const url = `notifications`;
    const res = await axiosInstance.get(url);
    return res.data;
};

export const MarkNotifcationAsRead = async (notificationId: string) => {
    const url = `notifications/read/${notificationId}`;
    const res = await axiosInstance.patch(url);
    return res.data;
};

export const MarkAllNotificationAsRead = async () => {
    const url = `notifications/read/all`;
    const res = await axiosInstance.patch(url);
    return res.data;
};
