import { AuthBg, NullImg } from "Assets/Index";
import ModalPanel from "Components/elements/Modal/Index";
import React, { useState } from "react";
import BuySharesFromInvestorDetailsModal from "./BuySharesFromInvestorDetailsModal";

type Props = {
    closeModal: () => void;
};

const ShareCard = ({ item, closeModal }: any) => {
    const [showModal, setShowModal] = useState(false);

    const { propertyData, shareNumber, sharePrice } = item;

    return (
        <>
            <div
                onClick={() => {
                    setShowModal(true);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                    setShowModal(true);
                }}
                className="border border-assetize-gray p-1 items-center rounded font-latoRegular flex my-2"
            >
                <div
                    className="flex-[.3]"
                    style={{
                        backgroundImage: `url(${NullImg})`
                    }}
                >
                    <img src={AuthBg} alt="" />
                </div>
                <div className="flex-[.5] flex flex-col justify-between  pl-3">
                    <h3 className="text-[16px] md:text-md font-latoBold leading-6">{propertyData?.property?.propertyName}</h3>
                    <p className=" text-md text-assetize-dark-text leading-6">{propertyData?.property?.location}</p>
                    <div className="flex items-center">
                        <p className="text-assetize-primary font-latoBold ">${sharePrice / shareNumber}</p>
                        <p className="text-[11px] pl-2 pb-2">Per share</p>
                    </div>
                </div>
                <div className="flex-[.2] justify-center items-center">
                    <p className="text-[12px] text-md text-center">SHARES</p>
                    <p className="text-sm text-center text-md">{shareNumber}</p>
                </div>
            </div>
            <ModalPanel
                closeModal={() => {
                    setShowModal(false);
                }}
                open={showModal}
                closeButton
            >
                <BuySharesFromInvestorDetailsModal property={item} close={() => setShowModal(false)} />
            </ModalPanel>
        </>
    );
};

export default ShareCard;
