export enum UserAccountType {
    INVESTOR = "investor",
    DEVELOPER = "developer",
    ADMIN = "admin"
}

export interface User {
    isVerified: boolean;
    name: string;
    userAccountType: UserAccountType;
    email: string;
    emailVerified: boolean;
    firstName: string;
    id?: number;
    kycVerified: boolean;
    lastName: string;
    phoneNumber: null | string;
    dateOfBirth: string;
    // Add any other user properties
}

export type TUser = {
    accountType: string;
    active: boolean;
    dateOfBirth: string;
    emailAddress: string;
    emailVerified: boolean;
    firstName: string;
    id: number;
    kycVerified: boolean;
    lastName: string;
    phoneNumber: null | string;
};

export type LoginDataType = {
    email: string;
    password: string;
};

export type UpdatePassType = {
    oldPassword?: string;
    newPassword?: string;
    confirmPassword: string;
};

export interface UserType {
    accountType: UserAccountType;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    emailAddress: string;
    password: string;
    phoneNumber: string;
}

export interface CardType {
    image: string;
    id: number;
    title: string;
    availability: string;
    address: string;
    price: string;
    pricePerShare: string;
    availableShares: string;
    ownership: string;
}

export interface SignupStageType {
    setStage: (stage: string) => {};
}

export interface kycPayloadType {
    documentType: string;
    documentNumber: string;
    consent: boolean;
}

export enum AuthActionStatus {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected"
}

export interface DeveloperType {
    id: number;
    userId: number;
    notificationType: string;
    notificationMessage: string;
    createdAt: Date;
    notificationTitle: string;
    read: boolean;
    resolvedByAdmin: boolean;
    createdAtGen: Date;
    actionStatus: AuthActionStatus;
    metaData: any;
    updatedAt: Date;
    firstName: string;
    lastName: string;
    emailAddress: string;
    accountDeactivated: boolean;
    active: boolean;
}

export interface KycDocsProps {
    companyProfileDocumentUrl: string;
    corporateGovernanceUr: string;
    incorporationDocumentUrl: string;
}

export interface DeveloperPersonalData {
    notification: DeveloperType;
    kycDocs: KycDocsProps;
}

export interface PropertyType {
    id: number;
    propertyName: string;
    location: string;
    fiatValue: number;
    galleryId: number;
    propertyType: string;
    investmentType: string;
    sharePrice: number;
    numberOfShares: number;
    expectedCompletionDate: Date;
    expectedAnnualROI: number;
    description: string;
    availabilityStatus: string;
    developerId: number;
    developerName: string;
    additionalInfo: string;
    approvedByAdmin: boolean;
}
