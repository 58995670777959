import React, { ChangeEvent } from "react";

interface FormTextareaProps {
    label?: string;
    name?: string;
    value?: string;
    placeholder?: string;
    error?: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
    disabled?: boolean;
    rows?: number;
}

const Textarea: React.FC<FormTextareaProps> = ({
    label,
    name,
    rows,
    value,
    placeholder,
    error,
    onChange,
    className = "",
    disabled = false
}) => (
    <div className="flex flex-col">
        <label className="pb-1 text-sm font-latoRegular text-gray-800" htmlFor={name}>
            {label || ""}
        </label>
        <textarea
            className={`w-full h-250px p-2 ${
                error
                    ? "border-assetize-primary bg-assetize-primary-gradient focus:ring-assetize-primary focus:border-assetize-primary"
                    : "border-assetize-gray focus:ring-assetize-gray focus:border-assetize-gray"
            } border rounded font-latoRegular text-gray-500 text-sm appearance-none outline-none ${className}`}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows || 5}
            cols={8}
        />
        {error && <p className="mt-1 text-xs font-latoRegular text-assetize-primary">{error}</p>}
    </div>
);

export default Textarea;
