import React, { useState } from "react";
import { Verified } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Textarea from "Components/elements/Forms/TextArea";
import { authenticateDeveloper } from "Services/apis/admin.api";
import { useNavigate } from "react-router-dom";
import { BuyShares } from "Services";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";

interface AnswerProps {
    id: string;
    isHome: boolean;
    onClose: () => void;
}

const DeleteProperty = ({ id, isHome, onClose }: AnswerProps) => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    // const buyShares = async () => {
    //     setError('');

    //     try {
    //         setLoading(true);
    //         const payload = {
    //             id,
    //             numberOfShares: parseInt(numberOfShares, 10),
    //             paymentMethod: 'USD'
    //         };
    //         const res = await BuyShares(payload);

    //         setAuthenticated(true);
    //         toast.success('Purchase was succesfull');
    //     } catch (error: any) {
    //         setLoading(false);
    //         toast.error(error?.response?.data?.message);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const currentLocation = window.location;

    return (
        <div>
            {authenticated ? (
                <SuccessMessageModal
                    handleClick={() => (isHome ? currentLocation.reload() : navigate("/investor/portfolio"))}
                    descriptiontext=" Your shares have been bought successfully."
                    headingText="Sent"
                />
            ) : (
                <ConfirmActionModal
                    headingText="Delete Property"
                    descriptionText={` You are about to delete this property, Please confirm you want to delete this property`}
                    handleCancel={() => onClose()}
                    handleAction={() => {}}
                    loading={loading}
                    buttonText="Okay"
                    cancelButton="Cancel"
                />
            )}
        </div>
    );
};

export default DeleteProperty;
