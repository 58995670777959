import React, { useEffect, useState } from "react";
import { NoShareListed, NullImg, Verified } from "Assets/Index";
import { MainButton } from "Components/elements/Button/Index";
import Textarea from "Components/elements/Forms/TextArea";
import { authenticateDeveloper } from "Services/apis/admin.api";
import { useNavigate } from "react-router-dom";
import Input from "Components/elements/Forms/Input";
import Select from "react-select";
import { Switch } from "@headlessui/react";
import ShareCard from "./ShareCard";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import { GetOffersOnProperty } from "Services";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import ModalPanel from "Components/elements/Modal/Index";
import BuySharesFromInvestorDetailsModal from "./BuySharesFromInvestorDetailsModal";
import { useSelector } from "react-redux";

interface BuyListingProps {
    id: string;
    closeModal: () => void;
}

const BuyListingFromInvestor = ({ id, closeModal }: BuyListingProps) => {
    const navigate = useNavigate();
    const [successful, setSuccessful] = useState(false);
    const [shares, setShares] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user } = useSelector((state: any) => state.auth);

    const createListing = async () => {
        try {
            setLoading(true);
            const res = await GetOffersOnProperty(id);
            const data = res.refinedListings.filter((item: any) => item.investorId !== user.id);
            setShares(data);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        createListing();
    }, []);

    return (
        <div>
            {successful ? (
                <SuccessMessageModal
                    handleClick={() => navigate("/investor/portfolio")}
                    descriptiontext="Your offer has been bought successfully. You can check your portfolio to see your offer property list"
                    headingText="Offer Bought Successfully"
                />
            ) : (
                <div className="container p-5 py-3 mx-auto  rounded bg-white w-[100%] md:w-[100%]">
                    <h2 className="text-assetize-primary text-2xl font-latoBold text-center  align-center mt-2">Buy Shares</h2>
                    <p className="text-assetize-dark-gray my-2 text-md align-center text-md text-center font-latoBold">
                        Here are the offers available for you
                    </p>
                    {loading ? (
                        <div className="w-full h-full flex justify-center items-center ">
                            <BounceLoader color="#ca1611" />
                        </div>
                    ) : (
                        <div className="mt-5">
                            {shares?.length === 0 ? (
                                <div
                                    className="w-full h-full flex justify-center items-center flex-col py-10"
                                    style={{
                                        backgroundImage: `url(${NullImg})`
                                    }}
                                >
                                    <img src={NoShareListed} className="w-24" alt="" />
                                    <p className="text-md text-assetize-dark">No Available Shares</p>
                                </div>
                            ) : (
                                shares?.map((item: any) => <ShareCard item={item} closeModal={closeModal} />)
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default BuyListingFromInvestor;
