import React from "react";
import Skeleton from "react-loading-skeleton";

const PropertySkeleton = () => (
    <div className="grid grid-cols-5 p-2 my-10 border rounded-lg gap-x-8">
        <div className="grid grid-cols-3 col-span-2 gap-x-2">
            <Skeleton width="90%" height={200} containerClassName="col-span-2" />
            <div>
                <Skeleton width="100%" height={30} />
                <Skeleton width="50%" height={20} />
            </div>
        </div>
        <div className="my-auto">
            <Skeleton width="80%" height={40} />
        </div>
        <div className="my-auto">
            <Skeleton width="80%" height={40} />
        </div>
        <div className="my-auto">
            <Skeleton width="80%" height={40} />
        </div>
    </div>
);

export default PropertySkeleton;
