import { PropertyIcon, Sample, Shield } from "Assets/Index";
import React from "react";

export enum DashboardStyle {
    grid,
    list
}

export enum PropertyStatus {
    AVAILABLE = "AVAILABLE",
    SOLD_OUT = "SOLD_OUT",
    RESALE = "RESALE"
}

export const PropertyTypes = {
    ALL: "All",
    APARTMENT: "Apartment",
    COMMERCIAL: "Commercial",
    ESTATE: "Estate",
    MALL: "Mall",
    LAND: "Land",
    OFFICE: "Office",
    OTHERS: "Others"
};

export interface Option {
    name: string;
}

export interface PropertyDataProps {
    id?: string;
    image?: string;
    status?: PropertyStatus;
    name?: string;
    address?: string;
    amount?: number;
    pricePerShare?: number;
    propertyType?: string;
    aboutProperty?: string;
    documents?: string[];
    expectedAnnualROI?: number;
    dividend?: number;
    dateListed?: Date;
    developer?: string;
    totalShares?: number;
    expectedCompletionDate?: Date;
    availabilityStatus?: string;
}

export interface InvestActionProps {
    id?: number;
    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    heading?: string;
    body?: string;
    done?: boolean;
}

export const propertyTypeOptions: Option[] = Object.values(PropertyTypes).map((propType) => ({ name: propType }));

export const investmentTypeOptions: Option[] = [{ name: "All" }, { name: "Rental" }, { name: "Non-Rental" }];

export const statusOptions: Option[] = [{ name: "All" }, { name: "Re-sale" }, { name: "Available" }, { name: "Sold Out" }];

export const transactionFilter: Option[] = [{ name: "All" }, { name: "Debit" }, { name: "Credit" }];

export const joinedFilter: Option[] = [{ name: "All" }, { name: "Newest" }, { name: "Oldest" }];

export const dateFilter: Option[] = [
    { name: "Jan 01 - Jan 31" },
    { name: "Feb 1 - Feb 28" },
    { name: "Mar 1 - Mar 31" },
    { name: "Apr 1 - Apr 31" },
    { name: "May 1 - May 31" },
    { name: "Jun 1 - Jun 30" },
    { name: "Jul 1 - Jul 30" },
    { name: "Aug 1 - Aug 30" },
    { name: "Sep 1 - Sep 30" },
    { name: "Oct 1 - Oct 30" },
    { name: "Nov 1 - Nov 30" },
    { name: "Dec 1 - Dec 30" }
];

export const investActions: InvestActionProps[] = [];

export const properties: PropertyDataProps[] = [];

export enum BlogsCategory {
    articles,
    news
}

export interface BlogProps {
    id: string;
    title: string;
    content: string;
    headerImage: string;
    lastUpdated: string;
    category: BlogsCategory;
    dateCreated: string;
    numberOfViews: number;
}

export interface Walletprops {
    walletId: number;
    walletAddress: string;
    type: string;
    userId: number;
    signedHash: string;
    balance: number;
    tierLevel: null;
    createdAt: string;
    updatedAt: string;
    totalBalance: number;
}

export interface safeLockProps {
    balance: number;
    createdAt: string;
    signedHash: string;
    tierLevel: string;
    type: string;
    updatedAt: string;
    userId: number;
    walletAddress: string;
    walletId: number;
    totalBalance: number;
    withdrawableBalance: number;
}

export enum Status {
    SUCCESSFUL = "SUCCESSFUL",
    FAILED = "FAILED",
    PENDING = "PENDING"
}

export interface WalletTransactionProps {
    transactionId: number;
    transactionDetails: string;
    transactionStatus: Status;
    walletId: number;
    amount: number;
    paymentMethod: string;
    balanceBefore: number;
    balanceAfter: number;
    transactionType: string;
    metaData: null;
    transactionDate: string;
    updatedAt: string;
    convertedAmount: number;
    conversionRate: number;
    originCurrencyFundingAmount: number;
    transactionReference: string;
}

export interface SafeLockTransactionProps {
    associatedListingId: number;
    createdAt: string;
    fiatShareValue: number;
    id: number;
    numberOfTokens: number;
    ownerUserId: number;
    propertyId: number;
    shareNumberTransacted: number;
    shareTransactionType: string;
    transactionFees: number;
    transactionReference: string;
    transactionStatus: string;
    updatedAt: string;
}

// Define the type for each notification item
type NotificationItem = {
    id: number;
    imageSrc: string;
    content: string;
    time: string;
    isDividend: boolean;
};

// data for notifications
export const notificationData: NotificationItem[] = [];

export interface OptionsProps {
    text: string;
    value: string;
}

export interface PropertyPortfolioProps {
    percentageOwned: number;
    property: any;
    propertyUrl: string;
    sharesOwned: number;
}
