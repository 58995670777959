import React, { useEffect, useState } from "react";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import AboutBox from "Components/elements/Display/AboutBox";
import GoBack from "Components/elements/Display/GoBack";
import Input from "Components/elements/Forms/Input";
import { GetDevelpersDetails } from "Services/apis/admin.api";
import { getErrorMessge, readableDate } from "Utils/Helpers";
import { FiDownload, FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "redux/store";
import MenuList, { Option } from "Components/elements/Menu";
import { ChevronDown, UserIcon, caretVerticalDown } from "Assets/Index";
import { OptionsProps } from "types/data";
import { addDeveloperData, addDeveloperKycData } from "redux/slices/admin.slice";
import ModalPanel from "Components/elements/Modal/Index";
import AnswerModal from "./AsnwerModal";
import WrappedMenu from "Components/elements/Menu/WrappedMenu";
import { toast } from "react-toastify";

const DeveloperDetails = () => {
    const { id } = useParams();

    const { developerData, kycDocuments } = useSelector((state: any) => state.admin);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    const [showModal, setSHowModal] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });
    const [modalData, setModalData] = useState({
        image: "",
        heading: ""
    });
    const getDevelopersDetails = async (id: string) => {
        try {
            const res = await GetDevelpersDetails(id);
            dispatch(addDeveloperData(res.notification));
            dispatch(addDeveloperKycData(res.kycDocs));
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        }
    };

    useEffect(() => {
        if (id !== null) {
            getDevelopersDetails(id as string);
        }
    }, [id]);

    const options: OptionsProps[] = [
        { text: "Approve", value: "approve" },
        { text: "Reject", value: "reject" }
    ];

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "approve") {
            setShowReplyModal({
                open: true,
                action: "approve"
            });
        } else if (option?.value === "reject") {
            setShowReplyModal({
                open: true,
                action: "reject"
            });
        }
    };

    return (
        <div className="container p-5 mx-auto my-2 rounded">
            <GoBack link="/admin/authentication" />

            <div className="relative w-full flex justify-end ">
                <div className="bg-assetize-primary w-[150px]  lg:w-[180px] mb-10 rounded-lg ml-2 lg:ml-5">
                    <WrappedMenu
                        options={options}
                        onSelect={handleOptionSelect}
                        icon={ChevronDown}
                        bg="white"
                        size="40"
                        textColor="assetize-dark-gray"
                        child={<div className="text-white">Choose Option </div>}
                    />
                </div>
            </div>
            <div className="bg-assetize-primary-gradient p-10 flex items-center ">
                <div className="rounded-full border-2 border-assetize-primary bg-assetize-gray w-20 h-20 flex justify-center items-center">
                    <UserIcon className="w-[60px] text-[60px] text-white" />
                </div>
                <div className="pl-5">
                    <p className="font-latoBold text-sm  text-assetize-light-text">{developerData?.metaData?.developerName}</p>
                    <p className="font-latoRegular text-sm   text-assetize-light-text">{developerData?.metaData?.email}</p>
                </div>
            </div>
            <div className="border-t border-b border-l-0 border-r-0 border-assetize-primary-gradient ">
                <div className="border-b py-4 border-assetize-gray flex justify-between items-center px-3">
                    <h4 className="font-latoBold text-sm  text-assetize-light-text">Incorporation Documents</h4>
                    <div className="w-[180px]">
                        <a
                            href={kycDocuments?.incorporationDocumentUrl}
                            className="flex items-center justify-between  cursor-pointer"
                            key={kycDocuments?.incorporationDocumentUrl}
                            download="Coperate Governacne"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <OutlineButton color="assetize-gray">
                                <div className="flex items-center justify-around">
                                    <FiEye className=" text-assetize-light-text" />
                                    <p className="font-latoBold text-sm  text-assetize-light-text">View document</p>
                                </div>
                            </OutlineButton>
                        </a>
                    </div>
                </div>

                <div className="border-b py-4 border-assetize-gray flex justify-between items-center px-3">
                    <h4 className="font-latoBold text-sm  text-assetize-light-text">Company’s Profile Document</h4>
                    <div className="w-[180px]">
                        <a
                            href={kycDocuments?.companyProfileDocumentUrl}
                            className="flex items-center justify-between  cursor-pointer"
                            key={kycDocuments?.companyProfileDocumentUrl}
                            download="Coperate Governacne"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <OutlineButton color="assetize-gray">
                                <div className="flex items-center justify-around">
                                    <FiEye className=" text-assetize-light-text" />
                                    <p className="font-latoBold text-sm  text-assetize-light-text">View document</p>
                                </div>
                            </OutlineButton>
                        </a>
                    </div>
                </div>

                <div className="border-b py-4 border-assetize-gray flex justify-between items-center px-3">
                    <h4 className="font-latoBold text-sm  text-assetize-light-text">Corporate Governance</h4>
                    <div className="w-[180px]">
                        <a
                            href={kycDocuments?.corporateGovernanceUrl}
                            className="flex items-center justify-between  cursor-pointer"
                            key={kycDocuments?.corporateGovernanceUrl}
                            download="Cooperate Governance"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <OutlineButton color="assetize-gray">
                                <div className="flex items-center justify-around">
                                    <FiEye className=" text-assetize-light-text" />
                                    <p className="font-latoBold text-sm  text-assetize-light-text">View document</p>
                                </div>
                            </OutlineButton>
                        </a>
                    </div>
                </div>
            </div>

            <ModalPanel open={showReplyModal.open} closeButton closeModal={() => setShowReplyModal({ open: false, action: "" })}>
                <AnswerModal action={showReplyModal.action} developerId={developerData?.userId} isHome={false} />
            </ModalPanel>
        </div>
    );
};

export default DeveloperDetails;
