import React, { useEffect, useState } from "react";
import UserTotal from "../UserTotal";
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import { HiOutlineRefresh } from "react-icons/hi";
import Select from "Components/elements/Forms/Select";
import { Option } from "types";
import LineChart from "./Charts/Linechart";
import { ScaleLoader } from "react-spinners";
import { GetDevelopersAnalytics } from "Services";
import { getErrorMessge } from "Utils/Helpers";
import { toast } from "react-toastify";

const DeveloperAnalytics = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [developersData, setDevelopersData] = useState<any>();
    const [refreshing, setRefreshing] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        transactionFilter: { name: "Monthly" }
    });
    const [sumTotal, setSumTotal] = useState(0);
    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    const getCurrentMonth = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString("default", { month: "short" }).toLowerCase();
        return currentMonth;
    };

    const currentMonth = getCurrentMonth();
    const previousMonth = (() => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1); // Subtract one month to get the previous month
        return currentDate.toLocaleString("default", { month: "short" }).toLowerCase();
    })();

    const previousDay = (() => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1); // Subtract one day to get the previous day
        return currentDate.toLocaleDateString(undefined, { weekday: "short" }).toLowerCase();
    })();

    const previousYear = (() => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 1); // Subtract one year to get the previous year
        return currentDate.getFullYear();
    })();

    const getAnalytics = async () => {
        try {
            setRefreshing(true);
            setLoading(true);
            const res = await GetDevelopersAnalytics(selectedOptions.transactionFilter.name);

            setDevelopersData(res);
        } catch (err: any) {
            const error = getErrorMessge(err);
            toast.error(error);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    useEffect(() => {
        getAnalytics();
    }, [selectedOptions]);

    let lastMonthKey: string | undefined;
    let lastMonthValue: number | undefined;
    let lastYearKey: string | undefined;
    let lastYearValue: number | undefined;
    let lastDayKey: string | undefined;
    let lastDayValue: number | undefined;

    if (developersData?.investmentByTimeFrame) {
        Object.keys(developersData.investmentByTimeFrame).forEach((key) => {
            switch (selectedOptions.transactionFilter.name) {
                case "Monthly":
                    if (key === previousMonth) {
                        lastMonthKey = key;
                        lastMonthValue = Number(developersData.investmentByTimeFrame[key]);
                    }
                    break;
                case "Daily":
                    if (key === previousDay) {
                        lastDayKey = key;
                        lastDayValue = Number(developersData.investmentByTimeFrame[key]);
                    }
                    break;
                case "Yearly":
                    if (key === previousYear.toString()) {
                        lastYearKey = key;
                        lastYearValue = Number(developersData.investmentByTimeFrame[key]);
                    }
                    break;

                default:
                    break;
            }
        });
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center mt-64">
                <ScaleLoader color="#ca1611" />
            </div>
        );
    }

    if (!developersData) {
        return <div>Error loading data.</div>;
    }

    return (
        <div>
            <div className="flex flex-col lg:flex-row justify-between gap-2 mb-4 items-center w-full">
                <UserTotal title="Total Investment value" value={`$${developersData?.totalInvestmentValue?.toLocaleString()}`} />
                <UserTotal title="Total properties listed" value={developersData?.totalPropertyCount} />
                <UserTotal title="Active listings value" value={developersData?.availableCount} />
                <UserTotal title="Sold Properties value" value={developersData?.soldOutCount} />
            </div>
            <div className="flex flex-col lg:flex-row justify-between gap-2">
                <div className="flex lg:w-[70%] h-[50vh] lg:h-full flex-col gap-2 border shadow-md px-3 md:pr-0 md:px-5">
                    <div className="flex text-assetize-primary items-center justify-between">
                        <h4 className="mt-2">Investment Statistics</h4>
                        <div className="flex p-3 gap-3 items-center relative">
                            <HiOutlineRefresh
                                className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                                onClick={() => {
                                    if (!refreshing) {
                                        getAnalytics();
                                    }
                                }}
                            />
                            <div className="space-x-3" style={{ zIndex: 1 }}>
                                <Select
                                    options={[{ name: "Daily" }, { name: "Monthly" }, { name: "Yearly" }]}
                                    placeholder={selectedOptions.transactionFilter.name}
                                    onChange={(selectedOption) => {
                                        handleOptionChange("transactionFilter", selectedOption);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="px-0 ">
                        <LineChart data={developersData?.investmentByTimeFrame} />
                    </div>
                </div>
                <div className="flex lg:w-[30%] flex-col gap-2 border shadow-md px-3 items-center ">
                    <h4 className="mt-2 mb-8 flex text-assetize-primary">Properties Overview</h4>
                    <PieChart
                        activeColor="#CA1611"
                        soldOutColor="#FBBC05"
                        totalPropertyCount={developersData?.totalPropertyCount || 0}
                        soldOutPercentage={developersData?.soldOutPercentage || 0}
                        availablePercentage={developersData?.availablePercentage || 0}
                    />
                </div>
            </div>
            <div className="flex w-full mt-8 h-full flex-col gap-2 border shadow-md px-3 md:pr-0 md:px-5">
                <div className="flex text-assetize-primary items-center justify-between">
                    <h4 className="mt-2">Total Investments</h4>
                    <div className="flex p-3 gap-3 items-center relative">
                        <HiOutlineRefresh
                            className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                            onClick={() => {
                                if (!refreshing) {
                                    getAnalytics();
                                }
                            }}
                        />
                        <div className="space-x-3" style={{ zIndex: 1 }}>
                            <Select
                                options={[{ name: "Daily" }, { name: "Monthly" }, { name: "Yearly" }]}
                                placeholder={selectedOptions.transactionFilter.name}
                                onChange={(selectedOption) => {
                                    handleOptionChange("transactionFilter", selectedOption);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="hidden lg:flex items-center gap-3 text-assetize-dark ">
                    <h2>{`$${developersData?.totalInvestmentValue?.toLocaleString()}`}</h2>
                    <p>
                        {selectedOptions.transactionFilter.name === "Daily" && <p>Yesterday ${lastDayValue?.toLocaleString() || "0"}</p>}
                        {selectedOptions.transactionFilter.name === "Monthly" && (
                            <p>last month ${lastMonthValue?.toLocaleString() || "0"}</p>
                        )}
                        {selectedOptions.transactionFilter.name === "Yearly" && <p>last year ${lastYearValue?.toLocaleString() || "0"}</p>}
                    </p>
                </div>
                <div className="lg:hidden flex items-center gap-3 text-assetize-dark ">
                    <h5>{`$${developersData?.totalInvestmentValue?.toLocaleString()}`}</h5>
                    <p>
                        {selectedOptions.transactionFilter.name === "Daily" && <p>Yesterday ${lastDayValue?.toLocaleString() || "0"}</p>}
                        {selectedOptions.transactionFilter.name === "Monthly" && (
                            <p>last month ${lastMonthValue?.toLocaleString() || "0"}</p>
                        )}
                        {selectedOptions.transactionFilter.name === "Yearly" && <p>last year ${lastYearValue?.toLocaleString() || "0"}</p>}
                    </p>
                </div>
                <div className="px-0">
                    <BarChart data={developersData.investmentByTimeFrame} />
                </div>
            </div>
        </div>
    );
};

export default DeveloperAnalytics;
