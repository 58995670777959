import { NotFoundImg } from "Assets/Index";
import { GetAdminNotification } from "Services";
import { GetAllNotifications, MarkAllNotificationAsRead, MarkNotifcationAsRead } from "Services/apis/notification.api";
import { getErrorMessge, getTimeAgo } from "Utils/Helpers";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Button } from "rsuite";

export type NotificationItem = {
    id: number;
    imageSrc: string;
    content: string;
    time: string;
    isDividend: boolean;
};

type Props = {
    onCloseModal: () => void;
};

const NotificationDetails: React.FC<Props> = ({ onCloseModal }) => {
    const [activeButton, setActiveButton] = useState<"All" | "Properties" | "Dividends">("All");
    const { user } = useSelector((state: any) => state.auth);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [displayList, setDisplayList] = useState<any[]>([]);
    const [readNotificationLoading, setReadNotificationLoading] = useState(false);
    const handleButtonClick = (button: "All" | "Properties" | "Dividends") => {
        setActiveButton(button);
    };
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getNotifications = async () => {
        try {
            setLoading(true);
            let res;
            if (user?.accountType !== "admin") {
                res = await GetAllNotifications(user.id);
            } else {
                res = await GetAdminNotification();
            }
            const filternNotifications = user?.accountType === "admin" ? res : res.notifications;
            const sortedNotifications = filternNotifications.sort((a: any, b: any) => {
                const dateA: any = new Date(a.createdAt);
                const dateB: any = new Date(b.createdAt);

                return dateB - dateA;
            });
            setNotifications(sortedNotifications);
            setDisplayList(sortedNotifications);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    const readNotification = async (notificationId: string) => {
        try {
            const res = await MarkNotifcationAsRead(notificationId);
        } catch (err) {
            const errMsg = getErrorMessge(err);
            toast.error(errMsg);
        }
    };

    const markAllNotificationAsRead = async () => {
        try {
            setReadNotificationLoading(true);
            const res = await MarkAllNotificationAsRead();

            const updatedNotification = notifications.map((notification) => ({
                ...notification,
                read: true
            }));
            setNotifications(updatedNotification);
            setDisplayList(updatedNotification);
        } catch (err) {
            setReadNotificationLoading(false);
            const errMsg = getErrorMessge(err);
            toast.error(errMsg);
        } finally {
            setReadNotificationLoading(false);
        }
    };

    const categories = [
        "TRANSACTIONS",
        "SHARE_LISTINGS",
        "CREDIT",
        "DEBIT",
        "WITHDRAWAL",
        "REVERSAL",
        "DIVIDEND_REMINDER",
        "CONVERSION_TRANSACTION"
    ];

    useEffect(() => {
        if (activeButton === "All") {
            setDisplayList(notifications);
        } else if (activeButton === "Properties") {
            const data = notifications.filter(
                (item) =>
                    item.notificationType === "TRANSACTIONS" ||
                    item.notificationType === "SHARE_LISTINGS" ||
                    item.notificationType === "CREDIT" ||
                    item.notificationType === "DEBIT" ||
                    item.notificationType === "WITHDRAWAL" ||
                    item.notificationType === "REVERSAL" ||
                    item.notificationType === "DEVELOPER_WITHDRAWAL_REQUEST" ||
                    item.notificationType === "PROPERTY_LISTING_REJECTED" ||
                    item.notificationType === "OTHERS" ||
                    item.notificationType === "CONVERSION_TRANSACTION"
            );

            setDisplayList(data);
        } else if (activeButton === "Dividends") {
            const data = notifications.filter((item) => item.notificationType === "DIVIDEND_REMINDER");

            setDisplayList(data);
        }
    }, [activeButton]);

    const handleView = (id: string, type: string) => {
        if (categories.includes(type)) {
            if (type !== "SHARE_LISTINGS") {
                navigate("/investor/wallets");
            }

            readNotification(id);
        } else {
            readNotification(id);
        }
    };

    return (
        <div>
            <div className="flex flex-row justify-between items-center md:mx-6 font-latoRegular mt-8">
                <div className="text-2xl font-latoRegular font-bold text-[#262324] w-[140px] shrink-0">Notifications</div>
                <button
                    onClick={markAllNotificationAsRead}
                    onKeyDown={markAllNotificationAsRead}
                    type="button"
                    className="whitespace-nowrap text-sm  font-latoRegular text-[#ca1611] w-24 shrink-0 cursor-pointer"
                >
                    {readNotificationLoading ? "Loading..." : " mark all as read"}
                </button>
            </div>
            <div className="border-solid mb-3 h-px shrink-0 border-t border-b-0 border-black border-x-0" />
            <div className="bg-assetize-gray  flex flex-row justify-around w-full items-center mb-5  p-1 rounded-full">
                <button
                    type="button"
                    className={`text-sm  text-[#4b4b4d] w-[30%] rounded-full  p-1 md:p-2 ${
                        activeButton === "All" ? "text-assetize-primary bg-assetize-primary-gradient" : "text-assetize-dark-gray"
                    }`}
                    onClick={() => handleButtonClick("All")}
                >
                    <div className={`${activeButton === "All" ? "text-assetize-primary" : "text-dark-gray"}`}>All</div>
                </button>
                <button
                    type="button"
                    className={` flex flex-col w-[30%] items-center rounded-full  p-1 md:p-2  ${
                        activeButton === "Properties" ? "text-assetize-primary bg-assetize-primary-gradient" : "text-assetize-dark-gray"
                    }`}
                    onClick={() => handleButtonClick("Properties")}
                >
                    <div className={`${activeButton === "Properties" ? "text-assetize-primary " : "text-dark-gray"}`}>Properties</div>
                </button>
                <button
                    type="button"
                    className={`text-sm  rounded-full w-[30%] p-1 md:p-2  ${
                        activeButton === "Dividends" ? "text-assetize-primary bg-assetize-primary-gradient" : "text-assetize-dark-gray"
                    }`}
                    onClick={() => handleButtonClick("Dividends")}
                >
                    <div className={`${activeButton === "Dividends" ? "text-assetize-primary" : "text-dark-gray"}`}>Dividends</div>
                </button>
            </div>
            <div className="w-full ">
                {loading ? (
                    <div className="w-full h-full my-10 flex justify-center items-center">
                        <ScaleLoader color="#ca1611" />
                    </div>
                ) : (
                    displayList.slice(0, 4).map((item: any) => {
                        const dateTime = getTimeAgo(item.createdAt);
                        return (
                            <div className="border-b border-assetize-gray py-2  flex font-latoRegular w-full">
                                <div className="flex items-center w-full">
                                    <div
                                        className={`${!item.read ? "bg-assetize-primary" : "bg-assetize-dark-text"} w-2 h-2 rounded-full`}
                                    />
                                    <div className="ml-3 w-full px-3">
                                        <h4 className="text-sm md:text-md">{item.notificationMessage}</h4>
                                        <div className="flex w-full items-center justify-between">
                                            <p className="text-assetize-dark-gray text-sm md:text-md">{dateTime}</p>

                                            <button
                                                type="button"
                                                onKeyDown={() => {
                                                    handleView(item.id, item.notificationType);

                                                    onCloseModal();
                                                    navigate("/notifications");
                                                }}
                                                onClick={() => {
                                                    handleView(item.id, item.notificationType);
                                                    onCloseModal();
                                                    navigate("/notifications");
                                                }}
                                                className="underline text-assetize-primary text-sm md:text-md  "
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>

            <div className="border-solid h-px shrink-0 mb-8 mr-px border-t border-b-0 border-black border-x-0" />
            <div
                onClick={() => {
                    onCloseModal();
                    navigate("/notifications");
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                    onCloseModal();
                    navigate("/notifications");
                }}
                className="whitespace-nowrap text-md  text-center font-latoRegular text-[#ca1611] self-center w-32 cursor-pointer"
            >
                View all notifications
            </div>
        </div>
    );
};

export default NotificationDetails;
