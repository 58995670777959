import { EmailConfirmation, Logo } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Password from "Components/elements/Forms/Password";
import Input from "Components/elements/Forms/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthLogoDisplay from "Components/elements/Display/AuthLogoDisplay";
import { register, welcomeUser } from "redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SignupStageType, UserAccountType } from "types/Auth.types";
import { validateAll, extend, validate } from "indicative/validator";
import { toast } from "react-toastify";
import { Radio } from "rsuite";
import { checkPasswordRequirements } from "Utils/Helpers";
import { date } from "yup";
import ControlledCheckBox from "Components/elements/Forms/ControlledCheckBox";
import ModalPanel from "Components/elements/Modal/Index";
import InvestorInformation from "Pages/appcore/invest/portfolio/modals/InvestorInformation";
import TermsAndCondition from "Pages/appcore/invest/portfolio/modals/TermsAndCondition";
import PrivacyPolicy from "Pages/appcore/invest/portfolio/modals/PrivacyPolicy";

interface ErrorType {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
    confirmPassword?: string;
    [key: string]: string | undefined;
}

const Register = ({ setStage }: SignupStageType) => {
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const authData = useSelector((state: any) => state.auth);
    const [formData, setFormData] = useState<any>({
        accountType: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        password: "",
        emailAddress: "",
        phoneNumber: ""
    });
    const [error, setErrors] = useState<any>({});
    const [selectedDate, setSelectedDate] = useState<Date | null>(null); // Specify the type as Date | null

    const [checkTermsAndConditionAgreement, setCheckTermsAndConditionAgreement] = useState(false);
    const [showInvestorFormModals, setShowInvestorFormModals] = useState(false);
    const [showTermsAndConditions, setShowTermsAndCondtionModals] = useState(false);
    const accountType = localStorage.getItem("selectedOption") as string;
    const handleInputChange = (name: string, value: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));

        setErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: undefined
        }));
    };

    const handleDateChange = (date: Date | null) => {
        // Explicitly specify the type for 'date'
        setSelectedDate(date);

        setFormData({
            ...formData,
            dateOfBirth: date
        });

        setErrors((prevErrors: any) => ({
            ...prevErrors,
            dateOfBirth: undefined
        }));
    };
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { firstName, lastName, dateOfBirth, email, password, confirmPassword } = formData;

        const validateMinAge = async (data: any, field: string, args: any, config: any) => {
            const dateOfBirth = new Date(data[field]);
            const currentDate = new Date();
            const age = Math.floor((currentDate.getTime() - dateOfBirth.getTime()) / (365.25 * 24 * 60 * 60 * 1000));

            if (age < 18) {
                throw new Error(`You must be at least 18 years old to register`);
            }

            return true;
        };
        extend("minAge", {
            async: true,
            validate: validateMinAge
        });

        const validationRules = {
            firstName: "required",
            lastName: "required",
            dateOfBirth: "required|minAge",
            email: "required|email",
            password: "required|min:8",
            phoneNumber: "required|min:11|max:11",
            confirmPassword: "required|same:password"
        };

        const validationMessages = {
            "firstName.required": "First name is required",
            "lastName.required": "Last name is required",
            "email.required": "Email address is required",
            "email.email": "Email address is not valid",
            "dateOfBirth.required": "Date of birth is required",
            "dateOfBirth.minAge": "You must be at least 18 years",
            "password.required": "Password is required",
            "password.min": "Password must be 8 characters long",
            "phoneNumber.required": "Phone Number is required",
            "phoneNumber.min": "Please enter a valid phone number",
            "phoneNumber.max": "Please enter a valid phone number",
            "confirmPassword.required": "Confirm password is required",
            "confirmPassword.same": "Passwords do not match"
        };

        if (!checkTermsAndConditionAgreement) {
            setErrors({ accept: "You must  accept the  privacy policy  and Terms and Conditions to continue! " });
            return;
        }

        try {
            await validateAll(formData, validationRules, validationMessages);

            const data = {
                accountType: accountType as UserAccountType,
                firstName: formData.firstName,
                lastName: formData.lastName,
                dateOfBirth: formData.dateOfBirth,
                password: formData.password,
                emailAddress: formData.email,
                phoneNumber: formData.phoneNumber
            };

            await dispatch(register(data));

            localStorage.setItem("userEmail", data.emailAddress);

            setStage("2");
        } catch (error: any) {
            if (Array.isArray(error)) {
                const formattedErrors: ErrorType = {};
                error.forEach((err) => {
                    formattedErrors[err.field] = err.message;
                });

                setErrors(formattedErrors);
                // toast.error(error[0].message);
            } else {
                toast.error(error.message);
            }
        }
    };

    return (
        <div className="flex flex-col items-center w-full mt-16 font-latoRegular ">
            <div className="flex flex-col items-center w-full ">
                <AuthLogoDisplay />
                <h2 className="mt-8 text-2xl text-assetize-primary font-latoBold align-center">Get Started</h2>
                <p className="text-sm text-assetize-dark-gray text-md align-center ">Enter details below to create an account</p>
            </div>

            <form onSubmit={handleSubmit} className=" w-full md:w-[90%] lg:w-[60%]  mt-4 ">
                <div className="py-2">
                    <Input
                        label="First Name (as it appears on your Government ID)"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        placeholder="e.g John"
                        className="focus:bg-[#F59999]/30 focus:ring-assetize-primary focus:border-assetize-primary border-1 rounded-md"
                        error={error.firstName}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="py-2">
                    <Input
                        label="Last Name"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        placeholder="e.g Doe"
                        className="focus:bg-[#F59999]/30 focus:ring-assetize-primary focus:border-assetize-primary border-1 rounded-md"
                        error={error.lastName}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="hidden lg:flex py-2">
                    <Input
                        label="Date of Birth"
                        type="date"
                        name="dateOfBirth"
                        value={formData.dateOfBirth}
                        placeholder="DD/MM/YYYY"
                        className="uppercase focus:bg-[#F59999]/30 focus:ring-assetize-primary focus:border-assetize-primary border-1 rounded-md"
                        error={error.dateOfBirth}
                        withCalendar
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className=" lg:flex py-2">
                    <Input
                        label="Phone Number"
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        placeholder="09012345678"
                        className="uppercase focus:bg-[#F59999]/30 focus:ring-assetize-primary focus:border-assetize-primary border-1 rounded-md"
                        error={error.phoneNumber}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="block lg:hidden py-2 w-full">
                    <label htmlFor="dateOfBirth" className="flex flex-col text-sm font-medium relative text-gray-700">
                        Date of Birth
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            className={`uppercase mt-1 border ${
                                error.dateOfBirth ? "bg-[#F59999]/20 border-assetize-primary" : "border-assetize-gray"
                            } w-[100%] outline-none px-3 py-3 rounded-md focus:bg-[#F59999]/30 focus:border-assetize-primary`}
                        />{" "}
                        {error.dateOfBirth && <span className="text-assetize-primary text-xs mt-1">{error.dateOfBirth}</span>}
                    </label>
                </div>
                <div className="py-2">
                    <Input
                        label="Email"
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder="hi@example.com"
                        className="focus:bg-[#F59999]/30 focus:ring-assetize-primary focus:border-assetize-primary border-1 rounded-md"
                        error={error.email}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="py-2">
                    <Password
                        label="Password"
                        name="password"
                        value={formData.password}
                        placeholder="Enter password"
                        error={error.password}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="py-2">
                    <Password
                        label="Confirm Password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        placeholder="Enter password"
                        error={error.confirmPassword}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="flex items-start  mt-7">
                    <div className=" mt-1">
                        <ControlledCheckBox
                            checked={checkTermsAndConditionAgreement}
                            name="checkTermsAndConditionAgreement"
                            onChange={() => {
                                setCheckTermsAndConditionAgreement(!checkTermsAndConditionAgreement);
                            }}
                            label=""
                            error=""
                        />
                    </div>
                    <p className="pl-2 text-assetize-dark-gray font-latoRegular">
                        I have read and understood Assetize&apos;s
                        <span
                            onClick={() => setShowTermsAndCondtionModals(true)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                    setShowTermsAndCondtionModals(true);
                                }
                            }}
                            role="button"
                            tabIndex={0}
                            className="text-assetize-primary underline cursor-pointer"
                        >
                            Privacy policy
                        </span>
                        and agree to it&apos;s
                        <span
                            onClick={() => setShowInvestorFormModals(true)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Space") {
                                    setShowInvestorFormModals(true);
                                }
                            }}
                            role="button"
                            tabIndex={0}
                            className="text-assetize-primary underline cursor-pointer"
                        >
                            {" "}
                            Terms and Conditions
                        </span>
                    </p>
                </div>
                {error.accept && <p className="mt-3 text-xs font-latoRegular text-assetize-primary ">{error.accept}</p>}
                <div className="pt-6 md:py-3">
                    <MainButton isLoading={authData.isLoading} disabled={authData.isLoading} type="submit">
                        Sign Up
                    </MainButton>
                </div>
                <div className="flex justify-center pb-10">
                    <p className="text-sm font-latoRegular ">
                        <span className="text-assetize-dark-gray ">Already have an account? </span>{" "}
                        <Link to="/auth/login" className="text-assetize-primary hover:text-black">
                            Login
                        </Link>
                    </p>
                </div>
            </form>
            <ModalPanel open={showInvestorFormModals} closeButton closeModal={() => setShowInvestorFormModals(false)}>
                <TermsAndCondition
                    cancel={() => {
                        setShowInvestorFormModals(false);
                        setCheckTermsAndConditionAgreement(false);
                    }}
                    accountType={accountType}
                    accept={() => {
                        setCheckTermsAndConditionAgreement(true);
                        setShowInvestorFormModals(false);
                    }}
                />
            </ModalPanel>
            <ModalPanel open={showTermsAndConditions} closeButton closeModal={() => setShowTermsAndCondtionModals(false)}>
                <PrivacyPolicy
                    cancel={() => {
                        setShowTermsAndCondtionModals(false);
                        setCheckTermsAndConditionAgreement(false);
                    }}
                    accept={() => {
                        setCheckTermsAndConditionAgreement(true);
                        setShowTermsAndCondtionModals(false);
                    }}
                />
            </ModalPanel>
        </div>
    );
};

export default Register;
