/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import { BiArrowBack } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Carousel, SelectPicker, Toggle } from "rsuite";
import DocumentBox from "Components/elements/Display/DocumentBox";
import AboutBox from "Components/elements/Display/AboutBox";
import { CarretDown, Cart, ChevronDown, InfoWarning, NullImg, SampleTwo, Send, Users } from "Assets/Index";
import { capitalizeFirstLetter, capitalizeText, getErrorMessge, getYearAndMonthAgo, readableDate } from "Utils/Helpers";
import GoBack from "Components/elements/Display/GoBack";
import MenuList, { Option } from "Components/elements/Menu";
import { OptionsProps } from "types/data";
import WrappedMenu from "Components/elements/Menu/WrappedMenu";
import ModalPanel from "Components/elements/Modal/Index";
import DeleteProperty from "../modals/DeleteProperty";
import { DeactivateLsting, InitiateWithdrawal } from "Services";
import SeeInvestors from "../modals/SeeInvestors";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import SuccessWithdrawInitiate from "../modals/SuccessIwthdrawInitiate";
import PropertyPerfomance from "./PropertyPerfomance";
import DisplayDocument from "Components/elements/Display/DisplayDocument";
import { toast } from "react-toastify";

const DeveloperPropertyDetail = () => {
    const navigate = useNavigate();
    const { state: item } = useLocation();

    const {
        approvedByAdmin,
        availabilityStatus,
        description,
        developerName,
        expectedAnnualROI,
        fiatValue,
        id,
        investmentType,
        location,
        numberOfShares,
        propertyGallery,
        propertyName,
        propertyType,
        sharePrice,
        dividend,
        createdAt,
        expectedCompletionDate
    } = item;

    const listedDate = readableDate(createdAt);
    const yearAndMonthAgo = readableDate(expectedCompletionDate);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });

    const options: OptionsProps[] = [
        { text: "Performance", value: "performance" },
        { text: "Edit Property", value: "edit" },
        { text: "Initiate Withdrawal", value: "withdraw" }
    ];

    const [propertyIsListed, setPropertyIsListed] = useState(false);
    const [performance, setPerformance] = useState(false);
    const [initiateWitdraw, setInitiateWitdraw] = useState(false);

    useEffect(() => {
        if (availabilityStatus === "AVAILABLE") {
            setPropertyIsListed(true);
        } else {
            setPropertyIsListed(false);
        }
    }, []);

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "performance") {
            setShowReplyModal({
                open: true,
                action: "performance"
            });
        } else if (option?.value === "edit") {
            navigate(`/developer/edit-property/${id}`, { state: item });
        } else {
            setInitiateWitdraw(true);
        }
    };

    const initiateWithdrawal = async () => {
        try {
            const res = await InitiateWithdrawal();

            setShowReplyModal({
                open: true,
                action: "withdraw"
            });
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        }
    };

    useEffect(() => {
        if (initiateWitdraw) {
            initiateWithdrawal();
        }
    }, [initiateWitdraw]);

    const withdrawSuccessText = (
        <p>
            Your funds Withdrawal for <span className="font-latoBold">{propertyName} </span> has been initiated and will be approved by the
            admin shortly.
        </p>
    );

    return (
        <div className="p-2 md:p-4 my-5 bg-white rounded">
            <GoBack link="/developer" />
            <div className="flex  flex-col items-start  justify-around px-5 py-8">
                <div className="flex-[.5]">
                    <div className="flex items-center">
                        <h2 className="md:text-md text-[16px] font-latoBold text-black ">{propertyName}</h2>
                        <div
                            className={`${
                                availabilityStatus === "SOLD_OUT" ? "bg-assetize-primary-gradient " : "bg-assetize-green-gradient "
                            } p-1 rounded-2xl ml-2`}
                        >
                            <p
                                className={`${
                                    availabilityStatus === "SOLD_OUT" ? "text-assetize-primary " : "text-assetize-green-text "
                                }  text-xs text-center `}
                            >
                                {!availabilityStatus ? "Sold Out" : "Available"}
                            </p>
                        </div>
                    </div>
                    <p className="text-xs md:text-sm  font-latoRegular my-1 text-assetize-dark-gray">{location}</p>
                    <h3 className="text-assetize-primary font-latoBold text-lg">${fiatValue?.toLocaleString("en-Us")}</h3>
                    <p className="text-xs md:text-sm  font-latoItalic my-1 text-assetize-dark-gray ">
                        Listed by <span className="text-assetize-primary">{developerName} </span> on <span>{listedDate}</span>
                    </p>
                </div>
                <div className="flex w-full  flex-col-reverse md:flex-col ">
                    <div className="flex  flex-col items-center  md:flex-row justify-end py-5">
                        <div className="flex gap-x-4 w-[100%] md:w-[180px] lg:w-[200px]">
                            <OutlineButton onClick={() => setShowReplyModal({ open: true, action: "seeInvestors" })} className="px-6">
                                <div className="flex justify-center md:justify-around items-center text-assetize-dark font-semibold">
                                    <Users />
                                    <p className="font-latoBold text-black ml-4">See Investors</p>
                                </div>
                            </OutlineButton>
                        </div>

                        <div className="bg-assetize-primary w-[100%] mt-3 md:mt-0 md:w-[180px] lg:w-[200px] rounded-lg md:ml-2 lg:ml-5">
                            <WrappedMenu
                                options={options}
                                onSelect={handleOptionSelect}
                                icon={CarretDown}
                                bg="white"
                                size="40"
                                textColor="assetize-dark-gray"
                                child={<div className="text-white">Choose Options </div>}
                            />
                        </div>
                    </div>

                    <Carousel autoplay shape="bar">
                        {propertyGallery?.propertyImages?.map((imgUrl: string) => (
                            <div className="relative w-full h-[300px] md:h-full" key={imgUrl}>
                                <img src={imgUrl} alt="property_img" className="absolute object-cover w-full h-full" />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>

            <div className="p-2 md:p-5 flex gap-2 mt-3">
                <div className="w-full border border-assetize-gray rounded md:py-4 py-2 px-1">
                    <h3 className="text-assetize-primary text-lg pl-3  my-3">Property Valuation </h3>
                    <div className="md:grid md:grid-cols-3 flex flex-col mt-10 ">
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center  font-latoLight text-sm uppercase font-semibold">
                                Property Type{" "}
                            </p>
                            <p className="text-assetize-light-text  text-center font-latoBold text-md  md:mt-2">
                                {capitalizeText(propertyType)}
                            </p>
                        </div>
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:border-assetize-gray md:py-4 px-1">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase"> Expected ROI </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md  md:mt-2">{expectedAnnualROI} %</p>
                        </div>
                        <div className="md:border-b flex justify-between md:flex-col w-full items-center md:border-assetize-gray md:py-4 px-1 ">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase font-semibold">
                                Total Shares{" "}
                            </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md  md:mt-2">{numberOfShares} </p>
                        </div>
                        <div className="md:border-r flex justify-between md:flex-col w-full items-center md:border-assetize-gray md:py-4 px-1 ">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase font-semibold">
                                Dividends{" "}
                            </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md md:mt-2 ">{dividend ? "Yes" : "No"}</p>
                        </div>
                        <div className="md:border-r flex justify-between md:flex-col w-full items-center md:border-assetize-gray md:py-4 px-1">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase font-semibold">
                                Original value of shares
                            </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md md:mt-2">
                                {/* {sharePrice}/  */}${fiatValue.toLocaleString("en-Us")}{" "}
                            </p>
                        </div>
                        <div className=" md:border-assetize-gray flex md:flex-col justify-between w-full items-center md:py-4 px-1 ">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase font-semibold">
                                Completion date{" "}
                            </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md   "> {yearAndMonthAgo}</p>
                        </div>
                    </div>
                </div>
            </div>
            <AboutBox text={description as string} />
            <div className="md:mx-4 mt-5 px-5 py-4 space-y-3 border rounded-lg  ">
                <h6 className="text-assetize-primary">Documents</h6>
                <div className="space-y-2 ">
                    <DisplayDocument document={propertyGallery?.projectPlan} name="projectPlan" />
                    <DisplayDocument document={propertyGallery?.legalCompliance} name="legalCompliance" />
                    <DisplayDocument
                        document={propertyGallery?.insuranceAndPerformanceBondFromContractors}
                        name="insuranceAndPerformanceBondFromContractors"
                    />
                    <DisplayDocument document={propertyGallery?.financialProjectionsOfProject} name="financialProjectionsOfProject" />
                    <DisplayDocument document={propertyGallery?.approvalPermitOfProject} name="approvalPermitOfProject" />
                    <DisplayDocument document={propertyGallery?.titleDocumentOfProject} name="titleDocumentOfProject" />
                </div>
            </div>
            <div className="flex items-center justify-center p-3 mx-1 md:mx-8 mt-5 rounded bg-assetize-primary-gradient ">
                <InfoWarning />
                <p className="text-sm ml-1 md:ml-0 font-latoBold text-assetize-primary ">
                    Earned returns are paid into your wallet when due. Claim your capital anytime by selling your shares.
                </p>
            </div>

            <ModalPanel
                open={showReplyModal.open && showReplyModal.action === "delete"}
                closeButton
                closeModal={() => setShowReplyModal({ open: false, action: "" })}
            >
                <DeleteProperty id="" isHome={false} onClose={() => {}} />
            </ModalPanel>

            <ModalPanel
                open={showReplyModal.open && showReplyModal.action === "seeInvestors"}
                closeButton
                closeModal={() => setShowReplyModal({ open: false, action: "" })}
            >
                <SeeInvestors id={id} isHome={false} onClose={() => setShowReplyModal({ open: false, action: "" })} />
            </ModalPanel>
            <ModalPanel
                open={showReplyModal.open && showReplyModal.action === "withdraw"}
                closeButton
                closeModal={() => setShowReplyModal({ open: false, action: "" })}
            >
                <SuccessWithdrawInitiate
                    handleClick={() => {
                        window.location.reload();
                    }}
                    propertyName={propertyName}
                />
            </ModalPanel>
            <ModalPanel
                open={showReplyModal.open && showReplyModal.action === "performance"}
                closeButton
                closeModal={() => setShowReplyModal({ open: false, action: "" })}
                title="Property Performance"
                modalSize="w-[80%]"
            >
                {id && <PropertyPerfomance id={id} />}
            </ModalPanel>
        </div>
    );
};

export default DeveloperPropertyDetail;
