import React, { useState } from "react";
import { NotFoundImg } from "Assets/Index";
import { MainButton } from "Components/elements/Button/Index";
import SafeLockWithdrawal from "./sections/SafeLockWithdrawal";

interface SafeLockPocketCardProps {
    item: any;
}
interface OptionsProps {
    text: string;
    value: string;
}

const SafeLockPocketCard = ({ item }: SafeLockPocketCardProps) => {
    const options: OptionsProps[] = [{ text: "Withdraw", value: "withdraw" }];
    const [openWithdraw, setOpenWithdraw] = useState(false);
    const [action, setAction] = useState<string | null>(null);

    const handleOptionSelect = () => {
        setAction("safelockWithdraw");
    };
    const pocketCreationDate = item.pocketCreationDate ? new Date(item.pocketCreationDate) : null;
    const pocketMaturationDate = item.pocketMaturationDate ? new Date(item.pocketMaturationDate) : null;
    const profit = (item.pocketInterestRate * item.safelockAmount) / 100;

    const durationInMonths =
        pocketCreationDate && pocketMaturationDate
            ? Math.round((+pocketMaturationDate - +pocketCreationDate) / (30 * 24 * 60 * 60 * 1000))
            : null;

    if (!item) {
        return (
            <div className="flex flex-col justify-center items-center py-10">
                <img src={NotFoundImg} alt="Null" />
                <p className="text-base font-semibold uppercase">You do not have a safelock property</p>
                <MainButton>+ Add to Safelock</MainButton>
            </div>
        );
    }

    return (
        <div key={item.pocketId}>
            <div className="hidden md:flex items-center justify-between w-full px-4 py-5 my-2 border rounded border-assetize-gray">
                <h3 className=" w-1/6 text-md font-latoRegular capitalize flex justify-start pl-3 text-sm">{item.pocketName || "N/A"}</h3>
                <h3 className=" w-1/6 text-md font-latoRegular capitalize flex justify-start  text-sm">${item.safelockAmount}</h3>
                <h3 className=" w-1/6 text-md font-latoRegular capitalize flex justify-start  text-sm">
                    {item.pocketCreationDate &&
                        new Date(item.pocketCreationDate).toLocaleDateString("en-Uk", {
                            year: "numeric",
                            month: "short",
                            day: "numeric"
                        })}
                </h3>
                <h3 className="w-1/6 text-md font-latoRegular capitalize text-sm flex justify-start">{durationInMonths} months</h3>
                <h3 className=" w-1/6 text-md font-latoRegular capitalize flex justify-start  text-sm">
                    {item.pocketMaturationDate &&
                        new Date(item.pocketMaturationDate).toLocaleDateString("en-Uk", {
                            year: "numeric",
                            month: "short",
                            day: "numeric"
                        })}
                </h3>
                <h3 className=" w-1/6 text-md font-latoRegular capitalize flex justify-start  text-sm">
                    ${item.profit ? item.profit : profit}
                </h3>
                {item.pocketState !== "ACTIVE" ? (
                    <p>Broken</p>
                ) : (
                    <button
                        type="button"
                        onClick={() => setOpenWithdraw(true)}
                        className={`flex-[.1] w-[66px] text-assetize-primary justify-center text-md text-[16px] font-semibold 
                      underline 
                `}
                    >
                        Withdraw
                    </button>
                )}
            </div>

            <div className="flex md:hidden">
                <button type="button" className="cursor-pointer w-full">
                    <div className="flex items-center justify-between w-full p-3 px-4 py-5 my-2 border rounded border-assetize-gray">
                        <div className="text-start w-1/2 flex gap-3 items-start flex-col">
                            <h4 className=" flex-[.1] text-md font-latoRegular capitalize flex justify-start  text-sm">
                                {item.pocketName || "N/A"}
                            </h4>
                            {/* <h4 className="text-md font-latoRegular capitalize text-sm flex justify-start flex-[.1]">09 Dec. 2023</h4> */}
                            <h4 className="flex-[.1] text-md font-latoRegular capitalize text-sm flex justify-start  ">
                                Due{" "}
                                {item.pocketMaturationDate &&
                                    new Date(item.pocketMaturationDate).toLocaleDateString("en-Uk", {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric"
                                    })}
                            </h4>
                        </div>
                        <div className="items-end w-1/2 flex gap-3 flex-col">
                            <h4 className=" text-md font-latoRegular capitalize text-sm flex justify-start flex-[.1]">
                                ${item.safelockAmount}
                            </h4>
                            {item.pocketState !== "ACTIVE" ? (
                                <p>Broken</p>
                            ) : (
                                <button
                                    type="button"
                                    onClick={() => setOpenWithdraw(true)}
                                    className={`flex-[.1] w-[66px] text-assetize-primary justify-center text-md text-[16px] font-semibold 
                      underline 
                `}
                                >
                                    Withdraw
                                </button>
                            )}
                        </div>
                    </div>
                </button>
            </div>
            {openWithdraw && (
                <SafeLockWithdrawal
                    closeModal={() => setOpenWithdraw(false)}
                    safeLockBalance={item.safelockAmount}
                    pocketId={item.pocketId}
                    pocketData={item}
                />
            )}
        </div>
    );
};

export default SafeLockPocketCard;
