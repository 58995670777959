import React, { useState } from "react";
import { PropertyDataProps, PropertyStatus, OptionsProps } from "types/data";
import { MainButton } from "Components/elements/Button/Index";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowUp } from "react-icons/ai";
import { NullImg, ThreeDotsHorizontal } from "Assets/Index";
import ModalPanel from "Components/elements/Modal/Index";
import { readableDate } from "Utils/Helpers";
import MenuList, { Option } from "Components/elements/Menu";
import ViewListing from "./modals/ViewListing";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";
import { closeShareListing } from "Services";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";

interface ShareListingCardProps {
    property: any;
    isGrid: boolean;
}

const ShareListingCard = ({ property, isGrid }: ShareListingCardProps) => {
    // const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { shareNumber, propertyData, alwaysOpen, sharePrice, listingId, closeDate } = property;

    const navigate = useNavigate();
    const propertyCloseDate = readableDate(closeDate);
    const [actionData, setActionData] = useState({
        open: false,
        action: ""
    });

    const [loading, setLoading] = useState(false);
    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalMobileOpen = () => {
        setActionData({
            open: true,
            action: "view"
        });
    };

    const handleMobileKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" || event.key === " ") {
            handleModalMobileOpen();
        }
    };

    const options: OptionsProps[] = [
        { text: "View Listing", value: "view" },
        { text: "Edit Offer", value: "edit" },
        { text: "Close Offer", value: "close" }
    ];

    const handleOptionSelect = (option: Option) => {
        if (option?.value === "view") {
            setActionData({
                open: true,
                action: "view"
            });
        } else if (option?.value === "edit") {
            setActionData({
                open: true,
                action: "edit"
            });
        } else {
            setActionData({
                open: true,
                action: "close"
            });
        }
    };

    const isAvailable = property?.propertyData?.property?.availabilityStatus === "AVAILABLE";
    const isResale = property?.propertyData?.property?.availabilityStatus === "RESALE";

    const statusStyle = {
        background: "",
        text: "",
        textContent: ""
    };

    if (isAvailable) {
        statusStyle.background = "bg-green-200";
        statusStyle.text = "text-assetize-green-text";
        statusStyle.textContent = "Available";
    } else if (isResale) {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Re-sale ";
    } else {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Sold Out";
    }

    const closeShare = async () => {
        try {
            setLoading(true);
            const res = await closeShareListing(listingId);
            setActionData({
                open: true,
                action: "closeSuccess"
            });
            toast.success("Share listing closed successfully");
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div
                onClick={() => handleModalOpen()}
                role="button"
                tabIndex={0}
                onKeyDown={() => handleModalOpen()}
                className={`${
                    !isGrid ? "flex justify-between items-center w-full" : "w-[24%] flex flex-col"
                }  border border-[#DCD6D8] rounded-md p-2`}
            >
                <div className=" hidden md:flex h-full justify-between w-full">
                    <div className="h-[150px] md:h-[100%] rounded-md overflow-hidden w-[50%] md:w-[150px]">
                        <img
                            src={propertyData?.propertyImages[0]}
                            className={`h-full w-full `}
                            alt={propertyData?.property?.propertyName}
                            style={{
                                backgroundImage: `url(${NullImg})`
                            }}
                        />
                    </div>

                    <div className="flex flex-col md:flex-row items-center justify-between w-full">
                        <div className={`${!isGrid ? "ml-4" : "py-2"} w-[40%]`}>
                            <div className={`${!isGrid ? "flex items-center" : "flex flex-col-reverse"} `}>
                                <div>
                                    <h3 className="lg:w-[200px] md:text-md  font-latoBold text-assetize-dark-gray text-sm ">
                                        {propertyData?.property?.propertyName}
                                    </h3>
                                    <p className="text-[10px] text-assetize-dark-gray font-latoRegular">
                                        {propertyData?.property?.location}
                                    </p>
                                </div>

                                <p
                                    className={`text-xs mx-2 p-1 w-14 rounded-2xl text-center ${
                                        isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                                    } ${statusStyle.text}`}
                                >
                                    {statusStyle.textContent}
                                </p>
                            </div>
                            <p className="text-xs  font-latoRegular my-1 text-assetize-dark-gray">{property.address}</p>
                        </div>

                        <div className="md:hidden w-full border-t  my-1 border-assetize-gray " />
                        <div className=" flex justify-between md:flex-col w-full items-center px-2">
                            <p className="text-assetize-light-text font-latoLight text-[11px] md:text-sm uppercase">shares</p>
                            <p className={`${!isGrid ? "mt-2" : "mt-0"} text-assetize-light-text font-latoRegular text-center text-md`}>
                                {shareNumber}
                            </p>
                        </div>
                        <div className="flex justify-between w-full md:flex-col items-center px-2">
                            <p className="text-assetize-light-text font-latoLight text-[11px] md:text-sm uppercase">estimated roi</p>
                            <p className={`${!isGrid ? "mt-2" : "mt-0"} text-assetize-light-text font-latoRegular text-center text-md`}>
                                {propertyData?.property?.expectedAnnualROI}%
                            </p>
                        </div>
                        <div className="flex justify-between w-full md:flex-col items-center px-2">
                            <p className="text-assetize-light-text font-latoLight text-[11px] md:text-sm uppercase">close date</p>
                            <p
                                className={`${
                                    !isGrid ? "mt-2" : "mt-0"
                                } text-assetize-light-text font-latoRegular text-center text-sm flex items-center`}
                            >
                                {alwaysOpen ? "Always Open" : propertyCloseDate}
                            </p>
                        </div>
                        <div className="hidden  md:flex justify-center ">
                            <div className="">
                                <MenuList
                                    options={options}
                                    size="40"
                                    onSelect={handleOptionSelect}
                                    icon={ThreeDotsHorizontal}
                                    bg="white"
                                    textColor="assetize-dark-gray"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    role="button"
                    tabIndex={0}
                    onClick={handleModalMobileOpen}
                    onKeyPress={handleMobileKeyPress}
                    className=" md:hidden flex h-full justify-between w-full cursor-pointer"
                >
                    <div className="h-[80px] rounded-md overflow-hidden w-[30%]">
                        <img
                            src={propertyData?.propertyImages[0]}
                            className={`h-full w-full `}
                            alt={propertyData?.property?.propertyName}
                            style={{
                                backgroundImage: `url(${NullImg})`
                            }}
                        />
                    </div>

                    <div className="flex items-center justify-between w-full">
                        <div className="flex w-full justify-between items-center ml-2">
                            <div className="flex w-[40%] items-center">
                                <div>
                                    <h3 className=" md:text-md  font-bold text-assetize-dark-gray w-[200px] text-sm ">
                                        {propertyData?.property?.propertyName}
                                    </h3>
                                    <p className="text-[10px] text-assetize-dark-gray font-latoRegular">
                                        {propertyData?.property?.location}
                                    </p>
                                </div>
                            </div>
                            <div className=" flex w-[20%] flex-col justify-center items-center ">
                                <p className="text-assetize-light-text font-normal text-sm  uppercase">shares</p>
                                <p className={` text-assetize-primary font-semibold mt-0 text-center text-md`}>{shareNumber}</p>
                            </div>

                            {/* <div className=" ">
                                <MenuList
                                    options={options}
                                    size="40"
                                    onSelect={handleOptionSelect}
                                    icon={ThreeDotsHorizontal}
                                    bg="white"
                                    textColor="assetize-dark-gray"
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <ModalPanel
                closeModal={() => {
                    setActionData({
                        open: false,
                        action: ""
                    });
                }}
                open={actionData.action === "close" && actionData.open}
                closeButton
            >
                <ConfirmActionModal
                    headingText="Close Share Listing"
                    descriptionText="Are you sure you want to close this share listing?"
                    handleCancel={() => setActionData({ open: false, action: "" })}
                    handleAction={closeShare}
                    loading={loading}
                    buttonText="Close Listing"
                />
            </ModalPanel>

            <ModalPanel
                closeModal={() => {
                    setActionData({
                        open: false,
                        action: ""
                    });
                }}
                open={actionData.action === "closeSuccess" && actionData.open}
                closeButton
            >
                <SuccessMessageModal
                    handleClick={() => window.location.reload()}
                    descriptiontext="Your Share Listing has been closed successfully"
                    headingText="Listing closed Successful!"
                />
            </ModalPanel>

            <ModalPanel
                closeModal={() => {
                    setActionData({
                        open: false,
                        action: ""
                    });
                }}
                open={(actionData.action === "edit" || actionData.action === "view") && actionData.open}
                closeButton
            >
                <ViewListing
                    listingId={listingId}
                    totalShares={propertyData?.property?.numberOfShares}
                    fiatValue={propertyData?.property?.fiatValue}
                    alwaysOpen={alwaysOpen}
                    propertyId={propertyData?.property?.id}
                    totalSharesForSale={shareNumber * sharePrice}
                    sharePrice={sharePrice}
                    closeDate={closeDate}
                    shareNumber={shareNumber}
                    tokenEquivalent={propertyData?.property?.totalNumberOfIssuedTokens}
                    annualReturns={propertyData?.property?.expectedAnnualROI}
                    completionDate={propertyData?.property?.expectedCompletionDate}
                    originalValueOfShares={propertyData?.property?.sharePrice}
                />
            </ModalPanel>
        </>
    );
};

export default ShareListingCard;
