/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useCallback } from "react";
import ASDocUpload from "Components/elements/Forms/ASDocUpload";
import Input from "Components/elements/Forms/Input";
import { AddNewPropertyListingApi, GetSingleMarketPlaceApi } from "Services";
import { useFormik } from "formik";
import { BiArrowBack } from "react-icons/bi";
import { HiPlusSm, HiX } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import Select from "react-select";
import * as Yup from "yup";
import { AddImage, DelImage } from "Assets/Index";
import { toast } from "react-toastify";
import GoBack from "Components/elements/Display/GoBack";
import { BounceLoader } from "react-spinners";
import PreviewMode from "./PreviewMode";

const investmentType = [
    { label: "Rental", value: "RENTAL" },
    { label: "Non Rental", value: "NON_RENTAL" }
];
const porpertyType = [
    { label: "Apartment", value: "APARTMENT" },
    { label: "Commercial", value: "COMMERCIAL" },
    { label: "Estate", value: "ESTATE" },
    { label: "Mall", value: "MALL" },
    { label: "Land", value: "LAND" },
    { label: "Office", value: "OFFICE" },
    { label: "Others", value: "OTHERS" }
];

type PropertyData = {
    propertyName: string;
    propertyType: string;
    location: string;
    developer: string;
    investmentType: string;
    numberOfShares: number | string;
    fiatValue: string;
    sharePrice: string;
    expectedCompletionDate: number | Date | any;
    expectedAnnualROI: string;
    dividend: number;
    description: string;
    financialProjectionsOfProject: any;
    approvalPermitOfProject: any;
    legalCompliance: any;
    projectPlan: any;
    titleDocumentOfProject: any;
    insuranceAndPerformanceBondFromContractors: any;
};

const checkFileSize = () => [];

const CreateProperty = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState(0);

    const handleFileChange = (file: File | null) => {};
    const [loadingPropertyData, setLoadingPropertyData] = useState(false);

    const [imgError, setImgError] = useState("");
    const [loading, setLoading] = useState(false);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [showPreview, setShowPreview] = useState(false);
    const [previewData, setPreviewData] = useState<any>();
    const [imageFile, setImageFile] = useState<any>([]);

    const uploadProperty = async (values: any, uploadedImages: any) => {
        try {
            setLoading(true);
            const res = await AddNewPropertyListingApi(values, uploadedImages);

            toast.success(
                "Your property has been posted successfully, our team will review to ensure that your property meets our standards."
            );
            navigate("/developer");
            setUploadedImages([]);
        } catch (error: any) {
            const err = Array.isArray(error?.response?.data?.message)
                ? error?.response?.data?.message.join(",")
                : error?.response?.data?.message;
            toast.error(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        validateOnChange: true,
        initialValues: {
            propertyName: "",
            propertyType: "",
            location: "",
            developer: "",
            investmentType: "",
            numberOfShares: "0.0",
            fiatValue: "0.00",
            sharePrice: "0.00",
            expectedCompletionDate: "",
            expectedAnnualROI: "0%",
            dividend: 0,
            description: "",
            financialProjectionsOfProject: null,
            approvalPermitOfProject: null,
            legalCompliance: null,
            projectPlan: null,
            titleDocumentOfProject: null,
            insuranceAndPerformanceBondFromContractors: null
        },
        validationSchema: Yup.object({
            propertyName: Yup.string().required("Property Name is required"),
            propertyType: Yup.string().required("Property Type is required"),
            location: Yup.string().required("Property Location is required"),

            investmentType: Yup.string().required("Investment Type is required"),
            numberOfShares: Yup.number().required("Number of Shares is required"),
            fiatValue: Yup.string().required("Property Value is required"),
            sharePrice: Yup.number().required("Share Price is required"),
            expectedCompletionDate: Yup.date()
                .min(new Date(), "Date cannot be backgrounded than today")
                .required("Expected Completion Date is required"),
            expectedAnnualROI: Yup.string().required("Expected ROI is required"),
            dividend: Yup.string().required("Dividend is required"),
            description: Yup.string().required("Description is required").min(100, "Description should be atleast 100 characters"),

            legalCompliance: Yup.mixed()
                .test("fileSize", "File size should not exceed 5mb", (value: any) => {
                    if (!value) return true;
                    return value.size <= 5 * 1024 * 1024;
                })
                .required("Legal Compliance is required"),

            projectPlan: Yup.mixed()
                .test("fileSize", "File size should not exceed 5mb", (value: any) => {
                    if (!value) return true;
                    return value.size <= 5 * 1024 * 1024;
                })
                .required("Business Plan is required"),

            financialProjectionsOfProject: Yup.mixed()
                .test("fileSize", "File size should not exceed 5mb", (value: any) => {
                    if (!value) return true;
                    return value.size <= 5 * 1024 * 1024;
                })
                .required("Financial Projection is required"),

            approvalPermitOfProject: Yup.mixed()
                .test("fileSize", "File size should not exceed 5mb", (value: any) => {
                    if (!value) return true;
                    return value.size <= 5 * 1024 * 1024;
                })
                .required("Approval/Permit is required"),

            titleDocumentOfProject: Yup.mixed()
                .test("fileSize", "File size should not exceed 5mb", (value: any) => {
                    if (!value) return true;
                    return value.size <= 5 * 1024 * 1024;
                })
                .required("Title Document is required"),

            insuranceAndPerformanceBondFromContractors: Yup.mixed()
                .test("fileSize", "File size should not exceed 5mb", (value: any) => {
                    if (!value) return true;
                    return value.size <= 5 * 1024 * 1024;
                })
                .required("Insurance Bond Doc is required")
        }),
        onSubmit: (values, { resetForm }) => {
            const propertyData = {
                ...formik.values,
                createdAt: Date.now(),
                propertyGallery: {
                    legalDocuments: [
                        formik.values.financialProjectionsOfProject,
                        formik.values.approvalPermitOfProject,
                        formik.values.insuranceAndPerformanceBondFromContractors
                    ],
                    propertyFloorPlans: [formik.values.titleDocumentOfProject, formik.values.legalCompliance, formik.values.projectPlan],
                    propertyImages: [...uploadedImages]
                }
            };
            if (uploadedImages.length < 3) {
                setImgError("Upload atleast 3 images.");
                return;
            }
            setImgError("");
            setShowPreview(true);
            setPreviewData(propertyData);
        }
    });

    useEffect(() => {
        if (formik.values.fiatValue) {
            const token = parseInt(formik.values.fiatValue, 10) / 100;
            setToken(token);
        }
    }, [formik.values.fiatValue]);

    useEffect(() => {
        if (formik.values.fiatValue && formik.values.numberOfShares) {
            formik.values.sharePrice = (
                parseFloat(formik.values.fiatValue) / parseFloat(formik.values.numberOfShares.toString())
            ).toString();
        }
    }, [formik.values.fiatValue, formik.values.numberOfShares]);

    const handleImageUpload = (event: any, idx: number) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                setImgError("Image size exceeds 5MB. Please choose a smaller image.");
                return;
            }

            setImgError("");
            const imageUrl = URL.createObjectURL(file);
            const updatedImages = [...uploadedImages];
            const updatedImageFile = [...imageFile];
            updatedImageFile[idx] = file;
            updatedImages[idx] = imageUrl;

            setUploadedImages(updatedImages);
            setImageFile(updatedImageFile);
        }
    };

    const handleImageRemove = (idx: number) => {
        const updatedImages = [...uploadedImages];
        updatedImages[idx] = "";
        setUploadedImages(updatedImages);
    };

    if (loadingPropertyData) {
        return (
            <div className="w-full h-full flex justify-center items-center ">
                <BounceLoader color="#ca1611" />
            </div>
        );
    }

    return (
        <div>
            {!showPreview ? (
                <div className="container py-4 mt-2 mx-auto space-y-6 bg-white p-6 rounded-lg">
                    <div className="flex items-center justify-start font-semibold text-red-200 gap-x-2">
                        <GoBack link="/developer" />
                    </div>
                    <div className="w-full md:w-11/12 pb-4 md:pb-8 mx-auto space-y-8">
                        <div className="flex flex-col items-center justify-center">
                            <h4 className="text-assetize-primary">New Property Listing</h4>
                            <p className="font-bold text-center">Add a new property to make it available for investment</p>
                        </div>
                        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                            <div className="py-8 space-y-4 border-y-2">
                                <div className="grid grid-cols-1 lg:grid-cols-2  lg:gap-x-4 md:gap-x-8">
                                    <div>
                                        <label className="text-sm font-latoRegular font-bold" htmlFor="propertyName">
                                            Property Name
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.propertyName}
                                            type="text"
                                            className="bg-transparent"
                                            name="propertyName"
                                            placeholder="Enter property name"
                                            error={formik.errors.propertyName}
                                        />
                                    </div>
                                    <div className="space-y-3 md:mt-0.5 md:space-y-0  h-fit ">
                                        <label className="text-sm font-latoRegular font-bold" htmlFor="propertyType">
                                            Property Type
                                        </label>
                                        <div>
                                            <Select
                                                id="propertyType"
                                                options={porpertyType}
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        outline: "none",
                                                        background: "transparent",
                                                        marginTop: 3,
                                                        border: formik.errors.propertyType
                                                            ? "solid red 1px"
                                                            : "solid rgba(169, 169, 169, 0.5) 1px"
                                                    })
                                                }}
                                                placeholder="Select property type"
                                                name="propertyType"
                                                aria-errormessage={formik.errors.propertyType}
                                                onChange={(selectedOption) => formik.setFieldValue("propertyType", selectedOption?.value)}
                                            />
                                            <p className="mt-1 text-xs text-red-600">{formik.errors.propertyType}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="text-sm font-latoRegular font-bold" htmlFor="location">
                                        Property Location
                                    </label>
                                    <Input
                                        onChange={formik.handleChange}
                                        value={formik.values.location}
                                        type="text"
                                        name="location"
                                        placeholder="Enter property location"
                                        className="bg-transparent"
                                        error={formik.errors.location}
                                    />
                                </div>
                                <div className="grid grid-col2-1 md:grid-cols-2 py-2  gap-x-4">
                                    <div className="space-y-1">
                                        <label className="text-sm font-latoRegular font-bold" htmlFor="investmentType">
                                            Investment Type
                                        </label>
                                        <div>
                                            <Select
                                                options={investmentType}
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        outline: "none",
                                                        background: "transparent",
                                                        border: formik.errors.investmentType && "solid red 1px"
                                                    })
                                                }}
                                                placeholder="Select investment type"
                                                name="investmentType"
                                                aria-errormessage={formik.errors.investmentType}
                                                onChange={(selectedOption) => formik.setFieldValue("investmentType", selectedOption?.value)}
                                            />
                                            <p className="mt-1 text-xs text-red-600">{formik.errors.investmentType}</p>
                                        </div>
                                    </div>
                                    <div className="my-2 sm:my-0">
                                        <label className="flex items-center text-sm font-latoRegular font-bold" htmlFor="numberOfShares">
                                            Total Shares
                                            <button type="button" className="px-2">
                                                <FaRegQuestionCircle size={14} color="#4B4B4D" style={{ opacity: 0.5 }} />
                                            </button>
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.numberOfShares as string}
                                            type="number"
                                            name="numberOfShares"
                                            className="bg-transparent"
                                            placeholder="0.0"
                                            error={formik.errors.numberOfShares}
                                        />
                                    </div>
                                </div>
                                <div className="lg:flex lg:gap-4">
                                    <div className="lg:flex-none lg:w-2/4">
                                        <label htmlFor="fiatValue" className="text-sm font-latoRegular font-bold">
                                            Total Property Value
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.fiatValue}
                                            type="text"
                                            className="bg-transparent w-full"
                                            name="fiatValue"
                                            placeholder="$0.00"
                                            error={formik.errors.fiatValue}
                                        />
                                    </div>
                                    <div className="lg:flex-grow">
                                        <label className="text-sm font-latoRegular font-bold" htmlFor="sharePrice">
                                            Price per share
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.sharePrice}
                                            type="number"
                                            className="bg-[#F1F1F1] w-full"
                                            name="sharePrice"
                                            placeholder="$0.00"
                                            error={formik.errors.sharePrice}
                                            disabled
                                        />
                                    </div>
                                    <div className="lg:flex-grow lg:mt-5">
                                        <Input
                                            value={token.toString()}
                                            type="number"
                                            disabled
                                            className="bg-[#F1F1F1] w-full"
                                            placeholder="0.00 Tokens"
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-col-2 sm:grid-cols-6 gap-y-4 gap-x-8">
                                    <div className="col-span-2">
                                        <label className="text-sm font-latoRegular font-bold" htmlFor="expectedCompletionDate">
                                            Expected Completion Date
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.expectedCompletionDate}
                                            type="date"
                                            className="bg-transparent uppercase"
                                            name="expectedCompletionDate"
                                            placeholder="DD/MM/YYYY"
                                            error={formik.errors.expectedCompletionDate as string}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <label className="text-sm font-latoRegular font-bold" htmlFor="expectedAnnualROI">
                                            Expected Annual ROI
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.expectedAnnualROI.toString()}
                                            type="number"
                                            className="bg-transparent"
                                            name="expectedAnnualROI"
                                            placeholder="0%"
                                            error={formik.errors.expectedAnnualROI}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <label className="text-sm font-latoRegular font-bold" htmlFor="dividend">
                                            Dividend per share
                                        </label>
                                        <Input
                                            onChange={formik.handleChange}
                                            value={formik.values.dividend.toString()}
                                            type="number"
                                            className="bg-transparent"
                                            name="dividend"
                                            placeholder="$0"
                                            error={formik.errors.dividend}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="py-10 space-y-8">
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 lg:gap-x-10">
                                    <ASDocUpload
                                        label="Financial Projections of project"
                                        name="financialProjectionsOfProject"
                                        accept="image/jpeg, image/png, application/pdf"
                                        onChange={(e: any) =>
                                            formik.setFieldValue("financialProjectionsOfProject", e?.currentTarget?.files[0])
                                        }
                                        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                                            formik.setFieldValue("financialProjectionsOfProject", e.dataTransfer.files[0])
                                        }
                                        error={formik.errors.financialProjectionsOfProject}
                                    />
                                    <ASDocUpload
                                        label="Approval and Permit for the project"
                                        name="approvalPermitOfProject"
                                        accept="image/jpeg, image/png, application/pdf"
                                        onChange={(e: any) => formik.setFieldValue("approvalPermitOfProject", e?.currentTarget?.files[0])}
                                        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                                            formik.setFieldValue("approvalPermitOfProject", e.dataTransfer.files[0])
                                        }
                                        error={formik.errors.approvalPermitOfProject}
                                    />
                                    <ASDocUpload
                                        label="Title Document of project"
                                        name="titleDocumentOfProject"
                                        accept="image/jpeg, image/png, application/pdf"
                                        onChange={(e: any) => formik.setFieldValue("titleDocumentOfProject", e?.currentTarget?.files[0])}
                                        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                                            formik.setFieldValue("titleDocumentOfProject", e.dataTransfer.files[0])
                                        }
                                        error={formik.errors.titleDocumentOfProject}
                                    />
                                    <ASDocUpload
                                        label="Project/Business Plan"
                                        name="projectPlan"
                                        accept="image/jpeg, image/png, application/pdf"
                                        onChange={(e: any) => formik.setFieldValue("projectPlan", e?.currentTarget?.files[0])}
                                        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                                            formik.setFieldValue("projectPlan", e.dataTransfer.files[0])
                                        }
                                        error={formik.errors.projectPlan}
                                    />
                                    <ASDocUpload
                                        label="Legal Compliance"
                                        name="legalCompliance"
                                        accept="image/jpeg, image/png, application/pdf"
                                        onChange={(e: any) => formik.setFieldValue("legalCompliance", e?.currentTarget?.files[0])}
                                        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                                            formik.setFieldValue("legalCompliance", e.dataTransfer.files[0])
                                        }
                                        error={formik.errors.legalCompliance}
                                    />
                                    <ASDocUpload
                                        label="Insurance and Performance Bond from Contractors"
                                        name="insuranceAndPerformanceBondFromContractors"
                                        accept="image/jpeg, image/png, application/pdf"
                                        onChange={(e: any) =>
                                            formik.setFieldValue("insuranceAndPerformanceBondFromContractors", e?.currentTarget?.files[0])
                                        }
                                        onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                                            formik.setFieldValue("insuranceAndPerformanceBondFromContractors", e.dataTransfer.files[0])
                                        }
                                        error={formik.errors.insuranceAndPerformanceBondFromContractors}
                                    />
                                </div>

                                <div>
                                    <label
                                        htmlFor="textarea"
                                        className="mb-2 text-base font-latoRegular font-normal text-gray-800 cursor-pointer"
                                    >
                                        About Property
                                        <textarea
                                            className="w-full p-4 mt-4 bg-transparent border-2 rounded-lg"
                                            rows={8}
                                            id="textarea"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                        />
                                    </label>
                                    <p className="text-xs text-red-600">{formik.errors.description}</p>
                                </div>
                                <p className="text-md font-latoBold text-assetize-dark-gray">Upload Images</p>

                                <div className="grid justify-between grid-cols-3 lg:grid-cols-5 gap-y-8 gap-x-8">
                                    {[...Array(5)].map((_, idx) => (
                                        <label className="cursor-pointer" key={idx}>
                                            <div
                                                className={`flex flex-col items-center justify-center rounded-xl border h-20 md:h-40 overflow-hidden relative ${
                                                    uploadedImages[idx] ? "border-none p-1.5" : "border-dashed border-assetize-primary"
                                                }`}
                                            >
                                                {uploadedImages[idx] ? (
                                                    <>
                                                        <img
                                                            src={uploadedImages[idx]}
                                                            alt={`Uploaded ${idx}`}
                                                            className="w-full h-full object-cover rounded-md"
                                                        />
                                                        <span
                                                            onClick={() => handleImageRemove(idx)}
                                                            role="button"
                                                            tabIndex={0}
                                                            onKeyDown={() => handleImageRemove(idx)}
                                                            className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -right-0 dark:border-gray-900"
                                                        >
                                                            <DelImage />
                                                        </span>
                                                    </>
                                                ) : (
                                                    <div>
                                                        <AddImage className="w-14 sm:w-24" />
                                                        <input
                                                            type="file"
                                                            accept="image/jpg, image/jpeg, image/png"
                                                            className="hidden"
                                                            onChange={(event) => handleImageUpload(event, idx)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </label>
                                    ))}
                                </div>
                                {imgError && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{imgError}</p>}
                            </div>
                            <div className="my-6 ml-auto lg:justify-end md:justify-center md:mb-10 space-x-2 flex w-full sm:space-x-6 font-latoRegular">
                                <button
                                    type="button"
                                    className="px-10 flex-[.48] md:flex-[.2] lg:px-5 rounded-lg border py-2.5 border-assetize-dark-gray hover:bg-zinc-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="text-white flex-[.48] md:flex-[.2] hover:bg-assetize-primary py-2.5 rounded-lg px-8 lg:px-5 bg-assetize-primary"
                                >
                                    {loading ? "Uploading..." : "Create Listing"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            ) : (
                <PreviewMode
                    item={previewData}
                    onSubmit={() => uploadProperty(formik.values, imageFile)}
                    onEdit={() => setShowPreview(false)}
                    loading={loading}
                />
            )}
        </div>
    );
};

export default CreateProperty;
