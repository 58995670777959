import { NewLogoColored } from "Assets/Index";
import React from "react";

const AuthLogoDisplay = () => (
    <div className="w-full bg-white z-10 absolute md:relative left-0  top-0  flex md:mt-0 border border-t-0 md:border-0 border-b  h-[70px] md:h-fit items-start justify-center flex-col md:items-center">
        <img src={NewLogoColored} alt="logo" className="ml-[20px] md:ml-0 w-20 sm:w-24 md:w-40" />
    </div>
);

export default AuthLogoDisplay;
