import React, { ChangeEvent, FocusEvent } from "react";
import { AiOutlineCalendar } from "react-icons/ai";

interface FormInputProps {
    label?: string;
    type?: string;
    name?: string;
    value?: string;
    placeholder?: string;
    error?: string;
    size?: "sm" | "md" | "lg";
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    disabled?: boolean;
    amountInput?: boolean;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    withCalendar?: boolean;
}

const Input: React.FC<FormInputProps> = ({
    label = "",
    type = "text",
    name,
    value,
    placeholder,
    error,
    size,
    onChange,
    className = "",
    disabled = false,
    amountInput,
    onFocus,
    withCalendar
}) => (
    <div className="flex flex-col w-full">
        <label className="pb-1 text-sm font-latoRegular" htmlFor={name}>
            {label || ""}
        </label>
        <div className="relative">
            {amountInput && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-assetize-primary" style={{ pointerEvents: "none" }}>
                    $
                </span>
            )}
            <input
                className={` ${size === "sm" && "p-2"}  ${size === "lg" && "p-4"} ${
                    error
                        ? "border-assetize-primary bg-assetize-primary-gradient focus:ring-assetize-primary focus:border-assetize-primary"
                        : "border-assetize-gray  focus:ring-assetize-gray focus:border-assetize-gray"
                }  border rounded font-latoRegular text-gray-500 w-full p-2  py-3 text-sm apperance-none outline-none ${className} ${
                    amountInput ? "pl-5" : "pl-2"
                }`}
                type={type}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                disabled={disabled}
            />
            {/* {withCalendar && <div className="absolute right-5 top-2">
            <AiOutlineCalendar fontSize={'20px'}  /> 
            </div>} */}
        </div>
        {error && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{error}</p>}
    </div>
);

export default Input;
