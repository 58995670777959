import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import Pagination from "Components/elements/Pagination";
import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Option, WalletTransactionProps, Walletprops, dateFilter, transactionFilter } from "types/data";
import { useParams, useSearchParams } from "react-router-dom";

import ModalPanel from "Components/elements/Modal/Index";
import OrderListCard from "./OrderListCard";
import { GetOrders } from "Services";
import { filterDataByDateRange, getErrorMessge, getShortCodeDate, searchArray, sortByTransactionType } from "Utils/Helpers";
import { toast } from "react-toastify";

const DeveloperOrder = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState("");
    const [selectedOptions, setSelectedOptions] = useState({
        transactionFilter: { name: "All" },
        dateFilter: { name: "Jan 01 - Jan 31" }
    });
    const [orders, setOrders] = useState<any>([]);
    const [displayList, setDisplayList] = useState<any>([]);
    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    const getOrder = async () => {
        try {
            setLoading(true);
            const res = await GetOrders();
            setOrders(res);
            setDisplayList(res);
        } catch (err) {
            setLoading(false);
            const error = getErrorMessge(err);
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const initialDateFilter = getShortCodeDate();
        setSelectedOptions((prevOptions) => ({ ...prevOptions, dateFilter: initialDateFilter }));
    }, []);

    useEffect(() => {
        const searchResult = searchArray(orders, search);
        setDisplayList(searchResult);
    }, [search]);

    useEffect(() => {
        const transactionType = sortByTransactionType(selectedOptions.transactionFilter.name, orders);
        setDisplayList(transactionType);
    }, [selectedOptions.transactionFilter]);

    useEffect(() => {
        const filteredData = filterDataByDateRange(orders, selectedOptions.dateFilter);

        if (filteredData) {
            setDisplayList(filteredData);
        }
    }, [selectedOptions.dateFilter.name]);

    useEffect(() => {
        getOrder();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center mt-64">
                <ScaleLoader color="#ca1611" />
            </div>
        );
    }

    return (
        <div>
            <div>
                <div className="flex items-center justify-center px-2 mt-6 bg-white border rounded border-assetize-gray">
                    <div className="flex-[.4] py-2" />
                    <div className="flex-[.5] lg:flex justify-between">
                        <div className="hidden lg:flex flex-1 py-2 mx-1 ">
                            <ActiveSearch
                                bg="white"
                                border="border-aasetize-gray"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search here..."
                            />
                        </div>
                        <div className="hidden lg:flex mx-1 border-r border-assetize-gray h-14" />
                        {/* <div className="flex-[0.5] mx-1 py-2">
                            <Select
                                options={transactionFilter}
                                selected={selectedOptions.transactionFilter}
                                onChange={(option) => handleOptionChange('transactionFilter', option)}
                                placeholder="All"
                            />
                        </div> */}
                        <div className="flex-[0.7] mx-1 py-2">
                            <Select
                                options={dateFilter}
                                selected={selectedOptions.dateFilter}
                                onChange={(option) => handleOptionChange("dateFilter", option)}
                                placeholder="Jan 01 - January 31"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white mt-2 py-2  rounded ">
                <div className="hidden md:flex items-center justify-between w-full px-4">
                    <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-start text-[#625A67]">Customer</h3>
                    <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-start text-[#625A67] ml-2">Property</h3>
                    <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-start text-[#625A67] ml-2">Date</h3>
                    <h3 className="w-1/6 text-[14px] font-normal uppercase flex justify-start text-[#625A67] ">usd value</h3>
                    <h3 className="w-1/6 text-[14px] font-normal uppercase justify-start text-[#625A67] mr-6 flex-[.1] ">status</h3>
                    <h3 className="w-1/6 flex-[.1] font-normal uppercase justify-center text-[14px] text-[#625A67]">action</h3>
                </div>
                {displayList?.map((item: any, index: string) => (
                    <OrderListCard key={index} item={item} />
                ))}
            </div>
            {/* )} */}
        </div>
    );
};
export default DeveloperOrder;
