import axios, { InternalAxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { logoutUser } from "redux/Reducers/AuthReducer";
import store from "store";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json"
    }
});

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = store.get("atk");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

const successHandler = (response: any) => {
    const token = response.headers["x-access-token"];

    if (token) {
        store.set("atk", token);
    }
    return response;
};

const errorHandler = (error: any) => Promise.reject(error);

axiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => {
        if (error?.response?.status === 401) {
            toast.error("Session has expired. You will be redirected to the login page.", {
                position: toast.POSITION.TOP_RIGHT
            });
            logoutUser();
            window.location.href = "/auth/login";
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
