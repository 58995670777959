import { MainButton } from "Components/elements/Button/Index";
import { useNavigate } from "react-router-dom";
import { PropertyStatus } from "../../types/data";
import React from "react";
import { NullImg } from "Assets/Index";

interface PropertyProps {
    property: any;
    isGrid: boolean;
}

const PropertyCard = ({ property, isGrid }: PropertyProps) => {
    const navigate = useNavigate();

    const isAvailable = property.availabilityStatus === "AVAILABLE";

    const isResale = property.availabilityStatus === "RESALE";

    const statusStyle = {
        background: "",
        text: "",
        textContent: ""
    };

    if (isAvailable) {
        statusStyle.background = "bg-green-200";
        statusStyle.text = "text-assetize-green-text";
        statusStyle.textContent = "Available";
    } else if (isResale) {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Re-sale ";
    } else {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Sold Out";
    }

    return (
        <div
            className={`${
                !isGrid ? "flex justify-between items-center w-full" : "grid gap-0 md:grid-4"
            }  my-0  w-full h-full rounded-lg py-1 md:p-2`}
        >
            <div
                onClick={() => navigate(`${property.id}`, { state: property })}
                role="button"
                tabIndex={0}
                onKeyDown={() => navigate(`${property.id}`)}
                className={`${
                    !isGrid ? "flex flex-[1] justify-between items-center w-full" : "mb-5"
                }  border border-assetize-gray rounded-lg p-2 h-full`}
            >
                <div
                    className={`${!isGrid ? "relative md:w-40 w-full h-[100px]" : "relative  w-full h-32"} rounded-lg  `}
                    style={
                        true
                            ? {
                                  backgroundImage: `url(${NullImg})`
                              }
                            : {}
                    }
                >
                    <img
                        src={property.propertyGallery.propertyImages[0]}
                        className={`${!isGrid ? "w-full h-full object-cover" : "w-full h-full object-cover"} rounded-lg`}
                        alt={property.propertyName}
                    />
                </div>
                <div className={`${!isGrid ? " flex flex-col md:flex-row" : "flex flex-col "} w-full`}>
                    <div className={`${!isGrid ? "flex items-center flex-[.3] " : "flex flex-col"} `}>
                        <div className={`${!isGrid ? "ml-2" : "py-2"}`}>
                            <div>
                                {!isGrid ? (
                                    <div className={`${!isGrid ? "flex items-center " : "items-center  justify-between w-full"} `}>
                                        <h4 className="text-sm md:text-md font-latoBold text-assetize-dark-gray mt-2 mx-1">
                                            {property?.propertyName}
                                        </h4>
                                        <span
                                            className={`px-1 py-1 h-6 w-20 md:ml-3 md:mt-3 text-center justify-center text-[10px] rounded-full ${
                                                isAvailable ? "bg-[#22A83533]" : "bg-red-200"
                                            } ${statusStyle.text}`}
                                        >
                                            {statusStyle.textContent}
                                        </span>
                                    </div>
                                ) : (
                                    <div className={`${!isGrid ? "flex items-center   " : "items-center justify-between w-full"} `}>
                                        <span
                                            className={`px-2 py-1 h-5 w-20 text-center  text-[10px] rounded-full ${
                                                isAvailable ? "bg-[#22A83533]" : "bg-red-200"
                                            } ${statusStyle.text}`}
                                        >
                                            {statusStyle.textContent}
                                        </span>
                                        <h4 className="text-sm md:text-md font-latoBold text-assetize-dark-gray mt-2 mx-1">
                                            {property?.propertyName}
                                        </h4>
                                    </div>
                                )}
                            </div>
                            <p className="my-0 text-xs font-latoRegular text-assetize-dark-gray mx-1">{property.location}</p>
                            <h3 className="text-lg text-assetize-primary font-latoBold mx-1 my-2">
                                ${property.fiatValue?.toLocaleString("en-Us")}
                            </h3>
                        </div>
                    </div>
                    <div
                        className={`${
                            !isGrid
                                ? "flex w-full border-t my-1 md:hidden border-assetize-gray"
                                : "w-full border-t my-0 border-assetize-gray "
                        }`}
                    />

                    <div
                        className={`${
                            !isGrid
                                ? "lg:ml-[50px] flex md:flex-col justify-between m-2 flex-[.4]"
                                : "flex md:flex-row items-center justify-between mx-1 "
                        }`}
                    >
                        <p
                            className={`${
                                !isGrid
                                    ? "lg:mx-0 text-[10px] lg:text-[12px] text-assetize-gray-text lg:mt-2 font-latoLight lg:font-normal font-bold"
                                    : "text-[10px] text-assetize-gray-text md:items-center lg:mb-3 font-latoLight font-bold"
                            }`}
                        >
                            PRICE PER SHARE
                        </p>
                        <p
                            className={`${
                                !isGrid ? "mt-0" : "mt-0"
                            } text-assetize-light-text font-latoRegular lg:mb-4 text-[14px] md:text-lg font-semibold`}
                        >
                            ${property.sharePrice}
                        </p>
                    </div>
                    <div
                        className={`${
                            !isGrid
                                ? "flex md:flex-col justify-between m-2 flex-[.4]"
                                : "flex md:flex-row items-center justify-between mx-1 "
                        }`}
                    >
                        <p
                            className={`${
                                !isGrid
                                    ? "text-[10px] lg:text-[12px] text-assetize-gray-text lg:mt-2 font-latoLight lg:font-normal font-bold"
                                    : "text-[10px] text-assetize-gray-text md:items-center lg:mb-5 font-latoLight font-bold"
                            }`}
                        >
                            AVAILABLE SHARES
                        </p>
                        <p
                            className={`${
                                !isGrid ? "mt-0" : "md:mt-2 lg:mt-0"
                            } text-assetize-light-text font-latoRegular lg:mb-5 text-[14px] md:text-lg font-semibold`}
                        >
                            {property.availableNumberOfShares}
                        </p>
                    </div>
                    <div className={`${isGrid ? "hidden " : "hidden md:flex justify-center items-center "} w-[100px] hidden  `}>
                        <MainButton>View details</MainButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
