import { MainButton } from "Components/elements/Button/Index";
import AuthLogoDisplay from "Components/elements/Display/AuthLogoDisplay";
import Radio from "Components/elements/Forms/Radio";
import { welcomeUser } from "redux/Actions/AuthActions";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SignupStageType, UserAccountType } from "types/Auth.types";
import { validateAll } from "indicative/validator";

const Welcome = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isActive, setIsActive] = useState(false);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const navigate = useNavigate();

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const textClass = isActive ? "text-purple-500" : "text-assetize-primary";

    const handleRadioChange = (value: string) => {
        setSelectedOption(value);
    };

    const radioOptions = [
        {
            value: UserAccountType.INVESTOR,
            label: "Property Investor"
        },

        {
            value: UserAccountType.DEVELOPER,
            label: "Property Developer"
        }
    ];

    const handleSubmit = async () => {
        try {
            const rules = {
                selectedOption: "required"
            };

            const messages = {
                "selectedOption.required": "Please select an option"
            };

            const data = {
                selectedOption
            };

            await validateAll(data, rules, messages);
            dispatch(welcomeUser(selectedOption as UserAccountType));
            localStorage.setItem("selectedOption", selectedOption);
            navigate("/auth/signup");
        } catch (error) {
            setErrorMessage((error as Error[])[0].message);
        }
    };

    return (
        <div className="flex w-full  font-latoRegular flex-col mt-24 md:mt-16">
            <div className="flex w-full md:w-[90%] lg:w-[60%] flex-col md:justify-center md:self-center ">
                <AuthLogoDisplay />
                <div className="mt-5 lg:mt-10 ">
                    <h1 className="text-assetize-primary text-center font-latoBold text-2xl">Welcome </h1>
                    <p className="text-assetize-dark-gray text-center font-latoRegular text-sm">
                        Kindly choose the option that suits you best
                    </p>
                </div>
                <div className="flex flex-col justify-center lg:mt-6 lg:mb-10">
                    <Radio options={radioOptions} name="property-owner" selectedValue={selectedOption} onChange={handleRadioChange} />
                </div>
                {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
                <div className="flex flex-col justify-center pt-12 md:pt-6">
                    <MainButton onClick={handleSubmit}> Continue </MainButton>
                    <p className="font-latoRegular align-center text-sm self-center lg:mt-3">
                        <span className="text-assetize-dark-gray ">Already have an account? </span>{" "}
                        <Link
                            to="/auth/login"
                            className="cursor-pointer text-assetize-primary hover:text-black font-latoRegular font-bold hover:no-underline"
                        >
                            Log In
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
