/* eslint-disable jsx-a11y/label-has-associated-control */
import Input from "Components/elements/Forms/Input";
import Select from "react-select";
import ModalPanel from "Components/elements/Modal/Index";
import { AccountLookup, GetBanksApi, GetTransactionChargesApi, GetWalletUsdApi, WithdrawToExternalBank } from "Services";
import { useEffect, useState } from "react";
import { Option, Walletprops } from "types/data";
import { MainButton } from "Components/elements/Button/Index";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import { useNavigate } from "react-router-dom";
import { validateAll } from "indicative/validator";
import { getErrorMessge } from "Utils/Helpers";

/* eslint-disable react/react-in-jsx-scope */
type Props = {
    closeModal(): void;
    walletBalance: number;
};

type BankType = {
    label: string;
    value: string;
};

const WalletWithdrawal = ({ closeModal, walletBalance }: Props) => {
    const [allBanks, setAllBanks] = useState<BankType[]>([]);

    const [formData, setFormData] = useState<any>({
        amount: 0,
        accountNumber: "",
        accountName: ""
    });
    const [selectedBank, setSelectedBank] = useState({
        bankName: "",
        bankCode: ""
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [amountErr, setAmountError] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [verifyingAccount, setVerifyingAccount] = useState(false);
    const [processingFee, setProcessingFee] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [withdrawDone, setWithdrawDone] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [charges, setCharges] = useState(0);
    const navigate = useNavigate();

    const handleInputChange = (name: string, value: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const getBanks = async () => {
        try {
            const res = await GetBanksApi();

            const bankData = res.map((item: any) => ({ label: item.name, value: item.code }));

            setAllBanks(bankData);
        } catch (err: any) {
            const error = getErrorMessge(err);
            toast.error(error);
        }
    };

    const verifyaccount = async () => {
        try {
            setVerifyingAccount(true);
            const res = await AccountLookup(formData.accountNumber, selectedBank.bankCode);

            handleInputChange("accountName", res.data.account_name);
        } catch (err) {
            toast.error("Account verification failed. Please check the details and try again.");
            setVerifyingAccount(false);
            const error = getErrorMessge(err);
            toast.error(error);
        } finally {
            setVerifyingAccount(false);
        }
    };

    useEffect(() => {
        getBanks();
    }, []);

    useEffect(() => {
        if (formData.amount) {
            // const fee = (parseFloat(formData.amount) / 100) * 5;

            setTotalAmount(parseFloat(formData.amount));
            // setProcessingFee(fee);
        }
        if (parseFloat(formData.amount) > walletBalance) {
            setAmountError("Balance is insufficient for this transaction");
        }
    }, [formData.amount]);

    useEffect(() => {
        if (formData.accountNumber.length === 10) {
            verifyaccount();
        }
    }, [formData.accountNumber]);

    const handleOptionChange = (option: BankType | any) => {
        setSelectedBank({
            bankName: option.label,
            bankCode: option.value
        });
    };
    const getCharges = async () => {
        try {
            const res = await GetTransactionChargesApi("FUNDING");

            setCharges(res);
        } catch (err: any) {
            const error = getErrorMessge(err);
            toast.error(error);
        }
    };
    useEffect(() => {
        getCharges();
    }, []);

    const withdrawToExternalBank = async () => {
        try {
            setLoading(true);
            const payload = {
                bankCode: selectedBank.bankCode,
                accountNumber: formData.accountNumber,
                amount: parseFloat(formData.amount),
                currency: "USD",
                narration: "Transfer from assetize",
                customerName: formData.accountName
            };

            const res = await WithdrawToExternalBank(payload);

            setWithdrawDone(true);
        } catch (err: any) {
            setLoading(false);

            toast.error(
                err?.response?.data?.message
                    ? err?.response?.data?.message
                    : " Sorry, we could not process your Withdrawal at the moment, try again later."
            );
            setWithdrawDone(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const rules = {
            amount: "required|above:1",
            accountName: "required",
            accountNumber: "required",
            bankName: "required"
        };

        const messages = {
            "amount.required": "Amount is required",
            "amount.above": "Amount should be greater than 1 USD",
            "accountName.required": "Valid Account is required",
            "accountNumber.required": "Please enter an Account Number",
            "bankName.required": "Please select a bank"
        };
        const data = {
            ...formData,
            bankName: selectedBank.bankName
        };
        validateAll(data, rules, messages)
            .then(() => {
                withdrawToExternalBank();
            })
            .catch((err: any) => {
                const formattedErrors = {} as any;
                err.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setErrors(formattedErrors);
            });
    };

    return (
        <ModalPanel closeModal={closeModal} open closeButton>
            {!withdrawDone ? (
                <div className="w-full px-2 md:px-10 py-6 mx-auto  font-latoRegular">
                    <div className="py-6 mx-auto text-center w-full md:w-[80%] bg-assetize-primary/10">
                        <h4 className="text-md font-latoRegular text-assetize-primary">Wallet Balance</h4>
                        <h3 className="text-assetize-primary text-xl font-latoBold">
                            <span className="text-xl">$ {walletBalance.toLocaleString()}</span>
                        </h3>
                    </div>
                    <form className="w-full md:w-[80%] mx-auto my-10 space-y-4">
                        <div>
                            <Input
                                label="Amount"
                                type="number"
                                name="amount"
                                value={formData.amount}
                                placeholder="Amount"
                                size="md"
                                error={amountErr ?? errors.amount}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                amountInput
                            />
                        </div>
                        <div className="py-2">
                            <label htmlFor="bankName">Bank Name</label>

                            <Select
                                id="bankName"
                                options={allBanks}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        height: 45,
                                        outline: "none",
                                        background: "transparent",
                                        border: errors.bankName ? "solid #CA1611 1px" : "solid #D0D0D0 1px"
                                    })
                                }}
                                placeholder="Select bank"
                                name="bankName"
                                aria-errormessage={errors.bankName}
                                onChange={(selectedOption) => handleOptionChange(selectedOption)}
                            />
                            {errors.bankName && <p className="mt-1 text-xs text-red-600">{errors.bankName}</p>}
                        </div>
                        <div>
                            <Input
                                label="Account Number"
                                type="text"
                                name="accountNumber"
                                value={formData.accountNumber}
                                placeholder="Account Number"
                                size="md"
                                error={errors.accountNumber}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            />
                            <div className="flex justify-end">
                                {verifyingAccount && <p className="text-assetize-green text-[14px]">Verifying...</p>}
                            </div>
                        </div>
                        <div>
                            <Input
                                label="Account Name"
                                type="text"
                                name="accountName"
                                value={formData.accountName}
                                placeholder="Account Name"
                                size="md"
                                error={errors.accountName}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                disabled
                            />
                        </div>
                        <div className="border border-assetize-primary w-full rounded-md p-5 fex flex-col">
                            <div className="flex justify-between w-full items-center">
                                <p className="text-assetize-light-text font-latoRegular">Amount to withdraw</p>
                                <p className="text-assetize-light-text font-latoRegular">${formData.amount.toLocaleString("en-us")}</p>
                            </div>
                            <div className="flex justify-between w-full items-center">
                                <p className="text-assetize-light-text font-latoRegular">Processing Fee(0%)</p>
                                <p className="text-assetize-light-text font-latoRegular">$0</p>
                            </div>
                            <div className="flex justify-between w-full items-center">
                                <p className="text-assetize-light-text font-latoBold">Total</p>
                                <p className="text-assetize-light-text font-latoBold">${totalAmount.toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="w-full mt-10">
                            <MainButton isLoading={loading} disabled={loading} onClick={handleSubmit}>
                                Withdraw
                            </MainButton>
                        </div>
                    </form>
                </div>
            ) : (
                <SuccessMessageModal
                    handleClick={() => window.location.reload()}
                    descriptiontext="Your withdrawal was successful, you will receive your funds within 24 hours."
                    headingText="Withdrawal Successful!"
                />
            )}
        </ModalPanel>
    );
};

export default WalletWithdrawal;
