import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import TransactionCard from "Pages/Wallets/TransactionCard";
import React, { useEffect, useState } from "react";
import Pagination from "Components/elements/Pagination";
import { Option, dateFilter, joinedFilter } from "types";
import AuthenticationCard from "./AuthenticationCard";
import { GetDeveloperAuthRequest } from "Services/apis/admin.api";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { addDevelopersRequest } from "redux/slices/admin.slice";
import { NotFoundImg } from "Assets/Index";
import { Link } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { RootState } from "redux/store";
import { DeveloperType } from "types/Auth.types";
import { HiOutlineRefresh } from "react-icons/hi";
import { filterDataByDateRange, getShortCodeDate, searchArray, sortByRange } from "Utils/Helpers";

const Authentication = () => {
    const [search, setSearch] = useState("");
    const [refreshing, setRefreshing] = useState(false);
    const [_developersRequest, setDeveloperRequest] = useState<DeveloperType[]>([]);
    const [displayList, setDisplayList] = useState<DeveloperType[]>([]);
    const [loading, setLoading] = useState(false);
    const { developersRequest } = useSelector((state: RootState) => state.admin);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [page, setPage] = useState("1");

    const [selectedOptions, setSelectedOptions] = useState({
        joinedFilter: { name: "All" },
        dateFilter: { name: "" }
    });

    const [pageData, setPageData] = useState<any>();
    const [pageNum, setPageNum] = useState<number>(1);

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    useEffect(() => {
        const initialDateFilter = getShortCodeDate();
        setSelectedOptions((prevOptions) => ({ ...prevOptions, dateFilter: initialDateFilter }));
    }, []);

    useEffect(() => {
        const sortedData = sortByRange(selectedOptions.joinedFilter.name, _developersRequest, "createdAt");

        const filteredData = filterDataByDateRange(sortedData, selectedOptions.dateFilter);
        setDisplayList((displayList) => [...filteredData]);
    }, [selectedOptions.joinedFilter.name]);

    useEffect(() => {
        const filteredData = filterDataByDateRange(_developersRequest, selectedOptions.dateFilter);

        const sortedData = sortByRange(selectedOptions.joinedFilter.name, filteredData, "createdAt");

        setDisplayList((displayList) => [...sortedData]);
    }, [selectedOptions.dateFilter.name]);

    useEffect(() => {
        const searchResult = searchArray(_developersRequest, search);

        setDisplayList(searchResult);
    }, [search]);

    const fetchDevelopersRequest = async () => {
        setLoading(true);
        try {
            setRefreshing(true);
            const res = await GetDeveloperAuthRequest(pageNum);

            dispatch(addDevelopersRequest(res.notifications[0]));
            setDeveloperRequest(res.notifications[0]);
            setDisplayList(res.notifications[0]);
            setPageData(res.metadata);
        } catch (error: any) {
            setLoading(false);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    useEffect(() => {
        fetchDevelopersRequest();
    }, [pageNum]);

    let content;
    if (loading) {
        content = (
            <div className="flex items-center justify-center mt-64">
                <BounceLoader color="#ca1611" />
            </div>
        );
    } else if (displayList.length === 0) {
        content = (
            <div>
                <div className="flex flex-col items-center mt-20 justify-center h-full space-y-4 no-item">
                    <img src={NotFoundImg} alt="" />
                    <p className="text-base font-semibold">No developer request!</p>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="bg-white mt-2 py-2 rounded">
                <div className="hidden md:flex justify-between px-2">
                    <h3 className="uppercase w-[20%] flex justify-start font-latoLight pl-6 text-sm">id</h3>
                    <h3 className="uppercase w-[30%] flex justify-start font-latoLight text-sm">user</h3>
                    <h3 className="uppercase w-[30%] flex justify-start font-latoLight text-sm">date</h3>
                    <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">status</h3>
                    <h3 className="uppercase w-[10%] flex justify-start font-latoLight text-sm">action</h3>
                </div>
                {displayList.map((item: DeveloperType) => (
                    <AuthenticationCard key={item.id} item={item} />
                ))}
            </div>
        );
    }

    return (
        <div>
            <div className="border border-assetize-gray rounded px-2 mt-6 bg-white flex justify-end items-center">
                <div className="items-center flex-[.6] flex justify-between">
                    <div className="flex-1 mx-1 py-2">
                        <ActiveSearch
                            bg="white"
                            border="border-aasetize-gray"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search here..."
                        />
                    </div>
                    <div className="border-r border-assetize-gray mx-1 h-15" />
                    <div className="flex-[0.5] mx-1 py-2">
                        <Select
                            options={joinedFilter}
                            selected={selectedOptions.joinedFilter}
                            onChange={(option) => handleOptionChange("joinedFilter", option)}
                            placeholder="Newest"
                        />
                    </div>
                    <div className="flex-[0.7] mx-1 py-2">
                        <Select
                            options={dateFilter}
                            selected={selectedOptions.dateFilter}
                            onChange={(option) => handleOptionChange("dateFilter", option)}
                            placeholder="Jan 01 - January 31"
                        />
                    </div>
                    <HiOutlineRefresh
                        className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                        onClick={() => {
                            if (!refreshing) {
                                fetchDevelopersRequest();
                            }
                        }}
                    />
                </div>
            </div>
            {content}
            <div className="mt-5 flex justify-end">
                <Pagination pageData={pageData} setPageNum={setPageNum} />
            </div>
        </div>
    );
};

export default Authentication;
