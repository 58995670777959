/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import { BiArrowBack } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Carousel, SelectPicker, Toggle } from "rsuite";
import DocumentBox from "Components/elements/Display/DocumentBox";
import AboutBox from "Components/elements/Display/AboutBox";
import { CarretDown, Cart, ChevronDown, EditIcon, NullImg, SampleTwo, Send, Users } from "Assets/Index";
import { capitalizeText, getYearAndMonthAgo, readableDate } from "Utils/Helpers";
import GoBack from "Components/elements/Display/GoBack";
import MenuList, { Option } from "Components/elements/Menu";
import { OptionsProps } from "types/data";
import WrappedMenu from "Components/elements/Menu/WrappedMenu";
import ModalPanel from "Components/elements/Modal/Index";
import DeleteProperty from "../modals/DeleteProperty";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";

type Props = {
    item: any;
    onSubmit: () => void;
    onEdit: () => void;
    loading: boolean;
};

const PreviewMode = ({ item, onSubmit, onEdit, loading }: Props) => {
    const navigate = useNavigate();

    const {
        approvedByAdmin,
        availabilityStatus,
        description,
        developerName,
        expectedAnnualROI,
        fiatValue,
        id,
        investmentType,
        location,
        developer,
        numberOfShares,
        propertyGallery: { legalDocuments, propertyFloorPlans, propertyImages },
        propertyName,
        propertyType,
        sharePrice,
        dividend,
        createdAt,
        expectedCompletionDate
    } = item;

    const listedDate = readableDate(createdAt);
    const yearAndMonthAgo = readableDate(expectedCompletionDate);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });
    const [previewStage, setPreviewStage] = useState(0);

    const options: OptionsProps[] = [
        { text: "Edit Properrty", value: "edit" },
        { text: "Delete Property", value: "delete" },
        { text: "Perfomance", value: "perfomance" },
        { text: "Initiate Withdawal", value: "withdraw" }
    ];

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "edit") {
            navigate("/developer/edit-property", { state: { id } });
            setShowReplyModal({
                open: true,
                action: "edit"
            });
        } else if (option?.value === "delete") {
            setShowReplyModal({
                open: true,
                action: "delete"
            });
        } else if (option?.value === "perfomance") {
            setShowReplyModal({
                open: true,
                action: "perfomance"
            });
        } else {
            setShowReplyModal({
                open: true,
                action: "withdraw"
            });
        }
    };

    let componentTODisplay;

    return (
        <div className="my-5 bg-white rounded-md overflow-hidden">
            <div className="bg-assetize-primary p-3 w-full flex justify-center items-center ">
                <p className="text-md font-latoBold text-white">Preview Mode</p>
            </div>
            <div className="p-2 md:p-4 my-5">
                <div className="flex justify-end mb-3 xl:hidden">
                    <div className="flex gap-x-4 lg:w-[153px] md:w-[125px]">
                        <OutlineButton onClick={onEdit} className="px-8 border-[#4B4B4D]">
                            <div className="flex justify-evenly items-center text-assetize-dark font-semibold">
                                <EditIcon />
                                <p>Edit</p>
                            </div>
                        </OutlineButton>
                    </div>

                    <div className="lg:w-[153px] md:w-[125px] 2xl:w-[15%] rounded-lg ml-2 lg:ml-2 font-semibold">
                        <MainButton className="px-2 py-3" onClick={() => setPreviewStage(1)}>
                            Submit Listing
                        </MainButton>
                    </div>
                </div>
                <div className="flex  flex-col items-start  justify-around px-5 py-0">
                    <div className="flex-[.5]">
                        <div className="flex">
                            <h3 className="text-[16px] md:text-md leading-6  text-[#111]">{propertyName}</h3>
                        </div>
                        <p className="text-[12px] lg:text-[] lg:font-semibold font-normal text-[#515151]">{location}</p>

                        <p className="my-2 mb-3 text-xs font-latoItalic text-normal text-[#515151] block xl:hidden font-[14px]">
                            Listed by <span className="text-assetize-primary font-latoItalic">{developer} </span> on{" "}
                            <span className="font-latoItalic">{listedDate}</span>
                        </p>
                    </div>
                    <div className="flex w-full flex-col-reverse md:flex-col ">
                        <div className="flex justify-end mb-3">
                            <div className="mr-auto">
                                <h3 className="text-assetize-primary font-[22px] lg:mt-[10px] font-latoBold justify-start mr-auto text-lg">
                                    ${fiatValue?.toLocaleString("en-Us")}
                                </h3>

                                <p className="font-[14px] font-latoBold block xl:hidden">(Min Amount)</p>
                            </div>
                            <div className="gap-x-4 lg:w-[153px] md:w-[125px] hidden xl:block">
                                <OutlineButton onClick={onEdit} className="px-8 border-[#4B4B4D]">
                                    <div className="flex justify-evenly font-semibold items-center">
                                        <EditIcon />
                                        <p>Edit</p>
                                    </div>
                                </OutlineButton>
                            </div>

                            <div className=" w-[50%] lg:w-[153px] 2xl:w-[15%] rounded-lg ml-2 lg:ml-5 hidden xl:block">
                                <MainButton isLoading={loading} disabled={loading} onClick={() => setPreviewStage(1)}>
                                    Submit Listing
                                </MainButton>
                            </div>
                        </div>

                        <div
                            className="w-full mb-6 border"
                            style={{
                                backgroundImage: `url(${NullImg})`
                            }}
                        >
                            <img src={propertyImages[0]} alt="sample house" className="w-full rounded-xl h-full object-cover" />
                        </div>
                    </div>
                </div>

                <div className="p-2 md:p-5 flex gap-2 mt-3">
                    <div className="w-full border border-assetize-gray rounded md:py-4 py-2 px-1">
                        <h3 className="text-assetize-primary text-[18px] pl-3 my-3 ">Property Valuation </h3>
                        <div className="md:grid md:grid-cols-3 flex flex-col mt-10 ">
                            <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center align-center md:py-4 px-1  md:border-assetize-gray">
                                <p className="text-[#515151] text-center font-latoNormal md:text-[12px] uppercase">Property Type </p>
                                <p className="text-assetize-light-text  text-center font-latoBold text-md  md:mt-2 capitalize-first ">
                                    {capitalizeText(propertyType)}{" "}
                                </p>
                            </div>
                            <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center align-center md:border-assetize-gray md:py-4 px-1">
                                <p className="text-assetize-light-text text-center font-latoNormal text-sm uppercase"> Expected ROI </p>
                                <p className="text-assetize-light-text text-center font-latoBold text-md  md:mt-2">{expectedAnnualROI} %</p>
                            </div>
                            <div className="md:border-b flex justify-between md:flex-col w-full items-center align-center md:border-assetize-gray md:py-4 px-1 ">
                                <p className="text-assetize-light-text text-center font-latoNormal text-sm uppercase">Total Shares </p>
                                <p className="text-assetize-light-text text-center font-latoBold text-md  md:mt-2">{numberOfShares} </p>
                            </div>
                            <div className="md:border-r flex justify-between md:flex-col w-full items-center align-center md:border-assetize-gray md:py-4 px-1 ">
                                <p className="text-assetize-light-text text-center font-latoNormal text-sm uppercase">Dividends </p>
                                <p className="text-assetize-light-text text-center font-latoBold text-md md:mt-2 ">
                                    {dividend ? "Yes" : "No"}{" "}
                                </p>
                            </div>
                            <div className="md:border-r flex justify-between md:flex-col w-full items-center align-center md:border-assetize-gray md:py-4 px-1">
                                <p className="text-assetize-light-text text-center font-latoNormal text-sm uppercase">
                                    Original value of shares
                                </p>
                                <p className="text-assetize-light-text text-center font-latoBold text-md md:mt-2">
                                    {/* {sharePrice}/  */}${fiatValue.toLocaleString("en-Us")}{" "}
                                </p>
                            </div>
                            <div className=" md:border-assetize-gray flex md:flex-col justify-between w-full items-center align-center md:py-4 px-1 ">
                                <p className="text-assetize-light-text text-center font-latoNormal text-sm uppercase">Completion date </p>
                                <p className="text-assetize-light-text text-center font-latoBold text-md   "> {yearAndMonthAgo}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <AboutBox text={description as string} />
                <div className="md:mx-4 mt-5 px-5 py-4 space-y-3 border rounded-lg  ">
                    <h6>Documents</h6>
                    <div className="flex flex-col space-y-2">
                        <div>
                            {legalDocuments.map((doc: any) => (
                                <a
                                    href={doc}
                                    className="flex items-center my-2 justify-between text-[#111] px-4 py-3 border rounded-lg"
                                    key={doc}
                                >
                                    <p className="font-latoBold">{doc.name}</p>
                                    <span>
                                        <FiDownload className="text-assetize-primary" />
                                    </span>
                                </a>
                            ))}
                        </div>
                        <div className="">
                            {propertyFloorPlans.map((doc: any) => {
                                const title = doc.name;

                                return (
                                    <a
                                        href={doc}
                                        className="flex items-center my-2 justify-between text-[#111] px-4 py-3 border rounded-lg cursor-pointer"
                                        key={doc}
                                        download={title}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <p className="font-latoBold">{title}</p>
                                        <span>
                                            <FiDownload className="text-assetize-primary" />
                                        </span>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <ModalPanel
                    open={showReplyModal.open && showReplyModal.action === "delete"}
                    closeButton
                    closeModal={() => setShowReplyModal({ open: false, action: "" })}
                >
                    <DeleteProperty id="123" isHome={false} onClose={() => {}} />
                </ModalPanel>
                <ModalPanel open={previewStage === 1} closeButton closeModal={() => setPreviewStage(0)}>
                    <ConfirmActionModal
                        headingText="Submit Listing"
                        descriptionText="You are about to submit a property listing for approval."
                        handleCancel={() => setPreviewStage(0)}
                        handleAction={onSubmit}
                        loading={loading}
                        buttonText="Continue"
                        cancelButton="Discard"
                    />
                </ModalPanel>
                <ModalPanel open={previewStage === 2} closeButton closeModal={() => window.location.reload()}>
                    <SuccessMessageModal
                        handleClick={() => window.location.reload()}
                        descriptiontext="Your Property Listing has been submitted successfully. It will be approved and available for investment "
                        headingText="Listing submitted!"
                    />
                </ModalPanel>
            </div>
        </div>
    );
};

export default PreviewMode;
