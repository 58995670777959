import React, { useEffect, useState } from "react";
import { MainButton } from "Components/elements/Button/Index";
import AboutBox from "Components/elements/Display/AboutBox";
import Input from "Components/elements/Forms/Input";
import { capitalizeText, readableDate } from "Utils/Helpers";
import { FiDownload } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PropertyStatus } from "types";
import { InfoWarning, BackIcon } from "Assets/Index";
import ModalPanel from "Components/elements/Modal/Index";
import CreateListing from "../portfolio/modals/CreateListing";
import VerifyInfo from "../portfolio/modals/VerifyInfo";
import { GetSingleMarketPlaceApi } from "Services";
import DisplayDocument from "Components/elements/Display/DisplayDocument";
import BuyListingFromInvestor from "../portfolio/modals/BuyListingFromInvestor";
import { Carousel } from "rsuite";

const InvestPropertyDetails: React.FC = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [calculate, setCalculate] = useState("0");
    const [showActionModal, setShowActionModal] = useState({
        action: "",
        open: false
    });
    const [calculatedShare, setCalculatedShare] = useState(0);

    const { id } = useParams();

    const [propertyDetails, setPropertyDetails] = useState<any>({
        propertyName: "",
        availabilityStatus: "",
        developerName: "",
        description: "",
        fiatValue: 0,
        expectedAnnualROI: 0,
        investmentType: "",
        location: "",
        numberOfShares: 0,
        propertyGallery: [],
        propertyType: "",
        sharePrice: 0,
        dividend: 0,
        propertyDocuments: {
            approvalPermitOfProject: "",
            financialProjectionsOfProject: "",
            insuranceAndPerformanceBondFromContractors: "",
            legalCompliance: "",
            projectPlan: "",
            titleDocumentOfProject: ""
        }
    });

    const {
        createdAt,
        propertyName,
        availabilityStatus,
        developerName,
        description,
        fiatValue,
        expectedAnnualROI,
        investmentType,
        location,
        numberOfShares,
        availableNumberOfShares,
        totalNumberOfIssuedTokens,
        propertyGallery,
        propertyType,
        sharePrice,
        dividend,
        propertyDocuments: {
            approvalPermitOfProject,
            financialProjectionsOfProject,
            insuranceAndPerformanceBondFromContractors,
            legalCompliance,
            projectPlan,
            titleDocumentOfProject
        }
    } = propertyDetails;
    const listedDate = readableDate(createdAt);
    const [openBuyModal, setOpenBuyModal] = useState(false);

    const fetchSingleProperty = async () => {
        const property = await GetSingleMarketPlaceApi(Number(id));
        setPropertyDetails({
            ...property.property,
            propertyGallery: property?.propertyImages ? [...property.propertyImages] : [],

            propertyDocuments: {
                approvalPermitOfProject: property?.approvalPermitOfProject,
                financialProjectionsOfProject: property?.financialProjectionsOfProject,
                insuranceAndPerformanceBondFromContractors: property?.insuranceAndPerformanceBondFromContractors,
                legalCompliance: property?.legalCompliance,
                projectPlan: property?.projectPlan,
                titleDocumentOfProject: property?.titleDocumentOfProject
            }
        });

        if (property.existingPortfolio) {
            navigate(`/investor/portfolio/${id}`, { state: property });
        }
    };

    useEffect(() => {
        fetchSingleProperty();
    }, []);

    useEffect(() => {
        const calculatedShare = (fiatValue / numberOfShares) * parseInt(calculate, 10);
        setCalculatedShare(calculatedShare);
    }, [calculate]);

    const isAvailable = availabilityStatus === "AVAILABLE";
    const isResale = availabilityStatus === "RESALE";

    const statusStyle = {
        background: "",
        text: "",
        textContent: ""
    };

    if (isAvailable) {
        statusStyle.background = "bg-green-200";
        statusStyle.text = "text-assetize-green-text";
        statusStyle.textContent = "Available";
    } else if (isResale) {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Re-sale ";
    } else {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Sold Out";
    }

    return (
        <div className="container p-5 mx-auto my-2 bg-white rounded">
            <div
                role="button"
                onKeyDown={() => navigate(-1)}
                tabIndex={0}
                onClick={() => navigate(-1)}
                className="flex justify-start items-center"
            >
                <BackIcon className="w-8 h-8 pl-3 text-assetize-primary" />
                <p className="ml-2 text-assetize-dark font-latoBold font-[18px] text-md hover:text-assetize-dark-text">Back</p>
            </div>
            <div className="flex  flex-col items-start  justify-around px-2 md:px-5 py-8">
                <div className="flex-[.5]">
                    <div className="flex items-center">
                        <h2 className="md:text-md text-[22px] leading-6 font-latoBold text-black md:text-l">{propertyName}</h2>
                        <span
                            className={`text-xs ml-2 p-1 text-center  rounded-2xl ${
                                isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                            } ${statusStyle.text}`}
                        >
                            {statusStyle.textContent}
                        </span>
                    </div>
                    {openBuyModal && <p>12345</p>}
                    <p className="my-1 text-xs font-latoRegular text-assetize-dark-gray">{location}</p>
                    <h3 className="hidden md:flex text-lg text-assetize-primary font-latoBold">${fiatValue.toLocaleString()}</h3>
                    <p className="my-1 text-xs font-latoItalic text-assetize-dark-gray ">
                        Listed by <span className="text-assetize-primary">{developerName} </span> on <span>{listedDate}</span>
                    </p>
                </div>
                <div className="hidden md:flex w-full flex-col-reverse md:flex-col ">
                    {availableNumberOfShares > 0 ? (
                        <div className="ml-auto w-full mt-5 md:mt-0 flex justify-end ">
                            <MainButton
                                onClick={() => navigate(`/investor/buy-shares/${id}`, { state })}
                                className="w-full md:w-[300px] px-8 font-semibold"
                            >
                                Buy Shares
                            </MainButton>
                        </div>
                    ) : (
                        <div className="ml-auto w-full flex justify-end">
                            <MainButton onClick={() => setOpenBuyModal(true)} className="w-full md:w-[300px] px-8 font-semibold">
                                Buy From Investors
                            </MainButton>
                        </div>
                    )}
                </div>
            </div>

            <Carousel autoplay shape="bar">
                {state?.propertyGallery.propertyImages?.map((imgUrl: string) => (
                    <div className="relative w-full h-[300px] md:h-full" key={imgUrl}>
                        <img src={imgUrl} alt="property_img" className="absolute object-cover w-full h-full" />
                    </div>
                ))}
            </Carousel>

            <div className=" md:hidden flex w-full flex-col-reverse md:flex-col ">
                {availableNumberOfShares > 0 ? (
                    <div className="ml-auto w-full mt-5 md:mt-0 flex justify-between ">
                        <div className="w-[40%] flex-col">
                            <h3 className="text-lg text-assetize-primary font-latoBold">${sharePrice}</h3>
                            (Min Amount)
                        </div>
                        <div className="w-[40%]">
                            <MainButton
                                onClick={() => navigate(`/investor/buy-shares/${id}`, { state })}
                                className="w-full md:w-[300px] px-8 font-semibold"
                            >
                                Buy Shares
                            </MainButton>
                        </div>
                    </div>
                ) : (
                    <div className="ml-auto w-full mt-5 flex justify-between">
                        <div className="w-[40%] flex-col">
                            <h3 className="text-lg text-assetize-primary font-latoBold">${sharePrice}</h3>
                            (Min Amount)
                        </div>
                        <div className="w-[40%]">
                            <MainButton onClick={() => setOpenBuyModal(true)} className="w-full md:w-[300px] px-8 font-semibold">
                                Buy From Investors
                            </MainButton>
                        </div>
                    </div>
                )}
            </div>

            <div className="px-2 md:px-4 flex flex-col md:flex-row gap-2 mt-8 md:mt-5">
                <div className="w-full border border-assetize-gray rounded md:py-4 py-2 px-1">
                    <h5 className="text-assetize-primary text-lg pl-3 my-3">Property Information</h5>
                    <div className="md:grid md:grid-cols-3 flex flex-col mt-5 md:mt-10 ">
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Property Type</p>
                            <p className="text-assetize-light-text text-center font-latoRegular  text-sm mt-2">
                                {capitalizeText(propertyType)}
                            </p>
                        </div>
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase"> Expected Annual ROI</p>
                            <p className="text-assetize-light-text text-center font-latoRegular text-sm mt-2">{expectedAnnualROI}%</p>
                        </div>
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Total Shares</p>
                            <p className="text-assetize-light-text text-center font-latoRegular text-sm mt-2">{numberOfShares}</p>
                        </div>
                        <div className="md:border-b-0 md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Investment Type</p>
                            <p className="text-assetize-light-text text-center font-latoRegular text-sm mt-2">
                                {investmentType === "NON_RENTAL" ? "Non Rental" : "Rental"}
                            </p>
                        </div>
                        <div className="md:border-b-0 md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">
                                Original value of shares
                            </p>
                            <p className="text-assetize-light-text text-center font-latoRegular text-sm mt-2">${sharePrice}</p>
                        </div>
                        <div className="md:border-b-0 md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Dividends</p>
                            <p className="text-assetize-light-text text-center font-latoRegular text-sm mt-2">
                                {investmentType === "NON_RENTAL" ? "No" : "Yes"}{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <AboutBox text={description} />

            <div className="p-3 mx-2 border">
                <h5 className="my-3 text-assetize-primary">Documents</h5>
                <div className="flex flex-col space-y-2">
                    <div className="space-y-2 ">
                        <DisplayDocument document={projectPlan} name="projectPlan" />
                        <DisplayDocument document={legalCompliance} name="legalCompliance" />
                        <DisplayDocument
                            document={insuranceAndPerformanceBondFromContractors}
                            name="insuranceAndPerformanceBondFromContractors"
                        />
                        <DisplayDocument document={financialProjectionsOfProject} name="financialProjectionsOfProject" />
                        <DisplayDocument document={approvalPermitOfProject} name="approvalPermitOfProject" />
                        <DisplayDocument document={titleDocumentOfProject} name="titleDocumentOfProject" />
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center p-3 mx-1 md:mx-8 mt-5 rounded bg-assetize-primary-gradient ">
                <InfoWarning />
                <p className="text-sm ml-1 md:ml-0 font-latoBold text-assetize-primary ">
                    Earned returns are paid into your wallet when due. Claim your capital anytime by selling your shares.
                </p>
            </div>
            <ModalPanel open={showActionModal.open} closeButton closeModal={() => setShowActionModal({ open: false, action: "" })}>
                <VerifyInfo />
            </ModalPanel>
            <ModalPanel open={openBuyModal} closeButton closeModal={() => setOpenBuyModal(false)}>
                <BuyListingFromInvestor id={id as string} closeModal={() => setOpenBuyModal(false)} />
            </ModalPanel>
        </div>
    );
};

export default InvestPropertyDetails;
