import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import { BlogProps, BlogsCategory } from "types/data";
import React, { useEffect, useState } from "react";
import { GetLearnArticlesApi, GetLearnNewsApi } from "Services";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import BlogCard from "./Blogcard";
import { MainButton } from "Components/elements/Button/Index";
import Pagination from "Components/elements/Pagination";
import { getErrorMessge, searchArray } from "Utils/Helpers";
import { set } from "store";

const AdminLearn = () => {
    const [selectedOption, setSelectedOption] = useState(BlogsCategory.articles);
    const [search, setSearch] = useState("");

    const [newsList, setNewsList] = useState<BlogProps[]>([]);
    const [articles, setArticles] = useState<BlogProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [displayList, setDisplayList] = useState<BlogProps[]>([]);
    const [pageDataForNews, setPageDataForNews] = useState<any>();
    const [pageDataForArticles, setPageDataForAricles] = useState<any>();
    const [pageNumForNews, setPageNumForNews] = useState<number>(1);
    const [pageNumForArticles, setPageNumForAricles] = useState<number>(1);

    const navigate = useNavigate();

    const fetchArticles = async () => {
        setLoading(true);
        try {
            const res = await GetLearnArticlesApi(pageNumForArticles);
            const news = await GetLearnNewsApi(pageNumForNews);

            setArticles(res.postsWithImages);
            setNewsList(news.postsWithImages);
            setPageDataForNews(news.metadata);
            setPageDataForAricles(res.metadata);

            if (selectedOption === BlogsCategory.news) {
                setDisplayList(news.postsWithImages);
            } else {
                setDisplayList(res.postsWithImages);
            }
        } catch (error: string | any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedOption === BlogsCategory.news) {
            setDisplayList(newsList);
        } else {
            setDisplayList(articles);
        }
    }, [selectedOption]);

    useEffect(() => {
        let searchResult;
        if (selectedOption === BlogsCategory.news) {
            searchResult = searchArray(newsList, search);
        } else {
            searchResult = searchArray(articles, search);
        }
        setDisplayList(searchResult);
    }, [search]);

    useEffect(() => {
        fetchArticles();
    }, []);

    useEffect(() => {
        const getNews = async () => {
            try {
                const news = await GetLearnNewsApi(pageNumForNews);
                setNewsList(news.postsWithImages);
                setPageDataForNews(news.metadata);
                setDisplayList(news.postsWithImages);
            } catch (error) {
                const err = getErrorMessge(error);
                toast.error(err);
            }
        };
        getNews();
    }, [pageNumForNews]);

    useEffect(() => {
        const getNews = async () => {
            try {
                const res = await GetLearnArticlesApi(pageNumForArticles);
                setArticles(res.postsWithImages);
                setPageDataForAricles(res.metadata);
                setDisplayList(res.postsWithImages);
            } catch (error) {
                const err = getErrorMessge(error);
                toast.error(err);
            }
        };
        getNews();
    }, [pageNumForArticles]);

    return (
        <div>
            <div className="flex justify-between items-center mt-10">
                <div className="border-b flex  border-assetize-gray pt-2  flex-[.3]">
                    <div
                        onClick={() => setSelectedOption(BlogsCategory.articles)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => setSelectedOption(BlogsCategory.articles)}
                        className={`${selectedOption === BlogsCategory.articles ? "border-b border-assetize-primary  pb-1.5 " : ""} px-4`}
                    >
                        <p
                            className={`${
                                selectedOption === BlogsCategory.articles ? "text-assetize-primary" : "text-assetize-dark-gray"
                            } font-latoRegular`}
                        >
                            Articles
                        </p>
                    </div>
                    <div
                        onClick={() => setSelectedOption(BlogsCategory.news)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => setSelectedOption(BlogsCategory.news)}
                        className={`${selectedOption === BlogsCategory.news ? "border-b border-assetize-primary  pb-1.5  " : ""} px-8`}
                    >
                        <p
                            className={`${
                                selectedOption === BlogsCategory.news ? "text-assetize-primary" : "text-assetize-dark-gray"
                            } font-latoRegular`}
                        >
                            News
                        </p>
                    </div>
                </div>
                <div className=" lg:flex-[.45] mx-1 flex items-center">
                    <div className="flex-[.2]">
                        <ActiveSearch
                            bg="white"
                            border="border-assetize-gray"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search here..."
                        />
                    </div>
                    <div className="lg:flex-[.8] pl-2">
                        <MainButton onClick={() => navigate("create", { state: { isEdit: false } })}>
                            <div className="flex items-center px-2 justify-around">
                                {/* <p className="text-white px-2 font-latoBold text-xl">+</p> */}
                                <h3 className="text-white font-latoLight text-sm">+ New Post</h3>
                            </div>
                        </MainButton>
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="flex items-center justify-center mt-64">
                    <BounceLoader color="#ca1611" />
                </div>
            ) : (
                <div className="bg-white p-2 mt-5">
                    {displayList.map((item) => (
                        <div>
                            <BlogCard
                                key={item.id}
                                heading={item.title}
                                body={item.content}
                                id={item.id}
                                date={item.lastUpdated}
                                imgUrl={item.headerImage}
                                numberOfViews={item.numberOfViews}
                            />
                        </div>
                    ))}
                    <div className="mt-5 flex justify-end">
                        {selectedOption === BlogsCategory.news ? (
                            <Pagination pageData={pageDataForNews} setPageNum={setPageNumForNews} />
                        ) : (
                            <Pagination pageData={pageDataForArticles} setPageNum={setPageNumForAricles} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminLearn;
