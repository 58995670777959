import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    properties: []
};

const propertySlice = createSlice({
    name: "property",
    initialState,
    reducers: {
        addProperties: (state, { payload }: PayloadAction<any>) => {
            state.properties = payload;
        }
    }
});

const { actions, reducer: PropertyReducer } = propertySlice;

export const { addProperties } = actions;
export default PropertyReducer;
