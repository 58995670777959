import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetLearnDetailApi, UpdateLearnPost } from "Services";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import GoBack from "Components/elements/Display/GoBack";
import ModalPanel from "Components/elements/Modal/Index";
import AnswerModal from "./AnswerModal";
import { ChevronDown, EditIcon, Forwardicon, BackIcon } from "Assets/Index";
import { BlogProps, OptionsProps } from "types/data";
import { getErrorMessge, readableDate } from "Utils/Helpers";
import MenuList, { Option } from "Components/elements/Menu";
import { Switch } from "@headlessui/react";
import PreviewPost from "./PreviewPost";
import { BounceLoader } from "react-spinners";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import Textarea from "Components/elements/Forms/TextArea";
import ASDocUpload from "Components/elements/Forms/ASDocUpload";
import Input from "Components/elements/Forms/Input";
import { validateAll } from "indicative/validator";

const postType = [
    { label: "Article", value: "ARTICLE" },
    { label: "News", value: "NEWS" }
];

const EditPost = () => {
    const { id } = useParams();
    const [stage, setStage] = useState("1");
    const [pageId, setPageId] = useState<number>(1);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [post, setPost] = useState<any>({});
    const [formData, setFormData] = useState({
        title: "",
        content: "",
        postType: "",
        img: ""
    });
    const [errors, setErrors] = useState({
        title: "",
        content: "",
        category: "",
        postType: "",
        img: ""
    });
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const fetchBlog = async () => {
        try {
            setLoading(true);
            const res = await GetLearnDetailApi(id);

            setPost(res);
            setFormData({
                title: res.title,
                content: res.content,
                postType: res.category,
                img: res.headerImg
            });
        } catch (error) {
            setLoading(false);
            toast.error("Error fetching articles");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlog();
        setPageId(Number(id));
    }, [id]);

    const options: OptionsProps[] = [
        { text: "Edit post", value: "edit" },
        { text: "Delete post", value: "delete" }
    ];

    const handleChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const updatePost = async () => {
        try {
            setLoadingUpdate(true);
            const payload = {
                title: post.title,
                content: post.content,
                category: post.postType,
                postType: post.postType
            };
            await UpdateLearnPost(id as string, payload);
            toast.success("Post updated successfully!");
            navigate("/admin/learn");
        } catch (err) {
            setLoadingUpdate(false);
            const errMsg = getErrorMessge(err);
            toast.error(errMsg);
        } finally {
            setLoadingUpdate(false);
        }
    };

    const update = (e: React.FormEvent) => {
        e.preventDefault();
        const rules = {
            title: "required",
            content: "required"
        };

        const messages = {
            "title.required": "Amount is required",
            "content.required": "Content is required"
        };

        validateAll(formData, rules, messages)
            .then(() => {
                setErrors({
                    title: "",
                    content: "",
                    category: "",
                    postType: "",
                    img: ""
                });
                setStage("2");
                setPost({
                    ...post,
                    content: formData.content,
                    category: formData.postType,
                    postType: formData.postType,
                    title: formData.title
                });
            })
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });

                setErrors(formattedErrors);
            });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center mt-64">
                <BounceLoader color="#ca1611" />
            </div>
        );
    }

    return stage === "1" ? (
        <div className="container py-4 mx-auto space-y-6">
            <div className="flex items-center justify-start font-semibold gap-x-2">
                <button onClick={() => navigate(-1)} type="button" className="flex items-center gap-x-2">
                    <BiArrowBack className="text-assetize-primary" />
                    Back
                </button>
            </div>
            <div className="w-11/12 pb-8 mx-auto space-y-8">
                <div className="flex flex-col items-center justify-center">
                    <h4>Edit Post </h4>
                    <p>Edit Article or news</p>
                </div>
                <form onSubmit={update} encType="multipart/form-data">
                    <div className="py-8 space-y-4 border-y-2">
                        <div className="grid grid-cols-2 gap-x-8">
                            <div className="space-y-1 h-fit">
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="font-semibold text-gray-800" htmlFor="postType">
                                    Publication Type <span className="text-assetize-primary">*</span>
                                    <div>
                                        <Select
                                            id="postType"
                                            options={postType}
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    outline: "none",
                                                    background: "transparent",
                                                    border: errors.postType && "solid red 1px"
                                                })
                                            }}
                                            value={{ label: formData.postType.toUpperCase(), value: formData.postType }}
                                            placeholder="Select publication type"
                                            name="propertyType"
                                            aria-errormessage={errors.postType}
                                            onChange={(selectedOption) => handleChange("postType", selectedOption!.value)}
                                        />
                                        <p className="mt-1 text-xs text-red-600">{errors.postType}</p>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label className="font-semibold text-gray-800" htmlFor="title">
                                    Title <span className="text-assetize-primary">*</span>{" "}
                                </label>
                                <Input
                                    value={formData.title}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    type="text"
                                    name="title"
                                    className="bg-transparent"
                                    placeholder="Enter post name"
                                    error={errors.title}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="textarea" className="mb-2 text-base font-bold text-gray-800 cursor-pointer">
                                Content
                                <Textarea
                                    className="w-full p-4 mt-4 bg-transparent border-2 rounded-lg"
                                    rows={10}
                                    name="content"
                                    value={formData.content}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                />
                                <p className="text-xs text-red-600">
                                    {errors.content}
                                    {formData.content.length > 1500 && " Content should not exceed 1500 words"}
                                </p>
                            </label>
                            <p className="text-xs text-red-600">{errors.content}</p>
                        </div>
                        {/* <div className="w-1/2">
                            <ASDocUpload
                                label="Upload image"
                                name="image"
                                accept="image/jpeg, image/png, application/pdf"
                                onChange={(e: any) => formik.setFieldValue('image', e?.currentTarget?.files[0])}
                                error={formik.errors.image}
                            />
                        </div> */}
                    </div>
                    <div className="my-6 ml-auto space-x-6 font-semibold w-fit">
                        <button type="button" className="px-20 rounded-lg border py-2.5 border-black hover:bg-zinc-300">
                            Cancel
                        </button>

                        <button type="submit" className="text-white hover:bg-assetize-primary py-2.5 rounded-lg px-14 bg-red-500">
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    ) : (
        <div className="bg-white">
            <div className="bg-assetize-primary p-3 flex justify-center items-center font-latoRegular text-sm text-white rounded-tl-md rounded-tr-md mb-4">
                Preview Mode
            </div>

            <GoBack link="/admin/learn" />

            <div className="w-full flex pb-12 flex-col">
                <div className="flex w-[85%] md:justify-end py-4">
                    <div className="relative md:w-[50%] flex justify-end space-x-2">
                        <OutlineButton onClick={() => setStage("1")} color="assetize-gray">
                            <div className="flex items-center justify-around">
                                <EditIcon />
                                <p className="font-latoBold text-sm text-assetize-light-text">Edit document</p>
                            </div>
                        </OutlineButton>
                        <MainButton isLoading={loadingUpdate} disabled={loadingUpdate} onClick={updatePost}>
                            Publish
                        </MainButton>
                    </div>
                </div>
                <div className="flex w-[70%] flex-col items-center justify-center mx-auto">
                    <h3 className="text-xl font-latoBold py-2 text-center">{post?.title}</h3>
                    <img src={post?.headerImage} alt="blog" className="rounded my-6" />
                    <p className="text-sm text-assetize-light-text font-latoRegular">{post?.content}</p>
                </div>
            </div>
        </div>
    );
};

export default EditPost;
