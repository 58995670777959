import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TUser } from "types/Auth.types";

type TAuthState = {
    user: TUser | null;
    isAuthenticated: boolean;
};

const initialState: TAuthState = {
    user: null,
    isAuthenticated: false
};

const authSlice = createSlice({
    name: "dAuth",
    initialState,
    reducers: {
        signinUser: (state, { payload }: PayloadAction<TUser>) => {
            state.user = payload;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
        }
    }
});

const { actions, reducer: AuthReducer } = authSlice;

export const { signinUser } = actions;
export default AuthReducer;
