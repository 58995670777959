import ApiEndpoints from "Utils/ApiRoutes";
import { UserType } from "types/Auth.types";
import axiosInstance from "Utils/Https";
import { kycEndpoint } from "Utils/Helpers";

interface User {
    id: number;
    name: string;
    email: string;
    // any other user properties
}

class AuthService {
    // Disable class-methods-use-this rule for login and logout methods
    /* eslint-disable class-methods-use-this */

    async login(email: string, password: string): Promise<User> {
        try {
            const response = await axiosInstance.post(ApiEndpoints.LOGIN, {
                email,
                password
            });

            localStorage.setItem("accountType", response.data.user.accountType);

            return response.data;
        } catch (error) {
            throw new Error("Invalid credentials");
        }
    }

    async forgotPassword(emailAddress: string): Promise<User> {
        try {
            const response = await axiosInstance.post(ApiEndpoints.FORGOT, {
                emailAddress
            });

            return response.data;
        } catch (error) {
            throw new Error("Invalid email");
        }
    }

    async logout(): Promise<void> {
        // Perform logout API call using this.axiosInstance
        // await this.axiosInstance.post('/logout');
    }

    async register({ accountType, dateOfBirth, emailAddress, firstName, lastName, password, phoneNumber }: UserType): Promise<User> {
        try {
            const response = await axiosInstance.post(ApiEndpoints.SIGNUP, {
                accountType,
                dateOfBirth,
                emailAddress,
                firstName,
                lastName,
                password,
                phoneNumber
            });

            return response.data;
        } catch (error: any) {
            throw new Error(error.response.data.message);
        }
    }

    async refreshUser(): Promise<User> {
        try {
            const response = await axiosInstance.get(ApiEndpoints.GETUSER);

            return response.data;
        } catch (error: any) {
            throw new Error(error.response.data.message);
        }
    }

    async otpCode(emailAddress: string, otp: string): Promise<User> {
        try {
            const response = await axiosInstance.post(ApiEndpoints.OTPCODE, {
                emailAddress,
                otp
            });

            return response.data;
        } catch (error) {
            throw new Error("OTP has expired or is incorrect");
        }
    }

    async resetPassword(emailAddress: string | null, newPassword: string, otp: string): Promise<User> {
        try {
            const response = await axiosInstance.post(ApiEndpoints.RESET, {
                emailAddress,
                newPassword,
                otp
            });

            return response.data;
        } catch (error) {
            throw new Error("Invalid credentials");
        }
    }

    async kycVerify(documentType: string, documentNumber: string): Promise<string> {
        try {
            const response = await axiosInstance.post(kycEndpoint(documentType), {
                documentType,
                documentNumber,
                consent: true
            });

            return response.data;
        } catch (error) {
            throw new Error("Invalid credentials");
        }
    }
}

export default AuthService;
