import React from "react";
import { MainButton, OutlineButton } from "../Button/Index";

interface ConfirmActionModalProps {
    headingText: string;
    descriptionText: string;
    handleCancel: () => void;
    handleAction: () => void;
    loading: boolean;
    buttonText: string;
    cancelButton?: string;
}

const ConfirmActionModal = ({
    headingText,
    descriptionText,
    handleCancel,
    handleAction,
    loading,
    buttonText,
    cancelButton
}: ConfirmActionModalProps) => (
    <div className="container p-2 md:p-5 mx-auto my-2 rounded bg-white w-[100%]">
        <h3 className="text-md font-latoRegular mb-5 text-center text-assetize-primary">{headingText}</h3>
        <p className="text-[18px] text-assetize-dark-gray py-2 text-center">{descriptionText}</p>
        <div className="mt-8 w-full flex justify-evenly items-center">
            <div className="w-[45%]">
                <OutlineButton disabled={loading} onClick={handleCancel}>
                    {cancelButton || "Discard"}
                </OutlineButton>
            </div>

            <div className="w-[45%]">
                <MainButton isLoading={loading} disabled={loading} onClick={handleAction} type="submit">
                    {buttonText}
                </MainButton>
            </div>
        </div>
    </div>
);

export default ConfirmActionModal;
