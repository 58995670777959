import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { ContactSupportApi } from "Services";
import { toast } from "react-toastify";
import Input from "Components/elements/Forms/Input";
import Textarea from "Components/elements/Forms/TextArea";
import { useSelector } from "react-redux";
import rootReducer from "redux/RootReducer";
import { TUser } from "types/Auth.types";
import { validateAll } from "indicative/validator";
import { getErrorMessge } from "Utils/Helpers";
import { MainButton } from "Components/elements/Button/Index";

type FormDataProps = {
    email: string;
    subject: string;
    message: string;
    files?: File | undefined;
};

type ErrorResponse = {
    response: {
        data: {
            message: string;
        };
    };
};

const SupportModal: FC<{ onClose: () => void }> = ({ onClose }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state: any) => state.auth);

    const [formData, setFormData] = useState<FormDataProps>({
        email: "",
        subject: "",
        message: "",
        files: undefined
    });
    const [errors, setErrors] = useState({
        email: "",
        subject: "",
        message: "",
        files: ""
    });

    const { email, subject, message, files } = formData;

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files;
        if (selectedFile) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                files: selectedFile[0]
            }));
        }
    };

    const sendSupport = async () => {
        try {
            setLoading(true);

            const formDataToSend = new FormData();
            formDataToSend.append("email", user.email);
            formDataToSend.append("subject", subject);
            formDataToSend.append("message", message);

            if (files) {
                formDataToSend.append("files", files);
            }

            const response = await ContactSupportApi(formDataToSend);

            toast.success("Support sent successfully!");
            setFormData({
                email: "",
                subject: "",
                message: "",
                files: undefined
            });
            onClose();
        } catch (error: any) {
            const errMessage = getErrorMessge(error);
            toast.error(errMessage);
        } finally {
            setLoading(false);
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef!.current!.click();
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const rules = {
            email: "email",
            subject: "required",
            message: "required"
        };

        const messages = {
            "email.required": "Email is required",
            "subject.required": "Subject is required",
            "message.required": "Message is required"
        };

        setErrors({ email: "", subject: "", message: "", files: "" });
        validateAll(formData, rules, messages)
            .then(() => {
                sendSupport();
            })
            .catch((err: any) => {
                const formattedErrors = {} as any;
                err.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });

                setErrors(formattedErrors);
            });
    };

    useEffect(() => {
        if (user.email) setFormData({ ...formData, email: user.email });
    }, [user.email]);

    return (
        <div className="bg-white rounded-lg p-4 z-50">
            <Dialog.Title as="h3" className="text-2xl font-bold text-center text-assetize-primary mb-4 -mt-8">
                Customer Support
            </Dialog.Title>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="email" className="block text-gray-700 font-medium mb-1">
                        Email <span className=" text-assetize-primary">*</span>
                        <Input
                            type="email"
                            // disabled
                            name="email"
                            className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-assetize-primary focus:border-assetize-primary"
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />
                    </label>
                </div>
                <div className="mb-4">
                    <label htmlFor="subject" className="block text-gray-700 font-medium mb-1">
                        Subject{" "}
                    </label>
                    <Input
                        name="subject"
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-assetize-primary focus:border-assetize-primary"
                        value={subject}
                        onChange={handleChange}
                        error={errors.subject}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="message" className="block text-gray-700 font-medium mb-1">
                        Message{" "}
                    </label>
                    <Textarea name="message" value={message} placeholder="" error={errors.message} onChange={handleChange} />
                </div>
                <div className="mb-2">File</div>
                <div className="flex items-center mb-5">
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyDown={handleFileClick}
                        onClick={handleFileClick}
                        className="relative w-1/2 rounded-xl border-2 bg-assetize-light-red border-assetize-primary p-3 mb-2"
                    >
                        <div className="text-center">
                            <input
                                type="file"
                                id="files"
                                ref={fileInputRef}
                                name="files"
                                className="absolute inset-0 w-1/2 h-full opacity-0 cursor-pointer"
                                onChange={handleFileChange}
                                accept="image/*, .pdf, .doc, .docx"
                            />
                            {files ? <p className="text-gray-900">{files.name}</p> : <p className=" text-assetize-primary">Choose file</p>}
                        </div>
                    </div>
                </div>
                <div className="flex w-full">
                    {/* <button type="submit" className=" bg-assetize-primary w-full  text-white px-4 py-2 rounded-md ">
                        Submit
                    </button> */}
                    <MainButton isLoading={loading} disabled={loading} type="submit">
                        Submit
                    </MainButton>
                </div>
            </form>
        </div>
    );
};

export default SupportModal;
