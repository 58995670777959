import { BackIcon, BlogImage, Forwardicon } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import { BlogProps } from "types/data";
import { readableDate } from "Utils/Helpers";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetLearnDetailApi } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";

const LearnDetails = () => {
    const { id } = useParams();
    const [blogDetails, setBlogDetails] = useState<BlogProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const backButtonRef = useRef<HTMLDivElement | null>(null);
    const [showNextButton, setShowButton] = useState(true);
    const formatDate = (dateString: string | number | Date) => {
        const date = new Date(dateString);
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
        return formattedDate;
    };

    const navigate = useNavigate();
    const [pageId, setPageId] = useState<number>(parseInt(id as string, 10));
    const { user } = useSelector((state: any) => state.auth);

    const navigateToPrevious = () => {
        const previousId = pageId - 1;
        navigate(`/${user.accountType}/learn/${previousId}`);
    };

    const navigateToNext = () => {
        const nextId = pageId + 1;
        navigate(`/${user.accountType}/learn/${nextId}`);
    };

    const fetchBlog = async () => {
        try {
            setLoading(true);
            const res = await GetLearnDetailApi(id);

            setBlogDetails(res);
            res.dateCreated = formatDate(res.dateCreated);
            setShowButton(true);
        } catch (error) {
            // toast.error('Error fetching articles');
            // navigateToPrevious();
            setShowButton(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (backButtonRef.current) {
            backButtonRef.current.scrollIntoView({ behavior: "smooth" });
        }

        fetchBlog();
        setPageId(Number(id));
    }, [id]);

    return (
        <div>
            {loading ? (
                <div className="flex items-center justify-center mt-64">
                    <ScaleLoader color="#ca1611" />
                </div>
            ) : (
                <div ref={backButtonRef}>
                    <div className="bg-white p-2 md:p-4  mt-5">
                        <div
                            onClick={() => navigate(-1)}
                            onKeyDown={() => navigate(-1)}
                            role="button"
                            tabIndex={0}
                            className="flex justify-start items-center"
                        >
                            <BackIcon className="w-8 h-8 pl-3 text-assetize-primary" />
                            <p className="ml-2 text-assetize-dark font-latoBold font-[18px] text-md hover:text-assetize-dark-text">Back</p>
                        </div>
                        <div className="w-full flex justify-center  items-center">
                            <div className="flex flex-col items-center justify-center w-full md:w-[70%]">
                                <h3 className="md:text-2xl text-[18px] leading-8 font-latoBold py-2">{blogDetails?.title}</h3>
                                <p className="text-sm text-assetize-light-text font-latoRegular ">
                                    Published on {blogDetails?.dateCreated ? formatDate(blogDetails.dateCreated) : ""}
                                </p>
                                <div className="h-[300px] w-full">
                                    <img src={blogDetails?.headerImage} alt="blog" className="rounded my-6 h-full w-full object-cover" />
                                </div>
                                <p className="text-sm text-assetize-light-text font-latoRegular whitespace-pre-line mt-10">
                                    {blogDetails?.content}
                                </p>
                                <div className={`flex  w-full items-center my-10 ${pageId > 1 ? "justify-between" : "justify-end"}`}>
                                    {pageId > 1 && (
                                        <div className="flex-[.2]">
                                            <OutlineButton onClick={navigateToPrevious} color="assetize-dark-gray">
                                                <div className="flex justify-center items-center px-4">
                                                    <BackIcon className="w-6" />
                                                    <span className="ml-2 text-assetize-dark-gray text-md">Previous</span>
                                                </div>
                                            </OutlineButton>
                                        </div>
                                    )}
                                    {showNextButton && (
                                        <div className="flex-[.2] self-end flex">
                                            <MainButton onClick={navigateToNext}>
                                                <div className="flex justify-center items-center px-4">
                                                    <span className="mr-2 text-md">Next</span> <Forwardicon className="w-6" />
                                                </div>
                                            </MainButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LearnDetails;
