import { Transition } from "@headlessui/react";
import useCurrentPath from "Hooks/useCurrentPath";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    adminSidebarBottomLinks,
    adminSidebarLinks,
    developerSidebarBottomLinks,
    developerSidebarLinks,
    investorSidebarBottomLinks,
    investorSidebarLinks
} from "./data";
import { logoutUser } from "Services";
import { navigateWithAccountType } from "Utils/Helpers";
import SidebarLayout from "./SidebarLayout";
import ModalPanel from "Components/elements/Modal/Index";
import SupportModal from "Pages/Support/Index";
import KycModal from "Layouts/Kyc/KycModal";

export default function MobileSideNav() {
    const [isShowing, setIsShowing] = useState(false);

    const openSideNav = () => {
        setIsShowing(true);
    };

    const closeSideNav = () => {
        setIsShowing(false);
    };

    const currentPath = useCurrentPath();

    const navigate = useNavigate();
    const { user } = useSelector((state: any) => state.auth);

    const [showKycModal, setShowKycModal] = useState(false);
    const [supportToggleState, setSupportToggleState] = useState(false);

    const setSidebarData = useCallback(() => {
        switch (user?.accountType) {
            case "developer":
                return developerSidebarLinks;
            case "admin":
                return adminSidebarLinks;
            default:
                return investorSidebarLinks;
        }
    }, []);

    const setSidebarBottomData = useCallback(() => {
        switch (user?.accountType) {
            case "developer":
                return developerSidebarBottomLinks;
            case "admin":
                return adminSidebarBottomLinks;
            default:
                return investorSidebarBottomLinks;
        }
    }, []);

    const closeSupportModal = () => {
        setSupportToggleState(false);
    };

    const decideToNavigate = async (url: string) => {
        if (url && url.toLocaleLowerCase() === "support") {
            //  setIsShowing(false);
            setSupportToggleState(true);
        } else {
            await logoutUser();
        }
    };

    const handleNavigation = (url: string) => {
        const navUrl = navigateWithAccountType(user?.accountType, url);
        setIsShowing(false);
        navigate(navUrl);
    };

    useEffect(() => {
        if (!user) {
            setSidebarData();
            setSidebarBottomData();
        }
    }, []);

    useEffect(() => {
        let timer: any;
        if (user) {
            timer = setTimeout(() => {
                setShowKycModal(!user?.kycVerified);
            }, 7000);
        }
        return () => clearTimeout(timer);
    }, [user?.kycVerificationStatus, user]);

    return (
        <div className="flex flex-col items-center ">
            <div className="">
                <Transition
                    as={Fragment}
                    show={isShowing}
                    enter="transform transition duration-[400ms]"
                    enterFrom="opacity-0 translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="transform duration-200 transition ease-in-out"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-full"
                >
                    <div className="h-full w-[100%] fixed top-0  right-0 bg-black shadow-lg z-50">
                        <div className="w-full flex justify-end  ">
                            <button
                                type="button"
                                onClick={closeSideNav}
                                className=" rounded m-5 flex justify-center items-center w-7 h-7 bg-assetize-dark-gray"
                            >
                                <AiOutlineClose className="text-white text-xl" />
                            </button>
                        </div>
                        <div>
                            {setSidebarData().map(({ id, title, icon, url }) => (
                                <div
                                    onClick={() => handleNavigation(url)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => {
                                        handleNavigation(url);
                                    }}
                                    key={id}
                                    className={`${url === currentPath && "bg-[#2B2A2A]"} rounded py-[1px] mx-2`}
                                >
                                    <SidebarLayout title={title} icon={icon} />
                                </div>
                            ))}

                            <div className="mt-14">
                                {setSidebarBottomData().map((item) => (
                                    <div
                                        onClick={() => decideToNavigate(item.url)}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => {
                                            decideToNavigate(item.url);
                                        }}
                                        key={item.id}
                                        className={` ${item.title === "Logout" && "bg-assetize-primary py-[1px]"} rounded `}
                                    >
                                        <SidebarLayout title={item.title} icon={item.icon} />
                                    </div>
                                ))}
                            </div>

                            <ModalPanel
                                closeModal={() => {
                                    setSupportToggleState(false);
                                }}
                                open={supportToggleState}
                                closeButton
                            >
                                <SupportModal onClose={closeSupportModal} />
                            </ModalPanel>

                            {user?.accountType === "investor" && (
                                <ModalPanel open={showKycModal} closeButton closeModal={() => setShowKycModal(false)}>
                                    <KycModal onClose={() => setShowKycModal(false)} />
                                </ModalPanel>
                            )}
                        </div>
                    </div>
                </Transition>
            </div>

            <button
                type="button"
                onClick={openSideNav}
                onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === "Space") {
                        openSideNav();
                    }
                }}
                className="md:hidden cursor-pointer"
                aria-label="Open Side Navigation"
            >
                <BiMenu className="text-assetize-dark-text text-3xl" />
            </button>
        </div>
    );
}
