/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
import { EyeIcon, EyeIconOff, FilledWallet, ThreeDotsVertical } from "Assets/Index";
import MenuList, { Option } from "Components/elements/Menu";
import { useState } from "react";
import { Walletprops, safeLockProps } from "types/data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ModalPanel from "Components/elements/Modal/Index";
import KycModal from "Layouts/Kyc/KycModal";
import WalletWithdrawal from "./sections/WalletWithdrawal";

interface OptionsProps {
    text: string;
    value: string;
}

interface WalletcardProps {
    item: Walletprops | safeLockProps;
    walletKeys: (keyof Walletprops)[];
    title?: string;
    ctaAction: (choice: string) => void;
    ctaTitle?: string;
    slug?: string;
    pockets?: any;
}

const WalletCard = ({
    item,
    walletKeys,
    title = "Wallet Balance",
    ctaTitle = "Fund Wallet",
    ctaAction,
    slug = "fund",

    pockets
}: WalletcardProps) => {
    const options: OptionsProps[] = [{ text: "Withdraw", value: "withdraw" }];

    const [action, setAction] = useState(false);
    const [pocketId, setPocketId] = useState("");
    const navigate = useNavigate();
    const [openKycModal, setOpenKycModal] = useState(false);

    const { user } = useSelector((state: any) => state.auth);

    const handleOptionSelect = (option: Option) => {
        if (option?.value === "withdraw") {
            if (user.kycVerified) {
                setAction(true);
                setOpenKycModal(false);
            } else {
                setOpenKycModal(true);
                setAction(false);
            }
        }
    };

    const handleClick = () => {
        if (user.kycVerified) {
            if (slug === "fund") {
                ctaAction(slug);
            } else if (slug === "safelock") {
                navigate(`/${user.accountType}/wallets/safelock`);
            }
        } else {
            setOpenKycModal(true);
        }
    };
    const [hideBalance, setHideBalance] = useState(false);

    const handleEyeIconClick = () => {
        setHideBalance(!hideBalance);
    };

    return (
        <>
            <div className="rounded-2xl h-[160px] flex flex-col justify-around bg-white px-5 w-full md:w-fit py-4 border-t border-r border-r-[#EAEBF1] border-t-[#EAEBF1] border-b-[#EAEBF1] border-b border-l-8 border-assetize-primary ">
                <div className="flex items-center justify-between ">
                    <div className="flex items-center  px-1">
                        <div className="w-4 h-4 rounded bg-assetize-primary" />
                        <p className="px-3 uppercase text-[14px] font-latoRegular">{title}</p>
                        <span className="cursor-pointer">
                            {hideBalance ? <EyeIconOff onClick={handleEyeIconClick} /> : <EyeIcon onClick={handleEyeIconClick} />}
                        </span>
                    </div>
                    {slug !== "safelock" ? (
                        <div className="">
                            <MenuList
                                options={options}
                                onSelect={handleOptionSelect}
                                icon={ThreeDotsVertical}
                                bg="white"
                                textColor="assetize-light-text"
                                size="28"
                            />
                        </div>
                    ) : (
                        <div className="px-4" />
                    )}
                </div>
                <div className="flex items-center py-2 ml-2">
                    {hideBalance ? (
                        <h3 className="text-2xl font-latoBold">****</h3>
                    ) : (
                        <h3 className="text-[30px] text-[#000] font-latoBold">
                            {(slug === "fund" && item?.balance && `$${item.balance.toLocaleString("en-us")}`) ||
                                (slug === "safelock" && item?.totalBalance && `$${item.totalBalance.toLocaleString("en-us")}`) ||
                                `$0.00`}
                        </h3>
                    )}
                </div>

                <div className="flex justify-end">
                    <div
                        className="p-2 ml-7 rounded-lg flex items-center bg-assetize-primary-gradient w-[120px] "
                        onClick={handleClick}
                        role="button"
                    >
                        <FilledWallet /> <span className="text-[14px] text-assetize-primary">{ctaTitle}</span>
                    </div>
                </div>
            </div>

            {action && <WalletWithdrawal closeModal={() => setAction(false)} walletBalance={item.balance} />}

            <ModalPanel open={openKycModal} closeButton closeModal={() => setOpenKycModal(false)}>
                <KycModal onClose={() => setOpenKycModal(false)} />
            </ModalPanel>
        </>
    );
};

export default WalletCard;
