import { FilterButton } from "Components/elements/Button/Index";
import IconBox from "Components/elements/Display/IconBox";
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import { DashboardStyle, Option, investmentTypeOptions, propertyTypeOptions, statusOptions } from "types/data";
import React, { useEffect, useState } from "react";
import { CiBoxList, CiGrid41 } from "react-icons/ci";
import { BiSearch } from "react-icons/bi";
import { MdClose } from "react-icons/md";

interface Props {
    propertyType?: boolean;
    investmentType?: boolean;
    status?: boolean;
    value?: string;
    onChange?: (event: any) => void;
    showSearch?: boolean;
    isUnverified?: boolean;
    updatePropertyTypeFilter?: (value: string) => void;
    updateInvestmentTypeFilter?: (value: string) => void;
    updateSoldOutStatusFilter?: (value: string) => void;
    onSearch?: (value?: string) => void;
    closeModal: () => void;
}

const MobileFilter = ({
    propertyType,
    status,
    investmentType,
    value,
    isUnverified,
    onChange,
    updatePropertyTypeFilter,
    updateInvestmentTypeFilter,
    updateSoldOutStatusFilter,
    onSearch,
    showSearch,
    closeModal
}: Props) => {
    const [search, setSearch] = useState("");
    const [showMobilesreach, setShowMobilesreach] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState({
        propertyType: { name: "Property Type" },
        investmentType: { name: "Investment Type" },
        status: { name: "Status" }
    });

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));

        if (optionName === "propertyType") {
            if (updatePropertyTypeFilter) {
                updatePropertyTypeFilter(option.name);
            }
            closeModal();
        }
        if (optionName === "investmentType") {
            if (updateInvestmentTypeFilter) {
                updateInvestmentTypeFilter(option.name);
            }
            closeModal();
        }
        if (optionName === "status") {
            if (updateSoldOutStatusFilter) {
                updateSoldOutStatusFilter(option.name);
            }
            closeModal();
        }
    };

    return (
        <div className="w-full">
            <div className="flex flex-col items-center mt-5 w-full justify-between">
                {showSearch && (
                    <div className="w-full my-3 md:hidden flex">
                        <ActiveSearch
                            bg="white"
                            border="border-assetize-gray"
                            value={value}
                            showOnMobile
                            onChange={onChange}
                            placeholder="Search here..."
                            onSearch={(value) => {
                                if (onSearch) {
                                    onSearch(value);
                                }
                            }}
                        />
                    </div>
                )}

                {propertyType && (
                    <div className=" flex md:hidden w-full my-3">
                        <Select
                            options={propertyTypeOptions}
                            selected={selectedOptions.propertyType}
                            onChange={(option) => handleOptionChange("propertyType", option)}
                            placeholder="Property Type"
                        />
                    </div>
                )}
                {investmentType && (
                    <div className=" md:hidden flex w-full my-3">
                        <Select
                            options={investmentTypeOptions}
                            selected={selectedOptions.investmentType}
                            onChange={(option) => handleOptionChange("investmentType", option)}
                            placeholder="Investment Type"
                        />
                    </div>
                )}
                {status && (
                    <div className="md:hiddenflex w-full my-3">
                        <Select
                            options={statusOptions}
                            selected={selectedOptions.status}
                            onChange={(option) => handleOptionChange("status", option)}
                            placeholder="Status"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileFilter;
