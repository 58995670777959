/* eslint-disable no-useless-catch */
import { kycEndpoint } from "Utils/Helpers";
import axiosInstance from "Utils/Https";
import { TUser, kycPayloadType } from "types/Auth.types";
import store from "store";

type TSignInRequest = { email: string; password: string };
type TSigninResponse = {
    accessToken: string;
    refreshToken: string;
    user: TUser;
};

type TVerifyOTPRequest = { emailAddress: string; otp: string };
type TResendOTPRequest = { emailAddress: string };

type BvnPayload = {
    documentType: string;
    documentNumber: string;
    consent: boolean;
};

export const UserSignInApi = async (payload: TSignInRequest): Promise<TSigninResponse> => {
    try {
        const url = "auth/sign-in";
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const CreateNewAccountApi = async () => {};

export const VerifyUserByOTPApi = async (payload: TVerifyOTPRequest) => {
    try {
        const url = "auth/verify-otp";
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const ResendUserOTPApi = async (payload: TResendOTPRequest) => {
    try {
        const url = "auth/resend-otp";
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const ResendUserPasswordResetOTPApi = async (emailAddress: string) => {
    try {
        const url = "auth/resend-forgot-password-otp";
        const res = await axiosInstance.post(url, { emailAddress });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const ResetUserPasswordApi = async (payload: any) => {
    const bearerToken = localStorage.getItem("atk");

    try {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;
        const url = "auth/change-password";
        const res = await axiosInstance.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const AdminLoginApi = async () => {
    try {
        const url = "/admin/login";
        const res = await axiosInstance.post(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const kycVerification = async (payload: kycPayloadType) => {
    try {
        const res = await axiosInstance.post(kycEndpoint(payload.documentType), payload);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const VerifyBvn = async (payload: BvnPayload) => {
    try {
        const url = "/kyc/verify/bvn";
        const res = await axiosInstance.post(url, payload);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const developersKycVerification = async (formData: any) => {
    const bearerToken = localStorage.getItem("atk");

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;
    // axiosInstance.defaults.headers.
    try {
        const url = "developer/upload-verification-documents";
        const res = await axiosInstance.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const logoutUser = async () => {
    try {
        const url = "auth/logout";
        // await axiosInstance.post(url)
        store.set("isLoggedIn", false);
        store.set("rtk", "");
        store.set("atk", "");

        const currentLocation = window.location;

        currentLocation.reload();
        return true;
    } catch (err) {
        throw err;
    }
};

export const DeactivateUser = async () => {
    try {
        const url = "auth/deactivate-account";
        const res = await axiosInstance.put(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};
