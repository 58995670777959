import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import TransactionCard from "Pages/Wallets/TransactionCard";
import React, { useEffect, useState } from "react";
import Pagination from "Components/elements/Pagination";
import { Option, dateFilter, joinedFilter } from "types";
import { GetAllDevelopers, GetAllInvestors, GetProperties } from "Services/apis/admin.api";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { NotFoundImg } from "Assets/Index";
import { Link } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { RootState } from "redux/store";
import { DeveloperType } from "types/Auth.types";
import { filterDataByDateRange, getShortCodeDate, searchArray, sortByRange } from "Utils/Helpers";
import UserCard from "./UserCard";
import { HiOutlineRefresh } from "react-icons/hi";

// import PropertyCard from './PropertyCard';

const User = () => {
    const [search, setSearch] = useState("");
    const [refreshing, setRefreshing] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [investors, setInvestors] = useState<DeveloperType[]>([]);
    const [developers, setDevelopers] = useState<DeveloperType[]>([]);
    const [displayList, setDisplayList] = useState<DeveloperType[]>([]);
    const [loading, setLoading] = useState(false);
    const { developersRequest } = useSelector((state: RootState) => state.admin);
    const [selected, setSelected] = useState("developers");
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [devPageData, setDevPageData] = useState<any>();
    const [invPageData, setInvPageData] = useState<any>();
    const [devPageNum, setDevPageNum] = useState<number>(1);
    const [invPageNum, setInvPageNum] = useState<number>(1);

    const [selectedOptions, setSelectedOptions] = useState({
        joinedFilter: { name: "All" },
        dateFilter: { name: "" }
    });

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    useEffect(() => {
        const initialDateFilter = getShortCodeDate();
        setSelectedOptions((prevOptions) => ({ ...prevOptions, dateFilter: initialDateFilter }));
    }, []);

    useEffect(() => {
        const sortedData = sortByRange(selectedOptions.joinedFilter.name, investors, "createdAt");

        if (sortedData) {
            setDisplayList(sortedData);
        }
    }, [selectedOptions.joinedFilter.name]);

    useEffect(() => {
        const filteredData = filterDataByDateRange(developers, selectedOptions.dateFilter);

        if (filteredData) {
            setDisplayList(filteredData);
        }
    }, [selectedOptions.dateFilter.name]);

    useEffect(() => {
        if (selected === "developers") {
            setDisplayList(developers);
        } else {
            setDisplayList(investors);
        }
    }, [selected]);

    useEffect(() => {
        let searchResult: any[] = [];
        if (selected === "develpers") {
            searchResult = searchArray(developers, search);
        } else {
            searchResult = searchArray(investors, search);
        }
        setDisplayList(searchResult);
    }, [search]);

    useEffect(() => {
        const fetchUsersRequest = async () => {
            setRefreshing(true);
            setLoading(true);
            try {
                const res = await Promise.all([GetAllDevelopers(devPageNum), GetAllInvestors(invPageNum)]);
                setDevelopers(res[0].cleanedUsers);
                setDevPageData(res[0].metadata);
                setInvestors(res[1].cleanedUsers);
                setInvPageData(res[1].metadata);
                if (selected === "developers") {
                    setDisplayList(res[0].cleanedUsers);
                } else {
                    setDisplayList(res[1].cleanedUsers);
                }
                setRefreshPage(false);
            } catch (error: any) {
                setLoading(false);
                setRefreshPage(false);
            } finally {
                setLoading(false);
                setRefreshing(false);
                setRefreshPage(false);
            }
        };
        fetchUsersRequest();
    }, [invPageNum, devPageNum, refreshPage]);

    let content;
    if (loading) {
        content = (
            <div className="flex items-center justify-center mt-64">
                <BounceLoader color="#ca1611" />
            </div>
        );
    } else if (displayList.length === 0) {
        content = (
            <div>
                <div className="flex flex-col items-center mt-20 justify-center h-full space-y-4 no-item">
                    <img src={NotFoundImg} alt="" />
                    <p className="text-base font-semibold">No user registered!</p>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="bg-white mt-2 py-2 rounded">
                <div className="md:flex hidden justify-between">
                    <h3 className="uppercase w-[20%] flex justify-start font-latoLight pl-8 text-sm">id</h3>
                    <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">user</h3>
                    <h3 className="uppercase w-[30%] flex justify-start font-latoLight text-sm">email</h3>
                    <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">created on</h3>
                    <h3 className="uppercase w-[10%] flex justify-start font-latoLight text-sm">action</h3>
                </div>
                {displayList.map((item: DeveloperType) => (
                    <UserCard key={item.id} item={item} />
                ))}
                <div className="mt-5 flex justify-end">{/* <Pagination /> */}</div>
            </div>
        );
    }

    const handleSelected = (option: string) => {
        setSelected(option);

        if (option === "developers") {
            setDisplayList(developers);
        } else {
            setDisplayList(investors);
        }
    };

    return (
        <div>
            <div className="border border-assetize-gray rounded px-2 mt-6 bg-white flex justify-end items-center">
                <div className="items-center flex-[.6] flex justify-between">
                    <div className=" flex-1  mx-1 py-2">
                        <ActiveSearch
                            bg="white"
                            border="border-aasetize-gray"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search here..."
                        />
                    </div>
                    <div className="border-r border-assetize-gray mx-1 h-15" />
                    <div className="flex-[0.5] mx-1 py-2">
                        <Select
                            options={joinedFilter}
                            selected={selectedOptions.joinedFilter}
                            onChange={(option) => handleOptionChange("joinedFilter", option)}
                            placeholder="Newest"
                        />
                    </div>
                    <div className="flex-[0.7] mx-1 py-2">
                        <Select
                            options={dateFilter}
                            selected={selectedOptions.dateFilter}
                            onChange={(option) => handleOptionChange("dateFilter", option)}
                            placeholder="Jan 01 - January 31"
                        />
                    </div>
                    <HiOutlineRefresh
                        className={`w-6 h-6 text-assetize-dark ${refreshing ? "animate-spin" : ""}`}
                        onClick={() => {
                            if (!refreshing) {
                                setRefreshPage(true);
                            }
                        }}
                    />
                </div>
            </div>
            <div className="w-[70%] border-b border-assetize-gray flex mt-5">
                <div onClick={() => handleSelected("developers")} role="button" tabIndex={0} onKeyDown={() => handleSelected("developers")}>
                    <p
                        className={`${
                            selected === "developers"
                                ? "text-assetize-primary border-b  border-assetize-primary "
                                : "text-assetize-dark-gray border-0"
                        } text-lg px-5`}
                    >
                        Developers
                    </p>
                </div>
                <div
                    onClick={() => handleSelected("investors")}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => handleSelected("investors")}
                    className="ml-5"
                >
                    <p
                        className={`${
                            selected !== "developers"
                                ? "text-assetize-primary border-b border-assetize-primary "
                                : "text-assetize-dark-gray border-0"
                        } text-lg px-5`}
                    >
                        Investors
                    </p>
                </div>
            </div>
            {content}
            <div className="mt-5 flex justify-end">
                {selected === "developers" ? (
                    <Pagination pageData={devPageData} setPageNum={setDevPageNum} />
                ) : (
                    <Pagination pageData={invPageData} setPageNum={setInvPageNum} />
                )}
            </div>
        </div>
    );
};

export default User;
