/* eslint-disable react/react-in-jsx-scope */
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import Pagination from "Components/elements/Pagination";
import { GetTransactionHistoryApi, GetWalletUsdApi, SafelockWalletApi, getCardTransactionStatusApi } from "Services";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import { Option, WalletTransactionProps, Walletprops, dateFilter, safeLockProps, transactionFilter } from "types/data";
import BuyTokens from "./BuyTokens";
import FundWallet from "./FundWallet";
import Safelock from "./Safelock";
import TransactionCard from "./TransactionCard";
import WalletCard from "./WalletCard";
import { useParams, useSearchParams } from "react-router-dom";
import FundSuccess from "./FundSuccess";
import FundFailure from "./FundFailure";
import ModalPanel from "Components/elements/Modal/Index";
import {
    filterDataByDateRange,
    filterDataByDateRangeWithUpdatedAt,
    getErrorMessge,
    getShortCodeDate,
    searchArray,
    sortByTransactionType
} from "Utils/Helpers";
import { NotFoundImg } from "Assets/Index";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import KycModal from "Layouts/Kyc/KycModal";

const Wallets = () => {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useSelector((state: any) => state.auth);
    const [usdWallets, setUsdWallets] = useState<Walletprops>({
        walletId: 0,
        walletAddress: "",
        type: "",
        userId: 0,
        signedHash: "",
        balance: 0,
        tierLevel: null,
        createdAt: "",
        updatedAt: "",
        totalBalance: 0
    });
    const [safeLockWallet, setSafeLockWallet] = useState<safeLockProps>({
        balance: 0,
        totalBalance: 0,
        createdAt: "",
        signedHash: "",
        tierLevel: "",
        type: "",
        updatedAt: "",
        userId: 0,
        walletAddress: "",
        walletId: 0,
        withdrawableBalance: 0
    });
    const walletKeys = Object.keys(usdWallets) as (keyof Walletprops)[];
    const [walletActions, setWalletActions] = useState<Record<string, boolean>>({});
    const [cardPaymentFeedback, setCardPaymentFeedback] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [transactionHistory, setTransactionHistory] = useState<any>();
    const [displayList, setDisplayList] = useState<any>([]);
    const [selectedOptions, setSelectedOptions] = useState({
        transactionFilter: { name: "All" },
        dateFilter: { name: "" }
    });
    const [pageData, setPageData] = useState<any>();
    const [pageNum, setPageNum] = useState<number>(1);
    const [pockets, setPockets] = useState<any>();
    const handleWalletAction = (action: string) => {
        setWalletActions({ ...walletActions, [action]: true });
    };

    const closeWalletAction = () => {
        setWalletActions({});
    };

    const closeCardPaymentFeedbackAction = () => {
        setIsModalOpen(true);
    };

    const handleContinue = () => {
        setIsModalOpen(false);
        setCardPaymentFeedback("");
    };

    const handleOptionChange = (optionName: string, option: Option) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    const getWalletTransactions = async () => {
        try {
            const response = await Promise.all([GetWalletUsdApi(), GetTransactionHistoryApi(pageNum), SafelockWalletApi()]);
            const usdWalletsData = response[0];
            const transactionHistoryData = response[1];
            const safeLockWallet = response[2];
            setSafeLockWallet(safeLockWallet.userSafeLockWallet);
            setPockets(safeLockWallet.userSafeLockPockets);
            setUsdWallets(usdWalletsData);
            setPageData(transactionHistoryData.metadata);
            setTransactionHistory(transactionHistoryData.walletTransactions);
            setDisplayList(transactionHistoryData.walletTransactions);
        } catch (error) {
            // const err = getErrorMessge(error);
            // toast.error(err);
        } finally {
            setLoading(false);
        }
    };

    const getSingleCardTransactionStatus = async (reference: string) => {
        try {
            const response = await getCardTransactionStatusApi(reference);

            setCardPaymentFeedback(response.data.status);
        } catch (error) {
            // const err = getErrorMessge(error);
            // toast.error(err);
        } finally {
            setLoading(false);
        }
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const reference = searchParams.get("trxref");

    useEffect(() => {
        const initialDateFilter = getShortCodeDate();
        setSelectedOptions((prevOptions) => ({ ...prevOptions, dateFilter: initialDateFilter }));
    }, []);

    useEffect(() => {
        getWalletTransactions();

        if (reference) {
            getSingleCardTransactionStatus(reference);
        }
    }, [reference]);

    useEffect(() => {
        const getTransactions = async () => {
            try {
                const res = await GetTransactionHistoryApi(pageNum);

                setPageData(res.metadata);
                setTransactionHistory(res.walletTransactions);
                setDisplayList(res.walletTransactions);
            } catch (error) {
                // const err = getErrorMessge(error);
                // toast.error(err);
            }
        };

        getTransactions();
    }, [pageNum]);

    useEffect(() => {
        const searchData = searchArray(transactionHistory, search);
        setDisplayList(searchData);
    }, [search]);

    useEffect(() => {
        const transactionType = sortByTransactionType(selectedOptions?.transactionFilter?.name, transactionHistory);
        setDisplayList(transactionType);
    }, [selectedOptions?.transactionFilter]);

    useEffect(() => {
        const transactionType = filterDataByDateRangeWithUpdatedAt(transactionHistory, selectedOptions?.dateFilter);
        setDisplayList(transactionType);
    }, [selectedOptions.dateFilter]);

    return (
        <>
            <div>
                {loading ? (
                    <div className="flex items-center justify-center mt-64">
                        <BarLoader color="#ca1611" />
                    </div>
                ) : (
                    <div>
                        {/* <div className="grid grid-cols-1 my-8 gap-y-8 lg:grid-cols-3 lg:gap-x-8"> */}
                        <div className="flex w-full flex-col md:flex-row md:p-4 items-center ">
                            <div className="md:pr-3 md:my-3  w-full md:w-fit ">
                                <WalletCard item={usdWallets} walletKeys={walletKeys} ctaAction={handleWalletAction} />
                            </div>
                            <div className=" w-full md:w-fit mt-4 md:mt-0">
                                <WalletCard
                                    pockets={pockets}
                                    item={safeLockWallet}
                                    walletKeys={walletKeys}
                                    title="Safe Lock"
                                    ctaTitle="View Safelock"
                                    ctaAction={handleWalletAction}
                                    slug="safelock"
                                />
                            </div>
                        </div>
                        {/* </div> */}
                        <div className="lg:flex lg:items-center lg:justify-center lg:px-2 mt-6 lg:bg-white lg:border lg:rounded lg:border-assetize-gray">
                            <div className="flex-[.4] py-2">
                                <h3 className="text-[#000] text-[18px] lg:text-[20px] font-latoBold">Transactions</h3>
                            </div>
                            <div className="hidden flex-[.6] lg:flex justify-between">
                                <div className="flex-1 py-2 mx-1 ">
                                    <ActiveSearch
                                        bg="white"
                                        border="border-assetize-gray"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Search here..."
                                    />
                                </div>
                                <div className="mx-1 border-r border-assetize-gray h-14" />
                                <div className="flex-[0.5] mx-1 py-2">
                                    <Select
                                        options={transactionFilter}
                                        selected={selectedOptions.transactionFilter}
                                        onChange={(option) => handleOptionChange("transactionFilter", option)}
                                        placeholder="All"
                                    />
                                </div>
                                <div className="flex-[0.7] mx-1 py-2">
                                    <Select
                                        options={dateFilter}
                                        selected={selectedOptions.dateFilter}
                                        onChange={(option) => handleOptionChange("dateFilter", option)}
                                        placeholder="Jan 01 - January 31"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="lg:py-2 lg:mt-2 bg-white rounded ">
                            <div className="hidden md:flex items-center justify-between w-full px-4">
                                <h3 className="w-1/5 text-[14px] font-normal uppercase flex justify-start text-[#625A67]">details</h3>
                                <h3 className="w-1/5 text-[14px] font-normal uppercase flex justify-start text-[#625A67] ml-2">Date</h3>
                                <h3 className="w-1/5 text-[14px] font-normal uppercase flex justify-start text-[#625A67] mr-5">
                                    usd/token value
                                </h3>
                                <h3 className="w-1/5 text-[14px] font-normal uppercase justify-start text-[#625A67] mr-3 flex-[.1] ">
                                    status
                                </h3>
                                <h3 className="w-1/5 flex-[.1] font-normal uppercase justify-start text-[14px] text-[#625A67]">action</h3>
                            </div>
                            {displayList?.length === 0 && (
                                <div className="flex flex-col items-center justify-center h-full mt-20 space-y-4 no-item">
                                    <img src={NotFoundImg} alt="" className="w-20" />
                                    <p className="text-base font-semibold">No Transaction History</p>
                                </div>
                            )}
                            {displayList?.map((item: any) => (
                                <TransactionCard key={item.transactionId} item={item} selectedOptions={selectedOptions} />
                            ))}
                        </div>
                        <div className="flex justify-end mt-5">
                            <Pagination pageData={pageData} setPageNum={setPageNum} />
                        </div>
                    </div>
                )}
            </div>

            {walletActions.fund && <FundWallet closeModal={closeWalletAction} />}
            <ModalPanel closeModal={closeCardPaymentFeedbackAction} open={cardPaymentFeedback === "success"} closeButton>
                <FundSuccess onContinue={handleContinue} />
            </ModalPanel>
            {cardPaymentFeedback && cardPaymentFeedback === "failed" && (
                <ModalPanel closeModal={closeCardPaymentFeedbackAction} open={cardPaymentFeedback === "failed"} closeButton>
                    <FundFailure onContinue={handleContinue} />
                </ModalPanel>
            )}
        </>
    );
};

export default Wallets;
