import axiosInstance from "Utils/Https";

export const GetSettingsApi = async () => {
    const url = "auth/profile";
    const res = await axiosInstance.get(url);
    return res.data;
};

export const UpdateSettingsApi = async (payload: any) => {
    const url = "/auth/profile/update";
    const res = await axiosInstance.patch(url, payload);
    return res.data;
};
