/* eslint-disable react/react-in-jsx-scope */
import AuthHoc from "Components/AuthWrapper";
import AuthLayout from "Layouts/AuthLayout/Index";
import DashboardLayout from "Layouts/DashboardLayout/Index";
import AccountSettings from "Pages/AccountSettings";
import ForgotPassword from "Pages/Auth/ForgotPassword";
import Login from "Pages/Auth/Login";
import ResetPassword from "Pages/Auth/ResetPassword";
import Signup from "Pages/Auth/Signup/index";
import Verification from "Pages/Auth/Verification";
import Welcome from "Pages/Auth/Welcome";
import Dashboard from "Pages/appcore/invest/property";
import Invest from "Pages/Invest";
import Learn from "Pages/Learn";
import LearnDetails from "Pages/Learn/LearnDetails";
import Wallets from "Pages/Wallets";
import AdminDashboard from "Pages/appcore/admin";
import Authentication from "Pages/appcore/admin/Authentication/index";
import Properties from "Pages/appcore/admin/Property/index";
import Support from "Pages/appcore/admin/Support";
import Users from "Pages/appcore/admin/Users/index";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import DeveloperDashboard from "Pages/appcore/developer/property";
import CreateProperty from "Pages/appcore/developer/property/CreateProperty";
import DeveloperDetails from "Pages/appcore/admin/Authentication/DeveloperDetails";
import UserDetail from "Pages/appcore/admin/Users/UserDetails";
import DeveloperAnalytics from "Pages/appcore/developer/analytics";
import DeveloperOrder from "Pages/appcore/developer/order";
import DeveloperWallets from "Pages/appcore/developer/wallets";
import AdminPropertyDetails from "Pages/appcore/admin/Property/PropertyDetails";
import AdminLearn from "Pages/appcore/admin/Learn";
import AdminLearnDetails from "Pages/appcore/admin/Learn/LearnDetails";
import EditPost from "Pages/appcore/admin/Learn/EditPost";
import Portfolio from "Pages/appcore/invest/portfolio";
import DeveloperPropertyDetail from "Pages/appcore/developer/property/PropertyDetail";
import Buyshares from "Pages/appcore/invest/property/BuyShares";
import InvestPropertyDetails from "Pages/appcore/invest/property/propertyDetails";
import NotificationList from "Pages/Notification";
import PropertyDetails from "Pages/Invest/PropertyDetails";
import EditProperty from "Pages/appcore/developer/property/EditProperty";
import Transactions from "Pages/appcore/developer/transactions";
import InvestmentPortfolioDetails from "Pages/appcore/invest/portfolio/PortfolioDetails";
import CreatePost from "Pages/appcore/admin/Learn/CreatePost";
import SafeLockPocket from "Pages/Wallets/SafeLockPocket";

export const DashboardRoutes = {
    path: "/",
    element: <AuthHoc component={DashboardLayout} />,
    children: [
        {
            path: "admin",
            children: [
                { index: true, element: <AdminDashboard /> },
                {
                    path: "properties",
                    children: [
                        { index: true, element: <Properties /> },
                        { path: ":id", element: <AdminPropertyDetails /> }
                    ]
                },
                {
                    path: "authentication",
                    children: [
                        { index: true, element: <Authentication /> },
                        { path: ":id", element: <DeveloperDetails /> }
                    ]
                },
                {
                    path: "users",
                    children: [
                        { index: true, element: <Users /> },
                        { path: ":id", element: <UserDetail /> }
                    ]
                },
                {
                    path: "learn",
                    children: [
                        { index: true, element: <AdminLearn /> },
                        { path: ":id", element: <AdminLearnDetails /> },
                        { path: "create", element: <CreatePost /> },
                        { path: "edit/:id", element: <EditPost /> }
                    ]
                },
                { path: "support", element: <Support /> }
            ]
        },
        {
            path: "developer",
            children: [
                { index: true, element: <DeveloperDashboard /> },
                { path: "create-property", element: <CreateProperty /> },
                {
                    path: "edit-property",
                    children: [
                        {
                            path: ":id",
                            element: <EditProperty />
                        }
                    ]
                },
                { path: ":id", element: <DeveloperPropertyDetail /> },
                {
                    path: "learn",
                    children: [
                        { index: true, element: <Learn /> },
                        {
                            path: ":id",
                            element: <LearnDetails />
                        }
                    ]
                },
                { path: "reports", element: <DeveloperAnalytics /> },
                { path: "orders", element: <DeveloperOrder /> },
                { path: "wallets", element: <DeveloperWallets /> },
                { path: "transactions", element: <Transactions /> }
            ]
        },
        {
            path: "investor",
            children: [
                { index: true, element: <Dashboard /> },
                { path: ":id", element: <InvestPropertyDetails /> },
                {
                    path: "portfolio",
                    children: [
                        {
                            index: true,
                            element: <Portfolio />
                        },
                        {
                            path: ":id",
                            element: <InvestmentPortfolioDetails />
                        }
                    ]
                },
                {
                    path: "buy-shares/:id",
                    element: <Buyshares />
                },
                {
                    path: "investment",
                    element: <Invest />
                },
                {
                    path: "investment/:id",
                    element: <InvestPropertyDetails />
                },
                {
                    path: "wallets",
                    element: <Wallets />
                },
                {
                    path: "wallets/safelock",
                    element: <SafeLockPocket />
                },
                {
                    path: "learn",
                    children: [
                        { element: <Learn />, index: true },

                        {
                            path: ":id",
                            element: <LearnDetails />
                        }
                    ]
                }
            ]
        },
        {
            path: "account-settings",
            element: <AccountSettings />
        },
        {
            path: "notifications",
            element: <NotificationList />
        }
    ]
};

export const AutheticationRoutes = {
    path: "/auth",
    element: <AuthLayout />,
    children: [
        {
            path: "/auth/welcome",
            element: <Welcome />
        },
        {
            path: "/auth/login/:deactivate?",
            element: <Login />
        },
        {
            path: "/auth/signup",
            element: <Signup />
        },
        {
            path: "/auth/reset-password",
            element: <ResetPassword />
        },
        {
            path: "/auth/forgot-password",
            element: <ForgotPassword />
        },
        {
            path: "/auth/verification",
            element: <Verification />
        },
        {
            path: "*",
            element: <Navigate to="/auth/login" replace />
        }
    ]
};
