import { NotFoundImg } from "Assets/Index";
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

type Props = {
    data: any;
};

const LineChart = ({ data }: Props) => {
    if (
        !data ||
        typeof data !== "object" ||
        Object.keys(data).length === 0 ||
        Object.values(data).length === 0 ||
        Object.values(data).every((val) => val === 0) ||
        Object.values(data).every((val) => val === null) ||
        Object.values(data).every((val) => val === undefined)
    ) {
        return (
            <div className="flex flex-col justify-center items-center py-10">
                <img src={NotFoundImg} alt="" />
                <p className="text-base font-semibold">No Data Available!</p>
            </div>
        );
    }
    const monthlyData = Object.values(data) as number[];
    const max = Math.max(...monthlyData);
    const verticalChartData: ApexOptions = {
        chart: {
            id: "vertical-bar",
            toolbar: {
                show: false
            },
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800
            }
        },
        yaxis: {
            show: true,
            min: 0,
            max: max + max * 0.1,
            tickAmount: 7,
            labels: {
                formatter(val: any) {
                    if (typeof val === "number") {
                        return `$${val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                    }
                    return "$0"; // Return 0 when no value is available
                }
            }
        },
        xaxis: {
            categories: Object.keys(data).map((month) => month.charAt(0).toUpperCase() + month.slice(1))
        },
        colors: ["#34A853"],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            colors: ["#34A853"],
            opacity: 0.2
        }
    };

    const horizontalChartData: ApexOptions = {
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false
            },
            animations: {
                enabled: true,
                easing: "easeinout",
                speed: 800
            }
        },
        xaxis: {
            categories: Object.keys(data).map((month) => month.charAt(0).toUpperCase() + month.slice(1))
        },
        yaxis: {
            show: true,
            min: 0,
            max: max + max * 0.1,
            tickAmount: 7,
            labels: {
                formatter(val: any) {
                    if (typeof val === "number") {
                        return `$${val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                    }
                    return "$0"; // Return 0 when no value is available
                }
            }
        },

        colors: ["#34A853"],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "smooth" // Make the line curved
        },
        fill: {
            type: "solid",
            colors: ["#34A853"], // Set the line color
            opacity: 0.2 // Set the background color opacity to 20%
        }
    };

    const verticalSeries = [
        {
            name: "Revenue",
            data: monthlyData
        }
    ];

    const horizontalSeries = [
        {
            name: "Revenue",
            data: monthlyData
        }
    ];

    return (
        <div>
            <div className="lg:flex">
                {/* Conditionally render vertical or horizontal chart */}
                <div className="hidden md:flex w-full">
                    <div className="vertical-chart w-full ">
                        <ReactApexChart options={verticalChartData} series={verticalSeries} type="area" height={350} />
                    </div>
                </div>
                <div className="md:hidden w-full">
                    <div className="horizontal-chart w-full">
                        <ReactApexChart options={horizontalChartData} series={horizontalSeries} type="area" height={350} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LineChart;
