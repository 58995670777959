/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-nested-ternary */
import { MainButton } from "Components/elements/Button/Index";
import AuthLogoDisplay from "Components/elements/Display/AuthLogoDisplay";
import CheckBox from "Components/elements/Forms/CheckBox";
import Input from "Components/elements/Forms/Input";
import Password from "Components/elements/Forms/Password";
import { UserSignInApi } from "Services";
import { validateAll } from "indicative/validator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { loginSuccess } from "redux/Reducers/AuthReducer";
import store from "store";

type TErrorResponse = {
    response: {
        data: {
            message: string;
        };
    };
};

const Login = () => {
    const navigate = useNavigate();
    const { deactivate } = useParams();

    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    const [formData, setFormData] = useState<any>({
        email: "",
        password: "",
        remember: false
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const rememberedEmail = localStorage.getItem("rememberedEmail");
    useEffect(() => {
        if (rememberedEmail) {
            setFormData({
                ...formData,
                email: rememberedEmail
            });
        }
    }, [rememberedEmail]);

    const textClass = isActive ? "text-purple-500" : "text-assetize-primary";

    const handleInputChange = (name: string, value: any) => {
        if (name === "remember") {
            setFormData((prevState: any) => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setFormData((prevState: any) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const { email, password, remember } = formData;
        try {
            setLoading(true);
            const rules = {
                email: "required|email",
                password: "required|string|min:6"
            };

            const messages = {
                "email.email": "Please enter a valid email address",
                "password.min": "Password must be at least 6 characters long"
            };

            const data = {
                email,
                password
            };

            await validateAll(data, rules, messages);
            setErrorMessage("");
            const res = await UserSignInApi(data);
            setLoading(false);
            dispatch(loginSuccess(res?.user));
            store.set("isLoggedIn", true);

            store.set("atk", res.accessToken);

            if (remember) {
                localStorage.setItem("rememberedEmail", email);
            } else {
                localStorage.removeItem("rememberedEmail");
            }

            const routeTo = deactivate
                ? "/account-settings"
                : res.user.accountType === "developer"
                ? "/developer"
                : res.user.accountType === "investor"
                ? "/investor"
                : res.user.accountType === "admin"
                ? "/admin"
                : "/";

            navigate(routeTo);
        } catch (error: TErrorResponse | any) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
                toast.error(error.response.data.message);
                setTimeout(() => {
                    setErrorMessage("");
                }, 6000);
            } else {
                setErrorMessage("Something went wrong. ensure you have good internet connection!");
                toast.error("Something went wrong. ensure you have good internet connection!");
                setTimeout(() => {
                    setErrorMessage("");
                }, 6000);
            }
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center w-full mt-16 font-latoRegular">
            <AuthLogoDisplay />
            <div className="mt-5">
                <h2 className="mt-5 mb-4 text-2xl text-center text-assetize-primary lg:text-3xl font-latoBold align-center lg:mt-9">
                    Welcome Back
                </h2>
                <p className="mb-5 text-center text-assetize-dark-gray text-[14px] align-center md:text-md">
                    Login to continue using Assetize
                </p>
            </div>

            <form autoComplete="off" className="w-full md:w-[90%] lg:w-[60%] mt-2" onSubmit={handleSubmit}>
                {errorMessage && (
                    <div className="p-3 text-center text-assetize-primary">
                        {errorMessage}
                        <div style={{ animation: "fadeOut 5s" }} onAnimationEnd={() => setErrorMessage("")} />
                    </div>
                )}
                <div className="">
                    <Input
                        label="Email Address"
                        type="email"
                        name="email"
                        value={formData.email}
                        className="focus:bg-[#F59999]/30 focus:ring-assetize-primary focus:border-assetize-primary border-1 rounded-md"
                        placeholder="Enter email address"
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="py-2">
                    <Password
                        label="Password"
                        name="password"
                        value={formData.password}
                        placeholder="Enter password"
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>

                <div className="flex items-center justify-between py-2">
                    <CheckBox
                        name="remember"
                        onChange={(checked: boolean) => handleInputChange("remember", checked)}
                        label="Remember me"
                        error=""
                    />
                    <Link
                        to="/auth/forgot-password"
                        className={`cursor-pointer underline hover:text-black font-latoRegular font-bold hover:no-underline ${textClass}`}
                        onClick={toggleActive}
                    >
                        Forgot Password
                    </Link>
                </div>
                <div className="pt-16 md:py-3">
                    <MainButton
                        isLoading={loading}
                        disabled={loading}
                        type="submit"
                        className={`${loading && "cursor-not-allowed bg-red-200"}`}
                    >
                        Login
                    </MainButton>
                </div>
                <div className="flex justify-center py-2">
                    <p className="text-sm font-latoRegular align-center">
                        <span className="text-assetize-dark-gray ">Don&apos;t have an account? </span>
                        <Link
                            to="/auth/welcome"
                            className={`cursor-pointer hover:text-black font-latoRegular font-bold hover:no-underline ${textClass}`}
                            onClick={toggleActive}
                        >
                            Sign Up
                        </Link>
                    </p>
                </div>
            </form>
        </div>
    );
};

export default Login;
