import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useCurrentPath = () => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        const pathSegments = location.pathname.split("/");
        const lastSegment = pathSegments[pathSegments.length - 1];

        setCurrentPath(lastSegment);
    }, [location]);

    return currentPath;
};

export default useCurrentPath;
