import React, { useEffect, useState } from "react";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import AboutBox from "Components/elements/Display/AboutBox";
import GoBack from "Components/elements/Display/GoBack";
import Input from "Components/elements/Forms/Input";
import {
    ActivateAccount,
    DeleteDeveloper,
    DeleteInvestor,
    GetDevelpersDetails,
    GetSingleDeveloper,
    GetSingleInvestor
} from "Services/apis/admin.api";
import { filterDataByDateRange, getShortCodeDate, readableDate, searchArray, sortByRange } from "Utils/Helpers";
import { FiDownload, FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "redux/store";
import MenuList, { Option } from "Components/elements/Menu";
import { ChevronDown, Key, NotFoundImg, UserIcon, caretVerticalDown } from "Assets/Index";
import { OptionsProps, dateFilter, joinedFilter, Option as SelectOption } from "types/data";
import { addDeveloperData, addDeveloperKycData } from "redux/slices/admin.slice";
import ModalPanel from "Components/elements/Modal/Index";
import ActiveSearch from "Components/elements/Forms/ActiveSearch";
import Select from "Components/elements/Forms/Select";
import PropertyCard from "./PropertyCard";
import { DeactivateUser } from "Services";
import { toast } from "react-toastify";
// import AnswerModal from './AsnwerModal';

const UserDetail = () => {
    const { id } = useParams();
    const { state } = useLocation();

    const { actionStatus, active, createdAt, id: userId, accountType, metaData, emailAddress, firstName, lastName } = state;
    const { developerData, kycDocuments } = useSelector((state: any) => state.admin);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [deactivateUser, setDeactivateUser] = useState(false);

    const [userRecord, setUserRcord] = useState({});
    const [userProperties, setUserProperties] = useState([]);
    const [showModal, setSHowModal] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });
    const [modalData, setModalData] = useState({
        image: "",
        heading: ""
    });
    const [reactivateIsLoading, setReactivateIsLoading] = useState(false);
    const [deleteIsLoading, setDeleteIsLoading] = useState(false);
    const [displayList, setDisplayList] = useState([]);
    const [search, setSearch] = useState("");
    const [kycDocs, setKycDocs] = useState<any>();

    const options: OptionsProps[] = [
        { text: "Approve", value: "approve" },
        { text: "Reject", value: "reject" }
    ];

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "approve") {
            setShowReplyModal({
                open: true,
                action: "approve"
            });
        } else if (option?.value === "reject") {
            setShowReplyModal({
                open: true,
                action: "reject"
            });
        }
    };

    const [selectedOptions, setSelectedOptions] = useState({
        joinedFilter: { name: "All" },
        dateFilter: { name: "" }
    });

    const handleOptionChange = (optionName: string, option: SelectOption) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [optionName]: option
        }));
    };

    useEffect(() => {
        const initialDateFilter = getShortCodeDate();
        setSelectedOptions((prevOptions) => ({ ...prevOptions, dateFilter: initialDateFilter }));
    }, []);

    useEffect(() => {
        const sortedData = sortByRange(selectedOptions.joinedFilter.name, userProperties, "createdAt");

        if (sortedData) {
            setDisplayList(sortedData);
        }
    }, [selectedOptions.joinedFilter.name]);

    useEffect(() => {
        const filteredData = filterDataByDateRange(userProperties, selectedOptions.dateFilter);

        if (filteredData) {
            setDisplayList(filteredData);
        }
    }, [selectedOptions.dateFilter.name]);

    useEffect(() => {
        const searchResult = searchArray(userProperties, search);

        setDisplayList(searchResult);
    }, [search]);

    const getUser = async () => {
        let res;

        if (accountType === "developer") {
            res = await GetSingleDeveloper(userId?.toString(), 1);

            setUserRcord(res.fullerUserRecord);
            setUserProperties(res.listedPropertiesByDeveloper.propertiesWithGallery);
            setDisplayList(res.listedPropertiesByDeveloper.propertiesWithGallery);
            setKycDocs(res.kycDocs);
        } else {
            res = await GetSingleInvestor(userId?.toString(), 1);
            setUserRcord(res.fullerUserRecord);

            setUserProperties(res.investorPortfolio);
            setDisplayList(res.investorPortfolio);
        }
    };

    const handleActivateUser = async () => {
        try {
            setReactivateIsLoading(true);
            const res = await ActivateAccount({ email: emailAddress });

            toast.success("User reactivated successfully");
        } catch (error) {
            toast.error("Error reactivating user");
        } finally {
            setReactivateIsLoading(false);
        }
    };

    const handleDeactivateUser = async () => {
        try {
            setDeactivateUser(true);
            const res = await DeactivateUser();

            toast.success("User deactivated successfully");
        } catch (error) {
            toast.error("Error deactivating user");
        } finally {
            setDeactivateUser(false);
        }
    };

    useEffect(() => {
        if (userId) getUser();
    }, []);

    return (
        <div className="container p-5 bg-white mx-auto my-2 rounded">
            <GoBack link="/admin/users" />

            <div className="bg-assetize-primary-gradient p-10 flex items-center mt-5">
                <div className="flex w-full justify-between items-center">
                    <div className="flex items-center">
                        <div className="rounded-full border-2 border-assetize-primary bg-assetize-gray w-20 h-20 flex justify-center items-center">
                            <UserIcon className="w-[60px] text-[60px] text-white" />
                        </div>
                        <div className="pl-5">
                            <p className="font-latoBold text-sm  text-assetize-light-text">
                                {firstName} {lastName}
                            </p>
                            <p className="font-latoRegular text-sm   text-assetize-light-text">{emailAddress}</p>
                        </div>
                    </div>
                    <div className="w-[150px]">
                        {active ? (
                            <OutlineButton onClick={handleDeactivateUser} color="assetize-primary">
                                <div className="flex items-center justify-around">
                                    <Key />
                                    <p className="font-latoBold text-sm  text-assetize-assetize-primary">
                                        {deleteIsLoading ? "deactivating user..." : "Deactivate User"}
                                    </p>
                                </div>
                            </OutlineButton>
                        ) : (
                            <OutlineButton onClick={handleActivateUser} color="assetize-primary">
                                <div className="flex items-center justify-around">
                                    <Key />
                                    <p className="font-lastoBold text-sm  text-assetize-assetize-primary">
                                        {reactivateIsLoading ? "reactivating user..." : "Reactivate User"}
                                    </p>
                                </div>
                            </OutlineButton>
                        )}
                    </div>
                </div>
            </div>
            {accountType === "developer" && (
                <div className="border-t border-b border-l-0 border-r-0 border-assetize-primary-gradient ">
                    <div className="border-b py-4 border-assetize-gray flex justify-between items-center px-3">
                        <h4 className="font-latoBold text-sm  text-assetize-light-text">Incorporation Documents</h4>
                        {accountType === "developer" && (
                            <div className="w-[180px]">
                                <OutlineButton color="assetize-gray">
                                    <a
                                        href={kycDocs?.incorporationDocumentUrl}
                                        // className="flex items-center focus:text-assetize-dark justify-between px-4 py-4 rounded-lg cursor-pointer bg-[#ccc] text-assetize-dark hover:text-assetize-primary"
                                        key={kycDocs?.incorporationDocumentUrl}
                                        download={kycDocs?.incorporationDocumentUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="flex items-center justify-around">
                                            <FiEye className=" text-assetize-light-text" />
                                            <p className="font-latoBold text-sm  text-assetize-light-text">View document</p>
                                        </div>
                                    </a>
                                </OutlineButton>
                            </div>
                        )}
                    </div>

                    <div className="border-b py-4 border-assetize-gray flex justify-between items-center px-3">
                        <h4 className="font-latoBold text-sm  text-assetize-light-text">Company’s Profile Document</h4>
                        <div className="w-[180px]">
                            {accountType === "developer" && (
                                <OutlineButton color="assetize-gray">
                                    <a
                                        href={kycDocs?.companyProfileDocumentUrl}
                                        // className="flex items-center focus:text-assetize-dark justify-between px-4 py-4 rounded-lg cursor-pointer bg-[#ccc] text-assetize-dark hover:text-assetize-primary"
                                        key={kycDocs?.companyProfileDocumentUrl}
                                        download={kycDocs?.companyProfileDocumentUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="flex items-center justify-around">
                                            <FiEye className=" text-assetize-light-text" />
                                            <p className="font-latoBold text-sm  text-assetize-light-text">View document</p>
                                        </div>
                                    </a>
                                </OutlineButton>
                            )}
                        </div>
                    </div>

                    <div className="border-b py-4 border-assetize-gray flex justify-between items-center px-3">
                        <h4 className="font-latoBold text-sm  text-assetize-light-text">Corporate Governance</h4>
                        <div className="w-[180px]">
                            {accountType === "developer" && (
                                <OutlineButton color="assetize-gray">
                                    <a
                                        href={kycDocs?.corporateGovernanceUrl}
                                        // className="flex items-center focus:text-assetize-dark justify-between px-4 py-4 rounded-lg cursor-pointer bg-[#ccc] text-assetize-dark hover:text-assetize-primary"
                                        key={kycDocs?.corporateGovernanceUrl}
                                        download={kycDocs?.corporateGovernanceUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="flex items-center justify-around">
                                            <FiEye className=" text-assetize-light-text" />
                                            <p className="font-latoBold text-sm  text-assetize-light-text">View document</p>
                                        </div>
                                    </a>
                                </OutlineButton>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="  px-2 mt-6 flex justify-between items-center">
                <div>
                    <h3 className="font-latoBold text-md text-black "> Properties</h3>
                </div>
                <div className="flex-[.6] flex justify-between">
                    <div className=" flex-1  mx-1 py-2">
                        <ActiveSearch
                            bg="white"
                            border="border-aasetize-gray"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search here..."
                        />
                    </div>
                    <div className="border-r border-assetize-gray mx-1 h-15" />
                    <div className="flex-[0.5] mx-1 py-2">
                        <Select
                            options={joinedFilter}
                            selected={selectedOptions.joinedFilter}
                            onChange={(option) => handleOptionChange("joinedFilter", option)}
                            placeholder="Newest"
                        />
                    </div>
                    <div className="flex-[0.7] mx-1 py-2">
                        <Select
                            options={dateFilter}
                            selected={selectedOptions.dateFilter}
                            onChange={(option) => handleOptionChange("dateFilter", option)}
                            placeholder="Jan 01 - January 31"
                        />
                    </div>
                </div>
            </div>
            <div className="hidden md:flex justify-between w-full py-3 px-2">
                <h3 className="uppercase w-[30%] flex justify-start font-latoLight pl-4 text-sm">Details</h3>
                <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">Date</h3>
                <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">usd/token value</h3>
                <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">status</h3>
                <h3 className="uppercase w-[20%] flex justify-start font-latoLight text-sm">action</h3>
            </div>
            {Array.isArray(displayList) ? (
                displayList.map((item: { id: string }) => (
                    <PropertyCard key={item.id} property={accountType === "investor" ? item : { property: item }} />
                ))
            ) : (
                <div>
                    <div className="flex flex-col items-center mt-20 justify-center h-full space-y-4 no-item">
                        <img src={NotFoundImg} alt="" />
                        <p className="text-base font-semibold">No property uploaded!</p>
                    </div>
                </div>
            )}

            <ModalPanel open={showModal} closeButton closeModal={() => setSHowModal(false)}>
                <div className="w-[70%] h-[90%] ">
                    <h3 className="text-md font-latoRegular">{modalData.heading}</h3>
                    <img src={modalData.image} alt="kyc documents" className="w-full h-full" />
                </div>
            </ModalPanel>
        </div>
    );
};

export default UserDetail;
