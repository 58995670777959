import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Cart, InfoWarning, NullImg, Sample, Send, GoBack as GoBackIcon } from "Assets/Index";
import { PropertyDataProps, PropertyStatus, properties } from "types/data";
import { capitalizeFirstLetter, capitalizeText, getYearAndMonthAgo, readableDate } from "Utils/Helpers";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import AboutBox from "Components/elements/Display/AboutBox";
import DocumentBox from "Components/elements/Display/DocumentBox";
import ModalPanel from "Components/elements/Modal/Index";
import CreateListing from "./modals/CreateListing";
import BuyListingFromInvestor from "./modals/BuyListingFromInvestor";
import DisplayDocument from "Components/elements/Display/DisplayDocument";
import { Carousel } from "rsuite";

const InvestmentPortfolioDetails = () => {
    const { id } = useParams();
    const { state } = useLocation();

    const [openBuyModal, setOpenBuyModal] = useState(false);
    const [openSellModal, setOpenSellModal] = useState(false);
    const [calculate, setCalculate] = useState("1");

    const navigate = useNavigate();

    const {
        property,
        sharesOwned,
        percentageOwned,
        approvalPermitOfProject,
        financialProjectionsOfProject,
        insuranceAndPerformanceBondFromContractors,
        legalCompliance,
        projectPlan,
        titleDocumentOfProject,
        propertyImages
    } = state;

    const listedDate = readableDate(property.createdAt);
    const completedDate = readableDate(property.expectedCompletionDate);

    const isAvailable = property.availabilityStatus === "AVAILABLE";
    const isResale = property.availabilityStatus === "RESALE";

    const statusStyle = {
        background: "",
        text: "",
        textContent: ""
    };

    if (isAvailable) {
        statusStyle.background = "bg-green-200";
        statusStyle.text = "text-assetize-green-text";
        statusStyle.textContent = "Available";
    } else if (isResale) {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Re-sale ";
    } else {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Sold Out";
    }

    return (
        <div className="md:p-3 mt-10 bg-white rounded">
            <div
                role="button"
                onKeyDown={() => navigate("/investor")}
                tabIndex={0}
                onClick={() => navigate("/investor")}
                className="flex justify-start items-center"
            >
                <GoBackIcon className="w-8 h-8 pl-3 text-assetize-primary" />
                <p className="ml-2 text-assetize-dark font-latoBold font-[18px] text-md hover:text-assetize-dark-text">Back</p>
            </div>
            <div className="flex flex-col gap-3 md:flex-row md:items-end justify-between px-5 py-8">
                <div className="md:flex-[.5]">
                    <div className="flex items-center">
                        <h2 className="text-[22px] md:text-md  font-latoBold leading-6 text-black ">{property.propertyName}</h2>
                        <span
                            className={`text-xs mx-2 p-1 rounded-2xl text-center ${
                                isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                            } ${statusStyle.text}`}
                        >
                            {statusStyle.textContent}
                        </span>
                    </div>
                    <p className="text-xs  font-latoRegular my-1 text-assetize-dark-gray">{property.location}</p>
                    <h3 className="text-assetize-primary font-latoBold text-lg">${property.fiatValue.toLocaleString()}</h3>
                    <p className="text-xs  font-latoItalic my-1 text-assetize-dark-gray ">
                        Listed by <span className="text-assetize-primary">{property.developerName} </span> on <span>{listedDate}</span>
                    </p>
                </div>
                {property.availableNumberOfShares > 0 ? (
                    <div className="hidden lg:flex-[.5] gap-2 md:flex justify-between">
                        <div className="flex-[.48]">
                            <MainButton onClick={() => setOpenSellModal(true)}>
                                <div className="flex justify-center items-center px-2">
                                    <Send /> <span className="ml-2">Sell this property</span>
                                </div>
                            </MainButton>
                        </div>
                        <div className="flex-[.48]">
                            <OutlineButton
                                onClick={() => navigate(`/investor/buy-shares/${id}`, { state: property })}
                                color="assetize-dark-gray"
                            >
                                <div className="flex justify-center items-center px-2">
                                    <Cart /> <span className="ml-2 text-assetize-dark-gray">Buy more Shares</span>
                                </div>
                            </OutlineButton>
                        </div>
                    </div>
                ) : (
                    <div className="hidden lg:flex-[.5] gap-2 md:flex justify-between">
                        <div className="flex-[.48]">
                            <MainButton onClick={() => setOpenSellModal(true)}>
                                <div className="flex justify-center items-center px-2">
                                    <Send /> <span className="ml-2">Sell this property</span>
                                </div>
                            </MainButton>
                        </div>
                        <div className="flex-[.48]">
                            <OutlineButton onClick={() => setOpenBuyModal(true)} color="assetize-dark-gray">
                                <div className="flex justify-center items-center px-2">
                                    <Cart /> <span className="ml-2 text-assetize-dark-gray">Buy From Investors</span>
                                </div>
                            </OutlineButton>
                        </div>
                    </div>
                )}
            </div>

            <Carousel autoplay shape="bar">
                {property?.propertyImages?.map((imgUrl: string) => (
                    <div className="relative w-full h-[300px] md:h-full" key={imgUrl}>
                        <img src={imgUrl} alt="property_img" className="absolute object-cover w-full h-full" />
                        <p>{imgUrl}</p>
                    </div>
                ))}
            </Carousel>

            {property.availableNumberOfShares > 0 ? (
                <div className="flex mt-4 w-full md:w-[50%]  px-3 gap-2 md:hidden justify-between">
                    <div className="flex flex-[.5]">
                        <MainButton onClick={() => setOpenSellModal(true)}>
                            <div className="flex justify-center items-center px-2">
                                <Send /> <span className="ml-2">Sell this property</span>
                            </div>
                        </MainButton>
                    </div>
                    <div className="flex flex-[.5] ">
                        <OutlineButton
                            onClick={() => navigate(`/investor/buy-shares/${id}`, { state: property })}
                            color="assetize-dark-gray"
                            className=""
                        >
                            <div className="flex justify-center items-center px-2">
                                <Cart /> <span className="ml-2 text-assetize-dark-gray">Buy more Shares</span>
                            </div>
                        </OutlineButton>
                    </div>
                </div>
            ) : (
                <div className="flex mt-4 w-full md:w-[50%]   px-3 gap-2 md:hidden justify-between">
                    <div className="flex flex-[.5]">
                        <MainButton onClick={() => setOpenSellModal(true)}>
                            <div className="flex justify-center items-center px-2">
                                <Send /> <span className="ml-2">Sell this property</span>
                            </div>
                        </MainButton>
                    </div>
                    <div className="flex flex-[.5]">
                        <OutlineButton onClick={() => setOpenBuyModal(true)} color="assetize-dark-gray">
                            <div className="flex justify-center items-center px-2">
                                <Cart /> <span className="ml-2 text-assetize-dark-gray">Buy From Investors</span>
                            </div>
                        </OutlineButton>
                    </div>
                </div>
            )}
            <div className="px-2 md:px-4 flex flex-col md:flex-row gap-2 mt-8 md:mt-5">
                <div className="w-full border border-assetize-gray rounded md:py-4 py-2 px-1">
                    <h3 className="text-assetize-primary text-lg pl-3 my-3">Your ownership </h3>
                    <div className="md:grid md:grid-cols-2 flex flex-col mt-5 md:mt-10 ">
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">shares owned </h3>
                            <h3 className="text-assetize-light-text  text-center font-latoRegular text-sm  mt-2">{sharesOwned} </h3>
                        </div>
                        <div className="md:border-b  flex md:flex-col justify-between w-full items-center md:border-assetize-gray md:py-4 px-1">
                            <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">
                                current value of shares{" "}
                            </h3>
                            <h3 className="text-assetize-light-text text-center font-latoRegular text-sm  mt-2">
                                ${(property.sharePrice * sharesOwned).toLocaleString()}
                            </h3>
                        </div>
                        <div className=" md:border-r flex md:flex-col justify-between w-full items-center md:border-assetize-gray md:py-4 px-1">
                            <h3 className="text-assetize-light-text text-center font-latoLight text-sm  uppercase">percentage owned</h3>
                            <h3 className="text-assetize-light-text text-center font-latoRegular text-sm mt-2">
                                {percentageOwned.toFixed(2)}%
                            </h3>
                        </div>
                        {/* <div className=" border-assetize-gray py-4 px-1 ">
                            <h3 className="text-assetize-light-text text-center font-latoLight text-sm uppercase">total return</h3>
                            <h3 className="text-assetize-light-text text-center font-latoRegular text-sm py-2  ">
                                {' '}
                                ${(property.sharePrice * sharesOwned).toLocaleString()}{' '}
                            </h3>
                        </div> */}
                    </div>
                </div>

                <div className="w-full border border-assetize-gray rounded md:py-0 py-2 px-1">
                    <h3 className="text-assetize-primary text-lg pl-3 my-3">Property Valuation </h3>
                    <div className="md:grid md:grid-cols-3 flex flex-col mt-5 md:mt-10 ">
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:py-4 px-1  md:border-assetize-gray">
                            <p className="text-assetize-light-text text-center font-latoLight uppercase text-sm ">Property Type </p>
                            <p className="text-assetize-light-text  text-center font-latoBold text-md   md:mt-2">
                                {capitalizeText(property.propertyType)}{" "}
                            </p>
                        </div>
                        <div className="md:border-b md:border-r flex md:flex-col justify-between w-full items-center md:border-assetize-gray md:py-4 px-1">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase"> Expected ROI </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md  md:mt-2">
                                {property.expectedAnnualROI}%
                            </p>
                        </div>
                        <div className="md:border-b flex justify-between md:flex-col w-full items-center md:border-assetize-gray md:py-4 px-1 ">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Total Shares </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md  md:mt-2">
                                {property.numberOfShares}{" "}
                            </p>
                        </div>
                        <div className="md:border-r flex justify-between md:flex-col w-full items-center md:border-assetize-gray md:py-4 px-1 ">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Dividends </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md md:mt-2 ">
                                {property.dividend ? "Yes" : "No"}
                            </p>
                        </div>
                        <div className="md:border-r flex justify-between md:flex-col w-full items-center md:border-assetize-gray md:py-4 px-1">
                            <p className="text-assetize-light-text text-left md:text-center font-latoLight text-sm uppercase">
                                Original value of shares
                            </p>
                            <p className="text-assetize-light-text w-32 md:w-full text-right md:text-center font-latoBold text-md md:mt-2">
                                ${property.sharePrice} Price per share/ ${property.fiatValue.toLocaleString("en-Us")}{" "}
                            </p>
                        </div>
                        <div className=" md:border-assetize-gray flex md:flex-col justify-between w-full items-center md:py-4 px-1 ">
                            <p className="text-assetize-light-text text-center font-latoLight text-sm uppercase">Completion date </p>
                            <p className="text-assetize-light-text text-center font-latoBold text-md   "> {completedDate}</p>
                        </div>
                    </div>
                </div>
            </div>
            <AboutBox text={property.description as string} />
            <div className="flex-[.4] border border-gray rounded  md:mx-4  m-2 md:my-3 p-2 mdp-3 ">
                <h5 className="text-assetize-primary my-2">Documents</h5>
                <DisplayDocument document={projectPlan} name="projectPlan" />
                <DisplayDocument document={legalCompliance} name="legalCompliance" />
                <DisplayDocument document={insuranceAndPerformanceBondFromContractors} name="insuranceAndPerformanceBondFromContractors" />
                <DisplayDocument document={financialProjectionsOfProject} name="financialProjectionsOfProject" />
                <DisplayDocument document={approvalPermitOfProject} name="approvalPermitOfProject" />
                <DisplayDocument document={titleDocumentOfProject} name="titleDocumentOfProject" />
            </div>

            <div className="flex items-center justify-center p-3 mx-1 md:mx-8 mt-5 rounded bg-assetize-primary-gradient ">
                <InfoWarning />
                <p className="text-sm ml-1 md:ml-0 font-latoBold text-assetize-primary ">
                    Earned returns are paid into your wallet when due. Claim your capital anytime by selling your shares.
                </p>
            </div>
            <ModalPanel open={openSellModal} closeButton closeModal={() => setOpenSellModal(false)}>
                <CreateListing isEdit={false} id={property.id} property={property} percentageOwned={percentageOwned} />
            </ModalPanel>
            <ModalPanel open={openBuyModal} closeButton closeModal={() => setOpenBuyModal(false)}>
                <BuyListingFromInvestor id={property.id} closeModal={() => setOpenBuyModal(false)} />
            </ModalPanel>
        </div>
    );
};

export default InvestmentPortfolioDetails;
