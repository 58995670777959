import { MainButton } from "Components/elements/Button/Index";
import Password from "Components/elements/Forms/Password";
import React, { useState } from "react";
import AuthLogoDisplay from "Components/elements/Display/AuthLogoDisplay";
import { useParams } from "react-router-dom";
import { resetPassword } from "redux/Actions/AuthActions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ResetPassword = () => {
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [formData, setFormData] = useState<any>({
        newPassword: "",
        confirmNewPassword: ""
    });
    const [error, setErrors] = useState<any>({});
    const handleInputChange = (name: string, value: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    // const reset = async (e: any) => {
    //     e.preventDefault()
    //     const { newPassword, confirmPassword } = formData;
    //     try {
    //         await dispatch(resetPassword(newPassword));
    //         toast.success('password changed successfully');
    //
    //     } catch (error: any) {
    //
    //         toast.error(error.message);
    //     }
    // };

    return (
        <div className="flex flex-col w-full items-center mt-20 font-latoRegular">
            <AuthLogoDisplay />
            <div className=" md:mt-16  w-full flex flex-col  items-center">
                <h2 className="text-assetize-primary text-2xl font-latoBold align-center mt-4">Reset Password</h2>
                <p className="text-assetize-dark-gray align-center text-sm">Enter new password below to login</p>
            </div>
            <form className="w-full md:w-[90%] lg:w-[60%] mt-2">
                <div className="py-2">
                    <Password
                        label="New Password"
                        name="newPassword"
                        value={formData.newPassword}
                        placeholder="Enter password"
                        error={error.newPassword}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>
                <div className="py-2">
                    <Password
                        label="Confirm New Password"
                        name="confirmNewPassword"
                        value={formData.confirmNewPassword}
                        placeholder="Enter password"
                        error={error.confirmNewPassword}
                        size="md"
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                </div>

                <div className="pt-20 md:py-3">
                    <MainButton>Save Password</MainButton>
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
