/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
import { MainButton } from "Components/elements/Button/Index";
import Input from "Components/elements/Forms/Input";
import ModalPanel from "Components/elements/Modal/Index";
import { FundWalletByCardApi, GetTransactionChargesApi, GetFundChargesApi, TFundWalletByCard, bankTransferApi } from "Services";
import { getErrorMessge } from "Utils/Helpers";
import { validateAll } from "indicative/validator";
import { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "redux/store";

const heading = [
    { id: 0, title: "Card Funding", slug: "card" },
    { id: 1, title: "Bank Transfer", slug: "bank" }
];

const handleCopyClick = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
        toast.success("Account has been copied to clipboard");
    } catch (error) {
        toast.error("Error copying to clipboard");
    }
};

type Props = {
    closeModal(): void;
};

export default function FundWallet({ closeModal }: Props) {
    const [active, setActive] = useState<string>("card");
    const [fundWallet, setFundWallet] = useState<any>();
    const [fundWalletData, setFundWalletData] = useState({
        amount: ""
    });
    const [error, setError] = useState<any>({});
    const user: any = useSelector((state: RootState) => state.auth);
    const [fee, setFee] = useState(0);
    const [processingFee, setProcessingFee] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const fundWalletByCard = async (data: any) => {
        const payload: TFundWalletByCard = {
            email: user.user.emailAddress || user.user.email,
            amount: Number(data.amount),
            currency: "USD"
        };
        try {
            setLoading(true);

            const res = await FundWalletByCardApi(payload);

            const paymentLink = res.authorization_url;

            window.location.href = paymentLink;
        } catch (err: any) {
            const errorMessage = getErrorMessge(err);
            toast.error(errorMessage);

            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const rules = {
            amount: "required"
        };

        const messages = {
            "amount.required": "Amount is required"
        };

        validateAll(fundWalletData, rules, messages)
            .then(() => {
                setError({});
                fundWalletByCard({ amount: fundWalletData.amount });
            })
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    const handleFundWallet = async () => {
        try {
            const response = await bankTransferApi();

            setFundWallet(response);
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        }
    };

    useEffect(() => {
        handleFundWallet();
    }, []);

    useEffect(() => {
        if (fundWalletData) {
            const fetchPrice = async () => {
                try {
                    const res = await GetFundChargesApi(fundWalletData.amount);
                    // const res2 = await GetTransactionChargesApi('FUNDING');
                    // (res2);

                    let calcFee = res.dollarEquivalent;

                    if (Number.isNaN(calcFee)) {
                        calcFee = 0;
                    }

                    // Use toFixed(2) to format the numbers to two decimal places
                    const formattedCalcFee = parseFloat(calcFee.toFixed(2));

                    // Format the other numbers as well
                    const formattedTotal = parseFloat((parseFloat(fundWalletData.amount) + calcFee).toFixed(2));
                    const formattedProcessingFee = parseFloat(res).toFixed(2);

                    setFee(formattedCalcFee);
                    setTotal(formattedTotal);
                    setProcessingFee(parseFloat(formattedProcessingFee));
                } catch (err) {
                    const errMsg = getErrorMessge(err);

                    toast.error(errMsg);
                }
            };
            fetchPrice();
        }
    }, [fundWalletData]);

    const amountToFund = parseFloat(fundWalletData.amount);

    return (
        <ModalPanel closeModal={closeModal} open closeButton title="Fund Wallet">
            <div className="my-10 space-y-8">
                <div className="flex items-center justify-center mx-auto text-gray-500 rounded-full w-fit bg-assetize-gray/30 font-latoRegular">
                    {heading.map(({ id, slug, title }) => (
                        <div
                            className={`${
                                active === slug && "rounded-full bg-assetize-primary/20 text-assetize-primary"
                            } px-8 py-2 cursor-pointer`}
                            key={id}
                            role="button"
                            onClick={() => setActive(slug)}
                        >
                            <h6>{title}</h6>
                        </div>
                    ))}
                </div>
                {active === "card" && (
                    <div className="w-full px-4 md:px-10 mx-auto">
                        <form className="space-y-4">
                            <div>
                                <Input
                                    label="Amount"
                                    type="number"
                                    value={fundWalletData.amount}
                                    name="amount"
                                    placeholder="0.00"
                                    onChange={(e) => setFundWalletData({ ...fundWalletData, [e.target.name]: e.target.value })}
                                    error={error.amount}
                                    amountInput
                                />
                            </div>
                            <div className="p-3 space-y-3 border rounded-lg border-assetize-primary">
                                <div className="flex items-center justify-between">
                                    <p>Amount to fund</p>
                                    <span>${Number.isNaN(amountToFund) ? "0.00" : amountToFund.toFixed(2)}</span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <p>Processing Fee </p>
                                    <span>${Number.isNaN(fee.toLocaleString("en-us")) ? "0.00" : fee.toLocaleString("en-us")}</span>
                                </div>
                                <div className="flex items-center justify-between font-semibold">
                                    <p>Total</p>
                                    <span>${Number.isNaN(total) ? "0.00" : total}</span>
                                </div>
                            </div>
                            <div className="pt-4">
                                <MainButton
                                    isLoading={loading}
                                    disabled={loading}
                                    className="py-3 font-bold"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Fund Wallet
                                </MainButton>
                            </div>
                        </form>
                    </div>
                )}
                {active === "bank" && (
                    <div className="w-full px-4 md:px-10 mx-auto space-y-6">
                        <p className="font-semibold text-center">
                            Fund your account directly using your USD account number. Your funds will reflect immediately.
                        </p>
                        <div className="flex items-start justify-between p-6 bg-gray-200 rounded-lg">
                            <div className="space-y-5 text-assetize-primary font-latoLight">
                                <div>
                                    <p className="text-xs font-bold">USD Account Number</p>
                                    <h6>{fundWallet?.accountNumber}</h6>
                                </div>
                                <div>
                                    <p className="text-xs font-bold">Bank Name</p>
                                    <h6>{fundWallet?.bankName}</h6>
                                </div>
                                <div>
                                    <p className="text-xs font-bold">Account Name</p>
                                    <h6>{fundWallet?.accountName}</h6>
                                </div>
                            </div>
                            <div className="cursor-pointer">
                                <FaCopy
                                    size={20}
                                    onClick={() => {
                                        const textToCopy = `${fundWallet?.accountNumber}\n${fundWallet?.bankName}\n${fundWallet?.accountName}`;
                                        handleCopyClick(textToCopy);
                                    }}
                                    className="text-assetize-primary/60"
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <MainButton onClick={() => closeModal()} className="py-3 font-bold">
                                Done
                            </MainButton>
                        </div>
                    </div>
                )}
            </div>
        </ModalPanel>
    );
}
