import React, { Component, ErrorInfo, ReactNode } from "react";
import { MainButton } from "./Components/elements/Button/Index";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    // eslint-disable-next-line class-methods-use-this
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        throw new Error(`"Uncaught error: " ${error} ${errorInfo}`);
    }

    public render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                <main>
                    <h1>An error occured</h1>
                    <MainButton onClick={() => window.location.reload()} type="button">
                        Refresh App
                    </MainButton>
                </main>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
