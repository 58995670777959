import React, { useEffect, useState } from "react";
import Register from "./Register";
import Otp from "./Otp";
import { useNavigate } from "react-router-dom";

interface ErrorType {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    [key: string]: string | undefined;
}

const Signup = () => {
    const [signupStage, setSignupStage] = useState("1");
    const [error, setError] = useState("");

    const setStage = (stage: string) => {
        setSignupStage(stage);
        localStorage.setItem("signupStage", stage);

        return {};
    };

    useEffect(() => {
        const stage = localStorage.getItem("signupStage");
        if (!stage) {
            setStage("1");
        } else {
            setStage(stage as string);
        }
    }, []);

    let componentToRender;

    switch (signupStage) {
        case "1":
            componentToRender = <Register setStage={setStage} />;
            break;
        case "2":
            componentToRender = <Otp setStage={setStage} />;
            break;
        default:
            componentToRender = null;
    }
    return componentToRender;
};

export default Signup;
