const ApiEndpoints = {
    LOGIN: "auth/sign-in",
    SIGNUP: "auth/sign-up",
    FORGOT: "auth/forgot-password",
    OTPCODE: "auth/verify-otp",
    RESET: "auth/reset-password",
    LOGOUT: "auth/logout",
    VERIFYBVN: "kyc/verify/bvn",
    VERIFYNIN: "kyc/verify/nin",
    VERIFYPVC: "kyc/verify/pvc",
    VERIFYDRIVERSLICENSE: "kyc/verify/drivers-license",
    GETUSER: "auth/profile"
};

export default ApiEndpoints;
