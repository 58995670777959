import { ChevronRIght } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import ModalPanel from "Components/elements/Modal/Index";
import { GetWalletUsdApi } from "Services/apis/wallet.api";
import React, { useEffect, useState } from "react";
import InvestorInformation from "./InvestorInformation";
import TermsAndCondition from "./TermsAndCondition";
import { readableDate } from "Utils/Helpers";
import { toast } from "react-toastify";
import { ClaimMarketOffer } from "Services";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import { useNavigate } from "react-router-dom";
import ControlledCheckBox from "Components/elements/Forms/ControlledCheckBox";
import FundWallet from "Pages/Wallets/FundWallet";
import { useSelector } from "react-redux";

interface BuySharesFromInvestorDetailsModalProps {
    property: any;
    close: () => void;
}

const BuySharesFromInvestorDetailsModal = ({ property, close }: BuySharesFromInvestorDetailsModalProps) => {
    const [isConfirm, setIsConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [calculatedShare, setCalculatedShare] = useState(0);
    const [Walletbalance, setWalletBalance] = useState(0);
    const [showInvestorFormModals, setShowInvestorFormModals] = useState(false);
    const [showTermsAndConditions, setShowTermsAndCondtionModals] = useState(false);
    const [checkInvestorInformForm, setCheckInvestorInformForm] = useState(false);
    const [checkTermsAndConditionAgreement, setCheckTermsAndConditionAgreement] = useState(false);
    const [error, setError] = useState("");
    const [showFundingCard, setShowFundingCard] = useState(false);
    const navigate = useNavigate();

    const { investorId, listingId, propertyId, propertyData, sharePrice, shareNumber, alwaysOpen, closeDate } = property;
    const completionDate = readableDate(closeDate);

    const claimOffer = async () => {
        const payload = {
            listerId: investorId,
            propertyId,
            listingId
        };
        try {
            setLoading(true);
            const res = await ClaimMarketOffer(payload);
            setIsSuccessful(true);
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const handleConfimation = () => {
        if (!checkInvestorInformForm || !checkTermsAndConditionAgreement) {
            setError("Please agree to the Investor form and our terms and conditions");
            return;
        }
        setError("");
        setIsConfirm(true);
    };

    const getWallet = async () => {
        try {
            const res = await GetWalletUsdApi();

            setWalletBalance(res.balance);
        } catch (error) {
            setWalletBalance(0);
        }
    };

    useEffect(() => {
        getWallet();
    }, []);

    return (
        <div>
            {isConfirm ? (
                <div>
                    {isSuccessful ? (
                        <SuccessMessageModal
                            handleClick={() => navigate("/investor/portfolio")}
                            descriptiontext="Your offer has been claimed successfully. You will can view it in your listing"
                            headingText="Offer Claimed Successfully!"
                        />
                    ) : (
                        <div className="container p-2 md:p-5 mx-auto my-2 rounded bg-white w-[100%]">
                            <h5 className=" font-latoRegular mb-5 text-center font-3xl">
                                You are about to invest ${(sharePrice / shareNumber) * shareNumber} in{" "}
                                {propertyData?.property?.propertyName}.<br /> Please confirm
                            </h5>

                            <div className="mt-8 w-full flex justify-evenly items-center">
                                <div className="w-[45%]">
                                    <OutlineButton onClick={close}> Discard</OutlineButton>
                                </div>

                                <div className="w-[45%]">
                                    <MainButton isLoading={loading} disabled={loading} onClick={claimOffer} type="submit">
                                        Yes, Continue
                                    </MainButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex flex-col  mt-7 font-latoRegular">
                    <div>
                        <h2 className="text-assetize-primary text-[19px] leading-3 pb-2 font-latoBold text-center ">Offer Info</h2>
                        <p className="text-sm  text-center ">View information about this offer</p>
                        <div className="flex justify-center flex-col  w-full my-4">
                            <h3 className="text-lg text-assetize-primary  text-center  ">${sharePrice}</h3>
                            {/* <p className="text-sm  text-center "> = {propertyData?.property?.totalNumberOfIssuedTokens} token</p>  */}
                        </div>
                    </div>
                    <div className="col-span-2  border rounded-lg font-latoRegular">
                        <div className="grid grid-cols-2  text-center md:grid-cols-2">
                            <div className="  border-r md:border-r uppercase p-5">
                                <p>total shares for sale</p>
                                <p className="font-semibold"> {shareNumber}</p>
                            </div>
                            <div className="  md:border-r uppercase p-5">
                                <p> original value of shares</p>
                                <p className="font-latoBold text-md">${propertyData?.property?.sharePrice}</p>
                            </div>
                            <div className=" border-t  border-r uppercase p-5">
                                <p>current value of shares</p>
                                <p className="font-latoBold text-md">${sharePrice / shareNumber}</p>
                            </div>
                            <div className="  border-t md:border-r uppercase p-5">
                                <p>token equivalent</p>
                                <p className="font-latoBold text-md text-assetize-primary">N/A</p>
                            </div>
                            <div className=" border-t border-r uppercase p-5">
                                <p>estimate annual returns</p>
                                <p className="font-latoBold text-md">{propertyData?.property?.expectedAnnualROI}%</p>
                            </div>
                            <div className=" border-t uppercase p-5">
                                <p>Maturity date</p>
                                <p className="font-latoBold text-md">{alwaysOpen ? "Always Open" : completionDate}</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className=" mt-2 border-b border-assetize-gray flex justify-between items-center py-1">
                                <p>Payment method </p>
                                <div>
                                    <p className="font-latoRegular text-sm flex items-center text-assetize-primary">USD Wallet</p>
                                </div>
                            </div>

                            <div className="   border-b border-assetize-gray flex justify-between items-center py-2">
                                <p className="font-latoRegular text-sm">Wallet Balance</p>
                                {Walletbalance > sharePrice ? (
                                    <p className="font-latoRegular text-sm text-green-500"> {Walletbalance.toFixed(2)}</p>
                                ) : (
                                    <p className="text-assetize-primary">Insufficient funds</p>
                                )}
                            </div>
                        </div>
                        <div className=" flex w-full mt-3 justify-center">
                            <div className="flex flex-col  mt-7">
                                <div className="flex items-start">
                                    <div className="mt-1">
                                        {/* <CheckBox
                                        onChange={() => {
                                            setCheckInvestorInformForm(!checkInvestorInformForm);
                                        }}
                                        name="checkInvestorInformForm"
                                        label=""
                                        error=""
                                        id="checkInvestorInformForm"
                                        /> */}
                                        <ControlledCheckBox
                                            checked={checkInvestorInformForm}
                                            onChange={() => {
                                                setCheckInvestorInformForm(!checkInvestorInformForm);
                                            }}
                                            name="checkInvestorInformForm"
                                            label=""
                                            error=""
                                        />
                                    </div>
                                    <p className="pl-2 text-assetize-dark-gray font-latoRegular pt-[-10px]">
                                        I have reviewed and agree with all the information on the{" "}
                                        <span
                                            onClick={() => setShowInvestorFormModals(true)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    setShowInvestorFormModals(true);
                                                }
                                            }}
                                            tabIndex={0}
                                            role="button"
                                            className="text-assetize-primary underline cursor-pointer"
                                        >
                                            Investor Information Form
                                        </span>
                                    </p>
                                </div>

                                <div className="flex mt-3 items-center">
                                    <ControlledCheckBox
                                        checked={checkTermsAndConditionAgreement}
                                        name="checkTermsAndConditionAgreement"
                                        onChange={() => {
                                            setCheckTermsAndConditionAgreement(!checkTermsAndConditionAgreement);
                                        }}
                                        label=""
                                        error=""
                                    />

                                    <p className="pl-2 text-assetize-dark-gray font-latoRegular">
                                        I have read and agree to the
                                        <span
                                            onClick={() => setShowTermsAndCondtionModals(true)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    setShowTermsAndCondtionModals(true);
                                                }
                                            }}
                                            tabIndex={0}
                                            role="button"
                                            className="text-assetize-primary underline cursor-pointer pl-1"
                                        >
                                            Terms and Conditions
                                        </span>
                                    </p>
                                </div>

                                {error && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{error}</p>}
                            </div>
                        </div>

                        <div className="flex justify-center mt-5">
                            {Walletbalance > sharePrice ? (
                                <div className="flex-[.7]">
                                    <MainButton onClick={handleConfimation}>Claim Offer</MainButton>
                                </div>
                            ) : (
                                <div className="flex-[.7]">
                                    <MainButton onClick={() => setShowFundingCard(true)}>Fund Wallet</MainButton>
                                </div>
                            )}
                        </div>
                    </div>
                    <ModalPanel open={showInvestorFormModals} closeButton closeModal={() => setShowInvestorFormModals(false)}>
                        <InvestorInformation
                            cancel={() => {
                                setShowInvestorFormModals(false);
                                setCheckInvestorInformForm(false);
                            }}
                            accept={() => {
                                setCheckInvestorInformForm(true);
                                setShowInvestorFormModals(false);
                            }}
                        />
                    </ModalPanel>
                    <ModalPanel open={showTermsAndConditions} closeButton closeModal={() => setShowTermsAndCondtionModals(false)}>
                        <TermsAndCondition
                            cancel={() => {
                                setShowTermsAndCondtionModals(false);
                                setCheckTermsAndConditionAgreement(false);
                            }}
                            accountType="investor"
                            accept={() => {
                                setCheckTermsAndConditionAgreement(true);
                                setShowTermsAndCondtionModals(false);
                            }}
                        />
                    </ModalPanel>
                    {showFundingCard && <FundWallet closeModal={() => setShowFundingCard(false)} />}
                </div>
            )}
        </div>
    );
};

export default BuySharesFromInvestorDetailsModal;
