/* eslint-disable react/react-in-jsx-scope */
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import Input from "Components/elements/Forms/Input";
import Select from "react-select";
import ModalPanel from "Components/elements/Modal/Index";
import React, { useEffect, useState } from "react";
import { validateAll } from "indicative/validator";
import { CreateSafelock, SafelockWalletFundsApi } from "Services";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import { useNavigate } from "react-router-dom";
import ConfirmActionModal from "Components/elements/Display/ConfirmActionModal";
import Textarea from "Components/elements/Forms/TextArea";

type Props = {
    closeModal(): void;
};

export default function Safelock({ closeModal }: Props) {
    const [formData, setFormData] = useState({
        threeMonths: "",
        sixMonths: "",
        nineMonths: "",
        twelveMonths: "",
        description: "",
        propertyNameOne: "",
        propertyNameTwo: "",
        propertyNameThree: ""
    });
    const [propertyName, setPropertyName] = useState<string[]>([]);
    const [duration, setDuration] = useState("");
    const [description, setDescription] = useState("");
    const [errors, setErrors] = useState<any>({});
    const [lockDone, setLockDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lockStage, setLockStage] = useState(0);

    const navigate = useNavigate();

    const handleChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const addProperty = () => {
        if (propertyName.length <= 2) {
            setPropertyName([...propertyName, ""]);
        }
    };

    const lockFunds = async () => {
        try {
            setErrors({});
            setLoading(true);

            const payload = {
                durationAndInterestRates: [
                    {
                        durationInMonths: 6,
                        interestRate: Number(formData.sixMonths)
                    },
                    {
                        durationInMonths: 3,
                        interestRate: Number(formData.threeMonths)
                    },
                    {
                        durationInMonths: 12,
                        interestRate: Number(formData.twelveMonths)
                    }
                ],
                propertyDetails: [
                    {
                        propertyName: formData.propertyNameOne,
                        propertyDescription: formData.description
                    },
                    {
                        propertyName: formData.propertyNameTwo,
                        propertyDescription: formData.description
                    },
                    {
                        propertyName: formData.propertyNameThree,
                        propertyDescription: formData.description
                    }
                ]
            };

            const res = await CreateSafelock(payload);
            setLockStage(2);
        } catch (err: any) {
            setLoading(false);
            toast.error(
                err?.response?.data?.message
                    ? err?.response?.data?.message
                    : " Sorry, we could not process your Withdrawal at the moment, try again later."
            );
            setLockDone(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const rules = {
            threeMonths: "required|above:0",
            sixMonths: "required|above:0",

            twelveMonths: "required|above:0",
            description: "required",
            propertyNameOne: "required",
            propertyNameTwo: "required",
            propertyNameThree: "required"
        };

        const messages = {
            "threeMonths.required": "Interest is required",
            "sixMonths.required": "Interest is required",
            "twelveMonths.required": "Interest is required",
            "description.required": "Description is required",
            "propertyNameOne.required": "Property Name is required",
            "propertyNameTwo.required": "Property Name is required",
            "propertyNameThree.required": "Property Name is required"
        };

        const data = {
            ...formData,
            threeMonths: formData.threeMonths,
            sixMonths: formData.sixMonths,

            twelveMonths: formData.twelveMonths,
            description: formData.description
        };
        validateAll(formData, rules, messages)
            .then(() => {
                setLockStage(1);
            })
            .catch((err: any) => {
                const formattedErrors = {} as any;
                err.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setErrors(formattedErrors);
            });
    };

    let componentTODisplay;
    if (lockStage === 0) {
        componentTODisplay = (
            <div className="my-3 space-y-8 font-latoRegular">
                <p className="font-latoBold text-md text-center">Edit and manage conversion Rate</p>
                <div className="w-full my-10 px-2 md:px-8 mx-auto">
                    <form onSubmit={handleSubmit} className="space-y-8">
                        <div className="flex p-1 justify-between items-center">
                            <p className="text-md flex-[.35] text-assetize-primary">Duration</p>
                            <div className="flex-[.7]">
                                <p className="text-assetize-primary"> % Interest per Anum</p>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex p-1 justify-between items-center">
                                <p className="text-md flex-[.35]">3 Months</p>
                                <div className="flex-[.7]">
                                    <Input
                                        label=""
                                        type="text"
                                        name="threeMonths"
                                        value={formData.threeMonths.toString()}
                                        placeholder="0%"
                                        size="md"
                                        error={errors.threeMonths}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex p-1 justify-between items-center">
                                <p className="text-md flex-[.35]">6 Months</p>
                                <div className="flex-[.7]">
                                    <Input
                                        label=""
                                        type="text"
                                        name="sixMonths"
                                        value={formData.sixMonths.toString()}
                                        placeholder="0%"
                                        size="md"
                                        error={errors.sixMonths}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* <div className="flex p-1 justify-between items-center">
                                <p className="text-md flex-[.35]">9 Months</p>
                                <div className="flex-[.7]">
                                    <Input
                                        label=""
                                        type="text"
                                        name="nineMonths"
                                        value={formData.nineMonths.toString()}
                                        placeholder="0%"
                                        size="md"
                                        error={errors.nineMonths}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                </div>
                            </div> */}
                            <div className="flex p-1 justify-between items-center">
                                <p className="text-md flex-[.35]">12 Months</p>
                                <div className="flex-[.7]">
                                    <Input
                                        label=""
                                        type="text"
                                        name="twelveMonths"
                                        value={formData.twelveMonths.toString()}
                                        placeholder="0%"
                                        size="md"
                                        error={errors.twelveMonths}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr className="bg-assetize-gray " />
                        <div className="">
                            <Input
                                label="Property Name"
                                type="text"
                                name="propertyNameOne"
                                value={formData.propertyNameOne}
                                placeholder="Enter property name"
                                size="md"
                                error={errors.propertyNameOne}
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                            />
                        </div>
                        {propertyName.length >= 1 && (
                            <div className="">
                                <Input
                                    label="Property Name"
                                    type="text"
                                    name="propertyNameTwo"
                                    value={formData.propertyNameTwo}
                                    placeholder="Enter property name"
                                    size="md"
                                    error={errors.propertyNameTwo}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                />
                            </div>
                        )}
                        {propertyName.length === 2 && (
                            <div className="">
                                <Input
                                    label="Property Name"
                                    type="text"
                                    name="propertyNameThree"
                                    value={formData.propertyNameThree}
                                    placeholder="Enter property name"
                                    size="md"
                                    error={errors.propertyNameThree}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                />
                            </div>
                        )}

                        <div>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="textarea" className="mb-2 text-base font-latoRegular font-normal text-gray-800 cursor-pointer">
                                <Textarea
                                    label="Property Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    error={errors.description}
                                />
                            </label>
                            <p className="text-xs text-red-600">{description}</p>
                        </div>

                        {propertyName.length <= 2 && (
                            <div className="w-1/2">
                                <OutlineButton
                                    color="#CA1611"
                                    onClick={addProperty}
                                    className="text-assetize-primary border-2 border-assetize-primary font-latoRegular"
                                >
                                    + Add Property
                                </OutlineButton>
                            </div>
                        )}

                        {(errors.propertyNameTwo || errors.propertyNameThree) && (
                            <p className="mt-3 text-xs font-latoRegular text-assetize-primary">
                                Please add property and fill in all three properties name
                            </p>
                        )}
                        {/* { errors.propertyNameTwo && <p className="mt-5 text-xs font-latoRegular text-assetize-primary">Please fill in all three property name</p>} */}

                        <div className="pt-4">
                            <MainButton type="submit" onClick={handleSubmit} className="py-3 font-bold">
                                Save
                            </MainButton>
                        </div>
                    </form>
                </div>
            </div>
        );
    } else if (lockStage === 1) {
        componentTODisplay = (
            <ConfirmActionModal
                headingText=""
                descriptionText={`You are about to create a safelock. Please confirm `}
                handleCancel={() => setLockStage(0)}
                handleAction={lockFunds}
                loading={loading}
                buttonText="Save"
                cancelButton="Discard"
            />
        );
    } else if (lockStage === 2) {
        componentTODisplay = (
            <SuccessMessageModal
                handleClick={() => window.location.reload()}
                descriptiontext="Safelock created successfully"
                headingText="Safe Lock Successful!"
            />
        );
    }

    return (
        <ModalPanel closeModal={closeModal} open closeButton title="Safe Lock Duration">
            {componentTODisplay}
        </ModalPanel>
    );
}
