import { GetApplicationStats } from "Services";
import { getErrorMessge } from "Utils/Helpers";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

const UserTotal = () => {
    const [stats, setStats] = React.useState<any>();

    const getStats = async () => {
        try {
            const res = await GetApplicationStats();

            setStats(res);
        } catch (error) {
            const err = getErrorMessge(error);
            toast.error(err);
        }
    };

    useEffect(() => {
        getStats();
    }, []);

    const titles = ["Total Investors", "Total Developers", "Total Properties Listed"];

    const getValueForTitle = (title: string) => {
        if (stats) {
            switch (title) {
                case "Total Investors":
                    return stats.investorStats || "N/A";
                case "Total Developers":
                    return stats.developerStats || "N/A";
                case "Total Properties Listed":
                    return stats.approvedProperties || "N/A";
                default:
                    return "N/A";
            }
        } else {
            return "N/A";
        }
    };

    return (
        <div className="flex flex-col md:flex-row lg:w-[70%] w-full">
            {titles.map((title, index) => (
                <div
                    key={index}
                    className="flex flex-col justify-center w-full rounded-2xl bg-white p-2 border my-2 md:m-2 border-b-8 border-b-red-600 "
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <div className="w-4 h-4 rounded bg-assetize-primary" />
                            <p className="px-2 uppercase text-xs lg:text-sm font-latoRegular">{title}</p>
                        </div>
                    </div>
                    <div className="flex items-center py-2 ml-2">
                        <h3 className="text-2xl font-latoBold">{getValueForTitle(title)}</h3>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UserTotal;
