import { NullImg } from "Assets/Index";
import React from "react";
import { useNavigate } from "react-router-dom";

const Card = ({ property }: any) => {
    const navigate = useNavigate();

    const isAvailable = property.availabilityStatus === "AVAILABLE";
    const isSoldOut = property.availabilityStatus === "SOLD_OUT";
    const statusStyle = {
        background: "",
        text: "",
        textContent: ""
    };

    if (isAvailable) {
        statusStyle.background = "bg-green-200";
        statusStyle.text = "text-assetize-green-text";
        statusStyle.textContent = "Available";
    } else if (isSoldOut) {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Sold Out";
    } else {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Re-sale";
    }

    const handleClick = () => {
        navigate(`/investor/${property.id}`, { state: property });
    };
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            navigate(`/investor/${property.id}`, { state: property });
        }
    };

    const propertyType = property?.propertyType;
    const capitalizedPropertyType = propertyType ? (propertyType.charAt(0)?.toUpperCase() || "") + propertyType.slice(1).toLowerCase() : "";

    return (
        <div onClick={handleClick} onKeyDown={handleKeyPress} role="button" tabIndex={0} className="w-full pr-2">
            <div className="p-4 mt-4 bg-white border border-gray-200 rounded-lg shadow-inner h-[500px] w-[315px]">
                <div
                    className="relative h-48 rounded-t-lg"
                    style={{
                        backgroundImage: `url(${NullImg})`
                    }}
                >
                    {property?.propertyGallery?.propertyImages[0] ? (
                        <img
                            src={property.propertyGallery.propertyImages[0]}
                            alt=""
                            className="absolute object-cover w-full h-full rounded-t-lg"
                        />
                    ) : (
                        <img src={NullImg} alt="Placeholder" className="absolute object-cover h-full rounded-t-lg" />
                    )}
                </div>
                <div className="flex flex-col justify-between">
                    <p className="my-3 space-x-2 text-xs">
                        <span className="px-2 py-1 rounded-full bg-assetize-gray text-assetize-dark"> {capitalizedPropertyType}</span>
                        <span
                            className={`px-2 py-1 rounded-full ${isAvailable ? "bg-assetize-light-green" : "bg-red-200"} ${
                                statusStyle.text
                            }`}
                        >
                            {statusStyle.textContent}
                        </span>
                    </p>
                    <h3 className="text-xl font-bold">{property?.propertyName}</h3>
                    <p className="text-gray-600">{property?.location}</p>
                    <p className="mt-2 text-lg font-bold text-assetize-primary">${property?.fiatValue?.toLocaleString()}</p>
                    <hr />
                    <p className="flex items-center justify-between text-gray-600">
                        Price per share <span className="font-semibold">${property?.sharePrice}</span>
                    </p>
                    <p className="flex justify-between text-gray-600">
                        Available shares <span className="font-semibold">{property?.availableNumberOfShares}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Card;
