/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */

import { DisabledButton, MainButton } from "Components/elements/Button/Index";
import React, { useEffect, useState } from "react";
import { DashboardStyle, investmentTypeOptions, propertyTypeOptions } from "types";
import { DevKycVerify, DevPropertyList, NotFoundImg, VerificationFailedIcon, VerificationPendingIcon } from "Assets/Index";
import { PropertySkeleton } from "Components/blocks";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { addProperties } from "redux/slices/property.slice";
import { RootState } from "redux/store";
import { GetDeveloperData, GetDeveloperPropertyListingApi } from "Services";
import SubHeader from "Components/blocks/SubHeader";
import { getErrorMessge, searchArray } from "Utils/Helpers";
import DeveloperPropertyCard from "./DeveloperPropertyCard";
import Pagination from "Components/elements/Pagination";
import ActionCard from "Components/elements/Display/ActionCard";
import { toast } from "react-toastify";

const DeveloperDashboard = () => {
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const { user } = useSelector((state: any) => state.auth);
    const [dashboardStyle, setDashboardStyle] = useState(DashboardStyle.grid);
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState({
        propertyType: "",
        investmentType: ""
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [pageData, setPageData] = useState({ totalPages: 0 });
    const [pageNum, setPageNum] = useState<number>(1);
    const [propertyList, setPropertyList] = useState([]);
    const [displayList, setDisplayList] = useState<any[]>([]);
    const [developerData, setDeveloperData] = useState<any>();

    const navigate = useNavigate();

    const fetchAllPropertyListing = async () => {
        setLoading(true);
        try {
            const res = await GetDeveloperPropertyListingApi(pageNum);

            setPropertyList(res.propertiesWithGallery);
            setDisplayList(res.propertiesWithGallery);
            if (res.metadata) {
                setPageData(res.metadata);
            }
            dispatch(addProperties(res));
        } catch (err) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const getDeveloper = async () => {
            try {
                const res = await GetDeveloperData();

                setDeveloperData(res);
            } catch (error) {
                const err = getErrorMessge(error);
                toast.error(err);
            }
        };
        getDeveloper();
    }, []);

    useEffect(() => {
        fetchAllPropertyListing();
    }, [pageNum]);

    useEffect(() => {
        const value = searchArray(propertyList, search);

        setDisplayList(value);
    }, [search]);

    useEffect(() => {
        let filteredProperties: any[] = [];

        if (filters.propertyType.toLowerCase() === "all") {
            filteredProperties = propertyList;
            setDisplayList(filteredProperties);
            return;
        }

        if (filters.propertyType) {
            filteredProperties = propertyList.filter(
                (property: any) => property.propertyType.toLocaleLowerCase() === filters.propertyType.toLocaleLowerCase()
            );
        }

        if (filters.investmentType.toLowerCase() === "all") {
            filteredProperties = propertyList;
            setDisplayList(filteredProperties);
            return;
        }

        if (filters.investmentType) {
            filteredProperties = propertyList.filter(
                (property: any) => property.investmentType.toLocaleLowerCase() === filters.investmentType.toLocaleLowerCase()
            );
        }

        setDisplayList(filteredProperties);
    }, [filters]);

    const applyFilterOnProperties = (filterType: string, value: string) => {
        if (filterType === "propertyType") {
            setFilters({ ...filters, propertyType: value === "ALL" ? "" : value });
        }

        if (filterType === "investmentType") {
            let realValue = value;
            switch (value.toLocaleLowerCase()) {
                case "rental":
                    realValue = "rental";
                    break;
                case "non-rental":
                    realValue = "non_rental";
                    break;
                default:
                    realValue = value;
            }

            setFilters({ ...filters, investmentType: realValue });
        }
    };

    return (
        <div>
            <div className="container py-6 md:mt-10 mx-auto space-y-3 md:space-y-8">
                {developerData?.kycInfo?.kycStatus === "PENDING" && (
                    <div className="p-6 flex items-center rounded bg-[#FFF1D9] ">
                        <VerificationPendingIcon />
                        <p className="text-sm md:text-md ml-5 ">
                            {" "}
                            Your account verification is in progress, We&apos;ll notify you once it&apos;s completed
                        </p>
                    </div>
                )}
                {developerData?.kycInfo?.kycStatus === "FAILED" && (
                    <div className="p-6  flex items-center rounded bg-[#FEE4E2] ">
                        <VerificationFailedIcon />
                        <p className="text-sm md:text-md ml-5">
                            {" "}
                            Oops! It seems your account verification failed. Please contact our support team at{" "}
                            <a className="text-assetize-primary" href="mailto: hello@assetizenow.com">
                                hello@assetizenow.com
                            </a>{" "}
                            for assistance
                        </p>
                    </div>
                )}
                {developerData?.kycInfo?.kycStatus !== "PENDING" && (
                    <div className="flex flex-col md:flex-row md:justify-between bg-white rounded-md border p-5">
                        <div
                            onClick={() => {
                                localStorage.setItem("continue", "true");
                                navigate("/auth/verification");
                            }}
                            onKeyDown={() => {
                                localStorage.setItem("continue", "true");
                                navigate("/auth/verification");
                            }}
                            role="button"
                            tabIndex={0}
                            className="flex-[.49] my-3 md:my-0"
                        >
                            <ActionCard
                                heading="Complete KYC Verification"
                                body="Confirm your identity by providing some valid identification"
                                icon={DevKycVerify}
                                done={developerData?.kycInfo?.kycStatus === "SUCCESS"}
                            />
                        </div>
                        <div className="flex-[.49] my-3 md:my-0">
                            <ActionCard
                                heading="Submit a Listing"
                                body="Submit your first Property Listing for Investment by Investors"
                                icon={DevPropertyList}
                                done={developerData?.listedPropertiesByDeveloper?.propertiesWithGallery?.length > 1}
                            />
                        </div>
                    </div>
                )}
                <div className="flex items-center justify-between gap-x-6">
                    <SubHeader
                        child={
                            <>
                                {" "}
                                {user.kycVerified ? (
                                    <Link to="create-property" className=" md:w-3/12">
                                        {" "}
                                        <MainButton className="w-full lg:w-[310px] lg:px-7 py-2.5 ">
                                            <span className="text-[16px] font-normal px-2">+</span> Submit a Listing
                                        </MainButton>{" "}
                                    </Link>
                                ) : (
                                    <DisabledButton className="w-full lg:w-[310px] lg:px-7 py-2.5 ">+ Submit a listing</DisabledButton>
                                )}
                            </>
                        }
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        dashboardStyle={dashboardStyle}
                        setDashboardStyle={setDashboardStyle}
                        headerText="Find the Best Property"
                        canChangeStyle
                        showSearch
                        propertyType
                        investmentType
                        status={false}
                        filterBtn={false}
                        isUnverified={!user.kycVerified}
                        updatePropertyTypeFilter={(value) => {
                            applyFilterOnProperties("propertyType", value);
                        }}
                        updateInvestmentTypeFilter={(value) => {
                            applyFilterOnProperties("investmentType", value);
                        }}
                    />
                </div>
                <div className="">
                    {loading ? (
                        [1, 2, 3].map((idx) => <PropertySkeleton key={idx} />)
                    ) : displayList.length === 0 ? (
                        <div className="flex flex-col items-center justify-center h-full space-y-4 no-item">
                            <img src={NotFoundImg} alt="" />
                            <p className="text-[18px] lg:text-[25px] text-assetize-gray font-normal">
                                You do not have any Property Listings
                            </p>
                            <div>
                                {user.kycVerified ? (
                                    <Link to="create-property">
                                        {" "}
                                        <MainButton className="w-full lg:w-[310px] lg:px-7 py-2.5 ">
                                            <span className="text-[16px] font-normal px-2">+</span> Submit a Listing
                                        </MainButton>{" "}
                                    </Link>
                                ) : (
                                    <DisabledButton className="w-full lg:w-[310px] lg:px-7 py-2.5 ">+ Submit a listing</DisabledButton>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`${
                                dashboardStyle === DashboardStyle.grid ? "grid lg:grid-cols-3 xl:grid-cols-4" : "flex flex-col"
                            } p-0 md:p-8 mt-10 w-full rounded gap-x-1 md:gap-x-3 `}
                        >
                            {displayList.map((item) => (
                                <DeveloperPropertyCard property={item} isGrid={dashboardStyle === DashboardStyle.grid} />
                            ))}
                        </div>
                    )}
                    <div className="flex justify-end mt-5">
                        <Pagination pageData={pageData} setPageNum={setPageNum} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeveloperDashboard;
