import React from "react";

interface IconProps {
    children: React.ReactNode;
    handler?: () => void;
    isActive?: boolean;
}

const IconBox = ({ children, handler, isActive }: IconProps) => (
    <div
        onClick={handler}
        role="button"
        tabIndex={0}
        onKeyDown={handler}
        className={`border w-min p-1 rounded  ${
            isActive ? "bg-assetize-primary border-assetize-primary" : "bg-transparent border-assetize-dark-gray"
        }`}
    >
        {children}
    </div>
);

export default IconBox;
