import { DotsWithBg, EyeSee } from "Assets/Index";
import MenuList, { Option } from "Components/elements/Menu";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OptionsProps } from "types";
import AnswerModal from "./AnswerModal";
import ModalPanel from "Components/elements/Modal/Index";

interface BlogCardProps {
    heading: string;
    body: string;
    date?: string;
    id: string;
    imgUrl: string;
    numberOfViews: number;
}

const BlogCard = ({ imgUrl, heading, body, date, id, numberOfViews }: BlogCardProps) => {
    const navigate = useNavigate();
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });

    const options: OptionsProps[] = [
        { text: "Edit post", value: "edit" },
        { text: "Delete post", value: "delete" }
    ];

    const optionTwo: OptionsProps[] = [{ text: "View Details", value: "view" }];

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "edit") {
            navigate(`/admin/learn/edit/${id}`);
        } else if (option?.value === "delete") {
            setShowReplyModal({
                open: true,
                action: "delete"
            });
        }
    };

    const handleOptionTwo = async (option: Option) => {
        navigate(`${id}`);
    };

    return (
        <div className="rounded border flex-col md:flex-row border-assetize-gray py-1 px-2  my-2 flex justify-between">
            <div className=" first-letter: w-full  md:w-[20%] rounded">
                <img src={imgUrl} alt={heading} className="md:h-[100px] w-full" />
            </div>

            <div className="w-full md:w-[55%] md:pl-5">
                <h3 className="font-latoBold  text-assetize-dark-text text-sm">{heading.substring(0, 60)}</h3>
                <p className="text-assetize-light-text font-latoRegular  text-[12px] ">{body.substring(0, 80)}...</p>
                <p className="text-assetize-light-text font-latoLight text-[10px]"> {date ? date.substring(0, 10) : ""}</p>
            </div>
            <div className="md:flex hidden w-full md:w-[25%] lg:hidden items-center justify-between gap-3">
                <div>
                    <p className="text-assetize-light-text font-latoRegular text-sm py-1.5">{numberOfViews} views</p>
                </div>
                <div>
                    <div className="flex-[.2] justify-center flex">
                        <div className="bg-assetize-green-gradient flex justify-center px-2 py-1 rounded-2xl">
                            <p className="text-assetize-green-text text-sm ">Approved</p>
                        </div>
                    </div>
                </div>

                <div className="text-md font-latoRegular capitalize underline flex-[.1] flex justify-center ">
                    <div className="">
                        <MenuList
                            options={optionTwo}
                            size="28"
                            onSelect={handleOptionTwo}
                            icon={EyeSee}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                    <div className="">
                        <MenuList
                            options={options}
                            size="28"
                            onSelect={handleOptionSelect}
                            icon={DotsWithBg}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                </div>
            </div>

            <div className="flex md:hidden w-full md:w-[25%] lg:flex items-center justify-between gap-3">
                <div>
                    <p className="text-assetize-light-text font-latoRegular text-sm py-1.5">{numberOfViews} views</p>
                </div>
                <div>
                    <div className="flex-[.2] justify-center flex">
                        {/* <div className="bg-assetize-green-gradient flex justify-center px-2 py-1 rounded-2xl">
                            <p className="text-assetize-green-text text-sm ">Approved</p>
                        </div> */}
                    </div>
                </div>

                <div className="text-md font-latoRegular capitalize underline flex-[.1] flex justify-center ">
                    <div className="">
                        <MenuList
                            options={optionTwo}
                            size="28"
                            onSelect={handleOptionTwo}
                            icon={EyeSee}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                    <div className="">
                        <MenuList
                            options={options}
                            size="28"
                            onSelect={handleOptionSelect}
                            icon={DotsWithBg}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                </div>
            </div>

            <ModalPanel open={showReplyModal.open} closeButton closeModal={() => setShowReplyModal({ open: false, action: "" })}>
                <AnswerModal
                    action={showReplyModal.action}
                    id={id}
                    isHome
                    closeModal={() => setShowReplyModal({ open: false, action: "" })}
                />
            </ModalPanel>
        </div>
    );
};

export default BlogCard;
