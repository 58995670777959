import React, { useState, useCallback, DragEvent } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

type TASDocUpload = {
    onChange(e: any): void;
    onDrop?(e: DragEvent): void;
    error: string | undefined;
    accept?: string;
    name: string;
    label: string;
};

const ASDocUpload = ({ onChange, onDrop, error, accept = "image/jpeg, image/png, application/pdf", name, label }: TASDocUpload) => {
    const [uploadedFileName, setUploadedFileName] = useState("");

    // const handleDrop = useCallback(
    //     (e: DragEvent) => {
    //         e.preventDefault();
    //         const droppedFiles = Array.from(e.dataTransfer.files);

    //         if (droppedFiles.length > 0) {
    //             const selectedFile = droppedFiles[0];
    //             ('Dropped file:', selectedFile.name);
    //             setUploadedFileName(selectedFile.name);

    //             onChange(selectedFile);

    //             if (onDrop) {
    //                 onDrop(e);
    //             }
    //         }
    //     },
    //     [onChange, onDrop]
    // );

    const localOnChange = (e: any) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setUploadedFileName(selectedFile.name);
        } else {
            setUploadedFileName("");
        }

        onChange(e);
    };

    const subTextContent = !uploadedFileName ? (
        <p className="px-6 text-assetize-dark font-latoRegular text-[12px] ">
            Drag and drop your document here or <span className="text-assetize-primary font-bold">Browse</span> to upload
            <br />
            <span className="text-[#92929D] text-[10px]">Supported file types: JPEG, PNG, PDF, Max file size: 5mb</span>
        </p>
    ) : (
        <p className="px-6 text-assetize-dark">{uploadedFileName}</p>
    );
    return (
        <div>
            <label className="font-latoBold text-assetize-dark-gray  cursor-pointer" htmlFor={name}>
                {label}
                <div
                    onDragOver={(e) => e.preventDefault()}
                    // onDrop={handleDrop}
                    className="flex items-center justify-start w-full h-91 bg-[#F9FAFB] mt-3 border-2 border-dashed rounded-lg"
                >
                    <div className="flex align-center items-center px-10 py-5 pt-5 pb-5">
                        <AiOutlineCloudUpload className="text-5xl w-[24px] h-[24px] text-gray-400" />
                        {subTextContent}
                    </div>

                    <input type="file" name={name} className="hidden" onChange={localOnChange} accept={accept} id={name} />
                </div>
            </label>
            {error && <p className="text-xs text-red-600">{error}</p>}
        </div>
    );
};

export default ASDocUpload;
