import ModalPanel from "Components/elements/Modal/Index";
import { readableDate } from "Utils/Helpers";
import React, { useState } from "react";
import { Status } from "types/data";

type Props = {
    item: any;
};

const OrderListCard = ({ item }: Props) => {
    const createdAt = readableDate(item.createdAt);
    const [showTransactionDetails, setSHowTransactionDetails] = useState(false);
    let statusText;
    let viewStatusText;

    if (item.transactionStatus === Status.SUCCESSFUL) {
        statusText = (
            <div className="bg-assetize-green-gradient flex justify-start flex-[.05] p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-green-text">Successful</p>
            </div>
        );
    } else if (item.transactionStatus === Status.FAILED) {
        statusText = (
            <div className="bg-assetize-primary-gradient flex justify-start flex-[.05] p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-primary">failed</p>
            </div>
        );
    } else {
        statusText = (
            <div className="bg-assetize-warning-gradient flex justify-start flex-[.05]  p-1 rounded-2xl">
                <p className="text-[#F39B0D] text-sm px-2">pending</p>
            </div>
        );
    }

    if (item.transactionStatus === Status.SUCCESSFUL) {
        viewStatusText = <p className=" text-assetize-green-text">Successful</p>;
    } else if (item.transactionStatus === Status.FAILED) {
        viewStatusText = <p className=" text-assetize-primary">Failed</p>;
    } else {
        viewStatusText = <p className="text-[#F39B0D] ">Pending</p>;
    }

    const handleShowTransactionDetails = () => {
        setSHowTransactionDetails(!showTransactionDetails);
    };

    const amountOrder = item.numberOfSharesSold * item.propertySharePrice;

    return (
        <div>
            <div className="hidden md:flex items-center justify-between w-full px-4 py-5 my-2 border rounded border-assetize-gray">
                <h3 className=" w-1/6 text-md font-latoRegular capitalize flex justify-start  text-sm">{item?.customerName || "N/A"}</h3>
                <h3 className=" w-1/6 text-md font-latoRegular capitalize flex justify-start ml-2 text-sm">{item.propertyName || "N/A"}</h3>
                <h3 className="w-1/6 text-md font-latoRegular capitalize text-sm flex justify-start  ">{createdAt}</h3>
                <h3 className="w-1/6 text-md font-latoRegular capitalize text-sm flex justify-start px-4 ml-2">${amountOrder || 0}</h3>
                <h3 className="w-1/6 text-md font-latoRegular text-md capitalize text-sm flex justify-start  mr-6  flex-[.1]">
                    {statusText}
                </h3>
                <button
                    type="button"
                    onClick={() => {
                        setSHowTransactionDetails(true);
                    }}
                    className="flex-[.1] w-1/6 text-assetize-primary justify-center text-md text-base font-semibold underline"
                >
                    View
                </button>
            </div>
            <div className="flex md:hidden">
                <button type="button" onClick={handleShowTransactionDetails} className="cursor-pointer w-full">
                    <div className="flex items-center justify-between w-full p-3 px-4 py-5 my-2 border rounded border-assetize-gray">
                        <div className="text-start w-1/2 flex gap-3 items-start flex-col">
                            <h4 className=" flex-[.1] text-md font-latoRegular capitalize flex justify-start  text-sm">
                                {item?.customerName || "N/A"}
                            </h4>
                            <h4 className=" flex-[.1] text-md font-latoRegular capitalize flex justify-start  text-sm">
                                {item?.propertyName || "N/A"}
                            </h4>
                            {/* <h4 className="text-md font-latoRegular capitalize text-sm flex justify-start flex-[.1]">{statusText}</h4> */}
                        </div>
                        <div className="items-end w-1/2 flex gap-3 flex-col">
                            <h4 className=" text-md font-latoRegular capitalize text-sm flex justify-start flex-[.1]">
                                ${item?.propertySharePrice || "N/A"}
                            </h4>
                            <h4 className="flex-[.1] text-md font-latoRegular capitalize text-sm flex justify-start  ">{createdAt}</h4>
                        </div>
                    </div>
                </button>
            </div>
            <ModalPanel closeModal={() => setSHowTransactionDetails(false)} open={showTransactionDetails} closeButton>
                <div className="flex flex-col mb-4 px-2 ">
                    <div className="flex text-assetize-primary text-3xl font-latoBold mt-16">${amountOrder || 0}</div>
                    <hr className="border-t-4 border-solid " />
                    <div className="text-dark text-sm md:textmd justify-between flex">
                        <p className="text-sm font-latoRegular text-assetize-light-text">Details </p>{" "}
                        <p className="text-sm md:text-md font-assetizeRegular text-assetize-light-text">{item.propertyName}</p>
                    </div>
                    <hr className="border-t border-assetize-gray " />
                    <div className="text-dark text-sm md:textmd justify-between flex">
                        <p className="text-sm font-latoRegular text-assetize-light-text">Status</p>{" "}
                        <p className="text-sm font-assetizeRegular text-assetize-green capitalize"> {item.transactionStatus}</p>
                    </div>
                    <hr className="border-t border-assetize-gray " />
                    <div className="text-dark text-sm md:textmd justify-between flex">
                        <p className="text-sm font-latoRegular text-assetize-light-text"> Reference</p>
                        <p className="text-sm font-latoRegular text-assetize-light-text"> {item.transactionReference}</p>
                    </div>
                    <hr className="border-t border-assetize-gray " />
                    <div className="text-dark text-sm md:textmd justify-between flex">
                        <p className="text-sm font-latoRegular text-assetize-light-text"> Date</p>

                        <p className="text-sm font-latoRegular  text-assetize-light-text"> {createdAt}</p>
                    </div>
                    <hr />
                </div>
            </ModalPanel>
        </div>
    );
};

export default OrderListCard;
