import React, { useEffect, useState } from "react";
import { Building, Case, House, Land, Mall, NullImg, SecondBuilding } from "Assets/Index";
import { MainButton } from "Components/elements/Button/Index";
import Card from "Components/elements/Card/Index";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { addProperties } from "redux/slices/property.slice";
import {
    GetLearnArticlesApi,
    GetFastSellingPropertyApi,
    GetMarketPlaceApi,
    GetPropertyOfTheWeekApi,
    GetRecommendedPropertyApi,
    GetSingleDeveloper
} from "Services";
import { BlogProps } from "types";
import BlogCard from "Pages/Learn/BlogCard";
import { BounceLoader } from "react-spinners";

const nav = [
    {
        name: "All",
        img: Building
    },
    {
        name: "Apartment",
        img: House
    },
    {
        name: "Commercial",
        img: Building
    },
    {
        name: "Estate",
        img: SecondBuilding
    },
    {
        name: "Mall",
        img: Mall
    },
    {
        name: "Land",
        img: Land
    },
    {
        name: "Office",
        img: Case
    },
    {
        name: "Other Properties",
        img: Building
    }
];

const Dashboard = () => {
    // const [selectedOption, setSelectedOption] = useState(BlogsCategory.all);
    const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
    const [propertyOfTheWeek, setPropertyOfTheWeek] = useState<Record<string, any> | null>(null);
    const [recommendedProperty, setRecommendedProperty] = useState<any[]>([]);
    const [fastSellingProperty, setFastSellingProperty] = useState<Record<string, any> | any>(null);
    const [newList, setNewlist] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [articles, setArticles] = useState<BlogProps[]>([]);

    const isAvailable = propertyOfTheWeek?.availabilityStatus === "AVAILABLE";
    const isResale = propertyOfTheWeek?.availabilityStatus === "SOLD_OUT";

    const statusStyle = {
        background: "",
        text: "",
        textContent: ""
    };

    if (isAvailable) {
        statusStyle.background = "bg-green-200";
        statusStyle.text = "text-assetize-green-text";
        statusStyle.textContent = "Available";
    } else if (isResale) {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Re-sale ";
    } else {
        statusStyle.background = "bg-red-200";
        statusStyle.text = "text-assetize-primary";
        statusStyle.textContent = "Sold Out";
    }

    const navigate = useNavigate();
    const handleBlogCardClick = (id: any) => {
        navigate(`learn/${id}`);
    };
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleBlogCardKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, id: any) => {
        if (event.key === "Enter" || event.key === " ") {
            navigate(`learn/${id}`);
        }
    };

    function truncateText(text: string, maxLength: number): string {
        if (text?.length > maxLength) {
            return `${text?.slice(0, maxLength)}...`;
        }
        return text;
    }

    const fetchPropertyData = async () => {
        setLoading(true);
        try {
            const res = await Promise.all([
                GetPropertyOfTheWeekApi(),
                GetRecommendedPropertyApi(),
                GetFastSellingPropertyApi(),
                GetMarketPlaceApi(),
                GetLearnArticlesApi(1)
            ]);

            setPropertyOfTheWeek(res[0].propertyWithGallery);

            setRecommendedProperty(res[1]);
            setFastSellingProperty(res[2].propertyWithGallery);

            setNewlist(res[3].propertiesWithGallery);
            let articles = [];

            if (res[4] && res[4].postsWithImages && res[4].postsWithImages.length > 3) {
                articles = res[4].postsWithImages.slice(0, 3);
            } else if (res[4] && res[4].postsWithImages) {
                articles = [...res[4].postsWithImages];
            }

            setArticles(articles);

            dispatch(addProperties(res[3]?.propertiesWithGallery));
        } catch (err: any) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPropertyData();
    }, []);

    return (
        <div className="">
            {loading ? (
                <div className="flex items-center justify-center mt-64">
                    <BounceLoader color="#ca1611" />
                </div>
            ) : (
                <div>
                    {propertyOfTheWeek && (
                        <div className="lg:px-5 my-5 bg-white rounded  text-assetize-dark">
                            <div className="p-2 md:p-5 grid grid-cols-2 items-center justify-between lg:grid-cols-3">
                                <div className="flex items-center justify-center w-full h-48 md:h-60">
                                    <div className="flex flex-col justify-center items-center pr-3 md:pr-0  md:w-9/12 md:space-y-9 ">
                                        <div className="space-y-1">
                                            <h1 className="font-latoBold text-[18px] leading-6 md:text-lg">Property of the week</h1>
                                            <h5 className="font-latoBold  text-sm md:hidden text-assetize-dark-text ">
                                                {propertyOfTheWeek?.propertyName}
                                            </h5>
                                            <p className="font-latoRegular text-[11px]  md:hidden text-assetize-dark-text ">
                                                {propertyOfTheWeek?.location}
                                            </p>
                                            <h4 className="font-latoRegular text-md  md:hidden text-assetize-dark-text ">
                                                ${propertyOfTheWeek?.fiatValue.toLocaleString()}
                                            </h4>
                                            <p className="hidden md:flex">{truncateText(propertyOfTheWeek?.description, 100)} </p>
                                        </div>
                                        <MainButton
                                            onClick={() => navigate(`/investor/${propertyOfTheWeek?.id}`, { state: propertyOfTheWeek })}
                                            type="submit"
                                            className=" md:w-10/12 mt-4 font-semibold"
                                        >
                                            Buy Shares
                                        </MainButton>
                                    </div>
                                </div>
                                <div
                                    className="relative h-48 md:h-60"
                                    style={{
                                        backgroundImage: `url(${NullImg})`
                                    }}
                                >
                                    <img
                                        src={propertyOfTheWeek?.propertyGallery?.propertyImages[0]}
                                        alt="fast_selling"
                                        className="absolute top-0 object-cover w-full h-full rounded-lg shadow-lg"
                                    />
                                </div>
                                <div className="hidden lg:flex items-center justify-center w-full h-48 md:h-96 ">
                                    <div className="w-9/12">
                                        <div>
                                            <h3 className="flex items-center text-xl font-bold gap-x-4">
                                                {propertyOfTheWeek?.propertyName}
                                                <span
                                                    className={`px-2 py-1 text-center  text-[10px] rounded-full  ${
                                                        isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                                                    } ${statusStyle.text}`}
                                                >
                                                    {statusStyle.textContent}
                                                </span>
                                            </h3>
                                            <p className="text-gray-600">{propertyOfTheWeek?.location}</p>
                                            <p className="mt-2 text-lg font-bold">${propertyOfTheWeek?.fiatValue?.toLocaleString()}</p>
                                        </div>

                                        <hr />
                                        <div>
                                            <p className="flex items-center justify-between text-gray-600">
                                                Price per share{" "}
                                                <span className="font-semibold">${propertyOfTheWeek?.sharePrice?.toLocaleString()}</span>
                                            </p>
                                            <p className="flex justify-between text-gray-600">
                                                Available shares{" "}
                                                <span className="font-semibold">{propertyOfTheWeek?.availableNumberOfShares}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <nav className="mt-5 bg-white">
                        <div className="flex flex-wrap items-center content-end justify-between max-w-screen-xl px-5 py-1 mx-auto">
                            <ul className="items-center mt-4 justify-between w-full bg-white rounded-lg flex space-x-2 flex-row gap-2 overflow-auto border-0 hover:text-assetize-dark self-end">
                                {nav.map((item, index) => (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        key={index}
                                        className="flex cursor-pointer hover:text-assetize-primary self-end"
                                        onClick={() => navigate(`investment?propertyType=${item.name}`)}
                                    >
                                        <a
                                            className="flex-col items-center justify-center md:flex-row md:items-end py-2 text-xs rounded flex w-full  text-assetize-dark lg:text-sm  hover:text-assetize-primary"
                                            aria-current="page"
                                        >
                                            {item.img ? <img src={item.img} alt={item.name} className="w-5 h-5 mx-1" /> : null}
                                            <div className="ml-2  ">
                                                {" "}
                                                <p className="text-xs ">{item.name}</p>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </nav>
                    {loading
                        ? ""
                        : recommendedProperty.length > 0 && (
                              <div className="p-5 my-5 bg-white rounded">
                                  <h5 className="font-bold text-assetize-dark">Recommended for You</h5>
                                  <div className="flex py-4 overflow-x-scroll gap-x-2">
                                      {recommendedProperty.map((property) =>
                                          property && property.property ? (
                                              <Card
                                                  key={property.id}
                                                  property={{
                                                      ...property.property,
                                                      propertyGallery: { propertyImages: property.propertyImages }
                                                  }}
                                              />
                                          ) : null
                                      )}
                                  </div>
                              </div>
                          )}

                    {fastSellingProperty && (
                        <div className="lg:px-5 my-5 bg-white rounded  text-assetize-dark">
                            <div className="p-2 md:p-5 grid grid-cols-2 items-center justify-between lg:grid-cols-3">
                                <div className="flex items-center justify-center w-full h-48 md:h-60">
                                    <div className="flex flex-col justify-center items-center px-3  md:w-9/12 md:space-y-9 ">
                                        <div className="space-y-1">
                                            <h1 className="font-latoBold text-[18px] leading-6 md:text-lg">Fast Selling Property</h1>
                                            <h5 className="font-latoBold text-sm  md:hidden text-assetize-dark-text ">
                                                {fastSellingProperty?.propertyName}
                                            </h5>
                                            <p className="font-latoRegular text-[11px]  md:hidden text-assetize-dark-text ">
                                                {fastSellingProperty?.location}
                                            </p>
                                            <h4 className="font-latoRegular text-md  md:hidden text-assetize-dark-text ">
                                                ${fastSellingProperty?.fiatValue.toLocaleString("en-Us")}
                                            </h4>
                                            <p className="hidden md:flex">{truncateText(fastSellingProperty?.description, 100)} </p>
                                        </div>
                                        <MainButton
                                            onClick={() => navigate(`/investor/${fastSellingProperty?.id}`, { state: fastSellingProperty })}
                                            type="submit"
                                            className="w-11/12 mt-4 font-semibold"
                                        >
                                            Buy Shares
                                        </MainButton>
                                    </div>
                                </div>
                                <div
                                    className="relative h-48 md:h-60"
                                    style={{
                                        backgroundImage: `url(${NullImg})`
                                    }}
                                >
                                    <img
                                        src={fastSellingProperty?.propertyGallery?.propertyImages[0]}
                                        alt="fast_selling"
                                        className="absolute top-0 object-cover w-full h-full rounded-lg shadow-lg"
                                    />
                                </div>
                                <div className="hidden lg:flex items-center justify-center w-full h-48 md:h-96 ">
                                    <div className="w-9/12">
                                        <div>
                                            <h3 className="flex items-center text-xl font-bold gap-x-4">
                                                {fastSellingProperty?.propertyName}
                                                <span
                                                    className={`px-2 py-1 text-center  text-[10px] rounded-full  ${
                                                        isAvailable ? "bg-assetize-light-green" : "bg-red-200"
                                                    } ${statusStyle.text}`}
                                                >
                                                    {statusStyle.textContent}
                                                </span>
                                            </h3>
                                            <p className="text-gray-600">{fastSellingProperty?.location}</p>
                                            <p className="mt-2 text-lg font-bold">${fastSellingProperty?.fiatValue?.toLocaleString()}</p>
                                        </div>

                                        <hr />
                                        <div>
                                            <p className="flex items-center justify-between text-gray-600">
                                                Price per share{" "}
                                                <span className="font-semibold">${fastSellingProperty?.sharePrice?.toLocaleString()}</span>
                                            </p>
                                            <p className="flex justify-between text-gray-600">
                                                Available shares{" "}
                                                <span className="font-semibold">{fastSellingProperty?.availableNumberOfShares}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {newList.length > 0 && (
                        <div className="p-5 my-5 bg-white rounded">
                            <h5 className="font-bold text-assetize-dark">Newly Listed</h5>
                            <div className="flex  gap-x-2 overflow-x-scroll">
                                {newList.slice(0, 5).map((property) => (
                                    <Card key={property?.id} property={property} />
                                ))}
                            </div>

                            <div className="flex w-2/5 items-center justify-center mx-auto">
                                <MainButton
                                    onClick={() => navigate("investment?propertyType=All")}
                                    type="submit"
                                    className=" mt-10 mb-4 font-semibold"
                                >
                                    View More
                                </MainButton>
                            </div>
                        </div>
                    )}
                    {articles.length > 0 && (
                        <div className="p-5 my-5 bg-white rounded">
                            <h5 className="font-bold py-5 text-assetize-dark">Recent News</h5>
                            {articles?.map((item) => (
                                <div
                                    key={item.id}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleBlogCardClick(item.id)}
                                    onKeyDown={(event) => handleBlogCardKeyDown(event, item.id)}
                                    className="focus:outline-none"
                                >
                                    <BlogCard
                                        key={item.id}
                                        heading={item.title}
                                        body={item.content}
                                        id={item.id}
                                        date={item.lastUpdated}
                                        imgUrl={item.headerImage}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Dashboard;
