import React, { useState } from "react";
import { Verified } from "Assets/Index";
import { MainButton } from "Components/elements/Button/Index";
import Textarea from "Components/elements/Forms/TextArea";
import { ApproveProperty, authenticateDeveloper } from "Services/apis/admin.api";
import { useNavigate } from "react-router-dom";

interface AnswerProps {
    action: string;
    id: string;
    isHome: boolean;
}

const AnswerModal = ({ action, id, isHome }: AnswerProps) => {
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    const authenticate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!message || message.length < 10) {
            setError("Text should be at least 20 characters long");
            return;
        }

        setError("");

        try {
            setLoading(true);
            const data = {
                verdict: action === "approve",
                reason: message,
                id,
                reviewMessage: action === "approve" ? "Property Approved" : "Property Rejected"
            };
            const res = await ApproveProperty(data);

            setAuthenticated(true);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const currentLocation = window.location;

    return (
        <div>
            {authenticated ? (
                <div>
                    <div className="mt-8 flex flex-col justify-center items-center">
                        <img src={Verified} alt="verification successful" className="w-32" />
                        <h2 className="text-assetize-primary text-2xl font-latoBold align-center mt-8">Sent</h2>
                        <p className="text-assetize-dark-gray my-2 text-md align-center text-md ">
                            The Property Developer will be contacted
                        </p>

                        <div className="mt-5 w-full flex justify-center items-center">
                            <div className="w-[65%]">
                                <MainButton
                                    onClick={() => (isHome ? currentLocation.reload() : navigate("/admin/properties"))}
                                    type="submit"
                                >
                                    Continue
                                </MainButton>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container p-5 mx-auto my-2 rounded bg-white w-[90%] md:w-[100%]">
                    <form onSubmit={authenticate}>
                        <h3 className="text-md font-latoRegular mb-5 text-center text-assetize-primary">
                            {action === "reject" ? "Reason for rejection " : "Reply developer"}
                        </h3>
                        <Textarea
                            label="Message"
                            name="message"
                            value={message}
                            placeholder=""
                            error={error}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <div className="mt-5 w-full flex justify-center items-center">
                            <div className="w-[65%]">
                                <MainButton isLoading={loading} disabled={loading} type="submit">
                                    Send
                                </MainButton>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default AnswerModal;
