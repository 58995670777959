import { Account, Authentication, Dashboard, Invest, Learn, Logout, Portfolio, Support, Users, Wallet } from "Assets/Index";
import React from "react";
import { UserAccountType } from "types/Auth.types";

export interface SidebarLinks {
    id: number;
    title: string;
    url: string;
    icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    headingText: string;
}

export const investorSidebarLinks: SidebarLinks[] = [
    {
        id: 1,
        title: "Invest",
        url: "investor",
        icon: Invest,
        headingText: "Invest"
    },
    {
        id: 2,
        title: "My Portfolio",
        url: "portfolio",
        icon: Portfolio,
        headingText: "My Portfolio"
    },
    {
        id: 3,
        title: "Wallets",
        url: "wallets",
        icon: Wallet,
        headingText: "Wallet"
    },
    {
        id: 4,
        title: "Learn",
        url: "learn",
        icon: Learn,
        headingText: "Learn"
    },
    {
        id: 5,
        title: "Account Settings",
        url: "account-settings",
        icon: Account,
        headingText: "Account Settings"
    }
];

export const developerSidebarLinks: SidebarLinks[] = [
    {
        id: 1,
        title: "Property Listings",
        url: "developer",
        icon: Invest,
        headingText: "Property Listings"
    },

    {
        id: 2,
        title: "Analytics & Reports",
        url: "reports",
        icon: Invest,
        headingText: "Analytics & Reports"
    },

    {
        id: 4,
        title: "Order List",
        url: "orders",
        icon: Invest,
        headingText: "Order List"
    },
    {
        id: 5,
        title: "Learn",
        url: "learn",
        icon: Learn,
        headingText: "Learn"
    },
    {
        id: 6,
        title: "Account Settings",
        url: "account-settings",
        icon: Account,
        headingText: "Account Settings"
    }
];

export const adminSidebarLinks: SidebarLinks[] = [
    {
        id: 1,
        title: "Dashboard",
        url: "admin",
        icon: Dashboard,
        headingText: "Dashboard"
    },
    {
        id: 2,
        title: "Authentication",
        url: "authentication",
        icon: Authentication,
        headingText: "Authentication"
    },
    {
        id: 3,
        title: "Properties",
        url: "properties",
        icon: Invest,
        headingText: "Properties"
    },
    {
        id: 3,
        title: "Learn",
        url: "learn",
        icon: Learn,
        headingText: "Learn"
    },
    {
        id: 4,
        title: "Users",
        url: "users",
        icon: Users,
        headingText: "Users"
    }
];

export const sidebarLinks: Record<UserAccountType, SidebarLinks[]> = {
    [UserAccountType.INVESTOR]: investorSidebarLinks,
    [UserAccountType.DEVELOPER]: developerSidebarLinks,
    [UserAccountType.ADMIN]: adminSidebarLinks
};

const m = {
    m: investorSidebarLinks
};

export const investorSidebarBottomLinks: SidebarLinks[] = [
    {
        id: 1,
        title: "Customer Support",
        url: "support",
        icon: Support,
        headingText: "Customer Support"
    },
    {
        id: 2,
        title: "Logout",
        url: "logout",
        icon: Logout,
        headingText: "Logout"
    }
];

export const developerSidebarBottomLinks: SidebarLinks[] = [
    {
        id: 1,
        title: "Customer Support",
        url: "support",
        icon: Support,
        headingText: "Customer Support"
    },
    {
        id: 2,
        title: "Logout",
        url: "logout",
        icon: Logout,
        headingText: "Logout"
    }
];

export const adminSidebarBottomLinks: SidebarLinks[] = [
    {
        id: 1,
        title: "Logout",
        url: "logout",
        icon: Logout,
        headingText: "Logout"
    }
];

export const x: Record<UserAccountType, SidebarLinks[]> = {
    [UserAccountType.INVESTOR]: investorSidebarBottomLinks,
    [UserAccountType.DEVELOPER]: developerSidebarBottomLinks,
    [UserAccountType.ADMIN]: adminSidebarBottomLinks
};

export const sidebarBottomLinks = {
    m: investorSidebarBottomLinks
};

export default sidebarLinks;
