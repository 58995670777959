import React from "react";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { MainButton } from "./Index";

type PropertyPerformanceData = {
    numberOfInvestors: string | number;
    totalInvestmentValue: string | number;
    totalRevenue: string | number;
    investmentsByTimeFrame: Record<string, number>;
    // Add other relevant properties from your PropertyPerformance data structure
};

type Props = {
    data: PropertyPerformanceData;
};

const PropertyPerformanceExportButton = ({ data }: Props) => {
    const handleExportCSV = () => {
        // Prepare data for CSV export
        const csvData = [
            ["Property Performance Data"],
            ["Number of Investors", "Total Investment Value", "Total Revenue"],
            [data.numberOfInvestors.toString(), data.totalInvestmentValue.toString(), data.totalRevenue.toString()],
            [], // Add headers for other data properties
            ["Time Frame", "Value"],
            ...Object.entries(data.investmentsByTimeFrame).map(([timeFrame, value]) => [timeFrame, value.toString()])
        ];

        // Generate CSV file
        const csvHeaders = { filename: "property_performance.csv" };

        return (
            <CSVLink
                data={csvData}
                {...csvHeaders}
                className="w-[250px] items-center text-center py-2 text-sm text-white white rounded-md font-latoLight bg-assetize-primary hover:text-white"
            >
                Export as CSV
            </CSVLink>
        );
    };

    const handleExportPDF = () => {
        // Prepare data for PDF export
        const PdfData = `
            Property Performance Data
            Number of Investors: ${data.numberOfInvestors}
            Total Investment Value: ${data.totalInvestmentValue}
            Total Revenue: ${data.totalRevenue}
            
            Time Frame | Value
            ${Object.entries(data.investmentsByTimeFrame)
                .map(([timeFrame, value]) => `${timeFrame}: ${value}`)
                .join("\n")}
        `;

        // Generate PDF file

        // eslint-disable-next-line
        const Pdf = new jsPDF();
        Pdf.text(PdfData, 10, 10);
        Pdf.save("property_performance.pdf");
    };

    return (
        <div className="md:flex-row flex-col self-center items-center w-full flex justify-center gap-4">
            {handleExportCSV()}
            <div className="w-[250px]">
                <MainButton onClick={handleExportPDF}>Export as PDF</MainButton>
            </div>
        </div>
    );
};

export default PropertyPerformanceExportButton;
