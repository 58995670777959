/* eslint-disable react/button-has-type */
import { Filter } from "Assets/Index";
import React from "react";
import { ClipLoader } from "react-spinners";

type MainBttonProps = {
    type?: "button" | "submit" | "reset";
    children?: React.ReactNode;
    props?: any;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    disabled?: boolean;
    isLoading?: boolean;
};

type TOulineButton = Omit<MainBttonProps, "type"> & { color?: string };

export const MainButton = ({ isLoading, onClick, type = "button", children, className, disabled = false, ...props }: MainBttonProps) => (
    <button
        type={type}
        className={`w-full py-2 text-sm text-white rounded-md font-latoLight bg-assetize-primary  hover:text-white ${className} `}
        {...props}
        onClick={onClick}
        disabled={disabled}
    >
        {isLoading ? <ClipLoader color="#fff" size="20px" /> : children}
    </button>
);
export const DisabledButton = ({ onClick, type = "button", children, className, disabled = true, ...props }: MainBttonProps) => (
    <button
        type={type}
        className={`${className} w-full py-2 text-sm text-white rounded-md bg-assetize-dark-text font-latoLight  hover:text-white `}
        {...props}
        onClick={onClick}
        disabled={disabled}
    >
        {children}
    </button>
);

export const FilterButton = ({ ...props }: any) => (
    <button
        type="button"
        className="w-full py-2 text-sm flex items-center justify-center text-white rounded-md font-latoLight bg-assetize-primary-gradient border border-assetize-primary hover:text-white bg-assetize-"
        {...props}
    >
        <span>
            <Filter />{" "}
        </span>
        <span className="text-assetize-primary ml-2"> Filter</span>
    </button>
);

export const OutlineButton = ({ isLoading, onClick, className, children, color = "black", ...props }: TOulineButton) => (
    <button
        type="button"
        className={`${className} font-latoLight bg-white border-${color} text-sm  text-${color} border outline-none w-full rounded-lg py-2 hover:text-assetize-primary bg-assetize-`}
        {...props}
        onClick={onClick}
    >
        {isLoading ? <ClipLoader color="#CA1611" size="20px" /> : children}
    </button>
);
