import React, { useEffect, useState } from "react";
import { Verified } from "Assets/Index";
import { MainButton } from "Components/elements/Button/Index";
import { useNavigate } from "react-router-dom";
import Input from "Components/elements/Forms/Input";
import Select from "react-select";
import { Switch } from "@headlessui/react";
import { ListSharesApi, UpdateSharesApi } from "Services/apis";
import { validateAll } from "indicative/validator";
import { toast } from "react-toastify";
import SuccessMessageModal from "Components/elements/Display/SuccessMessageModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getErrorMessge } from "Utils/Helpers";
import { AiOutlineCalendar } from "react-icons/ai";

interface AnswerProps {
    id: string;
    property: any;
    percentageOwned: string | number;
    isEdit: boolean;
}

const CreateListing = ({ id, property, percentageOwned, isEdit }: AnswerProps) => {
    const valueType = [{ label: "USD", value: "USD" }];
    const [formData, setFormData] = useState({
        numberOfShares: "",
        priceOfShares: "",
        date: "",
        alwaysOpen: true
    });
    const [date, setDate] = useState<any>(null);
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [loading, setLoading] = useState(false);
    const [usdEquivalent, setUsdEquivalent] = useState(0);

    const handleInputChange = (name: string, value: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if (isEdit) {
            if (property.completionDate !== null) {
                setFormData({
                    numberOfShares: property.shareNumber,
                    priceOfShares: (property.sharePrice / property.shareNumber).toString(),
                    date: property.completionDate,
                    alwaysOpen: property.completionDate ? false : property.alwaysOpen
                });
                const formattedDate = property.completionDate.toLocaleString("en-US", {
                    timeZone: "Africa/Lagos",
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZoneName: "short"
                });

                const date = new Date(formattedDate);
                setDate(date);
            } else {
                setFormData({
                    numberOfShares: property.shareNumber,
                    priceOfShares: (property.sharePrice / property.shareNumber).toString(),
                    date: property.completionDate,
                    alwaysOpen: true
                });

                setDate(null);
            }
        }
    }, [isEdit]);

    useEffect(() => {
        if (formData.numberOfShares || formData.priceOfShares) {
            const value = Number(formData.priceOfShares) * Number(formData.numberOfShares);
            setUsdEquivalent(value);
        }
    }, [formData.numberOfShares, formData.priceOfShares]);

    const handleDateChange = (date: any) => {
        setDate(date);
    };

    const [successful, setSuccessful] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();

    const currentLocation = window.location;
    const formatDate = (date: { getFullYear: () => any; getMonth: () => number; getDate: () => any }) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const createListingRequest = async () => {
        if (isEdit) {
            const payload = {
                shareNumber: Number(formData.numberOfShares),
                sharePrice: parseFloat(formData.priceOfShares.toString()),
                alwaysOpen: formData.alwaysOpen,
                closeDate: formData.alwaysOpen ? null : formatDate(date)
            };
            try {
                setLoading(true);
                const res = await UpdateSharesApi(property.listingId, payload);

                setSuccessful(true);
                toast.success("Share listing updated successfully");
            } catch (error: any) {
                setErrors(error);

                setLoading(false);
                toast.error(error?.response?.data?.message[0]);
            } finally {
                setLoading(false);
            }
        } else {
            const payload = {
                numberOfShares: Number(formData.numberOfShares),
                pricePerShare: parseFloat(formData.priceOfShares.toString()),
                id,
                alwaysOpen: formData.alwaysOpen,
                closeDate: formData.alwaysOpen ? null : date
            };

            try {
                setLoading(true);

                const res = await ListSharesApi(payload);
                setSuccessful(true);
                toast.success("Share listing created successfully");
            } catch (error: any) {
                setLoading(false);
                setErrors({ async: error.message });
                const errMsg = getErrorMessge(error);
                toast.error(error?.response?.data?.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const createListing = async () => {
        const validationRules = {
            numberOfShares: "required",
            priceOfShares: "required",
            date: "required"
        };

        const validationMessages = {
            "numberOfShares.required": "Specify number of shares you want to purchase",
            "priceOfShares.required": "You have to set the price of each shares",
            "date.required": "Specify the close date"
        };

        const data = {
            ...formData,
            numberOfShares: formData.numberOfShares,
            priceOfShares: formData.priceOfShares,
            date: !date && !formData.alwaysOpen ? "" : "date"
        };

        validateAll(data, validationRules, validationMessages)
            .then(() => {
                createListingRequest();
            })
            .catch((error) => {
                if (Array.isArray(error)) {
                    const formattedErrors: any = {};
                    error.forEach((err) => {
                        formattedErrors[err.field] = err.message;
                    });

                    setErrors(formattedErrors);
                }
            });
    };

    return (
        <div>
            {successful ? (
                <SuccessMessageModal
                    handleClick={() => window.location.reload()}
                    descriptiontext={
                        isEdit
                            ? "Your offer has been edit successfully. You will be notified when other Investors buy from you"
                            : "Your offer has been created successfully. You will be notified when other Investors buy from you"
                    }
                    headingText={isEdit ? "Offer Edited Successfully" : "Offer Created Successfully!"}
                />
            ) : (
                <div className="container px-0 md:px-5 mx-auto my-1 rounded bg-white w-[100%] md:w-[100%]">
                    <form onSubmit={createListing}>
                        <h2 className="text-assetize-primary text-2xl font-latoBold text-center  align-center mt-2">
                            {isEdit ? "Edit Listing" : "Create Listing"}
                        </h2>
                        <p className="text-assetize-dark-gray my-2 text-md align-center text-md text-center font-latoBold">
                            {isEdit ? "Edit your share listing" : "    Sell your shares easily by creating listings"}
                        </p>

                        <div className="my-2">
                            <Input
                                label="Number of Shares"
                                type="number"
                                name="numberOfShares"
                                value={formData.numberOfShares.toString()}
                                placeholder="0"
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                error={errors.numberOfShares}
                            />
                        </div>
                        <p>The minimum number of shares that can be sold is 1.0</p>

                        <div className="my-2">
                            <label htmlFor="valueType" className="pb-1 text-sm font-latoRegular">
                                Price per Share
                                <Select
                                    id="valueType"
                                    options={valueType}
                                    defaultValue={{ label: "USD", value: "USD" }}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: 45,
                                            outline: "none",
                                            background: "transparent",
                                            border: errors.duration ? "solid #CA1611 1px" : "solid #D0D0D0 1px",
                                            boxShadow: state.isFocused ? "0 0 5px rgba(202, 22, 17, 0.5)" : "none",
                                            "&:hover": {
                                                border: "solid #CA1611 1px",
                                                boxShadow: "0 0 5px rgba(202, 22, 17, 0.5)"
                                            }
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            background: "white",
                                            color: "black",
                                            "&:hover": { background: "#CA1611" }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            border: "solid #CA1611 1px"
                                        })
                                    }}
                                    placeholder="Select value type"
                                    name="valueType"
                                    aria-errormessage={errors.valueType}
                                    onChange={(value) => handleInputChange("valueType", value)}
                                />
                                {errors && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{errors.valueType}</p>}
                            </label>
                        </div>
                        <div className="my-2">
                            {isEdit ? (
                                <Input
                                    label="Price of Shares"
                                    type="number"
                                    name="priceOfShares"
                                    value={formData.priceOfShares.toString()}
                                    placeholder="0"
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    error={errors.priceOfShares}
                                />
                            ) : (
                                <Input
                                    label="Price of Shares"
                                    type="number"
                                    name="priceOfShares"
                                    value={formData.priceOfShares.toString()}
                                    placeholder="0"
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    error={errors.priceOfShares}
                                />
                            )}
                        </div>

                        <div className="flex mt-5 justify-between w-full flex-row items-center">
                            <div className=" border-assetize-gray bg-assetize-primary-gradient focus:ring-assetize-gray focus:border-assetize-gray border rounded flex-[.4] relative">
                                <DatePicker
                                    selected={formData.alwaysOpen ? null : date}
                                    onChange={(date) => {
                                        handleDateChange(date);
                                        setFormData({ ...formData, alwaysOpen: false });
                                    }}
                                    minDate={new Date()}
                                    placeholderText="Pick Date"
                                    dateFormat="yyyy-MM-dd"
                                    disabled={formData.alwaysOpen}
                                    className="bg-transparent focus:ring-white focus:border-white font-latoRegular text-gray-500 w-[100%] p-2 py-4 text-sm apperance-none outline-none"
                                />
                                <div className="absolute right-5 top-4">
                                    <AiOutlineCalendar color="#CA1611" fontSize="20px" />
                                </div>
                            </div>
                            <div className=" mt-3 md:mt-0 flex-[.4]">
                                <div className="flex w-full justify-between items-center">
                                    <Switch
                                        checked={formData.alwaysOpen}
                                        onChange={(e) => {
                                            handleInputChange("alwaysOpen", !formData.alwaysOpen);

                                            handleDateChange(null);
                                        }}
                                        className={`${formData.alwaysOpen ? "bg-assetize-primary" : "bg-assetize-gray"}
        relative inline-flex md:h-[32px] h-[24px] w-[42px] md:w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={`${formData.alwaysOpen ? "translate-x-5" : "translate-x-0"}
          pointer-events-none inline-block h-[20px] md:h-[28px] w-[20px] md:w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                    <p className="md:text-lg text-sm text-assetize-dark font-latoRegular pl-3">Always Open</p>
                                </div>
                            </div>
                        </div>

                        {errors.date && <p className="mt-1 text-xs font-latoRegular text-assetize-primary ">{errors.date}</p>}

                        <div className="p-3 border border-1 border-assetize-primary rounded my-3">
                            <div className="flex justify-between items-center ">
                                <p className="uppercase  font-latoRegular text-assetize-dark-gray">USD Equivalent </p>

                                <p className="uppercase  font-latoBold text-assetize-dark-gray">${usdEquivalent} </p>
                            </div>
                            <div className="flex justify-between items-center ">
                                <p className="uppercase  font-latoRegular text-assetize-dark-gray">Estimated roi </p>
                                <p className="uppercase  font-latoBold text-assetize-dark-gray">{property.expectedAnnualROI}% </p>
                            </div>
                            <div className="flex justify-between items-center ">
                                <p className="uppercase  font-latoRegular text-assetize-dark-gray">Ownership percentage </p>
                                <p className="uppercase  font-latoBold text-assetize-dark-gray"> {percentageOwned}%</p>
                            </div>
                        </div>
                        {isEdit ? (
                            <div className="w-full mt-7">
                                <MainButton isLoading={loading} disabled={loading} onClick={createListing}>
                                    Update Listing
                                </MainButton>
                            </div>
                        ) : (
                            <div className="w-full mt-7">
                                <MainButton isLoading={loading} disabled={loading} onClick={createListing}>
                                    Create Listing
                                </MainButton>
                            </div>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
};

export default CreateListing;
