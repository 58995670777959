import React from "react";

type Props = {
    title: string;
    value: string;
};

const UserTotal = ({ value, title }: Props) => (
    <div className="flex flex-col md:flex-row w-full">
        <div className="flex flex-col justify-center w-full rounded-2xl bg-white p-2 border my-2 md:m-2 border-b-8 border-b-red-600 ">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className="w-4 h-4 rounded bg-assetize-primary" />
                    <p className="px-2 uppercase text-xs">{title}</p>
                </div>
            </div>
            <div className="flex items-center py-2 ml-2">
                <h3 className="text-2xl font-latoBold">{value}</h3>
            </div>
        </div>
    </div>
);

export default UserTotal;
