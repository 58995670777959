import axiosInstance from "Utils/Https";

// API TYPES
type TTansactionType = "FUNDING" | "PRIMARY_SALES" | "SECONDARY_SALES" | "SOLD_OUT_PROPERTY";
export type TFundWalletByCard = {
    email: string;
    amount: string | Number;
    currency: string;
};
type TWithdrawToExternalFiatAccount = {
    bankCode: string;
    accountNumber: string;
    amount: string;
    currency: string;
    narration: string;
    customerName: string;
};
type TDebitAndUSDTokenWallet = {
    amount: string;
};

type durationInterestProps = {
    durationInMonths: number;
    interestRate: number;
};

export type safeLockPropertyDataType = {
    propertyName: string;
    propertyDescription: string;
};

// type TSafelockWalletFunds = {
//     durationAndInterestRates: durationInterestProps[];
//     propertyDetails: any[];
// };

type WithdrawToExternalBankType = {
    bankCode: string;
    accountNumber: string;
    amount: number;
    currency: string;
    narration: string;
    customerName: string;
};

// type TWithdrawFromSafelockToFiat = Pick<TSafelockWalletFunds, 'amount'>;

// API IMPLEMENTATION FUNCTIONS
export const GetWalletUsdApi = async () => {
    const url = "wallets/usd";
    const res = await axiosInstance.get(url);

    return res.data;
};

export const GetWalletTokenApi = async () => {
    const url = "wallets/token";
    const res = await axiosInstance.get(url);
    return res.data;
};

export const GetTransactionHistoryApi = async (num?: number) => {
    const url = `/wallets/transaction-history?page=${num}`;
    const res = await axiosInstance.get(url);
    return res.data;
};

export const fundWalletApi = async () => {
    const url = "payment/initialize";
    const res = await axiosInstance.post(url);
    return res.data;
};

export const bankTransferApi = async () => {
    const url = "wallets/virtual-accounts";
    const res = await axiosInstance.get(url);
    return res.data;
};

export const GetSafeLockApi = async () => {
    const url = "wallets/safelock";
    const res = await axiosInstance.get(url);
    return res.data;
};

/**
 * Description
 * @returns {any}
 */
export const WalletDashboardApi = async (): Promise<any> => {
    const url = "wallets";
    const res = await axiosInstance.get(url);
    return res.data;
};

/**
 * Description
 * @param {any} transactionType:string
 * @returns {any}
 */
export const GetTransactionChargesApi = async (transactionType: TTansactionType): Promise<any> => {
    const url = `wallets/transaction-charges/${transactionType}`;
    const res = await axiosInstance.get(url);
    return res.data;
};

export const GetFundChargesApi = async (amount: string) => {
    try {
        const url = `payment/paystack/charges?amount=${amount}&currency=USD`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        return error;
    }
};

/**
 * Description
 * @param {any} payload:TFundWalletByCard
 * @returns {any}
 */
export const FundWalletByCardApi = async (payload: TFundWalletByCard): Promise<any> => {
    const url = "payment/initialize";
    const res = await axiosInstance.post(url, payload);
    return res.data;
};

/**
 * Description
 * @returns {any}
 */
export const FundByBankAccountApi = async (): Promise<any> => {
    const url = "wallets/fund/bank-transfer";
    const res = await axiosInstance.get(url);
    return res.data;
};

/**
 * Description
 * @returns {any}
 */
export const ConvertUSDToAssetizeTokensApi = async (): Promise<any> => {
    const url = "wallets/usd/convert-to-token";
    const res = await axiosInstance.post(url, {});
    return res.data;
};

/**
 * Description
 * @returns {any}
 */
export const ConvertAssetizeTokensToUSDApi = async (): Promise<any> => {
    const url = "wallets/token/convert-to-usd";
    const res = await axiosInstance.post(url, {});
    return res.data;
};

/**
 * Description
 * @param {any} payload:TWithdrawToExternalFiatAccount
 * @returns {any}
 */
export const WithdrawToExternalFiatAccountApi = async (payload: TWithdrawToExternalFiatAccount): Promise<any> => {
    const url = "payment/withdraw";
    const res = await axiosInstance.post(url, payload);
    return res.data;
};

/**
 * Description
 * @param {any} payload:TDebitTokenWallet
 * @returns {any}
 */
export const DebitTokenWalletApi = async (payload: TDebitAndUSDTokenWallet): Promise<any> => {
    const url = "wallets/token/debit";
    const res = await axiosInstance.post(url, payload);
    return res.data;
};

/**
 * Description
 * @param {any} payload:TDebitAndUSDTokenWallet
 * @returns {any}
 */
export const DebitUSDWalletApi = async (payload: TDebitAndUSDTokenWallet): Promise<any> => {
    const url = "wallets/usd/debit";
    const res = await axiosInstance.post(url, payload);
    return res.data;
};

/**
 * Description
 * @param {any} payload:TSafelockWalletFunds
 * @returns {any}
 */
export const SafelockWalletFundsApi = async (payload: any): Promise<any> => {
    const url = "wallets/create-safelock";
    const res = await axiosInstance.post(url, payload);
    return res.data;
};

/**
 * Description: Get Safelock Wallet
 * @returns {any}
 */
export const SafelockWalletApi = async (): Promise<any> => {
    const url = "wallets/safelock";
    const res = await axiosInstance.get(url);
    return res.data;
};

/**
 * Description: Get All Banks
 * @returns {any}
 */
export const GetBanksApi = async (): Promise<any> => {
    const url = "payment/banks";
    const res = await axiosInstance.get(url);
    return res.data.data;
};

/**
 * Description: Get All Banks
 * @returns {any}
 */
export const AccountLookup = async (accountNumber: string, bankCode: string): Promise<any> => {
    const url = `/payment/account/lookup?account_number=${accountNumber}&bank_code=${bankCode}`;
    const res = await axiosInstance.get(url);
    return res.data;
};

/**
 * Description
 * @param {any} payload:TWithdrawFromSafelockToFiat
 * @returns {any}
 */
export const WithdrawFromSafelockToFiatApi = async (payload: { amount: number }, pocketId: any): Promise<any> => {
    const url = `wallets/safelock/withdraw?pocketId=${pocketId}`;
    const res = await axiosInstance.post(url, payload);
    return res.data;
};

/**
 * Description
 * @param {any} payload:TWithdrawFromSafelockToFiat
 * @returns {any}
 */
export const WithdrawToExternalBank = async (payload: WithdrawToExternalBankType): Promise<any> => {
    const url = "payment/withdraw";
    const res = await axiosInstance.post(url, payload);
    return res.data;
};

export const getCardTransactionStatusApi = async (reference: string): Promise<any> => {
    const url = `payment/paystack/transaction?reference=${reference}`;
    const res = await axiosInstance.get(url);
    return res.data;
};
