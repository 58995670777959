import React from "react";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";

interface InvestmentProps {
    cancel: () => void;
    accept: () => void;
    accountType: string;
}

const TermsAndCondition = ({ cancel, accept, accountType }: InvestmentProps) => (
    <div>
        {accountType === "developer" ? (
            <div>
                <h2 className="text-assetize-primary text-2xl font-latoBold text-center align-center mt-8">
                    Terms and Condition for Developers
                </h2>
                <div className="border border-b border-assetize-gray pb-3 rounded p-2">
                    <p className="text-assetize-dark-gray  text-md align-center text-md w-full mt-5">
                        <p className="font-latoBold py-2"> 1. Introduction</p>
                        Welcome to Assetize, a platform operated by Veritasi Homes & Properties Limited, a company registered under the
                        Companies and Allied Matters Act (CAMA) of Nigeria. These Terms and Conditions govern your use of our platform as a
                        real estate developer. By using our services, you agree to comply with these terms.
                        <p className="font-latoBold py-2"> 2. Developer Eligibility</p>
                        To use Assetize as a developer, you must meet the following eligibility criteria:
                        <br />
                        a. You must be a legal entity registered under Nigerian law.
                        <br />
                        b. You must have a valid Tax Identification Number (TIN) issued by the Federal Inland Revenue Service (FIRS).
                        <br />
                        c. You must provide accurate and up-to-date information about your real estate projects.
                        <br />
                        <p className="font-latoBold py-2">3. Tokenization Process</p>
                        a. By using Assetize, you can tokenize your real estate properties, converting them into digital shares or tokens.
                        <br />
                        b. You must provide accurate and complete information about the properties you intend to tokenize.
                        <br />
                        c. Assetize will facilitate the tokenization process, including documentation, legal compliance, and regulatory
                        approvals, in accordance with Nigerian regulations.
                        <br />
                        <p className="font-latoBold py-2"> 4. Responsibilities</p>
                        a. You are responsible for providing accurate and legally compliant information about your real estate projects.
                        <br />
                        b. You agree to comply with all applicable Nigerian laws, regulations, and industry standards when using Assetize.
                        <br />
                        c. You are responsible for ensuring that your real estate projects meet all necessary legal and regulatory
                        requirements.
                        <br />
                        d. You agree to provide the necessary documentation and information to Assetize for the tokenization process.
                        <br />
                        <p className="font-latoBold py-2"> 5. Fees and Compensation</p>
                        a. Assetize may charge fees for its services related to tokenization. These fees will be agreed upon in a separate
                        agreement between you and Assetize.
                        <br />
                        b. You agree to pay any applicable fees promptly in accordance with the terms of the agreement.
                        <br />
                        <p className="font-latoBold py-2"> 6. Data Protection</p>
                        a. You agree to comply with Nigerian data protection laws, including the Nigerian Data Protection Regulation (NDPR),
                        in handling any user data or information obtained through Assetize.
                        <br />
                        b. You must obtain appropriate consent from users for the collection and processing of their data, and you must
                        safeguard this data as required by law.
                        <br />
                        <p className="font-latoBold py-2"> 7. Termination</p>
                        a. Assetize reserves the right to terminate or suspend your developer account if you violate these Terms and
                        Conditions or any applicable laws and regulations.
                        <br />
                        b. You may also terminate your account with Assetize at any time, subject to any contractual obligations.
                        <br />
                        <p className="font-latoBold py-2"> 8. Dispute Resolution</p>
                        a. Any disputes arising from these Terms and Conditions will be resolved in accordance with Nigerian commercial
                        dispute resolution procedures.
                        <br />
                        <p className="font-latoBold py-2"> 9. Changes to Terms and Conditions</p>
                        a. Assetize reserves the right to update these Terms and Conditions. Any changes will be communicated to you in
                        advance, and your continued use of the platform after such changes will constitute your acceptance of the updated
                        terms.
                        <br />
                        <p className="font-latoBold py-2"> 10. Governing Law</p>
                        a. These Terms and Conditions are governed by Nigerian law and are subject to the exclusive jurisdiction of Nigerian
                        courts.
                        <br />
                        <br />
                        By using Assetize as a developer, you acknowledge that you have read, understood, and agreed to these Terms and
                        Conditions.
                        <br />
                        <br />
                    </p>
                </div>
                <div className="w-full flex justify-center mt-5">
                    <div className="flex-[.45] pr-5">
                        <OutlineButton onClick={cancel}>Cancel</OutlineButton>
                    </div>
                    <div className="flex-[.45] pl-5">
                        <MainButton onClick={accept}>Agree</MainButton>
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <h2 className="text-assetize-primary text-2xl font-latoBold text-center align-center mt-8">
                    Terms and Condition for Investors
                </h2>
                <div className="border border-b border-assetize-gray pb-3 rounded p-2">
                    <p className="text-assetize-dark-gray  text-md align-center text-md w-full mt-5">
                        <p className="font-latoBold py-2"> Introduction</p>
                        Welcome to Assetize, a platform operated by Veritasi Homes & Properties Limited, a company registered under the
                        Companies and Allied Matters Act (CAMA) of Nigeria. By using this platform, you agree to comply with these Terms and
                        Conditions, which are governed by Nigerian law.
                        <p className="font-latoBold py-2"> User Eligibility</p>
                        You must be at least 18 years old, in compliance with the legal age of majority in Nigeria, and have the legal
                        capacity to enter into binding contracts under Nigerian law to use our platform.
                        <p className="font-latoBold py-2"> Token Ownership</p>
                        When investing in real estate properties through Assetize, you purchase digital tokens representing fractional
                        ownership of the underlying property. These tokens do not confer any direct ownership rights over the physical
                        property itself, by Nigerian property laws.
                        <p className="font-latoBold py-2"> Investment Risks</p>
                        Investing in real estate carries risks, and the value of your investment can go up or down. Assetize does not
                        guarantee any returns on your investments and is not responsible for any losses incurred. Please make sure you know
                        about the risks associated with real estate investments, as per guidelines from the Nigerian Securities and Exchange
                        Commission (SEC).
                        <p className="font-latoBold py-2">Security </p>
                        We take the security of your investments and personal information seriously, adhering to Nigerian data protection
                        laws. However, you are responsible for keeping your login credentials secure. Report any suspicious activity
                        immediately, by Nigerian cybersecurity laws.
                        <p className="font-latoBold py-2"> Fees</p>
                        Assetize may charge fees for its services, and these fees will be disclosed to you before you invest, in compliance
                        with Nigerian consumer protection laws.
                        <p className="font-latoBold py-2"> Legal Compliance</p>
                        You must comply with all applicable Nigerian laws and regulations when using Assetize, including tax laws and
                        regulations set forth by the Federal Inland Revenue Service (FIRS).
                        <br />
                        <p className="font-latoBold py-2">Termination</p>
                        Assetize reserves the right to terminate or suspend your account for any violation of these Terms and Conditions, by
                        Nigerian contract law.
                        <br />
                        <p className="font-latoBold py-2">Disclaimer of Warranties</p>
                        Assetize makes no warranties regarding the accuracy, reliability, or completeness of any information provided on the
                        platform, by Nigerian consumer protection laws.
                        <br />
                        Terms and Conditions are governed by the laws of Nigeria and are subject to the jurisdiction of Nigerian courts, by
                        Nigerian contract law.
                        <br />
                        <br />
                    </p>
                </div>
                <div className="w-full flex justify-center mt-5">
                    <div className="flex-[.45] pr-5">
                        <OutlineButton onClick={cancel}>Cancel</OutlineButton>
                    </div>
                    <div className="flex-[.45] pl-5">
                        <MainButton onClick={accept}>Agree</MainButton>
                    </div>
                </div>
            </div>
        )}
    </div>
);

export default TermsAndCondition;
