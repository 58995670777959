/* eslint-disable no-useless-catch */
import axiosInstance from "Utils/Https";

/**
 * Description
 * @returns {any}
 */
export const ViewPortfolioApi = async (): Promise<any> => {
    try {
        const url = "users/portfolio";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @param {any} payload:any
 * @returns {any}
 */
export const AddNewPropertyListingApi = async (payload: any, uploadedImages: any[]): Promise<any> => {
    try {
        const url = "developer/add-new-listing";
        const formData = new FormData();

        Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key]);
        });

        formData.append("availabilityStatus", "AVAILABLE");
        formData.append("additionalInfo", "For more details or to schedule a viewing, please contact our sales team.");

        uploadedImages.forEach((image, index) => {
            formData.append(`propertyImages`, image);
        });

        const res = await axiosInstance.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const EditListedProperty = async (payload: any, id: string, uploadedImages: any[]): Promise<any> => {
    try {
        const url = `developer/${id}/edit-property`;
        const formData = new FormData();

        Object.keys(payload).forEach((key) => {
            formData.append(key, payload[key]);
        });

        formData.append("availabilityStatus", "AVAILABLE");
        formData.append("additionalInfo", "For more details or to schedule a viewing, please contact our sales team.");

        uploadedImages.forEach((image, index) => {
            formData.append(`propertyImages`, image);
        });

        const res = await axiosInstance.put(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @returns {any}
 */
export const ViewAllListedPropertiesApi = async (): Promise<any> => {
    try {
        const url = "developer/my-listings?page=1";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err: any) {
        throw err;
    }
};

/**
 * Description
 * @returns {any}
 */
export const ListAllPropertiesApi = async (): Promise<any> => {
    try {
        const url = "propertys";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @returns {any}
 */
export const PropertyDashboardApi = async (): Promise<any> => {
    try {
        const url = "propertys";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetPropertyOfTheWeekApi = async (): Promise<any> => {
    try {
        const url = "property/property-of-the-week";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetRecommendedPropertyApi = async (): Promise<any> => {
    try {
        const url = "property/recommended-for-me?page=1&limit=12";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetFastSellingPropertyApi = async (): Promise<any> => {
    try {
        const url = "property/fast-selling-properties";
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (err) {
        throw err;
    }
};

/**
 * Description
 * @returns {any}
 */
export const GetMyPortfoilio = async (num?: number) => {
    try {
        const url = `investor/my-properties?page=${num}`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description
 * @returns {any}
 */
export const BuyShares = async (payload: any): Promise<any> => {
    try {
        const url = `market/properties/${payload.id}/invest `;
        const res = await axiosInstance.post(url, payload);
    } catch (error) {
        throw error;
    }
};

export const GetDeveloperPropertyListingApi = async (num?: number) => {
    try {
        const url = `developer/my-listings?page=${num}&limit=12`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const DeactivateLsting = async (id: string) => {
    try {
        const url = `propertys/${id}/deactivate-listing `;
        const res = await axiosInstance.patch(url, {
            availabilityStatus: ""
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetInvestorsInThisProperty = async (id: string) => {
    try {
        const url = `developer/properties/investors`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetOrders = async () => {
    try {
        const url = `developer/order-list`;
        const res = await axiosInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const InitiateWithdrawal = async () => {
    try {
        const url = `developer/withdraw`;
        const res = await axiosInstance.post(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetDevelopersAnalytics = async (timeFrame: string) => {
    try {
        const url = `developer/dashboard?timeFrame=${timeFrame}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetPropertyAnalytics = async (id: string, timeFrame: string) => {
    try {
        const url = `developer/property/${id}/performance?timeFrame=${timeFrame}`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const GetDeveloperData = async () => {
    try {
        const url = `developer/internal/kyc-docs`;
        const res = await axiosInstance.get(url);

        return res.data;
    } catch (error) {
        throw error;
    }
};
