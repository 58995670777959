import { OptionsProps, Status, WalletTransactionProps } from "types/data";
import React, { useEffect, useState } from "react";
import { AuthActionStatus, DeveloperType } from "types/Auth.types";
import { DotsWithBg, ThreeDotsVertical } from "Assets/Index";
import MenuList, { Option } from "Components/elements/Menu";
import { useNavigate } from "react-router-dom";
import ModalPanel from "Components/elements/Modal/Index";
import AnswerModal from "./AsnwerModal";

interface AuthenticationProps {
    item: DeveloperType;
}

const AuthenticationCard = ({ item }: AuthenticationProps) => {
    const { actionStatus, createdAt, id, metaData, userId } = item;
    const navigate = useNavigate();
    const [createdAtDate, setCreatedAtDate] = useState("");
    const [showModal, setSHowModal] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState({
        open: false,
        action: ""
    });
    useEffect(() => {
        const requestDate = new Date(createdAt);
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "long",
            year: "numeric"
        };
        const formatedDate = requestDate.toLocaleDateString("en-US", options);
        setCreatedAtDate(formatedDate);
    }, [createdAt]);

    const options: OptionsProps[] = [
        { text: "View Details", value: "view" },
        { text: "Approve", value: "approve" },
        { text: "Reject", value: "reject" }
    ];

    const handleOptionSelect = async (option: Option) => {
        if (option?.value === "view") {
            navigate(`${id}`);
        } else if (option?.value === "approve") {
            setShowReplyModal({
                open: true,
                action: "approve"
            });
        } else {
            setShowReplyModal({
                open: true,
                action: "reject"
            });
        }
    };

    let statusText;

    if (actionStatus === AuthActionStatus.APPROVED) {
        statusText = (
            <div className="bg-assetize-green-gradient   w-fit  p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-green-text">Verified</p>
            </div>
        );
    } else if (actionStatus === AuthActionStatus.REJECTED) {
        statusText = (
            <div className="bg-assetize-primary-gradient   w-fit   p-1 rounded-2xl">
                <p className="px-2 text-sm text-assetize-primary">Rejected</p>
            </div>
        );
    } else {
        statusText = (
            <div className="bg-assetize-warning-gradient   w-fit    p-1 rounded-2xl">
                <p className="text-[#F39B0D] text-sm px-2">pending</p>
            </div>
        );
    }

    return (
        <div>
            <div className="hidden md:flex items-center justify-between w-full p-2 px-4 py-5 my-2 border rounded border-assetize-gray">
                <h3 className="text-md font-latoRegular capitalize text-start flex justify-start w-[20%] text-sm">#{userId}</h3>
                <h3 className="text-md font-latoRegular capitalize text-sm flex justify-start w-[30%]">{metaData?.developerName}</h3>
                <h3 className="text-md font-latoRegular capitalize text-sm flex justify-start w-[30%] ">{createdAtDate}</h3>
                <div className="w-[20%] justify-start">{statusText}</div>
                <div className="text-md font-latoRegular  capitalize underline w-[10%] flex justify-start ">
                    <div className="">
                        <MenuList
                            options={options}
                            size="28"
                            onSelect={handleOptionSelect}
                            icon={DotsWithBg}
                            bg="white"
                            textColor="assetize-dark-gray"
                        />
                    </div>
                </div>

                <ModalPanel open={showReplyModal.open} closeButton closeModal={() => setShowReplyModal({ open: false, action: "" })}>
                    <AnswerModal
                        action={showReplyModal.action}
                        notificationId={item.id.toString()}
                        developerId={userId?.toString()}
                        isHome
                    />
                </ModalPanel>
            </div>
            <div className="flex md:hidden">
                <div className="flex items-center justify-between w-full px-4 py-5 my-2 border rounded border-assetize-gray">
                    <h3 className="text-md font-latoRegular capitalize text-center flex justify-center flex-[.2] text-sm">#{userId}</h3>
                    <div className="text-center w-1/2 flex gap-3 items-center flex-col">
                        <h4 className=" flex-[.1] text-md font-latoRegular capitalize flex justify-center text-sm">
                            {metaData?.developerName}
                        </h4>
                        <div className="flex justify-between text-sm font-bold text-dark">{statusText}</div>
                    </div>
                    <div className="items-end w-1/2 flex gap-3 flex-col">
                        <h4 className="flex-[.1] text-md font-latoRegular capitalize text-sm flex justify-start  ">{createdAtDate}</h4>
                        <div className="text-md font-latoRegular  capitalize underline flex-[.2] flex justify-center ">
                            <div className="">
                                <MenuList
                                    options={options}
                                    size="28"
                                    onSelect={handleOptionSelect}
                                    icon={DotsWithBg}
                                    bg="white"
                                    textColor="assetize-dark-gray"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthenticationCard;
