import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetLearnDetailApi } from "Services";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import GoBack from "Components/elements/Display/GoBack";
import ModalPanel from "Components/elements/Modal/Index";
import AnswerModal from "./AnswerModal";
import { BackIcon, CarretDown, ChevronDown, Forwardicon } from "Assets/Index";
import { BlogProps, OptionsProps } from "types/data";
import { readableDate } from "Utils/Helpers";
import MenuList, { Option } from "Components/elements/Menu";
import { Switch } from "@headlessui/react";
import WrappedMenu from "Components/elements/Menu/WrappedMenu";

const AdminLearnDetails = () => {
    const { id } = useParams();
    const [blogDetails, setBlogDetails] = useState<BlogProps | null>(null);
    const { user } = useSelector((state: any) => state.auth);
    const [showReplyModal, setShowReplyModal] = useState({ open: false, action: "" });
    const [enabled, setEnabled] = useState(false);
    const navigate = useNavigate();
    const [pageId, setPageId] = useState<number>(1);

    const fetchBlog = async () => {
        try {
            const res = await GetLearnDetailApi(id);
            setBlogDetails(res);
        } catch (error) {
            toast.error("Error fetching articles");
        }
    };

    useEffect(() => {
        fetchBlog();
        setPageId(Number(id));
    }, [id]);

    const navigateToPrevious = () => navigate(`/admin/learn/${pageId - 1}`);
    const navigateToNext = () => navigate(`/admin/learn/${pageId + 1}`);

    const options: OptionsProps[] = [
        { text: "Edit post", value: "edit" },
        { text: "Delete post", value: "delete" }
    ];

    const handleOptionsAvailableSelect = (option: Option) => {
        if (option?.value === "edit") {
            navigate(`/admin/learn/edit/${id}`);
        } else if (option?.value === "delete") {
            setShowReplyModal({
                open: true,
                action: "delete"
            });
        }
    };

    const handleOptionSelect = (option: Option) => {
        if (option?.value === "edit") {
            navigate(`/admin/learn/edit/${id}`);
        } else if (option?.value === "delete") {
            setShowReplyModal({ open: true, action: "delete" });
        }
    };

    return (
        <div className="bg-white">
            <GoBack link="/admin/learn" />

            <div className="w-full flex flex-col">
                <div className="flex w-[85%] justify-end py-4">
                    <div className="flex flex-col space-y-2">
                        <div className="bg-assetize-primary w-[180px] rounded-lg ml-2 lg:ml-5">
                            <WrappedMenu
                                options={options}
                                onSelect={handleOptionsAvailableSelect}
                                icon={CarretDown}
                                bg="white"
                                size="40"
                                textColor="assetize-dark-gray"
                                child={<div className="text-white">Choose Option </div>}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-[85%] flex-col mt-5 items-center justify-center mx-auto">
                    <h3 className="text-2xl text-black  font-latoBold py-2 text-center">{blogDetails?.title}</h3>
                    <img src={blogDetails?.headerImage} alt="blog" className="rounded my-6 h-[350px] w-full object-cover" />
                    <div className="w-full ">
                        <p className="text-sm text-assetize-light-text font-latoRegular">{blogDetails?.content}</p>
                    </div>
                    <div className={`flex w-full items-center my-10 ${pageId > 1 ? "justify-between" : "justify-end"}`}>
                        {pageId > 1 && (
                            <div className="flex-[.2]">
                                <OutlineButton onClick={navigateToPrevious} color="assetize-dark-gray">
                                    <div className="flex justify-center items-center px-4">
                                        <BackIcon className="w-6" />
                                        <span className="ml-2 text-assetize-dark-gray text-md">Previous</span>
                                    </div>
                                </OutlineButton>
                            </div>
                        )}
                        <div className="flex-[.2] self-end flex">
                            <MainButton onClick={navigateToNext}>
                                <div className="flex justify-center items-center px-4">
                                    <span className="mr-2 text-md">Next</span> <Forwardicon className="w-6" />
                                </div>
                            </MainButton>
                        </div>
                    </div>
                </div>
            </div>

            <ModalPanel open={showReplyModal.open} closeButton closeModal={() => setShowReplyModal({ open: false, action: "" })}>
                <AnswerModal
                    action={showReplyModal.action}
                    id={id as string}
                    isHome={false}
                    closeModal={() => setShowReplyModal({ open: false, action: "" })}
                />
            </ModalPanel>
        </div>
    );
};

export default AdminLearnDetails;
