/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */

import React, { Key, useEffect, useState } from "react";
import SubHeader from "Components/blocks/SubHeader";
import ActionCard from "Components/elements/Display/ActionCard";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { DashboardStyle, PropertyTypes, investActions } from "../../types/data";
import PropertyCard from "./PropertyCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetMarketPlaceFilterApi } from "Services";
import { NotFoundImg, ChevronRIght, GoBack as GoBackIcon, NewLogoColored } from "Assets/Index";
import { ScaleLoader, ClipLoader } from "react-spinners";
import ModalPanel from "Components/elements/Modal/Index";
import MobileFilter from "./MobileFilter";
import Pagination from "rsuite/esm/Pagination/Pagination";
import { searchArray } from "Utils/Helpers";

function Invest() {
    const [dashboardStyle, setDashboardStyle] = useState(DashboardStyle.grid);
    const [loading, setLoading] = useState(false);
    // const allProperties = useSelector((state: RootState) => state.properties.properties);

    const [searchParam, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState("");
    const [searchBox, setSearchBox] = useState("");
    const [propertyTypeFilter, setPropertyTypeFilter] = useState("");
    const [investmentTypeFilter, setInvestmentTypeFilter] = useState("");
    const [statusTypeFilter, setStatusTypeFilter] = useState("");
    // const [filteredProperties, setFilteredProperties] = useState([]);
    const [allProperties, setAllProperties] = useState([]);
    const [displayList, setDisplayList] = useState<any[]>([]);
    const [isFiltering, setIsFiltering] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);

    useEffect(() => {
        if (searchParam.get("propertyType")) {
            setPropertyTypeFilter(searchParam.get("propertyType") || "");
        }

        if (searchParam.get("investmentType")) {
            setInvestmentTypeFilter(searchParam.get("investmentType") || "");
        }
        if (searchParam.get("statusType")) {
            setStatusTypeFilter(searchParam.get("statusType") || "");
        }
    }, []);

    const fetchProperties = async () => {
        setLoading(true);

        try {
            let filterString = `propertyType=${propertyTypeFilter}`;
            if (investmentTypeFilter) {
                filterString = `${filterString}&investmentType=${investmentTypeFilter}`;
            }
            if (statusTypeFilter) {
                filterString = `${filterString}&availabilityStatus=${statusTypeFilter}`;
            }

            const filteredProperties = await GetMarketPlaceFilterApi(filterString);
            if ("propertiesWithGallery" in filteredProperties) {
                setAllProperties(filteredProperties.propertiesWithGallery);
            } else if (Array.isArray(filteredProperties)) {
                setAllProperties(filteredProperties as any);
            }
        } catch (err: any) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const filterProperties = async () => {
            setIsFiltering(true);
            try {
                let filterString = `propertyType=${propertyTypeFilter}`;

                if (investmentTypeFilter) {
                    filterString = `${filterString}&investmentType=${investmentTypeFilter}`;
                }
                if (statusTypeFilter) {
                    filterString = `${filterString}&availabilityStatus=${statusTypeFilter}`;
                }

                const filteredProperties = await GetMarketPlaceFilterApi(filterString);

                if ("propertiesWithGallery" in filteredProperties) {
                    setAllProperties(filteredProperties.propertiesWithGallery);
                    setDisplayList(filteredProperties.propertiesWithGallery);
                } else if (Array.isArray(filteredProperties)) {
                    setAllProperties(filteredProperties as any);
                    setDisplayList(filteredProperties as any);
                }
            } catch (err: any) {
                setIsFiltering(false);
            } finally {
                setIsFiltering(false);
            }
        };
        filterProperties();
    }, [propertyTypeFilter, investmentTypeFilter, statusTypeFilter]);

    useEffect(() => {
        if (search === "") {
            setDisplayList(allProperties);
        } else {
            const searchResult = searchArray(allProperties, search);

            setDisplayList(searchResult);
        }
    }, [search, allProperties]);

    const navigate = useNavigate();

    return (
        <div>
            {loading ? (
                <div className="flex items-center justify-center mt-64">
                    <ScaleLoader color="#ca1611" />
                </div>
            ) : (
                <div className="container mx-auto">
                    <div className="hidden md:flex">
                        <SubHeader
                            child={
                                <div
                                    className="flex justify-start items-center cursor-pointer"
                                    onClick={() => navigate(-1)}
                                    onKeyDown={() => navigate(-1)}
                                >
                                    <GoBackIcon className="w-8 h-8 pl-3  text-assetize-primary" />
                                    <p className="ml-2 font-latoBold font-[18px]">Back</p>
                                </div>
                            }
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            dashboardStyle={dashboardStyle}
                            setDashboardStyle={setDashboardStyle}
                            headerText="Find the Best Property"
                            canChangeStyle
                            showSearch
                            propertyType
                            investmentType
                            updatePropertyTypeFilter={(value: string) => setPropertyTypeFilter(value)}
                            updateInvestmentTypeFilter={(value: string) => {
                                const valueToSet = value === "Rental" ? "RENTAL" : "NON_RENTAL";
                                setInvestmentTypeFilter(valueToSet);
                            }}
                            updateSoldOutStatusFilter={(value: string) => {
                                let valueToSet = "";
                                if (value === "Re-sale") {
                                    valueToSet = "RESALE";
                                } else if (value === "Available") {
                                    valueToSet = "AVAILABLE";
                                } else {
                                    valueToSet = "ALL";
                                }
                                setStatusTypeFilter(valueToSet);
                            }}
                            onSearch={(value?: string) => {
                                if (value !== undefined && value !== "") {
                                    setSearchBox(value);
                                } else if (value === "") {
                                    setDisplayList(allProperties);
                                }
                            }}
                            status
                            filterBtn={false}
                            filterOperation={() => fetchProperties()}
                        />
                    </div>
                    <div className="flex md:hidden">
                        <SubHeader
                            child={
                                <div
                                    className="flex justify-start items-center cursor-pointer"
                                    onClick={() => navigate(-1)}
                                    onKeyDown={() => navigate(-1)}
                                >
                                    <GoBackIcon className="w-8 h-8 pl-3  text-assetize-primary" />
                                    <p className="ml-2 font-latoBold font-[18px]">Back</p>
                                </div>
                            }
                            onChange={(e) => setSearch(e.target.value)}
                            dashboardStyle={dashboardStyle}
                            setDashboardStyle={setDashboardStyle}
                            headerText="Find the Best Property"
                            canChangeStyle
                            filterBtn
                            propertyType
                            investmentType
                            updatePropertyTypeFilter={(value: string) => setPropertyTypeFilter(value)}
                            updateInvestmentTypeFilter={(value: string) => {
                                const valueToSet = value === "Rental" ? "RENTAL" : "NON_RENTAL";
                                setInvestmentTypeFilter(valueToSet);
                            }}
                            updateSoldOutStatusFilter={(value: string) => {
                                const valueToSet = value === "Re-sale" ? "SOLD_OUT" : "AVAILABLE";
                                setStatusTypeFilter(valueToSet);
                            }}
                            status
                            filterOperation={() => setOpenFilter(true)}
                        />
                    </div>
                    {isFiltering && (
                        <div className="w-full h-24 flex justify-center items-center">
                            <ClipLoader color="#ca1611" />
                        </div>
                    )}
                    <div
                        className={`${
                            dashboardStyle === DashboardStyle.grid ? "grid lg:grid-cols-3 xl:grid-cols-4" : "flex flex-col"
                        } p-2 md:p-2 mt-3 md:mt-3 w-full rounded gap-x-1 md:gap-x-2`}
                    >
                        {displayList.map((item) => (
                            <PropertyCard property={item} isGrid={dashboardStyle === DashboardStyle.grid} />
                        ))}
                    </div>
                    {displayList.length === 0 && (
                        <div className="flex flex-col items-center justify-center mt-20">
                            <img src={NewLogoColored} alt="Not Found" className="w-48" />
                            <p className="text-lg font-latoBold mt-5">
                                {isFiltering && "Loading properties"}
                                {!isFiltering && displayList.length === 0 && "No Properties Found"}
                            </p>
                        </div>
                    )}
                </div>
            )}
            <ModalPanel open={openFilter} closeButton closeModal={() => setOpenFilter(false)}>
                <MobileFilter
                    value={search}
                    closeModal={() => setOpenFilter(false)}
                    onChange={(e) => setSearch(e.target.value)}
                    showSearch
                    propertyType
                    investmentType
                    updatePropertyTypeFilter={(value: string) => setPropertyTypeFilter(value)}
                    updateInvestmentTypeFilter={(value: string) => {
                        const valueToSet = value === "Rental" ? "RENTAL" : "NON_RENTAL";
                        setInvestmentTypeFilter(valueToSet);
                    }}
                    updateSoldOutStatusFilter={(value: string) => {
                        const valueToSet = value === "Re-sale" ? "SOLD_OUT" : "AVAILABLE";
                        setStatusTypeFilter(valueToSet);
                    }}
                    onSearch={(value?: string) => {
                        if (value !== undefined && value !== "") {
                            setSearchBox(value);
                        } else if (value === "") {
                            setDisplayList(allProperties);
                        }
                    }}
                    status
                />
            </ModalPanel>
        </div>
    );
}

export default Invest;
function setDisplayList(searchResult: never[]) {
    throw new Error("Function not implemented.");
}
