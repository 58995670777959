import React from "react";
import { FiDownload, FiEye } from "react-icons/fi";
import { OutlineButton } from "../Button/Index";
import { convertToSentence } from "Utils/Helpers";

type Props = {
    document: string;
    name: string;
};

const DisplayAdminDocument = ({ document, name }: Props) => {
    const nameOfDocs = convertToSentence(name);
    return (
        <div className="my-1">
            <a
                href={document}
                className="flex items-center focus:text-assetize-dark justify-between px-4 py-4 rounded-lg cursor-pointer bg-[#ccc] text-assetize-dark hover:text-assetize-primary"
                key={document}
                download={document}
                target="_blank"
                rel="noreferrer"
            >
                <p className="fonnt-latoBold ">{nameOfDocs}</p>
                <span>
                    <OutlineButton className="md:w-56 w-40 border-[#4B4B4D] border bg-[#F9FAFB]" color="#4B4B4D">
                        <div className="flex items-center justify-around">
                            <FiEye className=" text-assetize-light-text" />
                            <p className="font-latoRegular text-sm  text-assetize-light-text">View document</p>
                        </div>
                    </OutlineButton>
                </span>
            </a>
        </div>
    );
};

export default DisplayAdminDocument;
