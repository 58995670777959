import React from "react";

interface PaginateProps {
    number?: number;
    isActive?: boolean;
    isGradient?: boolean;
    setPageToActive?: () => void;
}

const PaginateButton = ({ number, isActive, isGradient, setPageToActive }: PaginateProps) => (
    <button
        type="button"
        onClick={setPageToActive}
        className={`py-2 px-3 ${isActive ? "bg-assetize-primary " : "border border-assetize-light-text"} ${
            isGradient && "bg-opacity-5"
        } mx-2 rounded `}
    >
        <p className={`text-sm ${isActive ? "text-white " : "text-assetize-dark-gray"} `}>{number}</p>
    </button>
);

export default PaginateButton;
