import React from "react";

interface Option {
    value?: string;
    label?: string;
}

interface RadioProps {
    options: Option[];
    name?: string;
    selectedValue?: string;
    onChange: (value: string) => void;
}

const Radio: React.FC<RadioProps> = ({ options, name, selectedValue, onChange }) => {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const handleDivClick = (value: string) => {
        onChange(value);
    };

    return (
        <div>
            <fieldset>
                <div className="mt-6 space-y-6  ">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={`flex items-center gap-x-3 p-6 w-full  border rounded-lg ${
                                selectedValue === option.value ? "bg-assetize-primary-gradient border-assetize-primary" : "border-[#4B4B4D]"
                            }`}
                            onClick={() => handleDivClick(option.value as string)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    handleDivClick(option.value as string);
                                }
                            }}
                        >
                            <input
                                id={option.value}
                                name={name}
                                type="radio"
                                value={option.value}
                                checked={selectedValue === option.value}
                                className="h-4 w-4  border-[#4B4B4D] accent text-assetize-primary focus:ring-assetize-primary"
                                onChange={handleRadioChange}
                            />
                            <label
                                htmlFor={option.value}
                                className={`font-latoRegular block text-md md:text-lg font-medium leading-6 ${
                                    selectedValue === option.value ? "text-assetize-primary" : "text-gray-900"
                                }`}
                            >
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    );
};

export default Radio;
