import { Fail } from "Assets/Index";
import { MainButton, OutlineButton } from "Components/elements/Button/Index";
import React from "react";

const FundFailure = ({ onContinue }: { onContinue: () => void }) => (
    <div className="flex text-center flex-col px-5">
        <Fail />
        <h3 className="text-[#CA1611] text-xl self-center ml-5 font-latoBold my-3">Wallet Funding Failed</h3>
        <p className="text-latoRegular text-lg ml-5">Sorry. your Assetize wallet could not be funded</p>
        <div className="flex flex-col">
            <MainButton className="ml-5">Continue</MainButton>
        </div>
    </div>
);

export default FundFailure;
